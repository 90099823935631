import _ from 'lodash'

export const navigateToDeletedContract = (navigate, status, searchString) => {
  // const params = new URLSearchParams()
  // const searchString = params.toString()
  navigate(`/contractRecordDeleted/${searchString ? `?${searchString}` : ''}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
    }
  })
}
