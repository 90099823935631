import { convertToDayjs, parseDateObj } from '../../../common/util'
import _ from 'lodash'
import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import moment from 'moment'
import koKR from 'antd/es/date-picker/locale/ko_KR'
dayjs.locale('ko')

// DatePicker에서 영문을 한국어로 전환
export const customLocale = koKR

export const todayDate = dayjs().format('YYYY-MM-DD')

export const checkDatePickerFormat = (date) => {
  return (date !== null && date !== undefined) ? convertToDayjs(date) : null
}

export const formatMinutesToHHMM = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return moment(`${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`, 'HH:mm')
}

export const formatMinutesToMoment = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return moment(`${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`, 'HH:mm')
}
export const parseMetaToSelectOptions = v => ({ value: _.get(v, ['id']), label: _.get(v, ['name']) })

export const parseDateTimeToTime = (dateTime) => {
  if (dateTime === null || dateTime === undefined) {
    return
  }
  return dateTime.split(' ')[0]
}

export const convertTimestamp = (timestamp) => {
  if (timestamp === null || timestamp === undefined) {
    return
  }
  const date = new Date(timestamp)
  const hours = _.padStart(date.getHours(), 2, '0')
  const minutes = _.padStart(date.getMinutes(), 2, '0')
  return `${hours}:${minutes}`
}

// 계약 기록 > 계약 기록 페이지 상태 태그
const ContractStateContainer = styled.div`
  color: ${props => (props.$state === 'CONTRACT') ? `${COLORS.primaryColor}` : props.$state === 'PROGRESS' ? `${COLORS.green}` : props.$state === 'OVERDUE' ? `${COLORS.red}` : props.$state === 'NOCONTRACT' ? `${COLORS.orange}` : props.$state === 'CLOSE' ? `${COLORS.black}` : ''};
  border: 1px solid ${props => (props.$state === 'CONTRACT') ? `${COLORS.primaryColor}` : props.$state === 'PROGRESS' ? `${COLORS.green}` : props.$state === 'OVERDUE' ? `${COLORS.red}` : props.$state === 'NOCONTRACT' ? `${COLORS.orange}` : props.$state === 'CLOSE' ? `${COLORS.black}` : ''};
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 7px 0;
  width: 50px;
  font-size: 12px;
`

export const contractStateTag = (state) => {
  const check = state === 'CONTRACT' ? '체결' : state === 'PROGRESS' ? '진행' : state === 'OVERDUE' ? '추심' : state === 'NOCONTRACT' ? '미체결' : state === 'CLOSE' ? '종결' : ''
  return <ContractStateContainer $state={state}>{check}</ContractStateContainer>
}

export const roundDecimal = (value) => {
  const number = _.toNumber(value)
  if (value > -1 && value !== null) {
    return parseFloat(number.toFixed(2))
  } else {
    return 0
  }
}

export const getLabelByValue = (options, value) => {
  const option = _.find(options, { value })
  return option ? option.label : null
}

// 계약 기록 > 날짜 주기 개산
export const calculateDateDuration = (periodic, count, dueDate) => { // 주기 계산
  if (periodic === 'DAILY') {
    return _.range(count).map(i => dayjs(dueDate).add(i, 'day').format('YYYY-MM-DD'))
  } else if (periodic === 'WEEKLY') {
    return _.range(count).map(i => dayjs(dueDate).add(i, 'week').format('YYYY-MM-DD'))
  } else if (periodic === 'MONTHLY') {
    return _.range(count).map(i => dayjs(dueDate).add(i, 'month').format('YYYY-MM-DD'))
  } else { // YEARLY
    return _.range(count).map(i => dayjs(dueDate).add(i, 'year').format('YYYY-MM-DD'))
  }
}

export const calculatePaymentDuration = (count, dueAmount) => {
  let paymentArray = []
  let eachDueAmount
  if (dueAmount % count === 0) { // even
    eachDueAmount = dueAmount / count
    paymentArray = _.fill(Array(count), eachDueAmount)
  } else { // odd
    const roundedUpAmount = Math.round(dueAmount / count)
    const totalRounded = roundedUpAmount * count
    const remainder = totalRounded - dueAmount
    paymentArray = _.fill(Array(count), roundedUpAmount)
    paymentArray[count - 1] -= remainder
  }
  return paymentArray
}

// 콜기록 > 사건구분 선택됐는지 여부 확인
export const checkProejectTypeInput = (form, record, projectSubTypes, value = '') => {
  const formData = form.getFieldValue('call')
  let currentData = []
  if (formData !== undefined) {
    currentData = _.filter(formData, { id: record.id })
  }

  if (value.length > 0) {
    filterProjectSubtypes(form, record, projectSubTypes, value)
    return false
  } else {
    return !(currentData[0]?.projectType.id !== null)
  }
}

// 콜기록 > 세부사건구분 (사건구분 선택에따라 세부사건 필터)
export const filterProjectSubtypes = (form, record, projectSubTypes, projectType) => {
  return _.filter(projectSubTypes, { type: projectType })
}

export const checkDateTime = (date, time) => {
  if (date === null && time !== null) {
    return parseDateObj(new Date()) + ' ' + time
  } else if (date !== null && time === null) {
    return date + ' ' + '00:00:00'
  } else if (date === null && time === null) {
    return null
  } else {
    return date + ' ' + time
  }
}

export const fetchDate = (dateTime) => {
  if (dateTime !== null && dateTime !== undefined) {
    return dateTime.split(' ')[0]
  }
}

export const checkForNull = (filter, result) => {
  if (filter.length !== 0) {
    if (result === null) {
      return 0
    }
    return result
  } else if (result === null) {
    return 0
  } else {
    return 0
  }
}

export const formatArrayToComma = (array) => {
  return _.map(array, 'name').join(', ')
}

export const findUsersUsingIds = (users, ids) => {
  return _.filter(users, user => _.includes(ids, user.id))
}

export const parseToInt = (string) => {
  return parseInt(string, 10)
}

const totalReceivedAmount = (data) => {
  if (data !== undefined && data.length > 0) {
    return (
      data.reduce(
        (sum, deposit) => sum + (deposit.receivedAmount || 0),
        0
      )
    )
  } else {
    return 0
  }
}
export const formattedData = (value) => {
  return (
    _.map(_.groupBy(value, (record) => _.trim(record.periodicId)), (records, periodicId) => ({
      key: periodicId,
      id: records[0].id,
      periodicId,
      totalDueAmount: _.sumBy(records, 'dueAmount'),
      totalReceivedAmount: totalReceivedAmount(records[0].deposits),
      category: records[0].category,
      periodic: records[0].periodic,
      dueDate: records[0].dueDate,
      dueAmount: records[0].dueAmount,
      payments: _.map(records, (record) => ({
        key: record.id,
        dueDate: record.dueDate,
        dueAmount: record.dueAmount,
        deposits: record.deposits,
        category: record.category
      }))
    })))
}