import Folder from './Folder'
import _ from 'lodash'
import { Empty, Spin } from 'antd'
import { VirtualList } from './VirtualList'
import styled from 'styled-components'
import COLORS from '../../../../../common/Theme/colors'

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: ${COLORS.black_45};
  min-height: 100px;
  max-height: 160px;
`
export const CaseFiles = ({ readTimeDeltaPermission, virtualListRef, cases = [], totalCount, scrollRefetch, scrollDataParser, onDoubleClickFolder = () => {}, onCheckStar, loading, onInfiniteScrollUpdateCases }) => {
  if (_.isEmpty(cases)) {
    return (
      <EmptyContainer>
        {loading ? <Spin /> : <Empty />}
      </EmptyContainer>
    )
  }
  return (
    <VirtualList
      ref={virtualListRef}
      scrollId='TimesheetContainer'
      refetch={scrollRefetch}
      dataParser={scrollDataParser}
      loading={loading}
      onInfiniteScrollUpdateMeta={onInfiniteScrollUpdateCases}
      cases={cases}
      totalCount={totalCount}
      renderItem={({ key, id, ...props }) => {
        return (
          <Folder
            key={key}
            readTimeDeltaPermission={readTimeDeltaPermission}
            onDoubleClickFolder={() => onDoubleClickFolder(id)}
            onCheckStar={starred => onCheckStar(starred, key)}
            {...props}
          />
        )
      }}
    />
  )
}
