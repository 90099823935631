import _ from 'lodash'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
dayjs.locale('ko')

export const useDefaultDateRangeFilter = (meta, range = 'week', onResetDateTimeRange) => {
  useEffect(() => {
    if (range === 'week') {
      const curr = new Date()
      const first = curr.getDate() - curr.getDay() + 1
      const last = first + 6
      return onResetDateTimeRange([new Date().setDate(first), new Date().setDate(last)])
    } else { // month
      const lastDayOfThisMonth = new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0))
      return onResetDateTimeRange([dayjs(new Date().setDate(1)).startOf('day'), dayjs(lastDayOfThisMonth).endOf('day')])
    }
  }, [])
}
