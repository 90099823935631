import { Form } from 'antd'
import _ from 'lodash'

export const useDeleteScratchPad = (form, selectedRowKeys, setScratchPadList) => {
  const onDeleteScratchPad = () => { // 콜기록 > 임시저장
    let removedItems = []
    const data = JSON.parse(localStorage.getItem('scratchPad'))
    const removedData = _.filter(data, function (item) {
      if (_.includes(selectedRowKeys, item.key)) {
        removedItems.push(item)
        return false
      }
      return true
    })
    form.setFieldsValue({ scratchPad: removedData })
    localStorage.setItem('scratchPad', JSON.stringify(removedData))
    setScratchPadList(form.getFieldValue('scratchPad'))
  }

  return {
    onDeleteScratchPad
  }
}
