import Folder from './Folder'
import styled from 'styled-components'
import { Empty } from 'antd'
import _ from 'lodash'

const FolderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;
`

export const UserFiles = ({ users = [], onDoubleClickFolder = () => {} }) => {
  if (_.isEmpty(users)) return <Empty />
  return (
    <FolderContainer>
      {users.map(({ key, id, ...props }, i) => (
        <Folder key={i} onDoubleClickFolder={() => onDoubleClickFolder(id)} {...props} />
      ))}
    </FolderContainer>
  )
}
