import { useEffect, useRef } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import ClosedTag from '../../../../timeCharge/components/common/Tags/ClosedTag'
import CaseCategoryTag from '../../../../timeCharge/components/common/Tags/CaseCategoryTag'
import { Spacer } from '../../../../common/components/PageHeaderStyles'
import { CaseInfoButton, caseTableCommonProps, RowContainer } from '../../../../common/Theme/style'

import { useConfirmModal } from './useConfirmModal'
import { useCaseList } from '../../apollo/CaseList/useCaseList'
import { useCustomTable } from '../../common/useCustomTable'
import { useOpenCloseDeleteCases } from '../../apollo/mutations/useOpenCloseDeleteCases'

import { allocationOptions } from '../../../../common/config'
import { parseMinutesToHourMin, parseDateObj } from '../../../../common/util'
import { navigateToCaseForm } from '../../../cacheAndNavigation/caseForm/navigation'
import { navigateToCaseDetail } from '../../../cacheAndNavigation/caseDetail/navigation'

import styled from 'styled-components'
import COLORS from '../../../../common/Theme/colors'
import { ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Popover, Typography, Table } from 'antd'
import _ from 'lodash'

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`
const StyledButton = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
`

const FlatButton = styled(Button)`
  &.ant-btn {
    font-size: 18px;
    border: none;
    display: flex;
    align-items: center;
    width: 10px;
    justify-content: center;
    box-shadow: none;
    background: none;
  }
`
const TableComponent = styled(Table)`
  & colpsan > col:nth-child(2),
  & thead > tr > th:nth-child(2),
  & tbody > tr > td:nth-child(2) {
    min-width: 100px;
    max-width: 300px;
    width: 50%;
  }
`
const CaseNumber = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`
const WarningText = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  color: ${COLORS.red};
  font-size: 12px;
`

// 사건관리
export const useManageCaseTable = (categories, closed, requiresAllocation, searchQuery, userIds) => {
  const { permissions, userId } = useOutletContext()
  const allowedToCreateCase = _.includes(permissions, 'CREATE_PROJECT') || _.includes(permissions, 'CREATE_CONSULTATION') || _.includes(permissions, 'CREATE_ETC')
  const navigate = useNavigate()
  const tableProps = useCustomTable()
  const {
    page,
    limit,
    sortKey: _sortKey,
    sortAscending,
    selectedRowKeys,
    onChangePage
  } = tableProps
  const sortKey = _sortKey || 'UPDATED'
  const {
    cases,
    totalCount,
    loading,
    refetch
  } = useCaseList({
    categories,
    page,
    limit,
    sortKey,
    sortAscending,
    closed,
    requiresAllocation,
    permissions,
    caseSearchQuery: searchQuery,
    caseTableSelectedRowKeys: selectedRowKeys,
    userIds
  })
  const filter = {
    categories,
    closed,
    requiresAllocation,
    permissions,
    searchQuery
  }
  const prevFilter = useRef(filter)
  useEffect(() => {
    if (!_.isEqual(filter, prevFilter.current)) {
      prevFilter.current = filter
      onChangePage(1)
    }
  }, [filter])
  const {
    onCloseCases,
    onDeleteCases,
    onReopenCases,
    onRestoreCases
  } = useOpenCloseDeleteCases(refetch)

  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal,
    onConfirmModal
  } = useConfirmModal(
    onCloseCases,
    onDeleteCases,
    onReopenCases,
    onRestoreCases
  )

  // 유저의 권한에 따라 배당/위임 버튼의 유무에 따라 목록 수정 필요
  // variables
  const tableData = _.map(cases, v => ({
    ...v,
    key: _.get(v, ['id']),
    creator: _.get(v, ['createdBy', 'name'])
  }))
  // functions
  // table
  const onClickTableMenu = (menuKey, record, e) => {
    e.stopPropagation()
    const caseId = record.id
    if (menuKey === 'delete') {
      onOpenModal([caseId], 'delete')
    } else if (menuKey === 'manager') {
      onManageCaseRolls(caseId)
    } else if (menuKey === 'edit') {
      navigateToCaseForm(navigate, record.category2, 'editing', {
        ...record,
        caseId
      })
    } else if (menuKey === 'open') {
      onReopenCases([caseId])
    } else if (menuKey === 'closed') {
      onCloseCases([caseId])
    }
  }

  const onManageCaseRolls = (caseId) => navigate(`/manageCase/userAllocation/${caseId}`)

  const getAllocOptions = (showManager, record) => _.filter(allocationOptions, v => {
    if (v.key === 'manager') {
      return _.includes(permissions, 'ALLOCATE_OR_DELEGATE') || showManager
    } else if (v.key === 'edit') {
      return _.includes(permissions, 'UPDATE_PROJECT_DATA')
    } else if (v.key === 'delete') {
      // 권한 추가> 타임시트 삭제, 사건 삭제 (프런트 버튼 표시 룰 수정)
      return _.includes(permissions, 'UPDATE_PROJECT_DATA')
    } else if (v.key === 'open') {
      if (record.status === 'CLOSED') {
        return _.includes(permissions, 'REOPEN_PROJECT')
      } else {
        return false
      }
    } else if (v.key === 'closed') {
      if (record.status === 'OPEN') {
        return _.includes(permissions, 'CLOSE_PROJECT')
      } else {
        return false
      }
    } else return true
  })

  const formatDate = (data) => {
    return data ? parseDateObj(data, 'YYYY년 M월 D일') : ''
  }

  const caseFileUploadInvalidationMessage = (tag, warning) => {
    if (warning === 'ERR_NULL') {
      return '값을 입력해주세요.'
    } else if (warning === 'ERR_NOT_FOUND') {
      return `존재하지 않는 ${tag} 입니다.`
    } else if (warning === 'ERR_DUPLICATE') {
      return `중복된 ${tag}가 있습니다.`
    } else { // 'ERR_UNPROCESSABLE'
      return '입력 값을 수정해주세요.'
    }
  }

  const columns = [
    {
      title: '사건번호',
      dataIndex: 'caseNumber',
      key: 'PROJECT_NAME',
      sort: true,
      render: (value, record) => {
        return (
          <RowContainer>
            <CaseNumber>{value}</CaseNumber>
            <CaseCategoryTag category={record.category2} />
            {record.status === 'CLOSED' ? <ClosedTag /> : null}
            <Spacer />
            <Popover
              placement='right'
              content={
                <div>
                  <p><Typography.Text strong>사건번호:</Typography.Text> {record.caseNumber}</p>
                  <p><Typography.Text strong>사건분류: </Typography.Text>{record.category}</p>
                  <p><Typography.Text strong>생성자: </Typography.Text>{record.creator}</p>
                  <p><Typography.Text strong>생성일: </Typography.Text>{formatDate(record.created)}</p>
                  <p><Typography.Text strong>위임 인원: </Typography.Text>{record.delegates.length}</p>
                  <p><Typography.Text strong>배당 인원: </Typography.Text>{record.userCount}</p>
                </div>
              }
            >
              <CaseInfoButton>
                i
              </CaseInfoButton>
            </Popover>
          </RowContainer>
        )
      }
    },
    {
      title: '분류',
      dataIndex: 'category',
      key: 'CATEGORY'
    },
    {
      title: '담당자 수',
      dataIndex: 'userCount',
      key: 'N_USERS',
      sort: true
    },
    {
      title: '총 시간',
      dataIndex: 'totalMinutes',
      key: 'MINUTES',
      sort: true,
      render: v => <>{parseMinutesToHourMin(v)}</>
    },
    {
      title: '최근 타임시트 수정일',
      dataIndex: 'timesheetUpdated',
      key: 'TIMESHEET_UPDATED',
      sort: true,
      render: (v, record) => {
        return (
          <Typography.Text ellipsis style={caseTableCommonProps}>
            {formatDate(v)}
          </Typography.Text>
        )
      }
    },
    {
      title: '',
      dataIndex: 'userMenu',
      key: 'userMenu',
      width: 50,
      render: (_v, record) => {
        const showMangerButton = _.includes(_.map(_.get(record, ['delegates'], []), 'id'), userId)
        const allocOptions = getAllocOptions(showMangerButton, record)
        if (_.isEmpty(allocOptions)) return null
        return (
          <Popover
            onClick={(e) => {
              e.stopPropagation()
            }}
            trigger='click'
            placement='right'
            content={
              allocOptions.map(({ key, label, icon }) => {
                // 사건이 종결되어있을 경우
                return (
                  <ButtonContainer key={key}>
                    <StyledButton onClick={(e) => onClickTableMenu(key, record, e)}>
                      {icon}
                      {label}
                    </StyledButton>
                  </ButtonContainer>
                )
              })
            }
          >
            <FlatButton>
              <MoreOutlined />
            </FlatButton>
          </Popover>
        )
      }
    }
  ]

  const excelDisplayDataAndCheckValidation = (tag, value, valid, key, warning) => {
    return (
      <div>
        {value?.length > 0 && key !== 'delegatesValid' && key !== 'allocatesValid'
          ? (
            <Typography.Text ellipsis={{ tooltip: <>{value}</> }}>
              {value}
            </Typography.Text>
            )
          : null}
        {valid === false
          ? (
            <WarningText>
              <ExclamationCircleOutlined />
              {caseFileUploadInvalidationMessage(tag, warning)}
            </WarningText>)
          : ''}
      </div>
    )
  }
  const excelCompareTableColumns = [
    {
      title: '사건번호',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (name, record) => excelDisplayDataAndCheckValidation('사건번호', name, record.nameValid, 'nameValid', record.nameWarning)
    },
    {
      title: '분류',
      dataIndex: 'type',
      key: 'type',
      width: 170,
      render: (type, record) => excelDisplayDataAndCheckValidation('분류', type, record.typeValid, 'typeValid', record.typeWarning, record)
    },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'content',
      width: 220,
      render: (content, record) => excelDisplayDataAndCheckValidation('내용', content, record.contentValid, 'contentValid', record.contentWarning, record)
    },
    {
      title: '위임대상',
      dataIndex: 'delegates',
      key: 'delegates',
      width: 180,
      render: (delegates, record) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {delegates?.map((item, i) => {
                return (
                  <Typography.Text ellipsis={{ tooltip: <>{item}</> }} key={i}>{item}</Typography.Text>
                )
              })}
            </div>
            {record.delegatesValid === false
              ? (
                <WarningText>
                  {excelDisplayDataAndCheckValidation('계정', delegates, record.delegatesValid, 'delegatesValid', record.delegatesWarning, record)}
                </WarningText>)
              : ''}
          </>
        )
      }
    },
    {
      title: '배당대상',
      dataIndex: 'allocates',
      key: 'allocates',
      width: 180,
      render: (allocates, record) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {allocates?.map((item, i) => {
                return (
                  <Typography.Text ellipsis={{ tooltip: <>{item}</> }} key={i}>{item}</Typography.Text>
                )
              })}
            </div>
            {record.allocatesValid === false
              ? (
                <WarningText>
                  {/* <ExclamationCircleOutlined /> */}
                  {/* 수정 필요 */}
                  {excelDisplayDataAndCheckValidation('계정', allocates, record.allocatesValid, 'allocatesValid', record.allocatesWarning, record)}
                </WarningText>)
              : ''}
          </>
        )
      }
    },
    {
      title: '생성자',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 180,
      render: (createdBy, record) => excelDisplayDataAndCheckValidation('계정', createdBy, record.createdByValid, 'createdByValid', record.createdByWarning, record)
    },
    {
      title: '생성일',
      dataIndex: 'created',
      key: 'created',
      width: 130,
      render: (created, record) => excelDisplayDataAndCheckValidation('날짜', created, record.createdValid, 'createdValid', record.createdWarning, record)
    }
  ]

  const onClickRow = caseMeta => {
    navigateToCaseDetail(navigate, {
      caseNumber: _.get(caseMeta, ['caseNumber']),
      caseTag: _.get(caseMeta, ['caseType', 'name']),
      content: _.get(caseMeta, ['content'], ''),
      caseId: _.get(caseMeta, ['id'], ''),
      status: _.get(caseMeta, ['status'], '')
    })
  }

  return {
    confirmModalMeta,
    onConfirmModal,
    onCloseModal,
    ...tableProps,
    confirmModalTitle,
    total: totalCount,
    data: tableData,
    loading,
    columns,
    onClickRow,
    style: { flex: 1, cursor: 'pointer' },
    hiddenTableActionByKey: {
      stat: !(_.includes(permissions, 'READ_ALL_TIMESHEET') || _.includes(permissions, 'READ_SAME_DEPARTMENT_TIMESHEET')),
      // 권한 추가> 타임시트 삭제, 사건 삭제 (프런트 버튼 표시 룰 수정)
      deleteCases: !_.includes(permissions, 'UPDATE_PROJECT_DATA'),
      excelUpload: !allowedToCreateCase
    },
    disabledTableActionByKey: {
      deleteCases: _.isEmpty(selectedRowKeys),
      templateDownload: false
    },
    allowedToCreateCase,
    unit: '건',
    excelProps: {
      modalTitle: '사건추가',
      columns: excelCompareTableColumns,
      updatedText: '업데이트'
    },
    TableComponent,
    selectedRowKeys,
    refetch
  }
}
