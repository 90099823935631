import { getWindowHistoryMetaByKey } from '../../../timeCharge/cacheAndNavigation/common'

export const getRecordContentViewMode = () => {
  return getWindowHistoryMetaByKey('viewMode', true) || 'list'
}

export const getRecordContentGroupMode = () => {
  return getWindowHistoryMetaByKey('groupMode', true) || 'NAME'
}

export const getRecordContractGroupMode = () => {
  return getWindowHistoryMetaByKey('groupModeContract', true) || 'DEPARTMENT'
}

export const getRecordAllContractGroupMode = () => {
  return getWindowHistoryMetaByKey('groupModeAllContract', true) || 'DEPARTMENT'
}
