import { Form, Select } from 'antd'
import React, { useState } from 'react'
import { allPaymentCategoryOptions, expenseProofMethodOptions, paymentTypeOptions } from '../../config'
import { CustomDatePicker } from '../../components/common/CustomDatePicker'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { getLabelByValue } from '../../components/common/CommonFunction'
import { MoneyContainer } from '../../../common/Theme/style'
import COLORS from '../../../common/Theme/colors'
dayjs.locale('ko')

export const usePaymentCompleteColumns = (buttonKey, remain, index, fullPaymentForm, periodicId) => {
  const [selectDisabled, setSelectDisabled] = useState(true)

  const handleFieldChange = (value, fieldName) => {
    const currentValues = fullPaymentForm.getFieldsValue()
    const updatedValues = {
      ...currentValues,
      depositRecordList: {
        ...currentValues.depositRecordList,
        [periodicId]: {
          ...currentValues.depositRecordList[periodicId],
          [fieldName]: value
        }
      }
    }

    if (fieldName === 'type' && value === 'CARD') { // 입금 종류가 카드가 아닐 경우 지출증빙방식 reset
      updatedValues.depositRecordList[periodicId].expenseProofMethod = null
      setSelectDisabled(true)
    } else if (fieldName === 'type' && value !== 'CARD') {
      setSelectDisabled(false)
    }
    fullPaymentForm.setFieldsValue(updatedValues)
  }

  const paymentCompleteColumns = [
    {
      title: '금액 종류',
      dataIndex: 'category',
      key: 'category',
      render: (category) => {
        return (
          <Form.Item
            name={['depositRecordList', periodicId, 'category']}
          >
            <div bordered={false} color='processing' style={{ color: `${COLORS.primaryColor}` }}>
              {getLabelByValue(allPaymentCategoryOptions, category) + (index + 1)}
            </div>
          </Form.Item>
        )
      }
    },
    {
      title: '입금 종류',
      dataIndex: 'type',
      key: 'type',
      render: (type, record, index) => {
        return (
          <Form.Item
            name={['depositRecordList', periodicId, 'type']}
            rules={[{ required: true, message: '' }]}
          >
            <Select
              placeholder='선택'
              options={paymentTypeOptions}
              onChange={(value) => handleFieldChange(value, 'type')}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '입금 날짜',
      dataIndex: 'received',
      key: 'received',
      render: (received, record, index) => {
        return (
          <Form.Item
            name={['depositRecordList', periodicId, 'received']}
            rules={[{ required: false }]}
          >
            <CustomDatePicker
              date={dayjs()}
              onChange={(value) => handleFieldChange(value, 'received')}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '입금 금액',
      dataIndex: 'receivedAmount',
      key: 'receivedAmount',
      render: (receivedAmount, record) => {
        return (
          <MoneyContainer value={remain === null ? 0 : remain} />
        )
      }
    },
    {
      title: '지출증빙방식',
      dataIndex: 'expenseProofMethod',
      key: 'expenseProofMethod',
      render: (expenseProofMethod, record) => {
        return (
          <Form.Item
            name={['depositRecordList', periodicId, 'expenseProofMethod']}
          >
            <Select
              placeholder='선택'
              disabled={selectDisabled}
              options={expenseProofMethodOptions}
              onChange={(value) => handleFieldChange(value, 'expenseProofMethod')}
            />
          </Form.Item>
        )
      }
    }
  ]
  return {
    paymentCompleteColumns
  }
}
