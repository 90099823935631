import _ from 'lodash'
import styled from 'styled-components'
import { FiPlus } from 'react-icons/fi'
import { Button, Tag, Typography } from 'antd'
import { CloseCircleFilled, FilterOutlined } from '@ant-design/icons'

import COLORS from '../../../../common/Theme/colors'
import MultipleSelect from '../../common/MultipleSelect'
import { HeaderContainer as _HeaderContainer, HeaderGroup, Spacer } from '../../../../common/components/PageHeaderStyles'
import { useOutletContext } from 'react-router-dom'
import { RowContainer, RowGapWrapper } from '../../../../common/Theme/style'
import { CustomPopover } from '../../common/CustomPopover'
import { SearchParamsFilter } from '../../filters/SearchParamsFilter'
import { BaseText } from '../../../../common/util'

const checkedProps = { color: COLORS.primaryColor, borderColor: COLORS.primaryColor }

const TextContainer = styled.div`
  white-space: nowrap;
`
const StyledTag = styled(Tag)`
  display: flex;
  padding: 5px 11px;
  border: 1px solid ${COLORS.primaryColor};
  gap: 5px;
  color: ${COLORS.primaryColor};
  font-size: 13px;
`
const HeaderContainer = styled(_HeaderContainer)`
  justify-content: flex-end;
`
const UserTagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid ${COLORS.primaryColor};
  border-radius: 32px;
  padding: 5px 10px;
  gap: 10px;
  white-space: nowrap;
`

const UserTag = ({ label, onRemove }) => {
  return (
    <UserTagContainer
      onClick={e => e.preventDefault()}
      style={{
        ...checkedProps
      }}
      shape='round'
    >
      {label}
      <CloseCircleFilled style={{ cursor: 'pointer', color: `${COLORS.gray_2}` }} onClick={onRemove} />
    </UserTagContainer>
  )
}
export const BecomesTagOnSelect = ({ tagged, onSelect, onClose, permissions, children }) => {
  const name = _.includes(permissions, 'ALLOCATE_OR_DELEGATE') ? '배당 / 위임 필요 목록만 보기' : '배당 필요 목록만 보기'
  return (
    <>
      {tagged
        ? <StyledTag closable onClose={onClose}>{name}</StyledTag>
        : (
          <div onClick={onSelect} style={{ display: 'flex', gap: '5px' }}>
            {children}
          </div>
          )}
    </>
  )
}

export const ManageCaseTableContainerHeader = ({ filters = [], requiresAllocation, showCreateButton, onChangeRequiresAllocationFilter, onCreateCase = () => {}, openStatusByKey, departments }) => {
  const { permissions, userGroup } = useOutletContext()
  const flex = 30
  const showFilterUndelegatedCaseFilterButton = _.includes(permissions, 'ALLOCATE_OR_DELEGATE') && userGroup.name !== '직원' // 직원 계정에 '배당 필요 목록만 보기'버튼 불필요 하여 뜨지 않게 설정해주세요  04/05/2024 요청사항
  return (
    <HeaderContainer>
      <HeaderGroup>
        <RowGapWrapper>
          <FilterOutlined />
          <TextContainer>필터</TextContainer>
        </RowGapWrapper>
        {filters.map(({ value, key, icon, options = [], selectedItems, onChangeFilter, starredCaseListHidden, onOpenChange = () => {}, starredOptions = [], selectedOptions, onChangeDepartmentFilter = () => {}, selectedDepartmentOptions = [], unit, ...props }, index) => {
          const firstSelectedItem = _.find(selectedOptions, ({ value }) => value === _.get(selectedItems, [0]))
          const selectionDescription = `${firstSelectedItem?.name}${selectedItems?.length > 1 ? ` 외 ${selectedItems?.length - 1}${unit}` : ''}`
          const popoverWidth = value === '사건번호' ? 1100 : value === '작성자' ? 700 : 900

          return (
            (key === 'categories' || key === 'caseStatus')
              ? (
                <MultipleSelect
                  key={key}
                  style={{ minWidth: 120 }}
                  selectedValues={selectedItems}
                  options={options}
                  placeholder={value}
                  onSelectChange={v => onChangeFilter(v)}
                />
                )
              : (
                <RowContainer key={`CustomPopover_${index}`} style={{ alignItems: 'center', marginRight: '5px' }}>
                  <CustomPopover
                    icon={icon}
                    onOpenChange={onOpenChange}
                    style={{ width: popoverWidth }}
                    persistElement={<BaseText text={value} />}
                    triggerElement={<BaseText text={value} />}
                    content={
                      <SearchParamsFilter
                        selectedItems={selectedItems}
                        options={options}
                        starredOptions={starredOptions}
                        persistElement={<BaseText text={value} />}
                        onChange={e => onChangeFilter(e)}
                        onChangeDepartmentFilter={e => onChangeDepartmentFilter(e)}
                        selectedOptions={selectedOptions}
                        selectedDepartmentOptions={selectedDepartmentOptions}
                        openStatusByKey={openStatusByKey}
                        departments={departments}
                        {...props}
                      />
                    }
                  />
                  {firstSelectedItem ? <UserTag label={selectionDescription} onRemove={() => onChangeFilter([])} /> : null}
                </RowContainer>))
        })}
      </HeaderGroup>

      <Spacer />

      {showFilterUndelegatedCaseFilterButton
        ? (
          <HeaderGroup>
            <BecomesTagOnSelect
              tagged={requiresAllocation}
              onSelect={() => onChangeRequiresAllocationFilter(true)}
              onClose={() => onChangeRequiresAllocationFilter(false)}
              permissions={permissions}
            >
              {_.includes(permissions, 'ALLOCATE_OR_DELEGATE')

                ? (
                  <Button>
                    배당 / 위임 필요 목록만 보기
                  </Button>
                  )
                : _.includes(permissions, 'ALLOW_ALLOCATION')
                  ? (
                    <Button>
                      배당 필요 목록만 보기
                    </Button>
                    )
                  : null}
            </BecomesTagOnSelect>
          </HeaderGroup>
          )
        : null}

      <Spacer $flex={flex} />

      <HeaderGroup>
        {showCreateButton
          ? (
            <Button
              type='primary'
              style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              onClick={onCreateCase}
            >
              <FiPlus />
              사건 생성
            </Button>
            )
          : null}
      </HeaderGroup>
    </HeaderContainer>
  )
}
