import _ from 'lodash'
import { useCustomTable } from '../../common/useCustomTable'
import { useNavigate } from 'react-router-dom'
import { getRecordAllContractGroupMode, getRecordContentViewMode } from '../../../cacheAndNavigation/callRecord/cache'
import { useRecordFilterSearchParams } from '../../filter/callRecord/useRecordFilterSearchParams'
import { navigateToContractRecord } from '../../../cacheAndNavigation/paymentRecord/navigation'

export const usePaymentList = () => {
  const {
    meta: {
      filter: filterMeta
    },
    searchParams,
    getUpdatedSearchParams
  } = useRecordFilterSearchParams()
  const viewMode = getRecordContentViewMode() // for history

  const groupModeAllContract = getRecordAllContractGroupMode()
  const searchString = searchParams.toString()
  const navigate = useNavigate()
  const tableProps = useCustomTable()

  const {
    selectedRowKeys
  } = tableProps

  const disabledTableActionByKey = {
    copyRecord: _.isEmpty(selectedRowKeys),
    delete: _.isEmpty(selectedRowKeys),
    save: _.isEmpty(selectedRowKeys)
  }
  const hiddenTableActionByKey = {
    stat: false,
    excelDownload: false, 
    copyRecord: false,
    delete: false
  }
  const onChangeViewMode = v => {
    navigateToContractRecord(navigate, searchString, v)
  }
  const onChangeGroupMode = v => {
    navigateToContractRecord(navigate, searchString, 'group', v)
  }
  const onSelectFilter = (key, value) => {
    // user or case
    const newSearchParams = getUpdatedSearchParams('filter', key, value, searchParams)
    navigateToContractRecord(navigate, newSearchParams.toString(), viewMode)
  }
  return {
    viewMode,
    groupModeAllContract,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    onChangeViewMode,
    onChangeGroupMode,
    onSelectFilter,
    filterMeta,
    ...tableProps
  }
}
