import _ from 'lodash'

export const starIconWidth = 50
export const categoryWidth = 50
export const updatedWidth = 130
export const totalMinutesWidth = 80
export const minutesDifferenceWidth = 80
export const minutesAfterEditWidth = 80
export const iconDisplayWidth = 55

export const totalWidth = (isPartner, isDatetimerangeFilter, caseNumberWidth) => {
  const summedWidth = _.sum([
    starIconWidth,
    caseNumberWidth,
    categoryWidth,
    updatedWidth,
    totalMinutesWidth,
    isDatetimerangeFilter ? 0 : isPartner ? minutesDifferenceWidth : 0,
    isDatetimerangeFilter ? 0 : isPartner ? minutesAfterEditWidth : 0,
    iconDisplayWidth
  ])
  return summedWidth
}
