import { useMutation } from '@apollo/client'
import { delegateUsersToProject, abdicateUsersFromProject } from '../../../apollo/mutations'

export const useDelegateUsers = () => {
  const [allocateUsersToCaseFn] = useMutation(delegateUsersToProject)
  const [deallocateUsersFromCaseFn] = useMutation(abdicateUsersFromProject)

  const delegateUsers = (projectId, userIds) => allocateUsersToCaseFn({
    variables: {
      projectId,
      userIds
    }
  })

  const abdicateUsers = (projectId, userIds) => deallocateUsersFromCaseFn({
    variables: {
      projectId,
      userIds
    }
  })

  return {
    delegateUsers,
    abdicateUsers
  }
}
