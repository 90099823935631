import { RowGapWrapper, TextNoWrap } from '../../../common/Theme/style'
import { DateRangeFilter } from '../../../timeCharge/components/filters/DateRangeFilter'
import MultipleSelect from '../common/MultipleSelect'
import { Button, InputNumber } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import _ from 'lodash'

const CallRecordFilter = ({ viewMode, loading, meta, filters, onResetFilterAndGroup = () => {}, onChangeMeta = () => {}, border, onSelectFilter }) => {
  return (
    <div>
      <RowGapWrapper style={{ marginLeft: '15px', alignItems: 'center', padding: `${border === 'true' && '10px 15px'}`, borderBottom: `${border === 'true' && '1px solid #DFDFDF86'}` }}>
        <FilterOutlined />
        <TextNoWrap>필터</TextNoWrap>
        {viewMode !== 'calendar' && (
          <DateRangeFilter
            value={meta.filter.datetimeRange}
            onCalendarChange={v => onSelectFilter('datetimeRange', v)}
          />
        )}
        {_.filter(filters, ({ key }) => key !== '본사가 아닐 경우').map(({ key, selectedOptions, selectedItems, options, value, onChangeFilter, defaultValue, onChangeMeta, ...props }) => {
          return (
            (key !== 'price')
              ? (
                <MultipleSelect
                  multiple={false}
                  loading={loading}
                  key={key}
                  type={key}
                  style={{ minWidth: 200 }}
                  selectedOptions={selectedOptions}
                  selectedItems={selectedItems}
                  options={options}
                  placeholder={value}
                  onChangeFilter={v => onChangeFilter(v)}
                  pageType='call'
                  {...props}
                />)
              : (
                <InputNumber
                  key={key}
                  changeOnWheel
                  placeholder='0'
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  addonAfter='원'
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  style={{ minWidth: 120 }}
                />)
          )
        })}
        <Button onClick={onResetFilterAndGroup}>필터 초기화</Button>
      </RowGapWrapper>
    </div>
  )
}

export default CallRecordFilter
