import { useMutation } from '@apollo/client'
import { allocateUsersToCases, delegateUsersToProjects } from '../../../apollo/mutations'

export const useAllocateUsersToCases = () => {
  const [allocateUsersToCasesFn] = useMutation(allocateUsersToCases)
  const [delegateUsersToProjectsFn] = useMutation(delegateUsersToProjects)

  const allocateUsersToMultipleCases = (projectIds, userIds) => allocateUsersToCasesFn({
    variables: {
      projectIds,
      userIds
    }
  })

  const delegateUsersToMultipleProjects = (projectIds, userIds) => delegateUsersToProjectsFn({
    variables: {
      projectIds,
      userIds
    }
  })

  return {
    allocateUsersToMultipleCases,
    delegateUsersToMultipleProjects
  }
}
