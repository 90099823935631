import { useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { useConfirmModal } from './useConfirmModal'
import { useCustomTable } from '../../common/useCustomTable'
import { parseMinutesToHourMin, parseDateObj } from '../../../../common/util'
import { deserializeCaseList } from '../../../../common/fastapi/fetchers'
import { navigateToCaseDetail } from '../../../cacheAndNavigation/caseDetail/navigation'

import { Spacer } from '../../../../common/components/PageHeaderStyles'
import ClosedTag from '../../../../timeCharge/components/common/Tags/ClosedTag'
import { CaseInfoButton, caseTableCommonProps, RowContainer } from '../../../../common/Theme/style'
import CaseCategoryTag from '../../../../timeCharge/components/common/Tags/CaseCategoryTag'

import { useQuery } from '@apollo/client'
import { useDeletedCaseList } from '../../apollo/CaseList/useDeletedCaseList'
import { getCurrentUser } from '../../../apollo/queries'
import { useOpenCloseDeleteCases } from '../../apollo/mutations/useOpenCloseDeleteCases'

import styled from 'styled-components'
import { Button, Typography, message, Table, Popover } from 'antd'
import _ from 'lodash'

const TableComponent = styled(Table)`
  & colpsan > col:nth-child(2),
  & thead > tr > th:nth-child(2),
  & tbody > tr > td:nth-child(2) {
    min-width: 100px;
    max-width: 300px;
    width: 50%;
  }
`
const CaseNumber = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`

// 사건관리
export const useManageDeletedCaseTable = (categories, closed, allocatedUsers, delegatedUsers, deleted) => {
  const { permissions } = useOutletContext()
  const allowedToCreateCase = _.includes(permissions, 'CREATE_PROJECT') || _.includes(permissions, 'CREATE_CONSULTATION') || _.includes(permissions, 'CREATE_ETC')
  const navigate = useNavigate()
  const { data } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only'
  })
  const currentUser = _.get(data, ['CurrentUser'])
  const tableProps = useCustomTable()
  const {
    page,
    limit,
    sortKey: _sortKey,
    sortAscending,
    onChangePage
  } = tableProps
  const sortKey = _sortKey || 'TIMESHEET_UPDATED'
  const {
    cases,
    totalCount,
    loading,
    refetch
  } = useDeletedCaseList({
    categories,
    page,
    limit,
    sortKey,
    sortAscending,
    closed,
    allocatedUsers,
    delegatedUsers,
    permissions,
    deleted: true
  })
  useEffect(() => {
    onChangePage(1)
  }, [...categories, closed])
  const {
    onCloseCases,
    onDeleteCases,
    onReopenCases,
    onRestoreCases
  } = useOpenCloseDeleteCases(refetch)
  const {
    onCloseModal,
    onConfirmModal,
    confirmModalMeta,
    title: confirmModalTitle,
    onOpenModal
  } = useConfirmModal(
    onCloseCases,
    onDeleteCases,
    onReopenCases,
    onRestoreCases
  )
  const tableData = _.map(cases, v => ({
    ...v,
    key: _.get(v, ['id']),
    creator: _.get(v, ['createdBy', 'name']),
    userCount: _.get(v, ['userCount'])
  }))
  const onClickRecoveryCase = (record, e) => {
    e.stopPropagation()
    const caseId = record.id
    onOpenModal([caseId], 'restore')
  }
  const formatDate = (data) => {
    return data ? parseDateObj(data, 'YYYY년 M월 D일') : ''
  }
  const columns = [
    {
      title: '사건번호',
      dataIndex: 'caseNumber',
      key: 'PROJECT_NAME',
      sort: true,
      render: (value, record) =>
        <RowContainer style={{ width: 500 }}>
          <CaseNumber>{value}</CaseNumber>
          <CaseCategoryTag category={record.category2} />
          {record.status === 'CLOSED' ? <ClosedTag /> : null}
          <Spacer />
          <Popover
            placement='right'
            content={
              <div>
                <p><Typography.Text strong>사건번호:</Typography.Text> {record.caseNumber}</p>
                <p><Typography.Text strong>사건분류: </Typography.Text>{record.category}</p>
                <p><Typography.Text strong>생성자: </Typography.Text>{record.creator}</p>
                <p><Typography.Text strong>생성일: </Typography.Text>{formatDate(record.created)}</p>
                <p><Typography.Text strong>위임 인원: </Typography.Text>{record.delegates.length}</p>
                <p><Typography.Text strong>배당 인원: </Typography.Text>{record.userCount}</p>
              </div>
            }
          >
            <CaseInfoButton>
              i
            </CaseInfoButton>
          </Popover>
        </RowContainer>
    },
    {
      title: '분류',
      dataIndex: 'category',
      key: 'CATEGORY'
    },
    {
      title: '담당자 수',
      dataIndex: 'userCount',
      key: 'N_USERS',
      render: v => <div style={{ width: 100 }}>{v}</div>

    },
    {
      title: '총 시간',
      dataIndex: 'totalMinutes',
      key: 'MINUTES',
      sort: true,
      render: v => <div style={{ width: 100 }}>{parseMinutesToHourMin(v)}</div>
    },
    {
      title: '생성일',
      dataIndex: 'created',
      render: (v) => {
        return (
          <Typography.Text ellipsis style={caseTableCommonProps}>
            {v ? parseDateObj(v, 'YYYY년 M월 D일') : ''}
          </Typography.Text>
        )
      }
    },
    {
      title: '삭제일',
      dataIndex: 'updated',
      sort: true,
      sortKey: 'PROJECT_DELETED',
      render: (v) => {
        return (
          <Typography.Text ellipsis style={caseTableCommonProps}>
            {v ? parseDateObj(v, 'YYYY년 M월 D일') : ''}
          </Typography.Text>
        )
      }
    },
    {
      // 현재 사용자 == 삭제자, 관리자, 대표일경우 복구 가능
      title: '',
      dataIndex: 'userMenu',
      key: 'userMenu',
      render: (_v, record) => {
        return (
          currentUser?.id === record.updatedBy.id || currentUser?.group.name === '관리자' || currentUser?.group.name === '대표변호사'
            ? (
              <Button
                onClick={(e) => { onClickRecoveryCase(record, e) }}
                danger
              >
                복구
              </Button>
              )
            : null
        )
      }
    }
  ]
  const excelCompareTableColumns = [
    {
      title: '사건번호',
      dataIndex: 'caseNumber',
      minWidth: 200
    },
    {
      title: '변경 전 인원',
      dataIndex: 'preChangeUserCount',
      minWidth: 200
    },
    {
      title: '변경 후 인원',
      dataIndex: 'postChangeUserCount',
      minWidth: 200
    }
  ]

  const onClickRow = caseMeta => {
    navigateToCaseDetail(navigate, {
      caseNumber: _.get(caseMeta, ['caseNumber']),
      caseTag: _.get(caseMeta, ['caseType', 'name']),
      content: _.get(caseMeta, ['content'], ''),
      caseId: _.get(caseMeta, ['id'], ''),
      status: _.get(caseMeta, ['status'], '')
    })
  }

  // -엑셀 모달 사건추가
  const onUploadExcelFile = async (fileObj, proceedNext) => {
    const formData = new FormData()
    formData.append('file', fileObj)
    deserializeCaseList(formData).then(res => {
      proceedNext()
    }).catch(e => {
      console.log(e)
      message.error('업로드 실패')
    })
  }
  return {
    ...tableProps,
    onCloseModal,
    onConfirmModal,
    confirmModalMeta,
    confirmModalTitle,
    total: totalCount,
    data: tableData,
    loading,
    columns,
    onClickRow,
    style: { flex: 1, cursor: 'pointer' },
    allowedToCreateCase,
    unit: '건',
    excelProps: {
      modalTitle: '사건추가',
      columns: excelCompareTableColumns,
      updatedText: '업데이트',
      onUploadExcelFile
    },
    TableComponent
  }
}
