import { unAppointedDepartmentId } from '../../../../common/config'
import { useCustomTable } from '../../../hooks/common/useCustomTable'
import { useEmployeeTableForTossCase } from './useEmployeeTableForTossCase'
import { getUserListForTossCaseModal } from '../../../apollo/caseListQueries'
import { useQuery } from '@apollo/client'
import _ from 'lodash'

export const useTossCaseUserData = (departmentIds = [], searchQuery, skip = false) => {
  const {
    employeeTableColumns
  } = useEmployeeTableForTossCase()

  const parseData = (data, isPreAllocation) => _.map(data,
    v => ({
      ...v,
      key: v.id,
      type: _.get(v, ['group', 'name']),
      ranks: _.map(v?.ranks || [], 'name')?.join(', '),
      ranks2: v?.ranks,
      departments: _.map(v?.departments || [], 'name')?.join(', '),
      departments2: v?.departments,
      leaderId: v?.departments[0]?.leaders[0]?.id
    }))

  const preAllocationTableProps = useCustomTable()
  const filter = {
    notAppointed: _.includes(departmentIds, unAppointedDepartmentId),
    departmentIds,
    includeSelf: true,
    ...searchQuery ? { searchQuery } : {}
  }

  const { data, loading: preAllocationLoading, refetch } = useQuery(getUserListForTossCaseModal, {
    variables: {
      sortBy: {
        ascending: true
      },
      filter: {
        ...filter,
        includeDeleted: false, // 퇴사 직원 목록에서 보이게 설정
        includeSelf: true
      },
      // offset: (page - 1) * limit,
      limit: null
    },
    fetchPolicy: 'no-cache',
    skip
  })
  const filteredUsers = _.get(data, ['GetUserList', 'data'], [])
  const preAllocationData = parseData(filteredUsers, true)

  return {
    preAllocationTableProps: {
      ...preAllocationTableProps,
      data: preAllocationData,
      pageLimitHidden: true,
      tableActionHidden: true,
      columns: employeeTableColumns,
      style: {
        flex: 1,
        cursor: 'pointer'
      },
      scroll: { // 직원 목록 높이
        y: 400
      },
      loading: preAllocationLoading,
      total: _.get(data, ['GetUserList', 'totalCount'], 0),
      unit: '명',
      pagination: false,
      refetch
    },
    loading: preAllocationLoading
  }
}
