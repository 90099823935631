import _ from 'lodash'
import { useEffect, useState } from 'react'

import {
  getCaseTableColumns
} from './getCaseTableColumns'

import {
  getCaseUserExpandedTableColumns
} from './getCaseUserExpandedTableColumns'

import { Table } from 'antd'
import { totalWidth } from './columnWidths'
import styled from 'styled-components'
import { useNavigate, useOutletContext } from 'react-router-dom'
import COLORS from '../../../../../common/Theme/colors'
import { navigateToCaseDetail } from '../../../../cacheAndNavigation/caseDetail/navigation'
import { useTimesheetHeader } from '../../useTimesheetHeader'

const rowHeight = 55
const ExpandedContainer = styled.div`
  .ant-checkbox .ant-checkbox-inner {
    border: none;
    background-color: transparent;
  }
`
const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: ${COLORS.black_45};
  min-height: 100px;
`

export const useColumns = (
  dataLoaded,
  caseTableHoveredKey,
  caseTableExpandedKeys,
  onChangeHoveredKey,
  onChangeExpandedKeys,
  onAddTimesheetFromCase,
  onClickUser,
  onStarChange,
  onAddTimesheetDelta
) => {
  const caseNumberMinWidth = 100
  const { permissions } = useOutletContext()
  const isPartner = _.includes(permissions, 'WRITE_TIMESHEET_TIMEDELTA') || _.includes(permissions, 'READ_TIMESHEET_TIMEDELTA')
  const [caseNumberWidth, setCaseNumberWidth] = useState()
  const navigate = useNavigate()
  const {
    filterMeta
  } = useTimesheetHeader()
  const isDatetimerangeFilter = filterMeta.datetimeRange.length !== 0

  // get max case number width
  useEffect(() => {
    const caseNumbers = document.getElementsByClassName('caseTable-caseNumber')
    let maxWidth = 0
    _.range(caseNumbers.length).forEach(v => {
      if (maxWidth < caseNumbers.item(v).clientWidth) {
        maxWidth = caseNumbers.item(v).clientWidth
      }
    })
    if (maxWidth < caseNumberMinWidth) {
      setCaseNumberWidth(caseNumberMinWidth)
    } else setCaseNumberWidth(maxWidth)
  }, [dataLoaded])

  const filterColumns = (columns) => _.filter(columns, v => !v.hidden)
  const caseTableColumns = filterColumns(
    getCaseTableColumns({
      onAddTimesheet: onAddTimesheetFromCase,
      hoveredKey: caseTableHoveredKey,
      expandedKeys: caseTableExpandedKeys,
      isPartner,
      isDatetimerangeFilter,
      caseNumberWidth,
      rowExpanded: !_.isEmpty(caseTableExpandedKeys),
      onStarChange,
      permissions,
      onClickCaseNumber: (record) => {
        navigateToCaseDetail(navigate, { caseId: _.get(record, ['id']) })
      }
    })
  )
  const expandable = {
    expandIcon: () => <></>,
    columnWidth: 0,
    expandRowByClick: true,
    expandedRowRender: (record) => {
      const columns = filterColumns(
        getCaseUserExpandedTableColumns({
          onClickUserName: userMeta => onClickUser({ ...userMeta, caseNumber: record.caseNumber, caseId: record.key }),
          hoveredKey: caseTableHoveredKey,
          isPartner,
          isDatetimerangeFilter,
          caseNumberWidth,
          hideTimesheetDeltaEditButton: !_.includes(permissions, 'WRITE_TIMESHEET_TIMEDELTA'),
          caseId: record.key,
          permissions,
          onAddTimesheetDelta
        })
      )
      if (!caseNumberWidth) return null
      return (
        <ExpandedContainer>
          {_.isEmpty(record.users)
            ? (
              <EmptyContainer>배당받은 변호사가 없습니다.</EmptyContainer>)
            : (
              <Table
                style={{ width: totalWidth(isPartner, isDatetimerangeFilter, caseNumberWidth) }}
                rowSelection={{
                  selectedRowKeys: []
                }}
                columns={columns}
                onRow={(record) => {
                  return {
                    style: { height: rowHeight },
                    onMouseEnter: () => onChangeHoveredKey(record.key),
                    onMouseLeave: () => onChangeHoveredKey(null)
                  }
                }}
                expandable={{
                  expandedRowKeys: []
                }}
                dataSource={record.users}
                pagination={false}
                showHeader={false}
              />)}
        </ExpandedContainer>
      )
    },
    onExpandedRowsChange: onChangeExpandedKeys
  }

  return {
    expandable,
    totalWidth: caseNumberWidth ? totalWidth(isPartner, isDatetimerangeFilter, caseNumberWidth) : null,
    caseTableColumns
  }
}
