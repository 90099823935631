import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContractContainer } from './ContractContainer'
import { extraPaymentCategoryOptions } from '../../config'
import { GrayCustomCard } from '../../../common/Theme/style'
import { useConfirmModal } from '../../hooks/common/useConfirmModal'
import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { getRecordFormStatus } from '../../cacheAndNavigation/contractRecordDetail/cache'
import { navigateToContractRecordDetail } from '../../cacheAndNavigation/contractRecordDetail/navigation'
import { useContractRecordAddEditRemove } from '../../hooks/contractRecord/useContractRecordAddEditRemove'
import { useSavingContractRecordDetailForm } from '../../hooks/contractRecord/contractRecordDetail/useSavingContractRecordDetailForm'

import { Form } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { formattedData } from '../../components/common/CommonFunction'
import _ from 'lodash'
dayjs.locale('ko')

export const ExtraContractForm = ({ id, editMode, setEditMode, contractRecordData, refetch, form, formEditable, initialData }) => {
  const [pageDelete, setPageDelete] = useState(false)
  const [contractDataSource, setContractDataSource] = useState([]) // 추가 계약 목록
  const [extraContractDataSource, setExtraContractDataSource] = useState([]) // 추가 계약 목록
  const [depositDataSource, setDepositDataSource] = useState([]) // 입금 내역
  const [reasonDataSource, setReasonDataSource] = useState([])
  const [newContractIds, setNewContractIds] = useState([])
  const [deletedContractIds, setDeletedContractIds] = useState([])
  const [newReasonIds, setNewReasonIds] = useState([])
  const [removedReasonIds, setRemovedReasonIds] = useState([])
  const [newDepositIds, setNewDepositIds] = useState([])
  const [removedDepositIds, setRemovedDepositIds] = useState([])
  const [addContract, setAddContract] = useState(false)
  const navigate = useNavigate()

  const formStatus = getRecordFormStatus()
  const prevStatus = useRef(formStatus)

  useEffect(() => {
    // setDataSource(contractRecordData)
    const paymentRecords = _.get(contractRecordData, 'paymentRecords', [])
    // const paymentComments = _.get(contractRecordData, 'paymentComments', [])
    // setCommentContractData(paymentComments)
    const restructureContracts = (contracts) => {
      return contracts.reduce((acc, contract) => {
        acc[contract.periodicId] = contract
        return acc
      }, {})
    }
    /* 계약 총 금액 */
    // setContractTotal(_.sumBy(paymentRecords, 'dueAmount'))
    /* 총 계약 내역 계약 금액과 추가 계약 조건으로 분할 */
    // const contracts = _.filter(paymentRecords, record =>
    //   ['DEPOSIT', 'INTERIM', 'BALANCE', 'CONSULT'].includes(record.category)
    // )
    // const contractsData = restructureContracts(formattedData(contracts))
    // setContractDataSource(contractsData)
    // setIntialContractData(contractsData)

    const extraContracts = _.filter(paymentRecords, record =>
      ['CONTINGENT', 'EXTRA', 'ETC'].includes(record.category)
    )
    const extracontractData = restructureContracts(formattedData(extraContracts))
    setContractDataSource(extracontractData)
    // setIntialExtraContractData(extracontractData)
    /* 입금 내역 */
    // const depositsArray = _.flatMap(_.filter(paymentRecords, record => record.deposits.length > 0), 'deposits')
    // setDepositDataSource(depositsArray)

    // const initialValues = {
    //   contract: contractsData,
    //   extraContract: extracontractData,
    //   commentList: paymentComments,
    //   depositRecordList: depositsArray
    // }
    // form.setFieldsValue(initialValues)
  }, [contractRecordData])

  const {
    cancelModalOpen,
    onCloseCancelModal,
    onOkCancelModal,
    onEdit,
    onCancel,
    onSave,
    onDeleteContractPayment,
    readOnly
  } = useSavingContractRecordDetailForm(
    contractRecordData,
    formStatus,
    newContractIds,
    deletedContractIds,
    refetch,
    setNewContractIds,
    setDeletedContractIds,
    newReasonIds,
    setNewReasonIds,
    removedReasonIds,
    setRemovedReasonIds,
    newDepositIds,
    setNewDepositIds,
    removedDepositIds,
    setRemovedDepositIds,
    pageDelete,
    setEditMode,
    setContractDataSource,
    setExtraContractDataSource,
    setReasonDataSource,
    setDepositDataSource,
    contractDataSource,
    extraContractDataSource,
    initialData
    // setContractData
  )

  const {
    onRemoveContractRecord
  } = useContractRecordAddEditRemove(
    refetch
  )

  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal
  } = useConfirmModal(
    onRemoveContractRecord
    // onDeleteCallRecord,
    // onDeleteContract,
    // onDeleteContractItem,
    // onDeleteComment,
    // onDeletePaymentRecord
  )

  const onOkDeleteConfirmModal = () => {
    setPageDelete(true)
    onRemoveContractRecord(id)
  }

  const handleSave = (type) => {
    onSave(form, contractRecordData.id, type)
    navigateToContractRecordDetail(navigate, contractRecordData, null, 'view')
  }

  useEffect(() => {
    if (!cancelModalOpen && !pageDelete) {
      prevStatus.current = formStatus
    }
  }, [cancelModalOpen, pageDelete])

  const onFinish = (values) => {
    form.resetFields()
  }

  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onOkDeleteConfirmModal}
        onCancel={onCloseModal}
      />
      <Form
        form={form}
        onFinish={onFinish}
      >
        <GrayCustomCard $readOnly={readOnly}>
          <ContractContainer
            contractType='extraContract'
            title='추가 계약 조건(성공보수금 포함)'
            contractData={contractDataSource}
            setContractData={setContractDataSource}
            options={extraPaymentCategoryOptions}
            readOnly={readOnly}
            form={form}
            newContractIds={newContractIds}
            setNewContractIds={setNewContractIds}
            addContract={addContract}
            setAddContract={setAddContract}
            onEdit={onEdit}
            handleSave={handleSave}
            onCancel={onCancel}
            editMode={editMode}
            onDeleteContractPayment={onDeleteContractPayment}
            refetch={refetch}
            formEditable={formEditable}
          />
        </GrayCustomCard>
      </Form>
    </>
  )
}

