import { useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Popover, Typography } from 'antd'
import styled from 'styled-components'

import { PasswordResetModal } from '../account/PasswordResetModal'
import COLORS from '../../../common/Theme/colors'

const Container = styled.div`
  display: flex;
  background: white;
  padding: 10px 12px;
  margin-bottom: 10px;
  width: calc(100% - 15px);
  border-style: solid;
  border-radius: 10px;
  border-width: 0.5px;
  border-color: ${COLORS.gray_2};
  gap: 7px;
  cursor: pointer;
`

const UserDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`
const StyledButton = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  &:hover{
    font-weight: 600;
    background-color: ${COLORS.black_1};
    border-radius: 30px;
  }
`
export const AccountSetting = ({ name, email, onLogout, onClick }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const showModal = () => {
    setModalOpen(true)
  }

  return (
    <Popover
      trigger='click'
      content={
        <ButtonContainer>
          <StyledButton onClick={onLogout}>로그아웃</StyledButton>
          <StyledButton>
            <PasswordResetModal showModal={showModal} />
          </StyledButton>
        </ButtonContainer>
      }
    >
      <Container>
        <UserOutlined style={{ fontSize: 18 }} />
        <UserDescriptionContainer>
          <Typography.Text level={5} style={{ margin: 0 }}>{name}</Typography.Text>
          <Typography.Text type='secondary' style={{ fontSize: email?.length > 14 ? 12 : 14 }}>{email}</Typography.Text>
        </UserDescriptionContainer>
      </Container>
    </Popover>
  )
}
