import React from 'react'
import { TimePicker } from 'antd'
import { customLocale } from './CommonFunction'

export const CustomTimePicker = ({ time, style, onChange, placeholder }) => {
  return (
    <TimePicker
      locale={customLocale}
      format='HH:mm'
      placeholder={placeholder}
      defaultValue={time}
      style={style}
      needConfirm={false}
      onChange={onChange}
    />
  )
}
