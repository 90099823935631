import { useNavigate } from 'react-router-dom'
import { todayDate } from '../components/common/CommonFunction'
import { useOverdueList } from '../hooks/overdue/useOverdueList'
import { useOverdueTable } from '../hooks/overdue/table/useOverdueTable'
import ContractRecordFilter from '../components/filter/ContractRecordFilter'
import { useContractRecordList } from '../hooks/apollo/useContractRecordList'
import { HeaderContentFooter } from '../../common/components/HeaderContentFooter'
import { useOverdueContractFilter } from '../hooks/filter/pastPayment/useOverdueContractFilter'
import { ContractCustomTable } from '../components/customTable/ContractCustomTable'
import { navigateToOverdueList } from '../cacheAndNavigation/overdueRecord/navigation'
import { useOverdueViewerColumns } from '../hooks/overdue/table/useOverdueViewerColumns'
import { ColumnGapWrapper, MoneyContainer, ResponsiveGapContainer, RowGapWrapper } from '../../common/Theme/style'
import { useDefaultDateRangeFilter } from '../../timeCharge/hooks/filter/useDefaultDateRangeFilter'
import { useContractRecordColumnsList } from '../hooks/contractRecord/contractRecordDetail/useContractRecordColumnsList'
import styled from 'styled-components'
import COLORS from '../../common/Theme/colors'
import { Divider, Statistic } from 'antd'

const CollectionInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  white-space: nowrap;
  align-items: center;
  min-width: 200px;
  /* justify-content: space-between; */
`

const TotalCollectionInfoContainer = styled.div`
  display: flex;
  /* gap: 10px; */
  white-space: nowrap;
  align-items: center;
  min-width: 200px;
  justify-content: space-between;
`

const CollectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.red};
  padding: 15px;
  border-radius: 5px;
  /* width: 260px; */
`

const TotalMoneyContainer = styled(Statistic)`
  .ant-statistic-content-value-int,
  .ant-statistic-content-value-decimal {
    font-size: 16px;
    font-weight: 600;
  }
`
const Tag = styled.div`
  color: #545454;
  font-size: 14px;
`
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 15px 16px;
`

const FilterResultContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`

const CustomDivider = styled(Divider)`
  margin: 10px 0;
`

// 계약 기록 > 총 금액
const OverdueContainer = () => {
  const navigate = useNavigate()
  const {
    viewMode,
    groupModeContract,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    onSelectFilter,
    onChangeViewMode,
    onChangeGroupMode,
    filterMeta,
    ...props
  } = useOverdueList()
  const {
    getOverdueViewerTableGroupColumns,
    getOverdueViewerTableGroupTypeColumns
  } = useOverdueViewerColumns()
  const {
    meta,
    filters,
    searchParams,
    onChangeMeta,
    onResetFilterAndGroup,
    getUpdatedSearchParams
  } = useOverdueContractFilter()
  const {
    onClickRow
  } = useOverdueTable(searchParams)
  // 전체 추심 금액
  const {
    totalDueAmount
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    todayDate, // to,
    null, // departmentId,
    false, // deleted,
    null, // caseNumber,
    null, // projectTypeId,
    null, // assignedLawyerId,
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeContract,
    true, // outstanding
    null, // outstandingFrom
    todayDate, // outstandingTo
    false // closed
  )

  // 총 추심: 형사
  const {
    totalDueAmount: filterTotalDueAmount
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    todayDate, // to,
    null, // departmentId,
    false, // deleted,
    null, // caseNumber,
    '15ce1e5f-481b-4da6-a6d1-7dd7d8f2db11', // projectTypeId,
    null, // assignedLawyerId,
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeContract,
    true, // outstanding
    null, // outstandingFrom
    todayDate, // outstandingTo
    false // closed
  )
  // 총 추심: 민사
  const {
    totalDueAmount: filterTotalDueAmount2
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    todayDate, // to,
    null, // departmentId,
    false, // deleted,
    null, // caseNumber,
    'b17d7dbb-848c-4f9f-8b37-090c39a3098b', // projectTypeId,
    null, // assignedLawyerId,
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeContract,
    true, // outstanding
    null, // outstandingFrom
    todayDate, // outstandingTo
    false // closed
  )

  /*
    계약 기록 데이터 (필터용)
  */
  const {
    loading,
    totalCount,
    contractRecordsData,
    totalDueAmount: currentFilterTotalDueAmount,
    refetch,
    ...preAllocationTableProps
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    todayDate, // to,
    meta.filter.departmentId[0], // departmentId,
    false, // deleted,
    null, // caseNumber,
    meta.filter.projectTypeId[0], // projectTypeId,
    meta.filter.assignedLawyerId[0], // assignedLawyerId,
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeContract,
    true, // outstanding
    null, // outstandingFrom
    todayDate, // outstandingTo,
    false // closed
  )

  // 날짜 필터 형사
  const {
    totalDueAmount: currentfilteredType1
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    todayDate, // to,
    meta.filter.departmentId[0], // departmentId,
    false, // deleted,
    null, // caseNumber,
    meta.filter.projectTypeId[0] === undefined ? '15ce1e5f-481b-4da6-a6d1-7dd7d8f2db11' : meta.filter.projectTypeId[0], // projectTypeId,
    meta.filter.assignedLawyerId[0], // assignedLawyerId,
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeContract,
    true, // outstanding
    null, // outstandingFrom
    todayDate, // outstandingTo
    false // closed
  )
  // 날짜 필터 민사
  const {
    totalDueAmount: currentfilteredType2
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    todayDate, // to,
    meta.filter.departmentId[0], // departmentId,
    false, // deleted,
    null, // caseNumber,
    meta.filter.projectTypeId[0] === undefined ? 'b17d7dbb-848c-4f9f-8b37-090c39a3098b' : meta.filter.projectTypeId[0], // projectTypeId,
    meta.filter.assignedLawyerId[0], // assignedLawyerId,
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeContract,
    true, // outstanding
    null, // outstandingFrom
    todayDate, // outstandingTo
    false // closed
  )

  const {
    contractRecordTableColumns
  } = useContractRecordColumnsList(contractRecordsData)

  // 필터: '이번달' 기본 세팅
  useDefaultDateRangeFilter(meta, 'month', (defDateRange) => {
    const newSearchString = getUpdatedSearchParams('filter', 'datetimeRange', defDateRange, searchParams)
    navigateToOverdueList(navigate, newSearchString)
  })
  return (
    <>
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <>
            <ResponsiveGapContainer>
              <CollectionWrapper>
                <TotalCollectionInfoContainer>
                  <Tag>전체 추심금액</Tag>
                  <TotalMoneyContainer value={totalDueAmount !== null ? totalDueAmount : 0} />
                </TotalCollectionInfoContainer>
                <CustomDivider />
                <ColumnGapWrapper $gap={1} style={{ justifyContent: 'center', gap: '5px' }}>
                  <TotalCollectionInfoContainer>
                    <Tag>형사 추심금액</Tag>
                    <MoneyContainer value={filterTotalDueAmount !== null ? filterTotalDueAmount : 0} />
                  </TotalCollectionInfoContainer>
                  <TotalCollectionInfoContainer>
                    <Tag>민사 추심금액</Tag>
                    <MoneyContainer value={filterTotalDueAmount2 !== null ? filterTotalDueAmount2 : 0} />
                  </TotalCollectionInfoContainer>
                </ColumnGapWrapper>
              </CollectionWrapper>
              {/* <div style={{ minWidth: '300px' }}>
                <CustomSearch onSearch={v => setSearchQuery(v)} placeholder='의뢰인명/전화번호를 입력해주세요.' />
              </div> */}
              <FilterContainer>
                <ContractRecordFilter
                  meta={meta}
                  filters={filters}
                  onChangeMeta={onChangeMeta}
                  onResetFilterAndGroup={onResetFilterAndGroup}
                  onSelectFilter={onSelectFilter}
                  searchParams={searchParams}
                  mode={null}
                  pageType='overdue'
                />
                <CustomDivider />
                <FilterResultContainer>
                  <CollectionInfoContainer>
                    <Tag>총 추심금액</Tag>
                    <MoneyContainer value={currentFilterTotalDueAmount !== null ? currentFilterTotalDueAmount : 0} />
                  </CollectionInfoContainer>
                  <RowGapWrapper $gap={1}>
                    <CollectionInfoContainer>
                      <Tag>형사 추심금액</Tag>
                      <MoneyContainer value={currentfilteredType1} />
                    </CollectionInfoContainer>
                    <CollectionInfoContainer>
                      <Tag>민사 추심금액</Tag>
                      <MoneyContainer value={currentfilteredType2} />
                    </CollectionInfoContainer>
                  </RowGapWrapper>
                </FilterResultContainer>
              </FilterContainer>
            </ResponsiveGapContainer>
          </>
        )}
        content={(
          <ContractCustomTable
            data={contractRecordsData}
            defaultColumns={contractRecordTableColumns}
            groupColumns={getOverdueViewerTableGroupColumns}
            typeGroupColumns={getOverdueViewerTableGroupTypeColumns}
            total={totalCount}
            pageType='overdue'
            viewMode={viewMode}
            groupModeContract={groupModeContract}
            onClickRow={onClickRow}
            scroll={1000}
            loading={loading}
            onChangeViewMode={onChangeViewMode}
            onChangeGroupMode={onChangeGroupMode}
            {...props}
            {...preAllocationTableProps}
          />
        )}
      />
    </>
  )
}

export default OverdueContainer
