import { useEffect, useState } from 'react'
import { parseMinutesToHourMin } from '../../../../../common/util'
import { Spacer } from '../../../../../common/components/PageHeaderStyles'

import styled from 'styled-components'
import { withSize } from 'react-sizeme'
import { Tooltip, Typography } from 'antd'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`
const TimesheetTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
`
const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  background-color: ${props => props.color};
`

const CalendarCellItem = ({
  size: { width },
  color,
  title,
  hoveredCaseId,
  minutes,
  id,
  caseNumber,
  caseId,
  shouldDisplayHHMMFormat,
  onHoverItem = () => {},
  onClickCalendarListItem = () => {},
  onChangeOverflow = () => {},
  ...meta
}) => {
  const shouldloweropacity = hoveredCaseId && hoveredCaseId !== caseId
  const [initTitleWidth, setInitTitleWidth] = useState(0)
  const [initTextWidth, setInitTextWidth] = useState(0)
  useEffect(() => {
    const textelem = document.getElementById(`${id}_text`)
    const titleelem = document.getElementById(`${id}_title`)
    setInitTitleWidth(titleelem.clientWidth)
    setInitTextWidth(textelem.clientWidth)
  }, [])

  const isOverflow = initTitleWidth > width - 20 - initTextWidth
  useEffect(() => {
    onChangeOverflow(isOverflow)
  }, [isOverflow])
  return (
    <Container
      id='cell-item'
      style={{ opacity: shouldloweropacity ? 0.5 : 1 }}
      onClick={() => onClickCalendarListItem({ title, minutes, key: id, caseNumber, caseId, ...meta })}
    >
      <TimesheetTitleContainer id={`${id}_title`}>
        <Tooltip
          title={caseNumber}
          placement='left'
          color={color}
          onOpenChange={(opened) => opened ? onHoverItem(caseId) : onHoverItem(null)}
        >
          <Dot id={`${id}_button`} color={color} />
        </Tooltip>
        <Typography.Text style={{ maxWidth: width - 20 - initTextWidth, minWidth: 20 }} ellipsis={{ tooltip: true }}>
          {title}
        </Typography.Text>
      </TimesheetTitleContainer>
      <Spacer />
      <Typography.Text id={`${id}_text`} style={{ width: 'fit-content' }}>
        {`${parseMinutesToHourMin(minutes, shouldDisplayHHMMFormat ? 'h' : '시간', shouldDisplayHHMMFormat ? 'm' : '분', shouldDisplayHHMMFormat ? 'd' : '일')}`}
      </Typography.Text>
    </Container>
  )
}
export default withSize()(CalendarCellItem)
