import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { parseDateObj } from '../../../common/util'
import _ from 'lodash'
import { navigateToContractRecordDetail } from '../../../call/cacheAndNavigation/contractRecordDetail/navigation'
import { useRecordFilterSearchParams } from '../../../call/hooks/filter/callRecord/useRecordFilterSearchParams'
import { useContractRecordList } from '../../../call/hooks/apollo/useContractRecordList'
import { todayDate } from '../../components/common/CommonFunction'

export const useContractCalendar = (pageType, contractStateSwitch) => {
  const [activeStartDate, setActiveStartDate] = useState(new Date().setDate(1))

  const navigate = useNavigate()
  const {
    meta,
    searchParams
  } = useRecordFilterSearchParams()
  const searchString = searchParams.toString()
  const from = activeStartDate ? parseDateObj(new Date(activeStartDate).setMonth(new Date(activeStartDate).getMonth())) : undefined
  const to = activeStartDate ? parseDateObj(new Date(activeStartDate).setMonth(new Date(activeStartDate).getMonth() + 1)) : undefined

  const {
    contractRecordsData
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    from, // from,
    to, // to,
    meta.filter.departmentId[0], // departmentId,
    false, // deleted,
    null, // caseNumber,
    meta.filter.projectTypeId[0], // projectTypeId,
    meta.filter.assignedLawyerId[0], // assignedLawyerId,
    null, // viewMode
    !(pageType === 'contractRecord'), // outstanding
    from, // outstandingFrom
    pageType === 'overdue' ? todayDate : to, // outstandingTo
    pageType === 'overdue' ? false : contractStateSwitch
  )
  const updatedData = _.map(contractRecordsData, (item) => {
    const updatedPaymentRecords = _.map(item.paymentRecords, (record) => ({
      ...record,
      name: item.name,
      callRecords: {
        id: item.id
      }
    }))
    return {
      ...item,
      paymentRecords: updatedPaymentRecords
    }
  })
  const allPaymentRecords = _.flatMap(updatedData, item => _.get(item, 'paymentRecords', []))
  // 완납된 리스트 필터
  const filteredCompletePayment = _.filter(allPaymentRecords, (item) => {
    const totalReceivedAmount = _.sumBy(item.deposits, 'receivedAmount')
    const remainingAmount = item.dueAmount - totalReceivedAmount
    return remainingAmount !== 0
  })
  const contractsByDate = _.groupBy(filteredCompletePayment, 'dueDate')
  const onClickCalendarListItem = (v) => {
    navigateToContractRecordDetail(navigate, v.callRecords, searchString)
  }
  const formatDay = (l, date) => {
    if (activeStartDate && new Date(date).getMonth() !== new Date(activeStartDate).getMonth() && new Date(date).getDate() === 1) {
      return `${new Date(date).getMonth() + 1}월 1일`
    } else return new Date(date).getDate()
  }

  return {
    listByDate: contractsByDate, // 완납된 계약은 제거
    formatDay,
    onChangeActiveStartDate: v => setActiveStartDate(v),
    onClickCalendarListItem,
    totalCount: filteredCompletePayment.length,
    contractsByDate // 모든 계약 목록
  }
}
