import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import { editUserGroupPermissions } from '../../../../timeCharge/apollo/mutations'
import { useUserGroupList } from '../../../../timeCharge/hooks/apollo/useUserGroupList'
import _ from 'lodash'

export const useSettingGroupPermission = () => {
  const { userGroups } = useUserGroupList() // 쿼리: 그룹변 모든 권한
  const [handleUpdateGroup] = useMutation(editUserGroupPermissions)
  const [meta, setMeta] = useState({})
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
  const [checkboxState, setCheckboxState] = useState(userGroups)
  useEffect(() => {
    const defaultGroupPermissions = userGroups
    const editedGroupPermissions = userGroups
    const disabledUserType = _.filter(defaultGroupPermissions, group => !_.includes(group.permissions, 'READ_SAME_DEPARTMENT_PROJECT')).map(group => group.name)
    setMeta({
      defaultGroupPermissions,
      editedGroupPermissions,
      disabledUserType
    })
    setCheckboxState(userGroups)
  }, [userGroups])

  const handleCheckboxChange = (checked, userType, permission) => {
    setMeta(meta => {
      const userTypeId = userType.id
      const editedGroupPermissions = _.get(meta, ['editedGroupPermissions'], [])
      setSaveButtonDisabled(false)
      if (checked) { // 권한 종류중 박스에 체크가 되었을 경우
        const newEditedGroupPermissions = _.union(_.find(_.get(meta, ['editedGroupPermissions'], []), v => v.id === userTypeId)?.permissions, [permission.key])
        const checkCasePermissionOff = _.includes(newEditedGroupPermissions, 'READ_SAME_DEPARTMENT_PROJECT') ? [userType.name] : []
        const timesheetDisabled = _.without(meta.disabledUserType, ...checkCasePermissionOff)
        const updatedPermissions = (editedGroupPermissions, userTypeId, newEditedGroupPermissions) => {
          return editedGroupPermissions.map(({ permissions, id }, index) => {
            if (id === userTypeId) {
              return {
                ...editedGroupPermissions[index],
                permissions: newEditedGroupPermissions
              }
            } else {
              return editedGroupPermissions[index]
            }
          })
        }
        const updated = updatedPermissions(editedGroupPermissions, userTypeId, newEditedGroupPermissions)
        setCheckboxState(updated)
        return {
          ...meta,
          editedGroupPermissions: updated,
          disabledUserType: timesheetDisabled
        }
      } else { // 권한 종류중 박스에 체크가 꺼질 경우
        // 선택된 권한을 끈다
        const newEditedPermissions = _.without(_.find(_.get(meta, ['editedGroupPermissions'], []), v => v.id === userTypeId)?.permissions, permission.key)
        const containLookupCasePermissions = _.includes(newEditedPermissions, 'READ_SAME_DEPARTMENT_PROJECT')
        if (!containLookupCasePermissions) {
          _.remove(newEditedPermissions, permission => permission === 'READ_SAME_DEPARTMENT_TIMESHEET'
          )
        }
        const checkCasePermissionOff = !_.includes(newEditedPermissions, 'READ_SAME_DEPARTMENT_PROJECT') ? [userType.name] : []
        const timesheetDisabled = _.concat(meta.disabledUserType, checkCasePermissionOff)
        const updatedPermissions = (editedGroupPermissions, userTypeId, newEditedGroupPermissions) => {
          return editedGroupPermissions.map(({ permissions, id }, index) => {
            if (id === userTypeId) {
              return {
                ...editedGroupPermissions[index],
                permissions: newEditedGroupPermissions
              }
            } else {
              return editedGroupPermissions[index]
            }
          })
        }

        const updated = updatedPermissions(editedGroupPermissions, userTypeId, newEditedPermissions)
        setCheckboxState(updated)

        return {
          ...meta,
          editedGroupPermissions: updated,
          disabledUserType: timesheetDisabled
        }
      }
    })
  }

  // 저장
  const onSaveChanges = async () => {
    if (_.isEmpty(meta)) {
      return
    }
    try {
      await Promise.all(
        meta.editedGroupPermissions.map(async groupId => {
          await handleUpdateGroup({
            variables: {
              editUserGroupPermissionsId: groupId.id,
              permissions: groupId.permissions || []
            }
          })
        })
      )
      setSaveButtonDisabled(true)
    } catch (error) {
      console.error('Error during saving changes:', error)
      handleError()
    }
  }
  return {
    handleCheckboxChange,
    onSaveChanges,
    meta,
    saveButtonDisabled,
    checkboxState
  }
}
