import { message } from 'antd'
import { useState } from 'react'
import { addProjectsViaDeserialization, addUsersViaDeserialization } from '../../../common/fastapi/fetchers'
import axios from 'axios'
import { fastapiUrl } from '../../../common/config'

export const useExcelUpload = (dataCategory, refetch, onUpload = () => {}) => {
  const [isFileDataModalOpen, setIsFileDataModalOpen] = useState(false)
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [btnDisable, setBtnDisable] = useState(false)
  const [excelData, setExcelData] = useState([])
  const [fileUploadLoading, setFileUploadLoading] = useState(false)

  const showFileUploadModal = () => {
    setIsFileUploadModalOpen(true)
  }
  const handleCancel = () => {
    setIsFileUploadModalOpen(false)
    setIsFileDataModalOpen(false)
    setFileUploadLoading(false)
  }
  const handleOk = async () => {
    setBtnDisable(true)
    if (dataCategory === 'case') {
      try {
        await addProjectsViaDeserialization({ scratchPad: excelData })
        proceedNext()
        setIsFileDataModalOpen(false)
        refetch()
      } catch (error) {
        console.log(error)
        message.error('업로드 실패')
      }
    } else {
      try {
        await addUsersViaDeserialization({ scratchPad: excelData})
        proceedNext()
        setIsFileDataModalOpen(false)
        refetch()
      } catch (error) {
        console.log(error)
        message.error('업로드 실패')
      }
    }
  }
  const proceedNext = () => {
    setIsFileUploadModalOpen(false)
    setIsFileDataModalOpen(true)
  }
  const fetchDataFromBackend = dataCategory === 'case' ? '/deserialize_project_list' : '/deserialize_user_list'

  const fileUploaderProps = {
    action: async (file) => {
      // 1. s3 presigned url 가져오기
      const BASE_URL = fastapiUrl + '/deserialization'
      const { data } = await axios.get(BASE_URL, {
        headers: {
          atoken: sessionStorage.getItem('token')
        }
      })
      file.fetchedId = data.id
      return data.url
    },
    customRequest: async ({ file, action, onSuccess }) => {
      setFileUploadLoading(true)
      try {
        if (typeof file !== 'string') {
          await axios.put(action, file, {
            headers: {
              'Content-Type': file.type
            }
          })
          const { data: { data } } = await axios.put(fastapiUrl + fetchDataFromBackend, { id: file.fetchedId })
          onSuccess()
          setExcelData(data)
          proceedNext()
        }
      } catch (error) {
        message.error('파일 업로드 중 오류가 있습니다.')
      } finally {
        setFileUploadLoading(false)
      }
    },
    method: 'PUT'
  }

  return {
    showFileUploadModal,
    isFileUploadModalOpen,
    fileUploaderProps,
    isFileDataModalOpen,
    handleCancel,
    handleOk,
    excelData,
    btnDisable,
    fileUploadLoading
  }
}
