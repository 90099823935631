import { useQuery } from '@apollo/client'
import { getDepartmentList } from '../../../apollo/paymentQueries'
import _ from 'lodash'

export const useDepartmentList = (searchQuery, onComplete = () => {}, skip = false) => {
  const { data, loading, refetch } = useQuery(getDepartmentList, {
    variables: {
      filter: searchQuery ? { searchQuery } : {}
    },
    fetchPolicy: 'no-cache',
    skip,
    onCompleted: (data) => onComplete(_.get(data, ['GetDepartmentList'], []))
  })

  const departments = _.get(data, ['GetDepartmentList'], [])
  return {
    loading,
    departments,
    totalCount: departments.length,
    refetch
  }
}
