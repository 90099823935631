import { Tag } from 'antd'
import styled from 'styled-components'

const DepartmentContainer = styled.div`
  display: flex;
  gap: 3px;
`
export const useEmployeeTableForTossCase = () => {
  const employeeTableColumns = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'USER_NAME',
      width: 100
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      width: 220
    },
    {
      title: '직급',
      dataIndex: 'ranks',
      key: 'ranks',
      width: 120
    },
    {
      title: '권한그룹',
      dataIndex: 'type',
      key: 'roles',
      width: 110
    },
    {
      title: '부서',
      dataIndex: 'departments',
      key: 'departments',
      width: 210,
      render: (departments, record) => {
        return (
          <DepartmentContainer style={{ paddingLeft: '5px' }}>
            {departments}
            {record.id === record.leaderId
              ? (
                <Tag
                  color='geekblue'
                >
                  부서장
                </Tag>)
              : null}
          </DepartmentContainer>
        )
      }
    },
    {
      title: '담당사건',
      // sort: true,
      dataIndex: 'projectCount',
      key: 'N_PROJECTS',
      width: 80,
      render: (record) => {
        return (
          <div style={{ paddingLeft: '5px' }}>
            {record}
          </div>
        )
      }
    }
  ]

  return {
    employeeTableColumns
  }
}
