import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { Button } from 'antd'

const ContractStateContainer = styled(Button)`
  color: ${props => (props.$state === 'CONTRACT') ? `${COLORS.primaryColor}` : props.$state === 'PROGRESS' ? `${COLORS.green}` : props.$state === 'OVERDUE' ? `${COLORS.red}` : props.$state === 'NOCONTRACT' ? `${COLORS.orange}` : props.$state === 'CLOSE' ? `${COLORS.black}` : `${COLORS.orange}`};
  background-color: ${props => (props.$state === 'CONTRACT') ? `${COLORS.primaryColor10}` : props.$state === 'PROGRESS' ? `${COLORS.green_1}` : props.$state === 'OVERDUE' ? `${COLORS.pink_2_10}` : props.$state === 'NOCONTRACT' ? `${COLORS.orange_1_30}` : props.$state === 'CLOSE' ? `${COLORS.gray_1_50}` : `${COLORS.orange_1_30}`};
  border: 1px solid ${props => (props.$state === 'CONTRACT') ? `${COLORS.primaryColor10}` : props.$state === 'PROGRESS' ? `${COLORS.green_1}` : props.$state === 'OVERDUE' ? `${COLORS.pink_2_10}` : props.$state === 'NOCONTRACT' ? `${COLORS.orange_1_30}` : props.$state === 'CLOSE' ? `${COLORS.gray_1_50}` : `${COLORS.orange_1_30}`};
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 5px 0;
  width: 50px;
  font-size: 12px;
  z-index: 9999;
`

export const ContractStateColor = ({ state = 'NOCONTRACT', record, handleNavigateContractState = () => {} }) => {
  const check = state === 'CONTRACT' ? '체결' : state === 'PROGRESS' ? '진행' : state === 'OVERDUE' ? '추심' : state === 'NOCONTRACT' ? '미체결' : state === 'CLOSE' ? '종결' : '미체결'
  return <ContractStateContainer $state={state} onClick={() => handleNavigateContractState(record)}>{check}</ContractStateContainer>
}
