import { CustomTable } from '../../common/CustomTable'
import { DepartmentFilter } from '../../settings/DepartmentFilter'
import styled from 'styled-components'

const TossCaseContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const TopContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 900px;
`
export const EmployeeListForTossCase = ({
  preAllocationTableProps, // 직원 목록
  filterProps = {}, // 부서 필터 목록,
  onSelectChange,
  selectedRowKeys
}) => {
  const rowSelection = {
    onChange: onSelectChange,
    type: 'checkbox'
  }
  return (
    <TossCaseContentWrapper>
      <TopContainer>
        <DepartmentFilter // 필터전체
          {...filterProps}
        />
        <div style={{ width: '900px' }}>
          <CustomTable
            rowSelection={rowSelection}
            {...preAllocationTableProps}
          />
        </div>
      </TopContainer>
    </TossCaseContentWrapper>
  )
}
