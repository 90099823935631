import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { getUserListForTimesheet } from '../../apollo/inProgressCaseQueries'
import { useCaseList } from './InProgressCasePage/useCaseList'
import { useEffect, useState, useMemo } from 'react'

// 선택된 사건번호에 따라 작성자 목록
export const useCaseNumbersAndUsersList = (closed = false, selectedCaseIds = [], caseSearchQuery, userSearchQuery, caseFilterQuerySkip = false, userFilterSkip = false) => {
  const { cases: caseNumbers, totalCount: _caseTotalCount, refetch: caseRefetch, loading: caseLoading } = useCaseList({
    sortKey: 'TIMESHEET_UPDATED',
    sortAscending: true,
    closed,
    caseSearchQuery,
    limit: 20,
    skip: caseFilterQuerySkip
  })
  const [caseTotalCount, setCaseTotalCount] = useState(_caseTotalCount)

  const { cases: _selectedCaseNumbers, totalCount: _selectedCaseTotalCount, loading: selectedCaseNumbersLoading } = useCaseList({
    projectIds: selectedCaseIds,
    sortKey: 'TIMESHEET_UPDATED',
    sortAscending: true,
    limit: null,
    skip: _.isEmpty(selectedCaseIds)
  })
  const [selectedCaseNumbers, setSelectedCaseNumbers] = useState(_selectedCaseNumbers)
  const [selectedCaseTotalCount, setSelectedCaseTotalCount] = useState(_selectedCaseTotalCount)

  const { cases: starredCaseNumbers, totalCount: _starredCaseTotalCount, refetch: starredCaseRefetch, loading: starredCaseLoading } = useCaseList({
    sortKey: 'TIMESHEET_UPDATED',
    sortAscending: true,
    closed,
    starred: true,
    skip: caseFilterQuerySkip
  })
  const [starredCaseTotalCount, setStarredCaseTotalCount] = useState(_starredCaseTotalCount)

  useEffect(() => {
    if (!caseLoading) setCaseTotalCount(_caseTotalCount)
    if (!selectedCaseNumbersLoading) {
      setSelectedCaseNumbers(_selectedCaseNumbers)
      setSelectedCaseTotalCount(_selectedCaseTotalCount)
    }
    if (!starredCaseLoading) setStarredCaseTotalCount(_starredCaseTotalCount)
  }, [caseLoading, selectedCaseNumbersLoading, starredCaseLoading])

  const userListQueryOptions = {
    variables: {
      sortBy: { ascending: true },
      filter: { includeSelf: true },
      limit: null
    },
    fetchPolicy: 'network-only',
    skip: userFilterSkip
  }
  const { data: usersListData, loading: usersListDataLoading } = useQuery(getUserListForTimesheet, userListQueryOptions)
  const searchedUserListQueryOptions = useMemo(() => ({
    ...userListQueryOptions,
    variables: {
      ...userListQueryOptions.variables,
      filter: {
        ...userListQueryOptions.variables.filter,
        ...(userSearchQuery ? { searchQuery: userSearchQuery } : {})
      }
    }
  }), [userSearchQuery])

  const { data: searchedUserListData, loading: searchedUserListDataLoading } = useQuery(getUserListForTimesheet, searchedUserListQueryOptions)
  const users = useMemo(() => _.get(usersListData, ['GetUserList', 'data'], []), [usersListData])
  const searchedUsers = useMemo(() => _.get(searchedUserListData, ['GetUserList', 'data'], []), [searchedUserListData])

  return {
    caseRefetch,
    starredCaseRefetch,
    selectedCaseNumbers,
    starredCaseLoading,
    caseLoading,
    caseNumbers,
    starredCaseNumbers,
    users,
    searchedUsers,
    usersListDataLoading,
    searchedUserListDataLoading,
    caseTotalCount,
    selectedCaseTotalCount,
    starredCaseTotalCount
  }
}
