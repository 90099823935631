import _ from 'lodash'

export const navigateToCallRecord = (navigate, recordStatus, searchString, _viewMode, _groupMode) => {
  const viewMode = _viewMode
  const groupMode = _groupMode
  navigate(`/callRecord/${recordStatus}${searchString ? `?${searchString}` : ''}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      ...recordStatus ? { recordStatus } : {},
      ...viewMode ? { viewMode } : {},
      ...groupMode ? { groupMode } : {}
    }
  })
}
