import File from './File'
import styled from 'styled-components'
import { Empty } from 'antd'
import _ from 'lodash'

const FileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

export const TimesheetFiles = ({ timesheets = [], onDoubleClickFile = () => {} }) => {
  if (_.isEmpty(timesheets)) return <Empty />
  const orderByDate = _.orderBy(timesheets, ['updated']).reverse()
  return (
    <FileContainer>
      {orderByDate.map(({ id, ...props }, i) => {
        return (
          <File key={i} onDoubleClickFile={() => onDoubleClickFile({ id, ...props })} {...props} />
        )
      })}
    </FileContainer>
  )
}
