import { useQuery } from '@apollo/client'
import { getProjectNameCategories } from '../../apollo/queries'
import _ from 'lodash'

export const useTimesheetNameList = () => {
  const {
    data
  } = useQuery(getProjectNameCategories)

  return {
    timesheetNames: _.get(data, ['GetTimesheetNameTemplateList'], [])
  }
}
