import _ from 'lodash'

export const useTimesheetFormPermissions = (permissions) => {
  const writeTimesheet = _.includes(permissions, 'WRITE_TIMESHEET')
  const writeOtherTimesheet = _.includes(permissions, 'WRITE_OTHER_TIMESHEET')

  return {
    userInputEnabled: writeOtherTimesheet,
    shouldInitializeUserInputAsCurrentUser: writeTimesheet
  }
}
