import _ from 'lodash'

export const navigateToStatWithParams = (navigate, caseIds = [], userIds = []) => {
  const params = new URLSearchParams()
  caseIds.forEach(v => params.append('filter_caseIds', v))
  userIds.forEach(v => params.append('filter_userIds', v))
  navigate(`/stat?${params.toString()}`, {
    state: _.get(window, ['history', 'state', 'usr'], {})
  })
}
