import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CommentForm } from './CommentForm'
import { DepositForm } from './DepositForm'
import { ContractForm } from './ContractForm'
import { ExtraContractForm } from './ExtraContractForm'
import { getCurrentUser } from '../../../timeCharge/apollo/queries'
import { useConfirmModal } from '../../hooks/common/useConfirmModal'
import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { useContractRecord } from '../../hooks/apollo/useContractRecord'
import { InfoCard } from '../../components/contractRecordDetail/InfoCard'
import { BlockModal } from '../../../timeCharge/components/common/BlockModal'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { contractCustomerInfo, customerInfo, tmpDuplicationData } from '../../config'
import { getRecordFormStatus } from '../../cacheAndNavigation/contractRecordDetail/cache'
import { useContractRecordClose } from '../../hooks/contractRecord/useContractRecordClose'
import { ColumnGapWrapper, NarrowCustomCard, RowGapWrapper } from '../../../common/Theme/style'
import { ContractTotalRecordCard } from '../../components/contractRecordDetail/ContractTotalRecordCard'
import { HeaderContainer, PaymentHeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'
import { useContractRecordAddEditRemove } from '../../hooks/contractRecord/useContractRecordAddEditRemove'
import { useContractorInfoColumns } from '../../hooks/contractRecord/contractRecordDetail/useContractorInfoColumns'
import { useSavingContractRecordDetailForm } from '../../hooks/contractRecord/contractRecordDetail/useSavingContractRecordDetailForm'

import { useQuery } from '@apollo/client'
import COLORS from '../../../common/Theme/colors'
import styled from 'styled-components'
import _ from 'lodash'
import { Button, Modal, Table, Tabs, Form } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

const CustomTabs = styled(Tabs)`
  .ant-tabs-tab {
    font-size: 14px;
    font-weight: 600;
    padding: 0px 0 12px;
  }
`
const TableCategory = styled.div`
  width: 100px;
  color: ${COLORS.black_65};
  display: flex;
  align-items: center;
`
const StyledModal = styled(Modal)`
  .ant-modal-content {
    overflow: scroll;
  }
`
const HeaderSpacer = styled.div`
  box-shadow: 0px 2px 3px #DFDFDF86;
`
const StyledEditButton = styled(Button)`
  position: absolute;
  top: 11px;
  right: 10px;
  z-index: 999;
`
export const ContractRecordDetailContainer = () => {
  const { id } = useParams()
  const [pageDelete, setPageDelete] = useState(false)
  /*
  계약 기록 데이터
*/
  const {
    loading,
    contractRecordData,
    refetch
  } = useContractRecord(
    id
  )
  const totalReceivedAmount = (data) => {
    if (data !== undefined && data.length > 0) {
      return (
        data.reduce(
          (sum, deposit) => sum + (deposit.receivedAmount || 0),
          0
        )
      )
    } else {
      return 0
    }
  }
  const formattedData = (value) => {
    return (
      _.map(_.groupBy(value, (record) => _.trim(record.periodicId)), (records, periodicId) => ({
        key: periodicId,
        id: records[0].id,
        periodicId,
        totalDueAmount: _.sumBy(records, 'dueAmount'),
        totalReceivedAmount: totalReceivedAmount(records[0].deposits),
        category: records[0].category,
        periodic: records[0].periodic,
        dueDate: records[0].dueDate,
        dueAmount: records[0].dueAmount,
        payments: _.map(records, (record) => ({
          key: record.id,
          dueDate: record.dueDate,
          dueAmount: record.dueAmount,
          deposits: record.deposits,
          category: record.category
        }))
      })))
  }
  useEffect(() => {
    if (!loading) {
      // setDataSource(contractRecordData)
      const paymentRecords = _.get(contractRecordData, 'paymentRecords', [])
      const paymentComments = _.get(contractRecordData, 'paymentComments', [])
      // setCommentContractData(paymentComments)
      const restructureContracts = (contracts) => {
        return contracts.reduce((acc, contract) => {
          acc[contract.periodicId] = contract
          return acc
        }, {})
      }
      /* 계약 총 금액 */
      setContractTotal(_.sumBy(paymentRecords, 'dueAmount'))
      /* 총 계약 내역 계약 금액과 추가 계약 조건으로 분할 */
      const contracts = _.filter(paymentRecords, record =>
        ['DEPOSIT', 'INTERIM', 'BALANCE', 'CONSULT'].includes(record.category)
      )
      const contractsData = restructureContracts(formattedData(contracts))
      // setContractDataSource(contractsData)
      // setIntialContractData(contractsData)

      const extraContracts = _.filter(paymentRecords, record =>
        ['CONTINGENT', 'EXTRA', 'ETC'].includes(record.category)
      )
      const extracontractData = restructureContracts(formattedData(extraContracts))
      // setExtraContractDataSource(extracontractData)
      // setIntialExtraContractData(extracontractData)
      /* 입금 내역 */
      const depositsArray = _.flatMap(_.filter(paymentRecords, record => record.deposits.length > 0), 'deposits')
      // setDepositDataSource(depositsArray)

      const initialValues = {
        contract: contractsData,
        extraContract: extracontractData,
        commentList: paymentComments,
        depositRecordList: depositsArray
      }
      form.setFieldsValue(initialValues)
    }
  }, [contractRecordData])

  const [dataSource, setDataSource] = useState() // 계약 정보
  const [contractDataSource, setContractDataSource] = useState([]) // 계약 목록
  const [initialContractData, setIntialContractData] = useState([])
  const [initialExtraContractData, setIntialExtraContractData] = useState([])
  const [commentContractData, setCommentContractData] = useState([])
  const [extraContractDataSource, setExtraContractDataSource] = useState([]) // 추가 계약 목록
  const [depositDataSource, setDepositDataSource] = useState([]) // 입금 내역
  const [currentTab, setCurrentTab] = useState('callerInfo')
  const [closeContract, setCloseContract] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenContractorInfo, setIsModalOpenContractorInfo] = useState(false)
  const [isModalOpenCaseInfo, setIsModalOpenCaseInfo] = useState(false)
  const [receivedAmount, setReceivedAmount] = useState(0) // 회수 금액
  const [newContractIds, setNewContractIds] = useState([])
  const [deletedContractIds, setDeletedContractIds] = useState([])
  const [newReasonIds, setNewReasonIds] = useState([])
  const [removedReasonIds, setRemovedReasonIds] = useState([])
  const [newDepositIds, setNewDepositIds] = useState([])
  const [removedDepositIds, setRemovedDepositIds] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [form] = Form.useForm() // 수정중인데 나갈 경우, 경고창
  const formStatus = getRecordFormStatus()
  const prevStatus = useRef(formStatus)
  const [contractTotal, setContractTotal] = useState(0)
  const [formEditable, setFormEditable] = useState(true)
  useEffect(() => {
    sumReceivedAmount() // 회수금액
    setFormEditable(_.get(contractRecordData, 'deleted') === null && _.get(contractRecordData, 'closed') === null)
  }, [depositDataSource, contractRecordData])

  const {
    cancelModalOpen,
    onCloseCancelModal,
    onOkCancelModal,
    onEdit,
    onCancel,
    onSave,
    onDeleteContractPayment,
    readOnly
  } = useSavingContractRecordDetailForm(
    contractRecordData,
    formStatus,
    newContractIds,
    deletedContractIds,
    refetch,
    setNewContractIds,
    setDeletedContractIds,
    newReasonIds,
    setNewReasonIds,
    removedReasonIds,
    setRemovedReasonIds,
    newDepositIds,
    setNewDepositIds,
    removedDepositIds,
    setRemovedDepositIds,
    pageDelete,
    setEditMode,
    setContractDataSource,
    setExtraContractDataSource,
    null,
    setDepositDataSource,
    contractDataSource,
    extraContractDataSource,
    initialContractData,
    initialExtraContractData
  )

  const {
    onRemoveContractRecord
  } = useContractRecordAddEditRemove(
    refetch
  )

  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal
  } = useConfirmModal(
    onRemoveContractRecord
    // onDeleteCallRecord,
    // onDeleteContract,
    // onDeleteContractItem,
    // onDeleteComment,
    // onDeletePaymentRecord
  )

  const {
    callDataSource,
    isDuplicateCaseNumber,
    isDuplicateOpponent,
    duplicationTableColumns,
    caseDataSource
  } = useContractorInfoColumns() // 콜 고객 정보, 계약자 정보

  const {
    onCloseContractRecord,
    onUncloseContractRecord
  } = useContractRecordClose(refetch)
  const {
    onEditContractRecord
  } = useContractRecordAddEditRemove(refetch)
  // 종결 버튼 상태 확인
  const handleClose = (e) => {
    if (pageStatusClosed) {
      onCloseContractRecord(id)
    } else {
      onUncloseContractRecord(id)
    }
    setCloseContract(!closeContract)
  }
  const onOkDeleteConfirmModal = () => {
    setPageDelete(true)
    onRemoveContractRecord(id)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setIsModalOpenContractorInfo(false)
    setIsModalOpenCaseInfo(false)
    setPageDelete(true)
    contractUserInfoForm.submit()
    contractInfoForm.submit()
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setPageDelete(false)
    setIsModalOpenContractorInfo(false)
    setIsModalOpenCaseInfo(false)
  }
  const handleModal = (value) => {
    if (value === 'duplication') {
      setIsModalOpen(true)
    } else if (value === 'contractor') {
      setIsModalOpenContractorInfo(true)
    } else if (value === 'case') {
      setIsModalOpenCaseInfo(true)
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    }
  }
  const { data } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only'
  })
  const currentUser = _.get(data, ['CurrentUser'])

  // 회수금액
  const sumReceivedAmount = () => {
    const sum = _.sumBy(depositDataSource, 'receivedAmount')
    setReceivedAmount(sum)
  }

  const onFinish = (values) => {
    onEditContractRecord(id, values)
  }
  const [contractUserInfoForm] = Form.useForm()
  const [contractInfoForm] = Form.useForm()

  // 콜 고객 정보/계약자 정보 탭
  const InfoContainer = () => {
    let currentData = _.get(contractRecordData, ['callRecord'])
    let currentColumn = customerInfo
    if (currentTab !== 'callerInfo') {
      currentData = contractRecordData
      currentColumn = contractCustomerInfo
    }
    return (
      <>
        <ColumnGapWrapper $gap={15}>
          {currentColumn.map(({ key, label, value }) => {
            // 수정: 중복 기능 구현 필요
            let content = _.get(currentData, [value])
            if (_.isObject(content)) {
              content = content.name
            }
            return (
              <RowGapWrapper key={key}>
                <TableCategory>{label}</TableCategory>
                {/* {currentTab === 'contractorInfo'
                  ? (
                    <>
                      {key === 'opponent' && ( // 상대방
                        <StyledTag color='red' onClick={() => handleModal('duplication')}>
                          중복
                        </StyledTag>)}
                      <Input
                        defaultValue={data}
                        placeholder='내용을 입력해주세요.'
                        min={0}
                        style={{
                          width: 160
                        }}
                        // onChange={(value) => handleFieldChange(key, 'receivedAmount', value)}
                      />
                    </>)
                  : (
                    <div>{data}</div>)} */}
                <div>{content}</div>
              </RowGapWrapper>
            )
          })}
        </ColumnGapWrapper>
        {/* 계약자 정보 수정 모달 */}
        <StyledModal
          width={1700}
          height={800}
          open={isModalOpenContractorInfo}
          onOk={handleOk}
          onCancel={handleCancel}
          okText='저장'
          cancelText='취소'
        >
          <Form form={contractUserInfoForm} initialValues={contractRecordData} onFinish={values => onFinish(values)}>
            <h4>계약자 정보</h4>
            <ColumnGapWrapper $gap={15}>
              <Table
                pagination={false}
                columns={callDataSource}
                dataSource={[contractRecordData]}
              />
              {isDuplicateCaseNumber &&
                <>
                  <h4>중복된 사건 정보</h4>
                  <Table
                    rowSelection={{
                      type: 'radio',
                      ...rowSelection
                    }}
                    pagination={false}
                    columns={duplicationTableColumns}
                    dataSource={tmpDuplicationData}
                  />
                </>}
              {isDuplicateOpponent &&
                <>
                  <h4>중복된 상대방 정보</h4>
                  <Table
                    rowSelection={{
                      type: 'radio',
                      ...rowSelection
                    }}
                    pagination={false}
                    columns={duplicationTableColumns}
                    dataSource={tmpDuplicationData}
                  />
                </>}
            </ColumnGapWrapper>
          </Form>
        </StyledModal>
        {/* 사건 정보 수정 모달 */}
        <StyledModal
          width={1200}
          height={800}
          open={isModalOpenCaseInfo}
          onOk={handleOk}
          onCancel={handleCancel}
          okText='저장'
          cancelText='취소'
        >
          {/* <Button onClick={() => { console.log('contract', contractInfoForm.getFieldValue()) }}>DEBUG!</Button> */}
          <Form form={contractInfoForm} initialValues={contractRecordData} onFinish={values => onFinish(values)}>
            <h4>계약자 정보</h4>

            <ColumnGapWrapper $gap={15}>
              <Table
                pagination={false}
                columns={caseDataSource}
                dataSource={[contractRecordData]}
              />
              {isDuplicateCaseNumber &&
                <>
                  <h4>중복된 사건 정보</h4>
                  <Table
                    rowSelection={{
                      type: 'radio',
                      ...rowSelection
                    }}
                    pagination={false}
                    columns={duplicationTableColumns}
                    dataSource={tmpDuplicationData}
                  />
                </>}
              {isDuplicateOpponent &&
                <>
                  <h4>중복된 상대방 정보</h4>
                  <Table
                    rowSelection={{
                      type: 'radio',
                      ...rowSelection
                    }}
                    pagination={false}
                    columns={duplicationTableColumns}
                    dataSource={tmpDuplicationData}
                  />
                </>}
            </ColumnGapWrapper>
          </Form>
        </StyledModal>
      </>
    )
  }

  const tabItems = [
    {
      key: 'callerInfo',
      label: '콜 고객 정보',
      children: <InfoContainer tab='call' />
    },
    {
      key: 'contractorInfo',
      label: '계약자 정보',
      children: <InfoContainer tab='contract' />
    }
  ]

  const onChange = (key) => {
    setCurrentTab(key)
  }

  useEffect(() => {
    if (!cancelModalOpen && !pageDelete) {
      prevStatus.current = formStatus
    }
  }, [cancelModalOpen, pageDelete])

  const pageStatus = _.get(contractRecordData, 'deleted') === null && (editMode === false)
  const pageStatusClosed = _.get(contractRecordData, 'closed') === null
  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onOkDeleteConfirmModal}
        onCancel={onCloseModal}
      />
      <BlockModal
        title='수정을 취소하시겠습니까?'
        open={cancelModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <>
            <HeaderSpacer>
              <HeaderContainer>
                <PaymentHeaderTitle title={contractRecordData?.name} readOnly={readOnly} onOpenModal={onOpenModal} contractId={id} />
                <Spacer />
                {pageStatus
                  ? (
                    <>
                      <Button variant='outlined' danger onClick={() => onOpenModal(id, 'deleteContract')}>
                        <DeleteOutlined />
                        삭제하기
                      </Button>
                      <Button onClick={() => handleClose()}>
                        {pageStatusClosed
                          ? '종결하기'
                          : '종결취소'}
                      </Button>
                    </>)
                  : null}
              </HeaderContainer>
            </HeaderSpacer>
          </>
        )}
        content={
          <Form form={form}>
            <RowGapWrapper $gap={10}>
              <ColumnGapWrapper $gap={10} style={{ minWidth: '300px' }}>
                {/* <Button onClick={() => { console.log('contract', form.getFieldValue()) }}>DEBUG!</Button> */}
                <ContractTotalRecordCard contractTotal={contractTotal} receivedAmount={receivedAmount} />
                <InfoCard
                  title='사건 정보'
                  data={contractRecordData}
                  readOnly={readOnly}
                  pageStatus={pageStatus}
                  pageStatusClosed={pageStatusClosed}
                  handleModal={handleModal}
                />
                <NarrowCustomCard style={{ position: 'relative' }}>
                  {(readOnly && pageStatus && pageStatusClosed &&
                    currentTab === 'contractorInfo') && (
                      <StyledEditButton
                        onClick={() => handleModal('contractor')}
                      >
                        <EditOutlined />
                      </StyledEditButton>)}
                  <CustomTabs defaultActiveKey='0' items={tabItems} onChange={onChange} />
                </NarrowCustomCard>
              </ColumnGapWrapper>
              <ColumnGapWrapper $gap={10} style={{ maxWidth: '1100px' }}>
                <ContractForm
                  id={id}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  contractRecordData={contractRecordData}
                  contractDataSource={contractDataSource}
                  setContractDataSource={setContractDataSource}
                  refetch={refetch}
                  form={form}
                  formEditable={formEditable}
                  readOnly={readOnly}
                  initialData={initialContractData}
                />
                <ExtraContractForm
                  id={id}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  contractRecordData={contractRecordData}
                  contractDataSource={extraContractDataSource}
                  setContractDataSource={setExtraContractDataSource}
                  refetch={refetch}
                  form={form}
                  formEditable={formEditable}
                  initialData={initialExtraContractData}
                  readOnly={readOnly}
                  onSave={onSave}
                  onEdit={onEdit}
                  onCancel={onCancel}
                  onDeleteContractPayment={onDeleteContractPayment}
                />
                <CommentForm
                  id={id}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  currentUser={currentUser}
                  contractRecordData={contractRecordData}
                  commentContractData={commentContractData}
                  setCommentContractData={setCommentContractData}
                  refetch={refetch}
                  form={form}
                  readOnly={readOnly}
                  onSave={onSave}
                  onEdit={onEdit}
                  onCancel={onCancel}
                  formEditable={formEditable}
                  initialData={commentContractData}
                />
                <DepositForm
                  id={id}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  currentUser={currentUser}
                  contractRecordData={contractRecordData}
                  depositDataSource={depositDataSource}
                  setDepositDataSource={setDepositDataSource}
                  refetch={refetch}
                  form={form}
                  formEditable={formEditable}
                />
              </ColumnGapWrapper>
            </RowGapWrapper>
          </Form>
        }
      />
    </>
  )
}
