import { parseMinutesToHourMin } from '../../../../common/util'
import styled from 'styled-components'
import COLORS from '../../../../common/Theme/colors'
import { Table, Tooltip } from 'antd'

const Container = styled.div`
  .ant-tooltip .ant-tooltip-content {
    width: fit-content;
  }
`
const ItemCountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 30px;
  padding: 5px 22px;
  
  border: 2px solid ${COLORS.orange_1};
  border-radius: 5px;
  cursor: ${props => props.cursor};
`
const ColumnLine = styled.div`
`
const TableTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: ${props => props.$color};
`

const TimesheetTotalDeltaMinutesTable = ({ totalMinutes = 0, totalDeltaMinutes }) => {
  const columns = [
    {
      dataIndex: 'title',
      rowScope: 'row',
      render: (v, record) => <TableTitleContainer $color={record.color}>{v}</TableTitleContainer>
    },
    {
      dataIndex: 'minutes',
      render: (v, record) => parseMinutesToHourMin(v)
    }
  ]
  const isDiffPositive = totalDeltaMinutes >= 0
  return (
    <Table
      showHeader={false}
      columns={columns}
      pagination={false}
      dataSource={[
        {
          title: '총 시간',
          minutes: totalMinutes
        },
        {
          title: isDiffPositive ? '+' : '-',
          minutes: Math.abs(totalDeltaMinutes),
          color: isDiffPositive ? COLORS.primaryColor : COLORS.red
        },
        {
          title: '조정 후 총 시간',
          minutes: totalDeltaMinutes + totalMinutes
        }
      ]}
    />
  )
}

export const TimehseetTableTotalHourMin = ({ tooltipDisabled, totalMinutes = 0, totalDeltaMinutes }) => {
  const getPopupContainer = () => document.getElementById('TimehseetTableTotalHourMin')
  return (
    <Container id='TimehseetTableTotalHourMin'>
      <Tooltip getPopupContainer={getPopupContainer} {...tooltipDisabled ? { open: false } : {}} title={<TimesheetTotalDeltaMinutesTable totalMinutes={totalMinutes} totalDeltaMinutes={totalDeltaMinutes} />} color={`${COLORS.white}`} placement='bottom' style={{ padding: '20px 50px' }}>
        <ItemCountWrapper cursor={!tooltipDisabled && 'pointer'}>
          <div>등록된 총 시간:</div>
          {parseMinutesToHourMin(totalMinutes)}
        </ItemCountWrapper>
      </Tooltip>
      <ColumnLine />
    </Container>
  )
}
