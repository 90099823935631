import { Upload, Typography, Spin } from 'antd'

const { Dragger } = Upload

export const CustomDragger = ({ fileUploadLoading, ...draggerProps }) => {
  return (
    <Spin spinning={fileUploadLoading}>
      <Dragger {...draggerProps} fileList={[]}>
        <Typography.Text>
          {'이곳을 '}
        </Typography.Text>
        <Typography.Link>
          클릭
        </Typography.Link>
        <Typography.Text>
          {' 또는 '}
        </Typography.Text>
        <Typography.Link>
          파일을 드래그
        </Typography.Link>
        <Typography.Text>
          {' 하세요'}
        </Typography.Text>
      </Dragger>
    </Spin>
  )
}
