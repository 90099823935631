import { useOutletContext } from 'react-router-dom'
import { removeCallRecords } from '../../apollo/mutations'
import { MESSAGES } from '../../../common/config'
import { useMutation } from '@apollo/client'
import _ from 'lodash'

/* 콜 기록 삭제 */
export const useCallRecordRemove = (refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [removeCallRecordMutFn] = useMutation(removeCallRecords)
  const handleError = () => {
    messageApi.open(MESSAGES.removeCallRecords)
  }
  const onRemoveCallRecord = (ids) => {
    removeCallRecordMutFn({
      variables: {
        ids
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveCallRecords', 'ok'])) refetch(ids)
      else messageApi.open(MESSAGES.removeCallRecords)
    }).catch(e => {
      messageApi.open(MESSAGES.removeCallRecords)
      handleError()
    })
  }
  return {
    onRemoveCallRecord
  }
}
