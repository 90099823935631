import { RowContainer } from '../../../common/Theme/style'

import COLORS from '../../../common/Theme/colors'
import { Form, Input, Button, Select } from 'antd'

const layout = {
  labelCol: {
    span: 6
  }
}

const DepartmentForm = ({ users = [], onSave, onCancel, editing, initialValues = {} }) => {
  const userList = users.map(item => {
    return {
      label: item.name,
      value: item.id
    }
  })
  const handleChange = (leaders) => {
  }
  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  return (
    <Form
      initialValues={initialValues}
      onFinish={v => onSave(v)}
      name='basic'
      style={{ width: '800px' }}
    >
      <Form.Item
        label='부서 이름'
        name='name'
        {...layout}
        rules={[
          {
            required: true,
            message: '부서 이름을 입력하세요.'
          }
        ]}
      >
        <Input
          placeholder='부서 이름을 입력하세요.'
        />
      </Form.Item>
      {editing
        ? (
          <Form.Item
            label='부서장'
            name='leaders'
            {...layout}
            rules={[
              {
                required: true,
                message: '부서장을 선택하세요.'
              }
            ]}
          >
            {userList.length === 0
              ? (
                <div style={{ width: '100%', padding: '4px 10px', border: `1px solid ${COLORS.gray_4} `, borderRadius: '4px', backgroundColor: `${COLORS.white_2}`, color: `${COLORS.gray_3}` }}>
                  부서에 직원을 추가해주세요.
                </div>)
              : (
                <Select
                  mode='multiple'
                  allowClear
                  style={{ width: '100%' }}
                  filterOption={filterOption}
                  placeholder='부서장을 선택하세요.'
                  onChange={handleChange}
                  options={userList}
                />)}
          </Form.Item>
          )
        : null}
      <Form.Item>
        <RowContainer style={{ gap: '10px', justifyContent: 'right' }}>
          <Button onClick={onCancel}>
            취소하기
          </Button>
          <Button type='primary' htmlType='submit'>
            등록하기
          </Button>
        </RowContainer>
      </Form.Item>
    </Form>
  )
}

export default DepartmentForm
