import { useState } from 'react'
import { useDepartmentList } from '../../common/filter/useDepartmentList'
import _ from 'lodash'
import { useRecordFilterSearchParams } from '../callRecord/useRecordFilterSearchParams'
import { useUserList } from '../../../../common/hooks/apollo/useUserList'
import { projectTypeOptions } from '../../../config'

export const useContractRecordFilter = () => {
  const [openStatusByKey, setOpenStatusByKey] = useState({}) // 필터가 열려 렌더링 발생할때만 쿼리 호출할 수 있도록 스테이트 관리
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([])
  const {
    meta,
    searchParams,
    getUpdatedSearchParams,
    onChangeMeta,
    onResetFilterAndGroup
  } = useRecordFilterSearchParams()
  const onDepartmentListComplete = (departments) => {
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }
  const {
    departments
  } = useDepartmentList(null, onDepartmentListComplete)

  const convertToOptions = ({ id, name, group, ...v }) => {
    return {
      label: name,
      value: id,
      key: id,
      group
    }
  }

  const {
    loading,
    users
  } = useUserList(
    userSearchQuery
  )
  const filters = [
    {
      key: 'departmentId', // 본사 부서
      hideSearch: true,
      value: '부서',
      options: _.map(departments, v => convertToOptions(v, 'departments'))
    },
    {
      key: 'assignedLawyerId',
      value: '담당변호사',
      options: _.map(
        _.filter(
          users,
          user => {
            return _.isEmpty(meta.filter.caseIds) ? _.intersection(selectedDepartmentIds, _.map(_.get(user, ['departments'], []), 'id')).length > 0 : user
          }
        ),
        v => convertToOptions(v, 'assignedLawyerId')
      ),
      selectedDepartmentOptions: selectedDepartmentIds,
      onSearch: v => setUserSearchQuery(v),
      emptyText: '선택 가능한 사용자가 없습니다.'
    },
    {
      key: 'projectTypeId', // 본사 부서
      hideSearch: true,
      value: '사건구분',
      options: projectTypeOptions
    }
  ].map(v => ({
    ...v,
    selectedItems: _.get(meta, ['filter', v.key], ''),
    selectedOptions: _.isEmpty(v.selectedOptions) ? v.options : v.selectedOptions,
    onChangeFilter: (value = []) => {
      onChangeMeta('filter', v.key, value)
    },
    onChangeDepartmentFilter: (value) => {
      setSelectedDepartmentIds(value)
    },
    onOpenChange: open => setOpenStatusByKey(openStatusByKey => ({ ...openStatusByKey, [v.key]: open }))
  }))
  return {
    loading,
    meta,
    filters,
    searchParams,
    onChangeMeta,
    convertToOptions,
    onResetFilterAndGroup,
    getUpdatedSearchParams,
    openStatusByKey,
    departments
  }
}
