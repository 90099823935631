import { CustomDragger } from '../../CustomDragger'
import { HeaderContentFooter } from '../../../../../common/components/HeaderContentFooter'
import { Button, Modal, Table } from 'antd'
import _ from 'lodash'

export const ExcelUploadModal = ({
  isFileUploadModalOpen,
  fileUploaderProps,
  isFileDataModalOpen,
  handleCancel,
  handleOk,
  modalTitle,
  columns,
  tableData,
  onChangeRowKeys = [],
  onRegister = () => {},
  excelData,
  dataCategory,
  btnDisable,
  fileUploadLoading
}) => {
  const modifiedData =
  dataCategory === 'case'
    ? _.map(excelData, (data, i) => {
      return {
        key: i,
        allocates: _.get(data, ['allocates', 'value'], []),
        allocatesValid: _.get(data, ['allocates', 'valid'], []),
        allocatesWarning: _.get(data, ['allocates', 'warning'], []),
        category: _.get(data, ['category', 'value'], []),
        categoryValid: _.get(data, ['category', 'valid'], []),
        categoryWarning: _.get(data, ['category', 'warning'], []),
        content: _.get(data, ['content', 'value'], []),
        contentValid: _.get(data, ['content', 'valid'], []),
        contentWarning: _.get(data, ['content', 'warning'], []),
        created: _.get(data, ['created', 'value'], []),
        createdValid: _.get(data, ['created', 'valid'], []),
        createdWarning: _.get(data, ['created', 'warning'], []),
        createdBy: _.get(data, ['created_by', 'value'], []),
        createdByValid: _.get(data, ['created_by', 'valid'], []),
        createdByWarning: _.get(data, ['created_by', 'warning'], []),
        delegates: _.get(data, ['delegates', 'value'], []),
        delegatesValid: _.get(data, ['delegates', 'valid'], []),
        delegatesWarning: _.get(data, ['delegates', 'warning'], []),
        name: _.get(data, ['name', 'value'], []),
        nameValid: _.get(data, ['name', 'valid'], []),
        nameWarning: _.get(data, ['name', 'warning'], []),
        type: _.get(data, ['type', 'value'], []),
        typeValid: _.get(data, ['type', 'valid'], []),
        typeWarning: _.get(data, ['type', 'warning'], [])
      }
    })
    : _.map(excelData, (data, i) => {
      return {
        key: i,
        name: _.get(data, ['name', 'value'], []),
        nameValid: _.get(data, ['name', 'valid'], []),
        nameWarning: _.get(data, ['name', 'warning'], []),
        email: _.get(data, ['email', 'value'], []),
        emailValid: _.get(data, ['email', 'valid'], []),
        emailWarning: _.get(data, ['email', 'warning'], []),
        rank: _.get(data, ['rank', 'value'], []),
        rankValid: _.get(data, ['rank', 'valid'], []),
        rankWarning: _.get(data, ['rank', 'warning'], []),
        userGroup: _.get(data, ['user_group', 'value'], []),
        userGroupValid: _.get(data, ['user_group', 'valid'], []),
        userGroupWarning: _.get(data, ['user_group', 'warning'], []),
        department: _.get(data, ['department', 'value'], []),
        departmentValid: _.get(data, ['department', 'valid'], []),
        departmentWarning: _.get(data, ['department', 'warning'], [])
      }
    })
  const isValidDataDoubleArray = excelData?.map(category => {
    return Object.values(category)?.map(item => {
      return item.valid === true
    })
  })

  const isValidDataArray = isValidDataDoubleArray?.map(category => {
    return Object.values(category)?.every(item => item === true)
  })
  const checker = array => array?.every(v => v === true)
  const validation = checker(isValidDataArray)

  const controlDisable = () => {
    if (!validation === true) {
      return true
    } else if (btnDisable === true && !validation === false) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      <Modal
        open={isFileUploadModalOpen}
        title='엑셀 파일 추가'
        onCancel={handleCancel}
        footer={[
          <Button
            key='back'
            onClick={handleCancel}
            loading={fileUploadLoading}
          >
            취소하기
          </Button>
        ]}
      >
        <CustomDragger fileUploadLoading={fileUploadLoading} {...fileUploaderProps} fileList={[]} />
      </Modal>
      <Modal
        title={modalTitle}
        open={isFileDataModalOpen}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button
            key='back'
            onClick={handleCancel}
          >
            취소하기
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={() => {
              handleOk()
              onRegister()
            }}
            disabled={controlDisable()}
          >
            등록하기
          </Button>
        ]}
      >
        <HeaderContentFooter
          totalHeight='400px'
          headerHeight={142}
          footerHeight={0}
          content={
            <>
              <Table
                columns={columns}
                dataSource={modifiedData}
                pagination={false}
                scroll={{
                  y: 500
                }}
              />
            </>
          }
          footer={null}
        />
      </Modal>
    </>
  )
}
