import styled from 'styled-components'

import { BlockModal } from '../../../timeCharge/components/common/BlockModal'
import { Allocator } from '../../../timeCharge/components/manageCase/allocate/Allocator'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'

import { FormContainerFooter } from '../../../timeCharge/components/manageCase/FormContainerFooter'

import { useBlockModal } from '../../../timeCharge/hooks/common/useBlockModal'
import { useUserAllocator } from '../../../timeCharge/hooks/manageCase/allocate/useUserAllocator'
import { Segmented } from 'antd'
import { CustomSearch } from '../../../common/components/CustomSearch'
import { useSavingUserAllocation } from '../../../timeCharge/hooks/manageCase/allocate/useSavingUserAllocation'
import { useOutletContext, useParams } from 'react-router-dom'
import { RowContainer } from '../../../common/Theme/style'
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { getCaseById } from '../../apollo/queries'
import _ from 'lodash'
import { useDepartmentList } from '../../../timeCharge/hooks/apollo/useDepartmentList'
import { useUserGroupList } from '../../../timeCharge/hooks/apollo/useUserGroupList'
import { useUserAllocatorByDepartment } from '../../../timeCharge/hooks/manageCase/allocate/useUserAllocatorByDepartment'
import { MAX_CONTENT_WIDTH } from '../../../common/config'
import { HeaderContainer as _HeaderContainer, HeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-width: 500px;
  max-width: ${MAX_CONTENT_WIDTH}px;
`
const ContentHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`
const ContentBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const HeaderContainer = styled(_HeaderContainer)`
  justify-content: flex-end;
`

export const UserAllocationContainer = () => {
  const { permissions } = useOutletContext()

  const [inputValue, setInputValue] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [chartState, setChartState] = useState(_.includes(permissions, 'ALLOCATE_OR_DELEGATE') ? '부서' : '직원')
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([])
  const {
    caseId
  } = useParams()
  const { data, loading } = useQuery(getCaseById, {
    variables: {
      getProjectId: caseId
    },
    fetchPolicy: 'no-cache'
  })
  const allocatedUsers = _.get(data, ['GetProject', 'users'], [])
  const removedDeallocated = _.filter(allocatedUsers, { relation: 'ALLOCATED' })
  const state = true
  const {
    blockModalOpen,
    onFetchAndNav,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()

  const {
    userIdsToAllocate,
    userIdsToDisallocate,
    preAllocationTableProps,
    postAllocationUsers,
    onAllocateUsers,
    onDisAllocateUser
  } = useUserAllocator(removedDeallocated, loading, selectedDepartmentIds, selectedEmployees, searchQuery, state)
  const onDepartmentListComplete = (departments) => {
    // if (chartState === '부서') return
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }

  const {
    departments,
    loading: allDepartmentsLoading
  } = useDepartmentList(chartState === '부서' ? searchQuery : null, onDepartmentListComplete)
  const delegates = _.get(data, ['GetProject', 'delegates'], [])
  const departmentsToDelegate = _.uniqBy(_.flatMap(delegates, v => _.get(v, ['departments'])), v => v.id)
  const {
    userIdsToDelegate,
    userIdsToAbdicate,
    postDelegationUsers,
    preAllocationTableProps: preDelegationTableProps,
    onAbdicateUser,
    onDelegateUsersByDepartments
  } = useUserAllocatorByDepartment(
    departments,
    allDepartmentsLoading,
    departmentsToDelegate,
    loading,
    delegates
  )

  const onUserGroupComplete = (users) => {
    setSelectedEmployees(_.map(users, 'id'))
  }
  const {
    userGroups
  } = useUserGroupList(onUserGroupComplete)

  const {
    onSave,
    onCancel
  } = useSavingUserAllocation(
    chartState,
    caseId,
    userIdsToAllocate,
    userIdsToDisallocate,
    userIdsToDelegate,
    userIdsToAbdicate,
    onFetchAndNav
  )
  const register = '등록'

  useEffect(() => {
    setInputValue(null)
    setSearchQuery(null)
  }, [chartState])
  return (
    <>
      <HeaderContentFooter
        header={
          <HeaderContainer>
            <HeaderTitle title='담당자 배당' />
            <Spacer />
            <FormContainerFooter onSave={onSave} onCancel={onCancel} isRegistering={register === '등록'} />
          </HeaderContainer>
        }
        content={
          <ContentContainer>
            <ContentHeaderContainer>
              <Segmented
                options={_.includes(permissions, 'ALLOCATE_OR_DELEGATE') ? ['부서', '직원'] : ['직원']}
                value={chartState}
                style={{ width: _.includes(permissions, 'ALLOCATE_OR_DELEGATE') ? 100 : 50, margin: '10px 0 15px 0' }}
                onChange={(value) => {
                  setChartState(value)
                }}
              />
              <CustomSearch value={inputValue} onChange={e => setInputValue(e.target.value)} onSearch={e => setSearchQuery(e)} />
            </ContentHeaderContainer>
            <ContentBodyContainer>
              <RowContainer style={{ alignItems: 'start', justifyContent: 'center' }}>
                <Allocator // 필터 & 차트 포함
                  filterProps={{
                    selectedDepartmentIds,
                    employees: _.map(userGroups, userGroup => ({ key: userGroup.id, allocation: userGroup?.name })),
                    departments: _.map(departments, department => ({ name: _.get(department, ['name']), key: _.get(department, ['id']) })),
                    onChangeSelection: (rowKeys) => setSelectedDepartmentIds(rowKeys),
                    selectedEmployees,
                    onChangeEmployeesSelection: rowKeys => setSelectedEmployees(rowKeys)
                  }}
                  allocatedUsers={postAllocationUsers}
                  delegatedUsers={postDelegationUsers}
                  chartState={chartState}
                  onAllocateUsers={onAllocateUsers}
                  onDisAllocateUser={onDisAllocateUser}
                  onAbdicateUser={onAbdicateUser}
                  disableAbdicate={!_.includes(permissions, 'ALLOCATE_OR_DELEGATE')}
                  onDelegateUsersByDepartments={onDelegateUsersByDepartments}
                  preAllocationTableProps={chartState === '직원' ? preAllocationTableProps : preDelegationTableProps}
                />
              </RowContainer>
            </ContentBodyContainer>
          </ContentContainer>
        }
      />
      <BlockModal
        title='담당자 배당을 취소하시겠습니까?'
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}
