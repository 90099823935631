import { useState } from 'react'
import { RowContainer } from '../../../common/Theme/style'
import { useProjectSubTypes } from '../../hooks/apollo/useProjectSubTypes'
import ProjectSubTypeAdder from '../settings/projectType/ProjectSubTypeAdder'
import { useProjectSubTypeAddEditDelete } from '../../../common/hooks/settings/useProjectSubTypeAddEditDelete'
import _ from 'lodash'
import { Form, Input, Button, ColorPicker } from 'antd'

const ProjectTypeForm = ({ onSave, onCancel, editing, initialValues = {} }) => {
  const { projectSubTypes, loading } = useProjectSubTypes()
  const {
    onAddProjectSubType,
    onEditProjectSubType,
    onRemoveProjectSubType
  } = useProjectSubTypeAddEditDelete()
  const [color, setColor] = useState('#1677ff')
  return (
    <Form
      initialValues={initialValues}
      onFinish={v => onSave(v)}
      name='basic'
      style={{ width: '800px' }}
    >
      <Form.Item>
        <RowContainer style={{ gap: '10px', justifyContent: 'right' }}>
          <Button onClick={onCancel}>
            취소하기
          </Button>
          <Button type='primary' htmlType='submit'>
            등록하기
          </Button>
        </RowContainer>
      </Form.Item>
      <Form.Item
        name='color'
        label='색깔'
        rules={[
          {
            required: true,
            message: '색깔을 선택하세요'
          }
        ]}
      >
        <ColorPicker value={color} onChange={setColor} />
      </Form.Item>
      <Form.Item
        label='사건분류 이름'
        name='name'
        style={{
          margin: 0
        }}
        rules={[
          {
            required: true,
            message: '이름을 입력하세요!'
          }
        ]}
      >
        <RowContainer style={{ gap: 10 }}>
          <Input defaultValue={initialValues.name} />
        </RowContainer>
      </Form.Item>
      {editing && (
        <Form.Item style={{ marginTop: 20 }}>
          {!loading && (
            <ProjectSubTypeAdder
              initDataSource={_.map(projectSubTypes, ({ ...v }) => ({ key: v.id, ...v }))}
              onAddProjectSubType={onAddProjectSubType}
              onEditProjectSubType={onEditProjectSubType}
              onRemoveProjectSubType={onRemoveProjectSubType}
            />)}
        </Form.Item>)}
    </Form>
  )
}

export default ProjectTypeForm
