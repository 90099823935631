import { useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { CalendarCell } from './CalendarCell'
import { parseDateObj } from '../../../../common/util'
import './style.css'
import _ from 'lodash'

const CustomCalendar = ({
  listByDate,
  onDoubleClickCalendarCell,
  onClickCalendarListItem,
  onChangeActiveStartDate,
  onClickCellSummary,
  formatDay
}) => {
  const calendarRef = useRef()
  const [hoveredId, setHoveredId] = useState()
  return (
    <Calendar
      locale='ko-KO'
      ref={calendarRef}
      formatDay={formatDay}
      minDetail='month'
      onActiveStartDateChange={({ activeStartDate }) => onChangeActiveStartDate(activeStartDate)}
      tileContent={({ date }) => {
        const list = _.get(listByDate, [parseDateObj(new Date(date))], [])
        return (
          <CalendarCell
            totalMinutes={_.sumBy(list, 'minutes')}
            onDoubleClickCalendarCell={() => onDoubleClickCalendarCell(date)}
            dataList={list}
            hoveredCaseId={hoveredId}
            onHoverItem={v => setHoveredId(v)}
            onClickCalendarListItem={onClickCalendarListItem}
            onClickCellSummary={() => onClickCellSummary(date, list)}
          />
        )
      }}
    />
  )
}

export default CustomCalendar
