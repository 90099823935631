import styled from 'styled-components'

const RemovedFromCaseTagContainer = styled.div`
  background-color: ${props => props.$faintcolor};
  color: rgba(76, 76, 76, 1);
  font-size: 11px;
  border-radius: 5px;
  padding: 2px 7px;
  margin-left: 5px;
  font-weight: 400;
`

const RemovedFromCaseTag = ({ faintcolor = 'rgba(76, 76, 76, 0.1)' }) => {
  return (
    <RemovedFromCaseTagContainer $faintcolor={faintcolor} color='rgba(76, 76, 76, 1)'>배제</RemovedFromCaseTagContainer>
  )
}

export default RemovedFromCaseTag
