import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { todayDate } from '../components/common/CommonFunction.js'
import { CustomSearch } from '../../common/components/CustomSearch.js'
import ContractRecordFilter from '../components/filter/ContractRecordFilter.js'
import { useContractRecordList } from '../hooks/apollo/useContractRecordList.js'
import { usePaymentTable } from '../hooks/contractRecord/table/usePaymentTable.js'
import { usePaymentList } from '../hooks/contractRecord/usePaymentList.js/index.js'
import { HeaderContentFooter } from '../../common/components/HeaderContentFooter.js'
import { ContractCustomTable } from '../components/customTable/ContractCustomTable.js'
import { useOverdueViewerColumns } from '../hooks/overdue/table/useOverdueViewerColumns.js'
import { CustomSearchContainer, ResponsiveGapContainer } from '../../common/Theme/style.js'
import { navigateToContractRecord } from '../cacheAndNavigation/paymentRecord/navigation.js'
import { useDefaultDateRangeFilter } from '../../timeCharge/hooks/filter/useDefaultDateRangeFilter.js'
import { useContractRecordFilter } from '../hooks/filter/contractRecordDetail/useContractRecordFilter.js'
import { useContractRecordColumnsList } from '../hooks/contractRecord/contractRecordDetail/useContractRecordColumnsList.js'

const ContractRecordContainer = () => {
  const [searchQuery, setSearchQuery] = useState(null) // 검색창
  const navigate = useNavigate()
  const [contractStateSwitch, setContractStateSwitch] = useState(false) // 계약상태 switch: true(전체보기), false(종결 보기)

  const {
    viewMode,
    groupModeAllContract,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    onChangeViewMode,
    onChangeGroupMode,
    onSelectFilter,
    filterMeta,
    ...props
  } = usePaymentList()

  const {
    meta,
    filters,
    searchParams,
    onChangeMeta,
    onResetFilterAndGroup,
    getUpdatedSearchParams
  } = useContractRecordFilter()

  /*
  계약 기록 데이터
*/
  const {
    loading,
    variables,
    contractRecordsData,
    totalCount,
    refetch,
    ...preAllocationTableProps
  } = useContractRecordList(
    true, // ascending
    null, // key
    searchQuery,
    meta.filter.datetimeRange[0], // from,
    meta.filter.datetimeRange[1], // to,
    meta.filter.departmentId[0], // departmentId,
    false, // deleted,
    null, // caseNumber,
    meta.filter.projectTypeId[0], // projectTypeId,
    meta.filter.assignedLawyerId[0], // assignedLawyerId,
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeAllContract,
    false, // outstanding
    null, // outstandingFrom
    null, // outstandingTo
    contractStateSwitch // closed
  )
  // 총 미수금
  const {
    totalDueAmount
  } = useContractRecordList(
    true, // ascending
    null, // key
    searchQuery,
    meta.filter.datetimeRange[0], // from,
    meta.filter.datetimeRange[1], // to,
    meta.filter.departmentId[0],
    false, // deleted,
    null, // caseNumber,
    meta.filter.projectTypeId[0],
    meta.filter.assignedLawyerId[0],
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeAllContract,
    false, // outstanding
    meta.filter.datetimeRange[0], // outstandingFrom
    meta.filter.datetimeRange[1], // outstandingTo
    contractStateSwitch // closed
  )
  // 날짜 필터 형사
  const {
    totalDueAmount: currentfilteredType1
  } = useContractRecordList(
    true, // ascending
    null, // key
    searchQuery, // searchQuery
    meta.filter.datetimeRange[0], // from,
    meta.filter.datetimeRange[1], // to,
    meta.filter.departmentId[0], // departmentId,
    false, // deleted,
    null, // caseNumber,
    meta.filter.projectTypeId[0] === undefined ? '15ce1e5f-481b-4da6-a6d1-7dd7d8f2db11' : meta.filter.projectTypeId[0], // projectTypeId,
    meta.filter.assignedLawyerId[0], // assignedLawyerId,
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeAllContract,
    false, // outstanding
    meta.filter.datetimeRange[0], // outstandingFrom
    meta.filter.datetimeRange[1], // outstandingTo
    contractStateSwitch // closed
  )
  // 날짜 필터 민사
  const {
    totalDueAmount: currentfilteredType2
  } = useContractRecordList(
    true, // ascending
    null, // key
    searchQuery,
    meta.filter.datetimeRange[0], // from,
    meta.filter.datetimeRange[1], // to,
    meta.filter.departmentId[0],
    false, // deleted,
    null, // caseNumber,
    meta.filter.projectTypeId[0] === undefined ? 'b17d7dbb-848c-4f9f-8b37-090c39a3098b' : meta.filter.projectTypeId[0], // projectTypeId,
    meta.filter.assignedLawyerId[0], // assignedLawyerId,
    viewMode === 'list' || viewMode === 'calendar' ? null : groupModeAllContract,
    false, // outstanding
    meta.filter.datetimeRange[0], // outstandingFrom
    meta.filter.datetimeRange[1], // outstandingTo
    contractStateSwitch // closed
  )

  const {
    contractRecordsData: overdueContractRecords
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    null, // to,
    null, // departmentId,
    null, // deleted,
    null, // caseNumber,
    null, // projectTypeId,
    null, // assignedLawyerId,
    null,
    true, // outstanding
    null, // outstandingFrom
    todayDate, // outstandingTo,
    null // closed
  )

  const {
    contractRecordTableColumns
  } = useContractRecordColumnsList(overdueContractRecords)

  const {
    onClickRow
  } = usePaymentTable(searchParams)
  const {
    getOverdueViewerTableGroupColumns,
    getOverdueViewerTableGroupTypeColumns
  } = useOverdueViewerColumns()
  // 필터: '이번달' 기본 세팅
  useDefaultDateRangeFilter(meta, 'month', (defDateRange) => {
    const newSearchString = getUpdatedSearchParams('filter', 'datetimeRange', defDateRange, searchParams)
    navigateToContractRecord(navigate, newSearchString)
  })

  return (
    <>
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <ResponsiveGapContainer>
            <CustomSearchContainer>
              <CustomSearch onSearch={v => setSearchQuery(v)} placeholder='의뢰인명/전화번호를 입력해주세요.' />
            </CustomSearchContainer>
            <ContractRecordFilter
              viewMode={viewMode}
              groupMode={groupModeAllContract}
              loading={loading}
              meta={meta}
              filters={filters}
              onChangeMeta={onChangeMeta}
              onResetFilterAndGroup={onResetFilterAndGroup}
              onSelectFilter={onSelectFilter}
              onChangeGroupMode={onChangeGroupMode}
            />
          </ResponsiveGapContainer>
        )}
        content={(
          <ContractCustomTable
            data={contractRecordsData}
            defaultColumns={contractRecordTableColumns}
            groupColumns={getOverdueViewerTableGroupColumns}
            typeGroupColumns={getOverdueViewerTableGroupTypeColumns}
            total={totalCount}
            pageType='contractRecord'
            viewMode={viewMode}
            groupModeContract={groupModeAllContract}
            onClickRow={onClickRow}
            scroll={1000}
            loading={loading}
            onChangeViewMode={onChangeViewMode}
            onChangeGroupMode={onChangeGroupMode}
            contractStateSwitch={contractStateSwitch}
            setContractStateSwitch={setContractStateSwitch}
            totalDueAmount={totalDueAmount}
            currentfilteredType1={meta.filter.projectTypeId[0] === 'b17d7dbb-848c-4f9f-8b37-090c39a3098b' ? 0 : currentfilteredType1}
            currentfilteredType2={meta.filter.projectTypeId[0] === '15ce1e5f-481b-4da6-a6d1-7dd7d8f2db11' ? 0 : currentfilteredType2}
            {...props}
            {...preAllocationTableProps}
          />
        )}
      />
    </>
  )
}

export default ContractRecordContainer
