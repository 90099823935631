import styled from 'styled-components'

const LeaveTagContainer = styled.div`
  background-color: rgba(76, 76, 76, 0.1);
  color: rgba(76, 76, 76, 1);
  font-size: 11px;
  border-radius: 5px;
  padding: 2px 7px;
  margin-left: 5px;
  font-weight: 400;
`

const LeaveTag = () => {
  return (
    <LeaveTagContainer>퇴사</LeaveTagContainer>
  )
}

export default LeaveTag
