import _ from 'lodash'

export const navigateToTimesheetForm = (navigate, formStatus = 'creating', timesheetMeta) => {
  // formStatus
  // 'crating' or 'editing'
  // timesheetMeta
  // {
  //     "title": "제목2",
  //     "updated": "2024-01-17T02:13:16.364Z",
  //     "caseNumber": "사건번호 1",
  //     "creator": "홍길동",
  //     "minutes": 662
  // }
  navigate('/timesheetForm', {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      timesheetFormStatus: formStatus,
      ..._.isObject(timesheetMeta) ? { timesheetMeta: JSON.stringify(timesheetMeta) } : {}
    }
  })
}
