import { useState } from 'react'

export const useCustomTable = () => {
  const [meta, setMeta] = useState({
    sortKey: null,
    sortAscending: null,
    hoveredKey: null,
    selectedRowKeys: []
  })

  const onChangeMeta = (key, value) => setMeta({
    ...meta,
    [key]: value
  })
  const onChangeSelectedRowKeys = (val) => onChangeMeta('selectedRowKeys', val)

  return {
    selectedRowKeys: meta.selectedRowKeys,
    onChangeSelectedRowKeys
  }
}
