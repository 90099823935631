import { CardHeader } from './CardHeader'
import { ColumnGapWrapper, MoneyContainer } from '../../../common/Theme/style'
import styled from 'styled-components'
import { Card } from 'antd'

const NarrowCustomCard = styled(Card)`
  min-width: 300px;
  width: 100%;
  
  .ant-card-body {
    padding: 5px 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .ant-card-body::before {
    content: none;
  }
  .ant-card-body::after {
    content: none;
  }
`
const MonyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 260px;
  background-color: ${props => props.$background};
  border-radius: 5px;
  padding: 2px 10px;
`
const ContentText = styled.div`
`

export const ContractTotalRecordCard = ({ contractTotal, receivedAmount = 0 }) => {
  const receivableAmount = contractTotal - receivedAmount
  return (
    <NarrowCustomCard>
      <CardHeader type='text' title='총 금액' contractTotal={contractTotal} />
      <ColumnGapWrapper>
        <MonyContainer $background='#E6F4FF'>
          <ContentText>회수금액</ContentText>
          <MoneyContainer value={receivedAmount} $color='#217AFF' />
        </MonyContainer>
        <MonyContainer $background='#FFF0F0'>
          <ContentText>미수금액</ContentText>
          <MoneyContainer value={receivableAmount} $color='#FF2B2B' />
        </MonyContainer>
      </ColumnGapWrapper>
    </NarrowCustomCard>
  )
}
