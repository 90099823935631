import { gql } from '@apollo/client'

// 휴지통 페이지 쿼리
export const getCaseList = gql`
   query GetProjectList($sortBy: SortBy!, $filter: ProjectListFilter, $offset: Int, $limit: Int) {
    GetProjectList(sortBy: $sortBy, offset: $offset, filter: $filter, limit: $limit) {
      totalCount
      totalMinutes
      ... on GetProjectWithTimeDeltaListResponse {
        totalDeltaMinutes
        data {
          id
          name
          totalMinutes
          totalDeltaMinutes
          status
          timesheetUpdated
          createdBy {
            id
            name
          }
          delegates {
            id
            name
          }
          type {
            id
            name
          }
          category
          updated
          updatedBy {
            name
            id
          }
          created
          userCount
        }
      }
      ... on GetProjectListResponse {
        data {
          id
          name
          totalMinutes
          status
          timesheetUpdated
          createdBy {
            id
            name
          }
          delegates {
            id
            name
          }
          type {
            id
            name
          }
          category
          updated
          updatedBy {
            name
            id
          }
          created
          userCount
        }
      }
    }
  }
`
