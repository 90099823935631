import React from 'react'
import { DatePicker } from 'antd'
import { customLocale } from './CommonFunction'

export const CustomDatePicker = ({ date, style, onChange, placeholder = '날짜 선택' }) => {
  return (
    <DatePicker
      locale={customLocale}
      placeholder={placeholder}
      defaultValue={date}
      style={style}
      needConfirm={false}
      onChange={onChange}
    />
  )
}
