import { getWindowHistoryMetaByKey } from '../common'

export const getCaseContentViewMode = () => {
  return getWindowHistoryMetaByKey('viewMode', true) || 'calendar'
}
export const getShowCaseTable = () => {
  return getWindowHistoryMetaByKey('showCaseTable', true)
}
export const getCaseListStatus = () => {
  return getWindowHistoryMetaByKey('caseListStatus', true)
}
