import { useState } from 'react'
import ContractCalendarCellItem from './ContractCalendarCellItem'
import { Spacer } from '../../../../../common/components/PageHeaderStyles'
import { ScrollContainer } from '../../../../../timeCharge/components/ScrollContainer'

import { Typography } from 'antd'
import styled from 'styled-components'
import COLORS from '../../../../../common/Theme/colors'
import _ from 'lodash'

const HourMinTextWidth = 120

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  > * {
    .ant-typography {
      font-size: 13px;
    }
  }
`

const EllipsisTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${COLORS.black_65};
  gap: 5px;
`

export const ContractCalendarCell = ({
  dataList = [],
  totalLength,
  hoveredCaseId,
  onHoverItem,
  onClickCalendarListItem = () => {}
}) => {
  const [overflowIds, setOverflowIds] = useState([])
  const shouldDisplayHHMMFormat = !_.isEmpty(overflowIds)
  return (
    <Container>
      <ScrollContainer height='92px' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
        {dataList.map((props, index) => {
          return (
            <ContractCalendarCellItem
              {...props}
              key={`${index}-${props.id}`}
              id={props.id}
              hoveredCaseId={hoveredCaseId}
              shouldDisplayHHMMFormat={shouldDisplayHHMMFormat}
              onHoverItem={onHoverItem}
              onChangeOverflow={(isOverflow) => setOverflowIds(overflowIds => {
                if (isOverflow) {
                  return [...overflowIds, props.key]
                } else {
                  return _.filter(overflowIds, v => v !== props.key)
                }
              })}
              onClickCalendarListItem={() => onClickCalendarListItem(props)}
            />
          )
        })}
      </ScrollContainer>
      <Spacer />
      {dataList.length
        ? (
          <EllipsisTextContainer style={{}}>
            <Typography.Text>
              총 개수:
            </Typography.Text>
            <Typography.Text ellipsis style={{ maxWidth: HourMinTextWidth, minWidth: 20, fontWeight: 'bold' }}>
              {totalLength}건
            </Typography.Text>
          </EllipsisTextContainer>)
        : null}
    </Container>
  )
}
