import React from 'react'
import styled from 'styled-components'
import { Progress } from 'antd'

const StyledProgress = styled(Progress)`
  width: 118px;
  .ant-progress-inner {
    border-radius: 5px;
  }
  .ant-progress-bg {
    border-radius: 5px;
  }
  .ant-progress-line {
    width: 0%;
  }
`
const checkStatus = (remain) => {
  if (remain < 0) {
    // 초과
    return 'exception'
  } else {
    // 미달 or 정확
    return ''
  }
}

const ProgressBar = ({ percent, remain }) => {
  return (
    <StyledProgress
      percent={percent}
      status={checkStatus(remain)}
      percentPosition={{
        align: 'center',
        type: 'inner'
      }}
      size={[120, 30]}
      strokeColor={percent === 0 ? '#b7dcf9' : percent === 100 ? '#52c41a' : percent > 100 ? '#ff4d4f' : '#1677ff'}
    />
  )
}

export default ProgressBar
