import { gql } from '@apollo/client'

// 설정 페이지 쿼리
export const getDepartmentList = gql`
query GetDepartmentList($filter: DepartmentListFilter) {
  GetDepartmentList(filter: $filter) {
    id
    name
    leaders {
      id
      name
    }
  }
}
`
export const getUserList = gql`
  query GetUserList($sortBy: SortBy!, $filter: UserListFilter, $offset: Int, $limit: Int) {
    GetUserList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        name
        email
        status
        departments {
          id
          name
        }
        group {
          id
          name
        }
        ranks {
          id
          name
        }
        projects {
          id
          name
          createdBy {
            id
          }
          category
          timesheets {
            id
            title
            minutes
            createdBy {
              id
            }
            
          }
        }
        projectCount
        timesheetCount
        totalMinutes
        lastLoggedIn
        passwordUpdated
      }
      totalCount
    }
  }
`

export const getUserListForPermissionChange = gql`
  query GetUserList($sortBy: SortBy!, $filter: UserListFilter, $offset: Int, $limit: Int) {
    GetUserList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        name
        email
        status
        group {
          id
          name
        }
        permissions
        permissionsAdd
        permissionsRemove
      }
      totalCount
    }
  }
`
