import { ContractStateColor } from '../../components/customTable/ContractStateColor'
import { Button } from 'antd'
import { MoneyContainer } from '../../../common/Theme/style'
import _ from 'lodash'
import { fetchDate, formatArrayToComma } from '../../components/common/CommonFunction'

export const useContractRecordDeletedColumns = (onClickRecoveryRecord, overdueContractRecords) => {
  const moment = require('moment')

  const contractRecordDeltedTableColumns = [
    {
      title: '계약상태',
      dataIndex: 'contractState',
      key: 'contractState',
      sort: true,
      width: 80,
      fixed: 'left',
      render: (contractState, record) => {
        const closed = record.closed !== null
        const overdue = _.some(overdueContractRecords, (data) => data.id === record.id)
        if (closed === true) {
          contractState = 'CLOSE'
        } else if (overdue === true) {
          contractState = 'OVERDUE'
        } else {
          contractState = 'PROGRESS'
        }
        return (
          <ContractStateColor state={contractState} />
        )
      }
    },
    {
      title: '삭제일',
      dataIndex: 'deleted',
      key: 'deleted',
      sort: true,
      width: 120,
      render: (deleted, record) => {
        return (
          <div>{fetchDate(deleted)}</div>
        )
      }
    },
    {
      title: '담당자',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sort: true,
      width: 100,
      render: (createdBy) => {
        return (
          <div>{createdBy.name}</div>
        )
      }
    },
    {
      title: '부서',
      dataIndex: 'department',
      key: 'department',
      sort: true,
      width: 140,
      render: (department) => {
        let name
        if (department === null) {
          name = '미배치'
        } else if (department.name === null) {
          name = '미배치'
        } else {
          name = department.name
        }
        return (
          <div>{name}</div>
        )
      }
    },
    {
      title: '입금 기한',
      dataIndex: 'paymentDeadLine',
      key: 'paymentDeadLine',
      sort: true,
      width: 100,
      render: (_, record) => {
        const payements = record.paymentRecords
        let closestDueDateRecord = null
        if (payements.length > 0) {
          let minDifference = Infinity
          payements.forEach(record => {
            const difference = Math.abs(moment(record.dueDate).diff(moment(), 'days'))
            if (difference < minDifference) {
              minDifference = difference
              closestDueDateRecord = record.dueDate
            }
          })
        }
        return (
          <div>{closestDueDateRecord}</div>
        )
      }
    },
    {
      title: '총 계약 금액',
      dataIndex: 'contractTotal',
      key: 'contractTotal',
      sort: true,
      width: 110,
      render: (contractTotal, record) => {
        const payments = _.sumBy(record.paymentRecords, 'dueAmount')
        return (
          <MoneyContainer value={payments} />
        )
      }
    },
    {
      title: '추심상황 기재 수',
      dataIndex: 'paymentComments',
      key: 'paymentComments',
      sort: true,
      width: 110,
      render: (paymentComments) => {
        return (
          paymentComments.length
        )
      }
    },
    {
      title: '사건구분',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 100,
      render: (projectType) => {
        return (
          <div>{projectType.name}</div>
        )
      }
    },
    {
      title: '사건명',
      dataIndex: 'caseName',
      key: 'caseName',
      width: 200
    },
    {
      title: '담당변호사',
      dataIndex: 'assignedLawyers',
      key: 'assignedLawyers',
      width: 100,
      render: (assignedLawyers) => {
        return (
          <div>{formatArrayToComma(assignedLawyers)}</div>
        )
      }
    },
    {
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name',
      width: 100
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      width: 125
    },
    {
      title: '',
      dataIndex: 'recover',
      key: 'recover',
      width: 80,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      fixed: 'right',
      render: (_, record) => {
        return (
          <Button
            onClick={(e) => { onClickRecoveryRecord(record, e) }}
            danger
          >
            복구
          </Button>
        )
      }
    }
  ]

  return {
    columns: contractRecordDeltedTableColumns
  }
}
