import { useRankList } from '../../../timeCharge/hooks/apollo/useRankList'
import RankAdder from '../../../timeCharge/components/settings/rank/RankAdder'
import { useRankAdder } from '../../../common/hooks/settings/useRankAdder'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { HeaderContainer, HeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'
import styled from 'styled-components'
import _ from 'lodash'

const Container = styled.div` 
  width: 100%; 
`
const ManageRankContainer = () => {
  const { ranks, loading } = useRankList()
  const {
    onAddRank,
    onEditRank,
    onRemoveRank
  } = useRankAdder()

  return (
    <HeaderContentFooter
      header={(
        <HeaderContainer>
          <HeaderTitle title='직급 관리' />
          <Spacer $flex={5} />
        </HeaderContainer>
      )}
      content={(
        <Container>
          {loading
            ? null
            : <RankAdder
                initDataSource={_.map(ranks, ({ ...v }) => ({ key: v.id, ...v }))}
                onAddRank={onAddRank}
                onEditRank={onEditRank}
                onRemoveRank={onRemoveRank}
              />}
        </Container>
      )}
    />
  )
}

export default ManageRankContainer
