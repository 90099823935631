import { useQuery } from '@apollo/client'
import { getUserList } from '../../../call/apollo/callRecordQueries'
import _ from 'lodash'

export const useUserList = (
  searchQuery,
  ascending = true,
  key = null,
  page,
  limit,
  includeDeleted,
  userIds
) => {
  const variables = {
    sortBy: {
      ascending,
      key
    },
    filter:
      searchQuery
        ? {
            searchQuery
          }
        : {}
  }

  const { data, loading, refetch } = useQuery(getUserList, {
    variables: {
      sortBy: {
        ascending,
        key
      },
      filter: {
        includeSelf: true,
        userIds,
        ...searchQuery ? { searchQuery } : {}
      },
      limit: null
    },
    fetchPolicy: 'no-cache'
  })
  return {
    loading,
    variables,
    users: _.get(data, ['GetUserList', 'data'], []),
    totalCount: _.get(data, ['GetUserList', 'totalCount'], 0),
    refetch
  }
}
