import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { getUserListForPermissionChange } from '../../../apollo/settingQueries'

export const useUserListForPersonalPermission = (
  ascending = true,
  key = null,
  searchQuery,
  page,
  limit,
  includeDeleted,
  userIds
) => {
  const variables = {
    sortBy: {
      ascending,
      key
    },
    filter:
      searchQuery
        ? {
            searchQuery
          }
        : {},
    ...page && (limit || limit === 0)
      ? {
          offset: (page - 1) * limit,
          limit
        }
      : {
          limit: null
        }
  }

  const { data, loading, refetch } = useQuery(getUserListForPermissionChange, {
    variables: {
      sortBy: {
        ascending,
        key
      },
      filter: {
        includeDeleted, // 퇴사 직원 목록에서 보이게 설정
        includeSelf: true,
        userIds
      },
      offset: (page - 1) * limit,
      limit
    },
    fetchPolicy: 'no-cache'
  })
  return {
    loading,
    variables,
    users: _.get(data, ['GetUserList', 'data'], []),
    totalCount: _.get(data, ['GetUserList', 'totalCount'], 0),
    refetch
  }
}
