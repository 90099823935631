import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

const HomeContainer = () => {
  return (
    <Container style={{ height: 1000 }}>
      {/* Home */}
    </Container>
  )
}

export default HomeContainer
