import { Segmented } from 'antd'

const groupModeOptions = [
  {
    value: 'DEPARTMENT',
    label: '부서'
  },
  {
    value: 'TYPE',
    label: '사건구분'
  }
]

export const ContractGroupMode = ({ groupModeContract, onChangeGroupMode }) => {
  return (
    <Segmented
      value={groupModeContract}
      options={groupModeOptions}
      onChange={e => onChangeGroupMode(e)}
    />
  )
}
