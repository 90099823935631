import { gql } from '@apollo/client'

export const addTimesheet = gql`
  mutation AddTimesheet($projectId: ID!, $userId: ID!, $input: TimesheetMutationInput!) {
    AddTimesheet(projectId: $projectId, userId: $userId, input: $input) {
      _debug
      data {
          title
          minutes
          id
          project {
            id
            name
          }
          user {
            name
            id
          }
        }
      error
      ok
    }
  }
`
export const closeCases = gql`
  mutation CloseProjects($ids: [ID]!) {
    CloseProjects(ids: $ids) {
      _debug
      error
      ok
    }
  }
`
export const closeCase = gql`
  mutation CloseProject($closeProjectId: ID!) {
    CloseProject(ids: $closeProjectId) {
      _debug
      error
      ok
    }
  }
`
export const reopenCases = gql`
mutation ReopenProjects($ids: [ID]!) {
  ReopenProjects(ids: $ids) {
    _debug
    error
    ok
  }
}
`
export const removeCases = gql`
  mutation RemoveProjects($ids: [ID]!) {
  RemoveProjects(ids: $ids) {
    _debug
    error
    ok
  }
}
`
export const restoreCases = gql`
  mutation UnremoveProjects($ids: [ID]!) {
    UnremoveProjects(ids: $ids) {
      _debug
      error
      ok
    }
  }
`

export const removeTimesheet = gql`
  mutation RemoveTimesheet($removeTimesheetId: ID!) {
  RemoveTimesheet(id: $removeTimesheetId) {
    _debug
    error
    ok
  }
}
`

export const editTimesheet = gql`
  mutation EditTimesheet($editTimesheetId: ID!, $input: TimesheetMutationInput!) {
    EditTimesheet(id: $editTimesheetId, input: $input) {
      _debug
      data {
        title
          minutes
          id
          project {
            name
            id
          }
          user {
            name
            id
          }
      }
      error
      ok
    }
  }
`

export const addStar = gql`
  mutation AddStar($projectId: ID!) {
    AddStar(projectId: $projectId) {
      ok
      error
      _debug
    }
  }
`

export const removeStar = gql`
  mutation RemoveStar($projectId: ID!) {
    RemoveStar(projectId: $projectId) {
      ok
    }
  }
`

export const addTimesheetDelta = gql`
  mutation Mutation($projectId: ID!, $userId: ID!, $input: TimeDeltaMutationInput!) {
    AddTimeDelta(projectId: $projectId, userId: $userId, input: $input) {
      ok
    }
  }
`

export const createCases = gql`
mutation AddProjects($projects: [AddProjectMutationInput!]!) {
  AddProjects(projects: $projects) {
    _debug
    data {
      id
    }
    error
    ok
  }
}
`
export const createCase = gql`
  mutation AddProject($project: AddProjectMutationInput!, $attachments: [ID], $automaticName: Boolean) {
    AddProject(project: $project, attachments: $attachments, automaticName: $automaticName) {
      _debug
      data {
        id
      }
      error
      ok
    }
  }
`
export const allocateUsersToCase = gql`
  mutation AllocateUsersToProject($projectId: ID!, $userIds: [ID]!) {
    AllocateUsersToProject(projectId: $projectId, userIds: $userIds) {
      _debug
      data {
        id
      }
      error
      ok
    }
  }
`
export const allocateUsersToCases = gql`
  mutation AllocateUsersToProjects($projectIds: [ID]!, $userIds: [ID]!) {
    AllocateUsersToProjects(projectIds: $projectIds, userIds: $userIds) {
      ok
      error
      _debug
      data {
        id
      }
    }
  }
`
export const deallocateUsersFromCase = gql`
mutation DeallocateUsersFromProject($projectId: ID!, $userIds: [ID]!) {
  DeallocateUsersFromProject(projectId: $projectId, userIds: $userIds) {
    _debug
    data {
      id
    }
    error
    ok
  }
}
`

export const updateCase = gql`
mutation UpdateProject($updateProjectId: ID!, $input: UpdateProjectMutationInput!, $attachments: [ID]) {
  UpdateProject(id: $updateProjectId, input: $input, attachments: $attachments) {
    _debug
    error
    ok
  }
}
`

export const updateUser = gql`
  mutation UpdateUser($updateUserId: ID!, $input: UpdateUserInput!) {
    UpdateUser(id: $updateUserId, input: $input) {
      _debug
      data {
        id
        group {
          id
          name
        }
        permissionsAdd
        permissionsRemove
      }
      error
      ok
    }
  }
`

export const addUsers = gql`
  mutation AddUsers($users: [UserInput]) {
  AddUsers(users: $users) {
    _debug
    data {
      id,
      permissionsAdd,
      permissionsRemove
    }
    error
    ok
  }
}
`
export const removeUsers = gql`
  mutation RemoveUsers($ids: [ID]!, $forceDelete: Boolean) {
  RemoveUsers(ids: $ids, forceDelete: $forceDelete) {
    _debug
    error
    ok
  }
}
`
export const unremoveUsers = gql`
  mutation UnremoveUsers($ids: [ID]!) {
    UnremoveUsers(ids: $ids) {
      _debug
      ok
      error
    }
  }
`

export const delegateUsersToProject = gql`
  mutation DelegateUsersToProject($projectId: ID!, $userIds: [ID]!) {
    DelegateUsersToProject(projectId: $projectId, userIds: $userIds) {
      _debug
      error
      ok
    }
  }
`

export const delegateUsersToProjects = gql`
  mutation DelegateUsersToProjects($projectIds: [ID]!, $userIds: [ID]!) {
    DelegateUsersToProjects(projectIds: $projectIds, userIds: $userIds) {
      ok
      error
      _debug
      data {
        id
      }
    }
  }
`
export const abdicateUsersFromProject = gql`
  mutation AbdicateUsersFromProject($projectId: ID!, $userIds: [ID]!) {
    AbdicateUsersFromProject(projectId: $projectId, userIds: $userIds) {
      _debug
      error
      ok
    }
  }
`
export const markAllPushNotificationRead = gql`
  mutation MarkAllPushNotificationRead {
    MarkAllPushNotificationRead {
      _debug
      error
      ok
    }
  }
`

export const markPushNotificationRead = gql`
  mutation MarkPushNotificationRead($id: ID!) {
  MarkPushNotificationRead(id: $id) {
    _debug
    error
    ok
  }
}
`

export const addProjectType = gql`
  mutation AddProjectType($type: AddProjectTypeMutationInput!) {
    AddProjectType(type: $type) {
      _debug
      data {
        color
        id
        name
        order
      }
      error
      ok
    }
  }
`

export const removeProjectType = gql`
  mutation RemoveProjectType($removeProjectTypeId: ID!) {
    RemoveProjectType(id: $removeProjectTypeId) {
      _debug
      error
      ok
    }
  }
`

export const editProjectType = gql`
  mutation EditProjectType($editProjectTypeId: ID!, $input: AddProjectTypeMutationInput!) {
    EditProjectType(id: $editProjectTypeId, input: $input) {
      _debug
      data {
        id
        color
        name
        order
      }
      error
      ok
    }
  }
`
export const addProjectSubType = gql`
  mutation AddProjectSubType($type: AddProjectSubTypeMutationInput!) {
    AddProjectSubType(type: $type) {
      _debug
      error
      ok
      data {
        id
        name
        order
        type {
          id
          name
        }
      }
    }
  }
`
export const editProjectSubType = gql`
  mutation EditProjectSubType($editProjectSubTypeId: ID!, $input: AddProjectSubTypeMutationInput!) {
    EditProjectSubType(id: $editProjectSubTypeId, input: $input) {
      _debug
      data {
        id
        name
        order
        type {
          id
          color
          name
        }
      }
      ok
      error
    }
  }
`
export const removeProjectSubType = gql`
  mutation RemoveProjectSubType($removeProjectSubTypeId: ID!) {
    RemoveProjectSubType(id: $removeProjectSubTypeId) {
      _debug
      error
      ok
    }
  }
`
export const addRank = gql`
  mutation AddRank($input: RankInput!) {
    AddRank(input: $input) {
      ok
      _debug
      data {
        order
        name
        id
      }
      error
    }
  }
`
export const editRank = gql`
  mutation EditRank($editRankId: ID!, $input: RankInput!) {
    EditRank(id: $editRankId, input: $input) {
      _debug
      data {
        id
        name
        order
      }
      error
      ok
    }
}
`
export const removeRank = gql`
  mutation Mutation($removeRankId: ID!) {
    RemoveRank(id: $removeRankId) {
      ok
      error
      _debug
    }
  }
`
export const addRanksToUser = gql`
  mutation AddRanksToUser($userId: ID!, $rankIds: [ID]!) {
    AddRanksToUser(userId: $userId, rankIds: $rankIds) {
      _debug
      error
      ok
      data {
        id
        name
        ranks {
          id
          name
          order
        }
      }
    }
  }
`
export const removeRanksFromUser = gql`
  mutation RemoveRanksFromUser($userId: ID!, $rankIds: [ID]!) {
    RemoveRanksFromUser(userId: $userId, rankIds: $rankIds) {
      _debug
      error
      ok
      data {
        id
        name
        ranks {
          id
          name
          order
        }
      }
    }
  }
`
export const addTimesheetTitleTemplate = gql`
  mutation AddTimesheetNameTemplate($type: TimesheetNameMutationInput!) {
    AddTimesheetNameTemplate(type: $type) {
      _debug
      data {
        id
        name
        order
      }
      error
      ok
    }
  }
`

export const editTimesheetTitleTemplate = gql`
  mutation EditTimesheetNameTemplate($editTimesheetNameTemplateId: ID!, $input: TimesheetNameMutationInput!) {
    EditTimesheetNameTemplate(id: $editTimesheetNameTemplateId, input: $input) {
      _debug
      data {
        id
        name
        order
      }
      error
      ok
    }
  }
`

export const removeTimesheetTitleTemplate = gql`
  mutation RemoveTimesheetNameTemplate($removeTimesheetNameTemplateId: ID!) {
  RemoveTimesheetNameTemplate(id: $removeTimesheetNameTemplateId) {
    _debug
    error
    ok
  }
}
`

export const editUserGroupPermissions = gql`
  mutation EditUserGroupPermissions($editUserGroupPermissionsId: ID!, $permissions: [UserPermission]!) {
    EditUserGroupPermissions(id: $editUserGroupPermissionsId, permissions: $permissions) {
      _debug
      error
      ok
    }
  }
`
export const editDepartment = gql`
  mutation EditDepartment($editDepartmentId: ID!, $input: DepartmentInput!) {
  EditDepartment(id: $editDepartmentId, input: $input) {
    _debug
    data {
      name
      leaders {
        id
        name
      }
      id
      order
    }
    error
    ok
  }
}
`

export const addDepartment = gql`
  mutation AddDepartment($input: DepartmentInput!) {
  AddDepartment(input: $input) {
    _debug
    data {
      leaders {
        id
        name
      }
      name
    }
    error
    ok
  }
}
`

export const removeDepartment = gql`
  mutation RemoveDepartment($removeDepartmentId: ID!) {
  RemoveDepartment(id: $removeDepartmentId) {
    _debug
    error
    ok
  }
}
`
export const addDepartmentsToUser = gql`
  mutation AddDepartmentsToUser($userId: ID!, $departmentIds: [ID]!) {
    AddDepartmentsToUser(userId: $userId, departmentIds: $departmentIds) {
      _debug
      error
      ok
      data {
        id
        name
      }
    }
  }
`

export const removeDepartmentsFromUser = gql`
  mutation RemoveDepartmentsFromUser($userId: ID!, $departmentIds: [ID]!) {
    RemoveDepartmentsFromUser(userId: $userId, departmentIds: $departmentIds) {
      _debug
      error
      ok
      data {
        id
        name
      }
    }
  }
`
