import _ from 'lodash'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import { navigateToTimesheetDetail } from '../../cacheAndNavigation//timesheetDetail/navigation'
import { navigateToCaseList } from '../../cacheAndNavigation//timesheet/navigation'
import { useCaseUserFilterSearchParams } from '../filter/params/useCaseUserFilterSearchParams'

import { parseCaseList } from '../../apollo/parser'
import { useStar } from '../apollo/mutations/useStar'
import { useCaseList } from '../apollo/useCaseList'
import { useRef } from 'react'
import { useTimesheetFinderState } from './useTimesheetFinderState'

export const useTimesheetFinder = () => {
  const { userId: myUserId } = useOutletContext()
  // hooks
  const caseVirtualListRef = useRef()
  const navigate = useNavigate()
  const { caseStatus } = useParams()
  const {
    meta: {
      filter: {
        caseIds,
        categories,
        userIds,
        datetimeRange,
        caseStatus: caseStatusFilterArr,
        viewMyCase
      }
    },
    searchParams,
    getUpdatedSearchParams
  } = useCaseUserFilterSearchParams()
  const from = _.get(datetimeRange, [0])
  const to = _.get(datetimeRange, [1])

  // gql
  const starred = caseStatus === 'starred' ? true : null
  const closed = caseStatus === 'closed'
    ? true
    : _.isEqual(caseStatusFilterArr, ['CLOSED'])
      ? true
      : _.isEqual(caseStatusFilterArr, ['OPEN'])
        ? false
        : null

  const {
    cases,
    totalCount,
    refetch,
    loading
  } = useCaseList({
    projectIds: caseIds,
    userIds,
    categories,
    from,
    to,
    sortKey: 'TIMESHEET_UPDATED',
    sortAscending: false,
    closed,
    starred,
    viewMyCase,
    limit: 40
  })
  const users = _.chain(cases)
    .filter(v => _.isEmpty(caseIds) ? true : _.includes(caseIds, v.id))
    .flatMap(v => _.get(v, ['users']))
    .filter(v => _.isEmpty(userIds) ? true : _.includes(userIds, v.id))
    .sortBy(v => v.id === myUserId ? 0 : 1) // 본인 폴더가 제일 앞에 위치하도록 수정
    .value()

  const timesheets = _.chain(users)
    .filter(v => _.isEmpty(userIds) ? true : _.includes(userIds, v.id))
    .flatMap(v => _.get(v, ['timesheets']))
    .value()

  const { onChangeStar } = useStar(
    () => {},
    projectId => {
      if (starred) caseVirtualListRef?.current?.removeItemById(projectId)
    }
  )

  const isCaseEmpty = _.isEmpty(caseIds)
  const isUserEmpty = _.isEmpty(userIds)
  const {
    finderState,
    changeFinderState
  } = useTimesheetFinderState(isCaseEmpty, isUserEmpty)

  // functions
  const onDoubleClickCaseFolder = (caseId) => {
    changeFinderState(2)
    const __newSp = getUpdatedSearchParams('filter', 'caseIds', [caseId], searchParams)
    const _newSp = getUpdatedSearchParams('folder', 'caseId', caseId, __newSp)
    const newSp = getUpdatedSearchParams('folder', 'view', 'user', _newSp)
    navigateToCaseList(navigate, caseStatus, undefined, undefined, newSp.toString())
  }
  const onDoubleClickUserFolder = (userId) => {
    changeFinderState(3)
    const __newSp = getUpdatedSearchParams('filter', 'userIds', [userId], searchParams)
    const _newSp = getUpdatedSearchParams('folder', 'userId', userId, __newSp)
    const newSp = getUpdatedSearchParams('folder', 'view', 'timesheet', _newSp)
    navigateToCaseList(navigate, caseStatus, undefined, undefined, newSp.toString())
  }
  const onDoubleClickTimesheetFile = (timesheetMeta) => {
    navigateToTimesheetDetail(navigate, timesheetMeta.id, timesheetMeta)
  }
  // case
  const caseInfiniteScrollFetcher = (offset, limit) => {
    return refetch({
      offset,
      limit
    })
  }
  const caseInfiniteScrollFetchParser = (data) => {
    return parseCaseList(_.get(data, ['GetProjectList', 'data'], []))
  }
  const onInfiniteScrollUpdateCases = (data) => {

  }

  return {
    cases,
    caseVirtualListRef,
    users,
    loading,
    timesheets,
    isCaseEmpty,
    isUserEmpty,
    finderState,
    onInfiniteScrollUpdateCases,
    caseInfiniteScrollFetcher,
    caseInfiniteScrollFetchParser,
    onChangeStar,
    onDoubleClickCaseFolder,
    onDoubleClickUserFolder,
    onDoubleClickTimesheetFile,
    totalCount
  }
}
