import styled from 'styled-components'
import { CreateButtons } from '../../../timeCharge/components/manageCase/CreateButtons'

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
`

export const ManageCaseCreateNavContainer = () => {
  return (
    <Container>
      <CreateButtons />
    </Container>
  )
}
