import { message } from 'antd'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { changePassword, login } from '../../../common/auth/authFetchers'
import { useNavigate } from 'react-router-dom'
const authenticateWarningMessages = {
  200: '성공',
  401: '인증실패',
  500: '시스템 문제'
}

export const useLogin = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loginMeta, setLoginMeta] = useState({
    userName: '',
    token: sessionStorage.getItem('token'),
    loggedIn: null
  })

  // update token
  useEffect(() => {
    sessionStorage.setItem('token', loginMeta.token)
  }, [loginMeta.token])

  const onLogin = async ({ username: name, password }) => {
    setLoading(true)
    try {
      const res = await login(name, password)
      const token = _.get(res, ['data', 'token'])
      setLoginMeta({ ...loginMeta, userName: name, token, loggedIn: true })
    } catch (e) {
      message.destroy()
      const status = e.response.status
      const errorStatus = _.get(authenticateWarningMessages, [status])
      if (errorStatus) { // 실패 알림 로딩 완료 후 뜨게 지연시킴
        setTimeout(() => {
          message.error(errorStatus)
        }, 1000)
      }
    } finally {
      setLoading(false)
    }
  }

  const onChangePassword = async ({ confirmPassword, newPassword }) => {
    if (confirmPassword !== newPassword) {
      message.error('비밀번호가 일치하지 않습니다.')
      return { userStatusChanged: false }
    }
    if (!confirmPassword || !newPassword) {
      return { userStatusChanged: false }
    }

    let ret
    try {
      ret = await changePassword(confirmPassword)
    } catch (e) {
      console.error(e)
      message.error('비밀번호 변경에 실패하였습니다. 잠시후 재시도 해주세요.')
      return { userStatusChanged: false }
    }
    if (_.get(ret, ['data']) === 'OK' &&
      _.get(ret, ['status']) === 200) {
      message.success('비밀번호가 변경 되었습니다.')
      return { userStatusChanged: true }
    } else {
      // ASSUMPTION
      message.warning('다른 비밀번호를 사용해주세요.')
      return { userStatusChanged: false }
    }
  }

  return {
    ...loginMeta,
    onLogin,
    onChangePassword,
    skipLogin: () => {
      setLoginMeta(loginMeta => ({ ...loginMeta, token: '', loggedIn: true }))
    },
    onLogout: () => {
      navigate('/logout')
    },
    logout: () => {
      setLoginMeta(loginMeta => ({ ...loginMeta, token: '', loggedIn: false }))
    },
    loading
  }
}
