import { Typography } from 'antd'
import styled from 'styled-components'

const RowContainer = styled.div`
display: flex;
gap: 10px;
`

export const LegendIcon = ({ color, label }) => {
  return (
    <RowContainer>
      <svg
        width='20'
        height='20'
      >
        <rect
          x='0'
          y='0'
          fill={color}
          opacity='1'
          strokeWidth='0'
          stroke='transparent'
          width='20'
          height='20'
          style={{ pointerEvents: 'none' }}
        />
      </svg>
      <Typography.Text ellipsis={{ tooltip: () => <>{label}</> }} style={{ width: 150 }}>
        {label}
      </Typography.Text>
    </RowContainer>
  )
}
