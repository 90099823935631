import axios from 'axios'
import { authUrl } from '../config'

const authInstance = (token) => axios.create({
  baseURL: authUrl,
  headers: {
    Authorization: token
  }
})

export const login = async (email, password) => {
  return await axios.post(`${authUrl}/authenticate`, {
    email,
    password
  })
}

export const changePassword = async (password) => {
  return await authInstance(sessionStorage.getItem('token')).post('/changePassword', {
    password
  })
}

export const resetPassword = async (userId) => {
  return await authInstance(sessionStorage.getItem('token')).post('/resetPassword', {
    user: userId
  })
}
