import { useMutation } from '@apollo/client'
import { addDepositRecord, editDepositRecord, removeDepositRecord } from '../../apollo/mutations'
import _ from 'lodash'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import { MESSAGES } from '../../../common/config'
import { useOutletContext } from 'react-router-dom'

export const useDepositAddEditRemove = (refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [addDepositRecordtMutFn] = useMutation(addDepositRecord)
  const [editDepositRecordtMutFn] = useMutation(editDepositRecord)
  const [removeDepositRecordtMutFn] = useMutation(removeDepositRecord)

  const onAddDepositRecord = (data) => {
    addDepositRecordtMutFn({
      variables: {
        paymentRecordId: data.id,
        input: data.input
      }
    }).then(v => {
      if (_.get(v, ['data', 'AddDepositRecord', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  const onEditDepositRecord = (data) => {
    editDepositRecordtMutFn({
      variables: {
        editDepositRecordId: data.id,
        input: data.input
      }
    }).then(v => {
      if (_.get(v, ['data', 'EditDepositRecord', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  const onRemoveDepositRecord = (id) => {
    removeDepositRecordtMutFn({
      variables: {
        removeDepositRecordId: id
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveDepositRecord', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  return {
    onAddDepositRecord,
    onEditDepositRecord,
    onRemoveDepositRecord
  }
}
