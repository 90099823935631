import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigateToContractRecordDetail } from '../../../cacheAndNavigation/contractRecordDetail/navigation'
import { useBlockModal } from '../../../../timeCharge/hooks/common/useBlockModal'
import _ from 'lodash'
import { useContractPaymentsAddEditRemove } from '../useContractPaymentsAddEditRemove'
import { useCommentAddRemove } from '../useCommentAddRemove'
import { useDepositAddEditRemove } from '../useDepositAddEditRemove'

export const useSavingContractRecordDetailForm = (
  payment,
  formStatus,
  newContractIds = [],
  deletedContractIds = [],
  refetch = () => {},
  setNewContractIds,
  setDeletedContractIds,
  newReasonIds,
  setNewReasonIds,
  removedReasonIds,
  setRemovedReasonIds,
  newDepositIds,
  setNewDepositIds,
  removedDepositIds,
  setRemovedDepositIds,
  pageDelete,
  setEditMode,
  setContractDataSource,
  setExtraContractDataSource,
  setReasonDataSource,
  setDepositDataSource,
  contractDataSource,
  extraContractDataSource,
  initialData
) => {
  const [readOnly, setReadOnly] = useState(true)
  const navigate = useNavigate()
  const onEdit = () => {
    setReadOnly(!readOnly)
    setEditMode(true)
    navigateToContractRecordDetail(navigate, payment, null, 'editing')
  }
  const onCancel = (form, contractType) => {
    // 새로 추가된 계약서 제거
    const currentFormValues = form.getFieldsValue()
    let newItemRemoved = null
    setReadOnly(!readOnly)
    setEditMode(false)
    navigateToContractRecordDetail(navigate, payment, null, 'view')
    if (contractType === 'contract') {
      newItemRemoved = _.omit(initialData, newContractIds)
      form.resetFields()
      setContractDataSource(newItemRemoved)
    } else if (contractType === 'extraContract') {
      newItemRemoved = _.omit(initialData, newContractIds)
      setContractDataSource(newItemRemoved)
    } else if (contractType === 'commentList') {
      newItemRemoved = initialData
      setReasonDataSource(newItemRemoved)
    } else { // deposit
      newItemRemoved = initialData
      setDepositDataSource(newItemRemoved)
    }
    // form.resetFields()
    const updatedFormValues = {
      ...currentFormValues,
      [contractType]: newItemRemoved
    }
    form.setFieldsValue(updatedFormValues)
    setEditMode(false)
  }
  const {
    onCreateContractPayments,
    onRemoveContractPayment,
    onEditContractPayment
  } = useContractPaymentsAddEditRemove(refetch)

  const {
    onAddComment,
    onEditComment,
    onRemoveComment
  } = useCommentAddRemove(refetch)

  const {
    onAddDepositRecord,
    onEditDepositRecord,
    onRemoveDepositRecord
  } = useDepositAddEditRemove(refetch)

  const onSave = async (form, id, type) => {
    const values = form.getFieldValue()
    const combinedContracts = _.merge({}, values.contract, values.extraContract)
    const commentList = values.commentList
    const depositList = values.depositRecordList
    const formattedDataArray = _.chain(combinedContracts)
      .filter((item) => newContractIds.includes(item.periodicId))
      .map((item) => {
        if (item.periodic !== null) { // 주기 입금
          return {
            contractId: id,
            periodic: item.periodic,
            periodicId: item.periodicId,
            payments: [
              {
                category: item.category,
                dueAmount: item.dueAmount,
                dueDate: item.dueDate,
                expenseProofMethod: null,
                type: null
              }
            ]
            // payments: _.map(item.payments, (payment) => ({
            //   category: item.category,
            //   dueAmount: payment.dueAmount,
            //   dueDate: payment.dueDate,
            //   expenseProofMethod: null,
            //   type: null
            // }))
          }
        } else { // 단일 입금
          return {
            contractId: id,
            periodic: item.periodic,
            periodicId: item.periodicId,
            payments: [
              {
                category: item.category,
                dueAmount: item.dueAmount,
                dueDate: item.dueDate,
                expenseProofMethod: null,
                type: null
              }
            ]
          }
        }
      })
      .value()
    if (type === 'contract' || type === 'extraContract') {
      try {
        setReadOnly(!readOnly)
        const editedFormatDataArray = _.chain(combinedContracts)
          .filter((item) => !newContractIds.includes(item.periodicId))
          .map((item) => {
            return {
              periodicId: item.periodicId,
              input: {
                category: item.category,
                dueAmount: item.dueAmount,
                dueDate: item.dueDate,
                expenseProofMethod: null,
                type: null
              }
            }
          })
          .value()
        if (newContractIds.length > 0) {
          _.forEach(formattedDataArray, onCreateContractPayments)
        }
        if (deletedContractIds.length > 0) {
          _.forEach(deletedContractIds, onRemoveContractPayment)
        }
        _.forEach(editedFormatDataArray, onEditContractPayment)
        setNewContractIds([])
        setDeletedContractIds([])
        form.resetFields()
      } catch (error) {
      }
    } else if (type === 'comment') {
      try {
        const addCommentFormatDataArray = _.chain(commentList)
          .filter((item) => newReasonIds.includes(item.id))
          .map((item) => {
            return {
              contractId: id,
              content: item.content
            }
          })
          .value()

        const editCommentFormatDataArray = _.chain(commentList)
          .filter((item) => !newReasonIds.includes(item.id))
          .map((item) => {
            return {
              editCommentId: item.id,
              content: item.content
            }
          })
          .value()

        if (newReasonIds.length > 0) {
          _.forEach(addCommentFormatDataArray, onAddComment)
        }
        if (removedReasonIds.length > 0) {
          _.forEach(removedReasonIds, onRemoveComment)
        }
        _.forEach(editCommentFormatDataArray, onEditComment)
        setReadOnly(!readOnly)
        setNewReasonIds([])
        setRemovedReasonIds([])
      } catch (error) {
      }
    } else if (type === 'depositRecordList') {
      try {
        const addDepositFormatDataArray = _.chain(depositList)
          .filter((item) => newDepositIds.includes(item.id))
          .map((item) => {
            const matchedContract = _.find(combinedContracts, { periodicId: item.periodicId })
            return {
              id: matchedContract.id,
              input: {
                received: item.received,
                receivedAmount: item.receivedAmount,
                type: item.type,
                expenseProofMethod: item.expenseProofMethod
              }
            }
          })
          .value()
        const editedDepositFormatDataArray = _.chain(depositList)
          .filter((item) => !newDepositIds.includes(item.id))
          .map((item) => {
            return {
              id: item.id,
              input: {
                // category: item.category,
                expenseProofMethod: item.expenseProofMethod,
                received: item.received,
                receivedAmount: item.receivedAmount,
                type: item.type
              }
            }
          })
          .value()

        if (newDepositIds.length > 0) {
          _.forEach(addDepositFormatDataArray, onAddDepositRecord)
        }
        if (removedDepositIds.length > 0) {
          _.forEach(removedDepositIds, onRemoveDepositRecord)
        }
        _.forEach(editedDepositFormatDataArray, onEditDepositRecord)
        setReadOnly(!readOnly)
        setNewDepositIds([])
        setRemovedDepositIds([])
      } catch (error) {
      }
    }
    setEditMode(false)
    form.resetFields()
  }

  const onDeleteContractPayment = (form, contractType, periodicId) => {
    const filterDataByPeriodicId = (data, periodicId) => _.omit(data, [periodicId])
    if (contractType === 'contract') {
      setContractDataSource(filterDataByPeriodicId(contractDataSource, periodicId))
    } else if (contractType === 'extraContract') {
      setContractDataSource(filterDataByPeriodicId(contractDataSource, periodicId))
    }
    if (_.includes(newContractIds, periodicId)) {
      setNewContractIds((current) => _.without(current, periodicId))
    } else {
      setDeletedContractIds((current) => [...current, periodicId])
    }
  }

  const {
    blockModalOpen,
    onOkCancelModal,
    onCloseCancelModal
  } = useBlockModal(null, formStatus === 'editing' && !pageDelete)
  return {
    cancelModalOpen: blockModalOpen,
    onCloseCancelModal,
    onOkCancelModal,
    onEdit,
    onCancel,
    onSave,
    onDeleteContractPayment,
    readOnly
  }
}
