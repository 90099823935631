import { YKLogo } from '../common/YKLogo'
import { AccountSetting } from './AccountSetting'
import { Spacer } from '../../../common/components/PageHeaderStyles'

import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { Menu } from 'antd'

const Sider = styled.div`
  width: 200px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${COLORS.white_1};
  overflow-y: auto;
`
const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledMenu = styled(Menu)`
  font-size: 13px;
  .ant-menu-item-selected {
    background-color: ${COLORS.white_1};
    font-weight: bold;
  }
  .ant-menu-item-group-title {
    font-size: 12px;
    padding: 15px 16px 0px;
  }
  .ant-menu-item {
    height: 35px;
  }
`

export const Sidebar = ({ selectedKey, onSelectSidebarMenu, sidebarItems, userId, userName, userEmail, onLogout, permissions }) => {
  return (
    <Sider>
      <YKLogo permissions={permissions} userId={userId} />
      <StyledMenu
        mode='inline'
        style={{
          borderRight: 0,
          background: `${COLORS.white_1}`
        }}
        selectedKeys={[selectedKey]}
        onSelect={onSelectSidebarMenu}
        items={sidebarItems}
      />
      <Spacer />
      <ItemContainer>
        <AccountSetting name={userName} email={userEmail} onLogout={onLogout} />
      </ItemContainer>
    </Sider>
  )
}
