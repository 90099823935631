import { useNavigate, useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../../common/config'
import { useMutation } from '@apollo/client'
import { removeCases, closeCases, restoreCases, reopenCases } from '../../../apollo/mutations'
import _ from 'lodash'

export const useOpenCloseDeleteCases = (refetch = () => {}, detailPage) => {
  const { messageApi } = useOutletContext()
  const [removeCaseMutFn] = useMutation(removeCases)
  const navigate = useNavigate()

  const [closeCaseMutFn] = useMutation(closeCases)
  const onCloseCases = (ids) => {
    closeCaseMutFn({
      variables: {
        ids
      }
    }).then(v => {
      if (_.get(v, ['data', 'CloseProjects', 'ok'])) refetch()
      else messageApi.open(MESSAGES.closeProjectsError)
    })
  }
  const onDeleteCases = (ids) => {
    removeCaseMutFn({
      variables: {
        ids
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveProjects', 'ok'])) {
        if (detailPage) {
          navigate('/manageCase/table')
        } else {
          refetch()
        }
      } else {
        messageApi.open(MESSAGES.removeProjectsError)
      }
    })
  }
  const [reopenCaseMutFn] = useMutation(reopenCases)
  const onReopenCases = (ids) => {
    reopenCaseMutFn({
      variables: {
        ids
      }
    }).then(v => {
      if (_.get(v, ['data', 'ReopenProjects', 'ok'])) refetch()
      else messageApi.open(MESSAGES.reopenProjectsError)
    })
  }

  const [restoreCaseMutFn] = useMutation(restoreCases)
  const onRestoreCases = (ids) => {
    restoreCaseMutFn({
      variables: {
        ids
      }
    }).then(v => {
      if (_.get(v, ['data', 'UnremoveProjects', 'ok'])) {
        if (detailPage) {
          navigate('/trash')
        } else {
          refetch()
        }
      } else messageApi.open(MESSAGES.restoreProjectsError)
    })
  }

  return {
    onCloseCases,
    onDeleteCases,
    onReopenCases,
    onRestoreCases
  }
}
