import COLORS from '../../../common/Theme/colors'
import { DeleteOutlined } from '@ant-design/icons'

export const scratchPadTableActionItems = [
  {
    key: 'delete',
    icon: <DeleteOutlined style={{ color: `${COLORS.red}` }} />,
    label: '삭제'
  }
]
