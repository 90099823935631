import { useQuery } from '@apollo/client'
import { getTimesheetList } from '../../../apollo/inProgressCaseQueries'
import _ from 'lodash'

export const useTimesheetList = ({
  projectIds = [],
  userIds = [],
  from,
  to,
  offset,
  limit,
  sortKey,
  sortAscending = true,
  skip,
  projectCategories,
  closed = null,
  viewMyCase = false
}) => {
  const variables = {
    filter: {
      ..._.isEmpty(projectIds) ? { } : { projectIds },
      ..._.isEmpty(userIds) ? { } : { userIds },
      ...from && to
        ? {
            dateRange: {
              from,
              to
            }
          }
        : {},
      closed,
      myProjectOnly: viewMyCase, // '내 사건만 보기'는 내가 속한 사건 필터
      ..._.isEmpty(projectCategories) ? {} : { projectCategories }
    },
    sortBy: {
      ascending: sortAscending,
      key: sortKey
    },
    ...offset || offset === null ? { offset } : {},
    ...limit || limit === null ? { limit } : {}
  }
  const { loading, data } = useQuery(getTimesheetList, {
    variables,
    skip,
    fetchPolicy: 'network-only'
  })
  const gqlData = _.get(data, ['GetTimesheetList'], {})
  const parseTimesheets = (timesheets) => {
    return _.map(timesheets, timesheet => {
      return {
        ...timesheet,
        case: {
          ..._.get(timesheet, ['project'], {}),
          caseNumber: _.get(timesheet, ['project', 'name'], {}),
          caseType: _.get(timesheet, ['project', 'type'], {})
        }
      }
    })
  }
  return {
    loading,
    timesheets: parseTimesheets(_.get(gqlData, ['timesheets'], [])),
    totalCount: _.get(gqlData, ['totalCount'], 0),
    totalMinutes: _.get(gqlData, ['totalMinutes'], 0),
    variables
  }
}
