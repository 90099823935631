import styled from 'styled-components'
import { Select, Space } from 'antd'

const CaseTagSelect = styled(Select)`
  & {
    width: 100%;
  }
  & .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }
`

const MultipleSelect = ({ options = [], selectedValues = [], placeholder, style = {}, onSelectChange = () => {}, mode = 'multiple' }) => {
  return (
    <Space direction='vertical'>
      <CaseTagSelect
        mode={mode}
        allowClear
        style={style}
        value={selectedValues}
        placeholder={placeholder}
        onChange={onSelectChange}
        options={options}
      />
    </Space>
  )
}

export default MultipleSelect
