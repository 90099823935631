import _ from 'lodash'
import { useCustomTable } from '../../common/useCustomTable'

export const useScratchPadTable = () => {
  const tableProps = useCustomTable()

  const {
    selectedRowKeys
  } = tableProps

  const disabledScratchPadTableActionByKey = {
    save: _.isEmpty(selectedRowKeys),
    delete: _.isEmpty(selectedRowKeys),
    join: _.isEmpty(selectedRowKeys)
  }

  return {
    ...tableProps,
    disabledScratchPadTableActionByKey
  }
}
