import { Typography } from 'antd'
import styled from 'styled-components'

import CustomSorter from './CustomSorter'

const RowContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const TableTitleWithSort = ({ title, children, ...props }) => {
  return (
    <RowContainer>
      {title ? <Typography.Text>{title}</Typography.Text> : children}
      <CustomSorter {...props} />
    </RowContainer>
  )
}

export default TableTitleWithSort
