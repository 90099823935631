import { useQuery } from '@apollo/client'
import { getDepartmentList } from '../../apollo/queries'
import _ from 'lodash'

// skip = true로 하면 담당자 배당에서 목록이 안뜸
export const useDepartmentList = (searchQuery, onComplete = () => {}, skip = false) => {
  const { data, loading, refetch } = useQuery(getDepartmentList, {
    variables: {
      filter: searchQuery ? { searchQuery } : {}
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => onComplete(_.get(data, ['GetDepartmentList'], [])),
    skip
  })

  const departments = _.get(data, ['GetDepartmentList'], [])
  return {
    loading,
    departments,
    totalCount: departments.length,
    refetch
  }
}
