import { useEffect, useState } from 'react'
import { Tag } from 'antd'
import { CustomReadOnlyInput } from '../../../../timeCharge/components/common/CustomReadOnlyInput'
import { useCategories } from '../../apollo/useCategories'
import styled from 'styled-components'
import _ from 'lodash'
import TextArea from 'antd/es/input/TextArea'

const StyledTag = styled(Tag)`
  padding: 4px 15px;
  border-radius: 8px;
  .ant-tag-close-icon {
    padding-left: 15px;
  }
`

export const useCreateCaseForm = (readOnly, initMeta = {
  caseNumber: '',
  caseTag: '',
  content: '',
  users: []
}, type, caseFormStatus, loading) => {
  const { categories } = useCategories(readOnly)
  const [meta, setMeta] = useState(initMeta)
  const [showAutoCreateTag, setShowAutoCreateTag] = useState(null)
  useEffect(() => {
    if (!loading) {
      setMeta(initMeta)
      if (!_.get(initMeta, ['caseNumber'], null)) setShowAutoCreateTag(true)
    }
  }, [loading])

  const onChangeCaseNumber = (caseNumber) => {
    if (!caseNumber) setShowAutoCreateTag(true)
    setMeta(meta => ({ ...meta, caseNumber }))
  }

  const onChangeCaseTag = (caseTag) => {
    const _name = _.get(_.find(caseTags, { value: caseTag }), 'label', '?')
    const defaultName = showAutoCreateTag ? `${secondLabelName}-${_name}-` : ''
    setMeta(meta => ({ ...meta, defaultName, caseNumber: defaultName, caseTag }))
  }
  const secondLabelName = type === 'case' ? '사건' : type === 'consultation' ? '자문' : type === 'etc' ? '기타' : ''
  const caseTags = categories.map(({ id, name }) => ({ value: id, label: name }))
  const onChangeAutoCaseNumber = () => {
    setShowAutoCreateTag(false)
    const resetCaseNumber = ''
    setMeta(meta => ({ ...meta, caseNumber: resetCaseNumber }))
  }
  const formItems = [
    {
      label: secondLabelName + '분류',
      style: { width: '100%' },
      ...readOnly
        ? {
            component: <CustomReadOnlyInput value={meta.caseTag} />
          }
        : {
            type: 'select',
            placeholder: '태그를 선택하세요.',
            value: meta.caseTag,
            items: caseTags,
            disabled: caseFormStatus === 'editing',
            onSelect: onChangeCaseTag
          }
    },
    {
      label: secondLabelName + '번호',
      style: { width: '100%' },
      ...readOnly
        ? {
            component: <CustomReadOnlyInput value={meta.caseNumber} />
          }
        : showAutoCreateTag && caseFormStatus === 'creating'
          ? {
              component: <StyledTag closable onClose={() => onChangeAutoCaseNumber()}>자동생성</StyledTag>
            }
          : {
              placeholder: `${secondLabelName} 번호를 입력하세요.`,
              type: 'input',
              value: showAutoCreateTag ? meta.defaultName : meta.caseNumber,
              onChange: onChangeCaseNumber
            }
    },
    {
      label: '내용',
      ...readOnly
        ? {
            component: <TextArea autoSize style={{ border: 'none' }} value={meta.content} readOnly />
          }
        : {
            type: 'textArea',
            placeholder: '내용을 입력해 주세요.',
            variant: false,
            value: meta.content,
            autoSize: {
              minRows: 12
            },
            onChange: (content) => setMeta(meta => ({ ...meta, content }))
          }
    }
  ]

  return {
    ...meta,
    formItems,
    showAutoCreateTag,
    caseTags
  }
}
