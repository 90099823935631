import { BarChartOutlined, CopyOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import COLORS from '../../../common/Theme/colors'

export const tableActionItems = [
  {
    key: 'stat',
    icon: <BarChartOutlined style={{ color: `${COLORS.green}` }} />,
    label: '통계보기'
  },
  // {
  //   key: 'excelDownload',
  //   icon: <DownloadOutlined style={{ color: `${COLORS.primaryColor}` }} />,
  //   label: '엑셀 다운로드'
  // },
  {
    key: 'copyRecord',
    icon: <CopyOutlined style={{ color: `${COLORS.orange}` }} />,
    label: '복제'
  },
  {
    key: 'delete',
    icon: <DeleteOutlined style={{ color: `${COLORS.red}` }} />,
    label: '삭제'
  }
]
