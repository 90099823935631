import _ from 'lodash'

export const navigateToCaseDetail = (navigate, caseMeta = {}, replace) => {
  navigate(`/caseDetail/${caseMeta.caseId}`, {
    state: {
      caseMeta: JSON.stringify(_.pick(caseMeta, ['caseNumber', 'caseTag', 'content', 'caseId', 'status', 'attachments']))
    },
    replace
  })
}
