import { Button, Popover, Typography } from 'antd'
import { parseDateObj, parseMinutesToHourMin } from '../../../../common/util'
import { navigateToSettingDetail } from '../../../cacheAndNavigation/setting/navigation'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { employersOptions } from '../../../../common/config'
import { navigateToEmployeeDetail } from '../../../cacheAndNavigation/employeeDetail/navigation'
import COLORS from '../../../../common/Theme/colors'
import { caseTableCommonProps } from '../../../../common/Theme/style'

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`
const StyledButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
`
const WarningText = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  color: ${COLORS.red};
  font-size: 12px;
`
export const useManageEmployeeTable = () => {
  const navigate = useNavigate()

  const onClickTableMenu = (e, key, record) => {
    e.stopPropagation()
    navigateToSettingDetail(navigate, 'editing', 'setting', key, record.id, record)
  }
  // 사건관리
  const checkStatus = (status) => status === 'NORMAL' ? '재직' : status === 'PASSWORD_RESET' ? '비밀번호 재설정 하기 전' : status === 'DELETED' ? '퇴사' : '삭제'
  const employeeTableColumns = [
    {
      title: '이름',
      // sort: true,
      dataIndex: 'name',
      key: 'USER_NAME',
      width: 160
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      width: 220
    },
    {
      title: '직급',
      dataIndex: 'ranks',
      key: 'ranks',
      width: 300
    },
    {
      title: '권한그룹',
      dataIndex: 'type',
      key: 'roles',
      width: 200
    },
    {
      title: '부서',
      dataIndex: 'departments',
      key: 'departments',
      width: 80
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      width: 220,
      render: (record) => {
        return (
          <div style={{ paddingLeft: '5px' }}>
            {checkStatus(record)}
          </div>
        )
      }
    },
    {
      title: '담당사건',
      // sort: true,
      dataIndex: 'projectCount',
      key: 'N_PROJECTS',
      width: 160,
      render: (record) => {
        return (
          <div style={{ paddingLeft: '5px' }}>
            {record}
          </div>
        )
      }
    },
    {
      title: '타임시트 작성',
      // sort: true,
      dataIndex: 'timesheetCount',
      key: 'N_TIMESHEETS',
      width: 160
    },
    {
      title: '총 시간',
      dataIndex: 'totalMinutes',
      key: 'totalMinutes',
      width: 200,
      render: v => <>{parseMinutesToHourMin(v)}</>
    },
    {
      title: '마지막 로그인',
      dataIndex: 'lastLoggedIn',
      key: 'lastLoggedIn',
      width: 200,
      render: (v) => {
        return (
          <Typography.Text ellipsis style={caseTableCommonProps}>
            {(v !== null) ? parseDateObj(v, 'YYYY년 MM월 DD일') : '로그인 정보 없음'}
          </Typography.Text>
        )
      }
    },
    {
      title: '비밀번호 변경 날짜',
      dataIndex: 'passwordUpdated',
      key: 'passwordUpdated',
      width: 160,
      render: (v) => {
        return (
          <Typography.Text ellipsis style={caseTableCommonProps}>
            {(v !== null) ? parseDateObj(v, 'YYYY년 MM월 DD일') : ''}
          </Typography.Text>
        )
      }
    },
    {
      title: '',
      key: 'userMenu',
      width: 50,
      render: (record) => (
        <Popover
          onClick={(e) => {
            e.stopPropagation()
          }}
          placement='right'
          trigger='click'
          content={
            employersOptions.map(({ key, label, icon }) => {
              return (
                <ButtonContainer key={key}>
                  <StyledButton onClick={(e) => onClickTableMenu(e, key, record)}>
                    {icon}
                    {label}
                  </StyledButton>
                </ButtonContainer>
              )
            })
          }
        >
          <Button style={{ fontSize: '18px', border: 'none', display: 'flex', alignItems: 'center', width: '10px', justifyContent: 'center' }}>
            <MoreOutlined />
          </Button>
        </Popover>
      )
    }
  ]

  const onClickRow = (employeeMeta) => {
    navigateToEmployeeDetail(navigate, employeeMeta)
  }

  const excelDisplayDataAndCheckValidation = (tag, value, valid, key, warning) => {
    return (
      <div>
        {value?.length > 0
          ? (
            <Typography.Text ellipsis={{ tooltip: <>{value}</> }}>
              {value}
            </Typography.Text>)
          : null}
        {valid === false
          ? (
            <WarningText>
              <ExclamationCircleOutlined />
              {userFileUploadInvalidationMessage(tag, value, key, warning)}
            </WarningText>)
          : ''}
      </div>
    )
  }

  const userFileUploadInvalidationMessage = (tag, value, key, warning) => {
    if (warning === 'ERR_NULL') {
      return '값을 입력해주세요.'
    } else if (warning === 'ERR_NOT_FOUND') {
      return `존재하지 않는 ${tag} 입니다.`
    } else if (warning === 'ERR_DUPLICATE') {
      return `중복된 ${tag}이 있습니다.`
    } else { // 'ERR_UNPROCESSABLE'
      return '입력 값을 수정해주세요.'
    }
  }
  const excelCompareTableColumns = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 70,
      render: (name, record) => excelDisplayDataAndCheckValidation('이름', name, record.nameValid, 'nameValid', record.nameWarning)
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      width: 180,
      render: (email, record) => excelDisplayDataAndCheckValidation('이메일', email, record.emailValid, 'emailValid', record.emailWarning)
    },
    {
      title: '직급',
      dataIndex: 'rank',
      key: 'rank',
      width: 120,
      render: (rank, record) => excelDisplayDataAndCheckValidation('직급', rank, record.rankValid, 'rankValid', record.rankWarning)
    },
    {
      title: '부서',
      dataIndex: 'department',
      key: 'department',
      width: 100,
      render: (department, record) => excelDisplayDataAndCheckValidation('부서', department, record.departmentValid, 'departmentValid', record.departmentWarning)
    },
    {
      title: '권한그룹',
      dataIndex: 'userGroup',
      key: 'userGroup',
      width: 90,
      render: (userGroup, record) => excelDisplayDataAndCheckValidation('권한그룹', userGroup, record.userGroupValid, 'userGroupValid', record.userGroupWarning)
    }
  ]

  return {
    employeeTableColumns,
    onClickRow,
    excelProps: {
      modalTitle: '직원추가',
      columns: excelCompareTableColumns,
      updatedText: '업데이트'
    }
  }
}
