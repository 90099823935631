import { useMutation } from '@apollo/client'
import { addComment, editComment, removeComment } from '../../apollo/mutations'
import _ from 'lodash'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import { MESSAGES } from '../../../common/config'
import { useOutletContext } from 'react-router-dom'

export const useCommentAddRemove = (refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [addCommentMutFn] = useMutation(addComment)
  const [editCommentMutFn] = useMutation(editComment)
  const [removeCommentMutFn] = useMutation(removeComment)

  const onAddComment = (data) => {
    addCommentMutFn({
      variables: {
        contractId: data.contractId,
        content: data.content
      }
    }).then(v => {
      if (_.get(v, ['data', 'AddComment', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  const onEditComment = (data) => {
    editCommentMutFn({
      variables: {
        editCommentId: data.editCommentId,
        content: data.content
      }
    }).then(v => {
      if (_.get(v, ['data', 'EditComment', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  const onRemoveComment = (id) => {
    removeCommentMutFn({
      variables: {
        removeCommentId: id
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveComment', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }
  return {
    onAddComment,
    onEditComment,
    onRemoveComment
  }
}
