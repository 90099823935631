import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import { uuidv4 } from '../../../common/util'
import { useContractRecord } from '../../hooks/apollo/useContractRecord'
import { GrayCustomCard, RowGapWrapper } from '../../../common/Theme/style'
import { CardHeader } from '../../components/contractRecordDetail/CardHeader'
import { HeaderGroup, Spacer } from '../../../common/components/PageHeaderStyles'
import { getRecordFormStatus } from '../../cacheAndNavigation/contractRecordDetail/cache'
import { navigateToContractRecordDetail } from '../../cacheAndNavigation/contractRecordDetail/navigation'
import { useEditContractRecordDetailForm } from '../../hooks/contractRecord/contractRecordDetail/useEditContractRecordDetailForm'
import { useSavingContractRecordDetailForm } from '../../hooks/contractRecord/contractRecordDetail/useSavingContractRecordDetailForm'

import styled from 'styled-components'
import _ from 'lodash'
import { Button, Table, Form } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

const StyledTable = styled(Table)`
  display: flex;
flex-direction: column;
  width: 100%;
  .ant-table-wrapper .ant-table-cell-fix-left, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 1;
  }
  .ant-table-row {
    cursor: pointer;
  }
`

export const CommentForm = ({ contractRecordData, editMode, setEditMode, currentUser, formEditable, refetch }) => {
  // const { id } = useParams()
  const { permissions } = useOutletContext()
  const [pageDelete, setPageDelete] = useState(false)
  /*
  계약 기록 데이터
*/
  // const {
  //   loading,
  //   contractRecordData,
  //   refetch
  // } = useContractRecord(
  //   id
  // )
  const parseAddKey = (data) => {
    return (
      _.map(data, v => {
        return {
          ...v,
          key: uuidv4()
        }
      })
    )
  }
  const formattedData = (value) => {
    return (
      _.map(_.groupBy(value, (record) => _.trim(record.periodicId)), (records, periodicId) => ({
        key: periodicId,
        id: records[0].id,
        periodicId,
        totalDueAmount: _.sumBy(records, 'dueAmount'),
        category: records[0].category,
        periodic: records[0].periodic,
        dueDate: records[0].dueDate,
        dueAmount: records[0].dueAmount,
        payments: _.map(records, (record) => ({
          key: record.id,
          dueDate: record.dueDate,
          dueAmount: record.dueAmount,
          category: record.category
        }))
      })))
  }
  useEffect(() => {
    setDataSource(contractRecordData)
    // const paymentRecords = _.get(contractRecordData, 'paymentRecords', [])
    const paymentComments = _.get(contractRecordData, 'paymentComments', [])
    /* 총 계약 내역 계약 금액과 추가 계약 조건으로 분할 */
    // const contracts = _.filter(paymentRecords, record =>
    //   ['DEPOSIT', 'INTERIM', 'BALANCE', 'CONSULT'].includes(record.category)
    // )
    // setContractDataSource(parseAddKey(contracts))

    // const extraContracts = _.filter(paymentRecords, record =>
    //   ['CONTINGENT', 'EXTRA', 'ETC'].includes(record.category)
    // )
    // setExtraContractDataSource(parseAddKey(extraContracts))

    /* 추심 목록 */
    setReasonDataSource(paymentComments)
    setInitialData(paymentComments)
    /* 입금 내역 */
    // const depositsArray = _.flatMap(_.filter(paymentRecords, record => record.deposits.length > 0), 'deposits')
    // setDepositDataSource(depositsArray)

    /* form 초기 값 */
    const initialValues = {
      // contract: formattedData(contracts),
      // extraContract: formattedData(extraContracts),
      commentList: paymentComments,
      // depositRecordList: depositsArray
    }
    form.setFieldsValue(initialValues)
    
  }, [contractRecordData])

  const [dataSource, setDataSource] = useState() // 계약 정보
  const [contractDataSource, setContractDataSource] = useState([]) // 계약 목록
  const [extraContractDataSource, setExtraContractDataSource] = useState([]) // 추가 계약 목록
  const [depositDataSource, setDepositDataSource] = useState([]) // 입금 내역
  const [reasonDataSource, setReasonDataSource] = useState([])
  const [newContractIds, setNewContractIds] = useState([])
  const [deletedContractIds, setDeletedContractIds] = useState([])
  const [newReasonIds, setNewReasonIds] = useState([])
  const [removedReasonIds, setRemovedReasonIds] = useState([])
  const [newDepositIds, setNewDepositIds] = useState([])
  const [removedDepositIds, setRemovedDepositIds] = useState([])
  const [initialData, setInitialData] = useState([])
  const [form] = Form.useForm() // 수정중인데 나갈 경우, 경고창
  const formStatus = getRecordFormStatus()
  const navigate = useNavigate()

  const {
    onEdit,
    onCancel,
    onSave,
    readOnly
  } = useSavingContractRecordDetailForm(
    dataSource,
    formStatus,
    newContractIds,
    deletedContractIds,
    refetch,
    setNewContractIds,
    setDeletedContractIds,
    newReasonIds,
    setNewReasonIds,
    removedReasonIds,
    setRemovedReasonIds,
    newDepositIds,
    setNewDepositIds,
    removedDepositIds,
    setRemovedDepositIds,
    pageDelete,
    setEditMode,
    setContractDataSource,
    setExtraContractDataSource,
    setReasonDataSource,
    setDepositDataSource,
    contractDataSource,
    extraContractDataSource,
    initialData
  )

  // 추심목록 및 입금 내역 삭제
  const handleDelete = (key, type) => {
    if (type === 'commentList') {
      const newData = _.reject(reasonDataSource, { id: key })

      setReasonDataSource(newData)
      form.setFieldsValue({ commentList: newData })

      /* 추가 계약 조건 */
      if (_.includes(newReasonIds, key)) {
        setNewReasonIds((current) => _.without(current, key))
      } else {
        setRemovedReasonIds((current) => [...current, key])
      }
    } else { // depositRecordList
      const newData = _.reject(depositDataSource, { id: key })

      setDepositDataSource(newData)
      form.setFieldsValue({ depositRecordList: newData })

      if (_.includes(newDepositIds, key)) {
        setNewDepositIds((current) => _.without(current, key))
      } else {
        setRemovedDepositIds((current) => [...current, key])
      }
    }
  }

  const {
    formItems
  } = useEditContractRecordDetailForm(
    readOnly,
    contractDataSource,
    setContractDataSource,
    reasonDataSource,
    extraContractDataSource,
    setExtraContractDataSource,
    depositDataSource,
    setDepositDataSource,
    handleDelete,
    currentUser
  )

  const handleAddComment = () => {
    const newId = uuidv4()
    const newData = {
      key: newId,
      id: newId,
      createdBy: currentUser.name,
      content: ''
    }
    setReasonDataSource([...reasonDataSource, newData])
    form.setFieldsValue({ commentList: [...reasonDataSource, newData] })
    setNewReasonIds([...newReasonIds, newId])
  }

  const handleSave = (type) => {
    onSave(form, contractRecordData.id, type)
    navigateToContractRecordDetail(navigate, contractRecordData, null, 'view')
  }

  return (
    <>
      <Form
        form={form}
        initialValues={{ commentList: reasonDataSource }}
      >
        {formItems.map(({ key, cardTitle, value, scrollHeight, columns, component, dataIndex }) => {
          return (
            <GrayCustomCard key={key} $readOnly={readOnly}>
              <RowGapWrapper $gap={20}>
                <CardHeader type='button' dataIndex={dataIndex} title={cardTitle} value={value} readOnly={readOnly} />
                {!readOnly && <Button onClick={handleAddComment}>추심 추가</Button>}
                <Spacer />
                {formEditable
                  ? (readOnly
                      ? (
                        <HeaderGroup>
                          {(_.includes(permissions, 'UPDATE_PROJECT_DATA')) && ( // 수정: permissions
                            <>
                              <Button
                                color='primary'
                                variant='outlined'
                                icon={<EditOutlined />}
                                style={{ display: 'flex', alignItems: 'center' }}
                                onClick={() => onEdit()}
                                disabled={editMode}
                              >
                                수정하기
                              </Button>
                            </>
                          )}
                        </HeaderGroup>)
                      : (
                        <HeaderGroup>
                          {_.includes(permissions, 'UPDATE_PROJECT_DATA') && (
                            <>
                              <Button onClick={() => onCancel(form, 'commentList')}>
                                취소하기
                              </Button>
                              <Button type='primary' onClick={() => handleSave('comment')}>
                                저장하기
                              </Button>
                            </>)}
                        </HeaderGroup>))
                  : null}
              </RowGapWrapper>
              <StyledTable
                key={key}
                columns={columns}
                dataSource={key === 'commentList' ? reasonDataSource : depositDataSource}
                pagination={false}
                scroll={{
                  y: scrollHeight
                }}
              />
            </GrayCustomCard>
          )
        })}
      </Form>
    </>
  )
}
