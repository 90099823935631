import { gql } from '@apollo/client'

// 타임시트 디테일 페이지 쿼리
export const getTimesheetInfoById = gql`
  query GetTimesheet($getTimesheetId: ID!) {
    GetTimesheet(id: $getTimesheetId) {
      id
      minutes
      title
      date
      created
      content
      history {
        date
        id
        content
        minutes
        title
        user {
          id
          name
        }
        created
        createdBy {
          name
        }
        attachments {
          filename
          id
          originalFilename
          sizeB
        }
        project {
          name
        }
      }
      project {
        id
        name
      }
      attachments {
        filename
        id
        originalFilename
        sizeB
      }
      user {
        id
        name
      }
    }
  }
`
