import { useState } from 'react'
import { TimeDeltaAdder } from './TimeDeltaAdder.js'
import { parseMinutesToHourMin } from '../../../../common/util.js'
import { MinutesDifferenceIndicatorTag } from '../MinutesDifferenceIndicatorTag.js'
import { useTimesheetDeltaAdder } from '../../../hooks/timesheet/useTimesheetDeltaAdder'
import styled from 'styled-components'
import COLORS from '../../../../common/Theme/colors.js'
import { Button, Modal, Space, Table, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  > * {
    max-width: 1000px;
  }
`
const TriggerContainer = styled.div`
`
const TableTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  padding:20px;
  background-color: ${COLORS.orange_1_30};
  color: ${COLORS.black};
  font-weight: bold;
  border-bottom: 1px solid ${COLORS.orange_1};
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin: 30px 0 5px;
`
const TotalMinutesAfterEditButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`

export const TimeEditModal = ({
  children,
  style = {},
  caseId,
  userId,
  onFinishAddingTimeDelta = () => {},
  totalMinutes = 0
}) => {
  const [open, setOpen] = useState(null)
  const [showTimeDeltaMinutesSetter, setShowTimeDeltaMinutesSetter] = useState()
  const {
    timeDelta,
    timeDeltas,
    onAddTimeDelta
  } = useTimesheetDeltaAdder(
    caseId,
    userId,
    () => {
      onFinishAddingTimeDelta()
      setShowTimeDeltaMinutesSetter(false)
    },
    !open
  )
  const timeEditHistoryColumns = [
    {
      title: '',
      dataIndex: 'empty'
    },
    {
      title: '등록일',
      dataIndex: 'created'
    },
    {
      title: '조정자',
      dataIndex: 'creator'
    },
    {
      title: '조정 시간',
      dataIndex: 'minutes',
      render: (minutes) => {
        return <MinutesDifferenceIndicatorTag minutes={minutes} />
      }
    }
  ]

  return (
    <>
      <Modal width='60%' footer={null} open={open} onCancel={() => setOpen(false)}>
        <ContentContainer>

          <Typography.Title level={4}>
            시간 조정 내역
          </Typography.Title>
          <ButtonContainer>
            <TotalMinutesAfterEditButton onClick={() => setShowTimeDeltaMinutesSetter(true)}>
              <PlusOutlined />
              <div>시간 조정 추가하기</div>
            </TotalMinutesAfterEditButton>
          </ButtonContainer>
          <TableTopContainer>
            <div>총 시간</div>
            <div>{parseMinutesToHourMin(totalMinutes)}</div>
          </TableTopContainer>
          <Table
            style={{ width: '100%' }}
            columns={timeEditHistoryColumns}
            dataSource={timeDeltas}
            pagination={false}
            scroll={{
              y: 340
            }}
            footer={
              showTimeDeltaMinutesSetter
                ? () => (
                  <TimeDeltaAdder
                    onAddTimeDelta={onAddTimeDelta}
                    onCancel={() => setShowTimeDeltaMinutesSetter(false)}
                  />)
                : null
              }
          />
          <TableTopContainer style={{ borderBottom: '0px solid', borderTop: `1px solid ${COLORS.orange_1} ` }}>
            <div>조정 후 시간</div>
            <div>{parseMinutesToHourMin(totalMinutes + timeDelta)}</div>
          </TableTopContainer>
          <Space style={{ padding: 50 }} />
        </ContentContainer>
      </Modal>
      <TriggerContainer style={style} onClick={() => setOpen(true)}>{children}</TriggerContainer>
    </>
  )
}
