import { Segmented } from 'antd'
import _ from 'lodash'

const groupModeOptions = [
  {
    value: 'NAME',
    label: '의뢰인명'
  },
  {
    value: 'TYPE',
    label: '사건구분'
  }
]

export const GroupMode = ({ groupMode, onChangeGroupMode }) => {
  return (
    <Segmented
      value={groupMode}
      options={groupModeOptions}
      onChange={e => onChangeGroupMode(e)}
    />
  )
}
