import _ from 'lodash'

import { getTimesheetFormStatus } from '../../cacheAndNavigation//timesheetForm/cache'
import { getTimesheetMeta } from '../../cacheAndNavigation//timesheetDetail/cache'

export const useTimesheetFormNavigationCache = (currentUserId, shouldInitializeUserInputAsCurrentUser) => {
  const _initMeta = getTimesheetMeta()
  const status = getTimesheetFormStatus()

  const initMeta =
    status === 'creating'
      ? {
          ..._initMeta,
          minutes: _.get(_initMeta, ['minutes'], 0),
          updated: _.get(_initMeta, ['updated'], new Date())
        }
      : status === 'editing'
        ? {
            ..._initMeta,
            minutes: _.get(_initMeta, ['minutes'], 0),
            userId: _.get(_initMeta, ['userId'], shouldInitializeUserInputAsCurrentUser ? currentUserId : '')
          }
        : {
            ..._initMeta,
            minutes: _.get(_initMeta, ['minutes'], 0)
          }

  return {
    status,
    initMeta
  }
}
