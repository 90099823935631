import { Tag } from 'antd'
import { parseMinutesToHourMin } from '../../../common/util'
import { ClockCircleOutlined } from '@ant-design/icons'
import { RowContainer } from '../../../common/Theme/style'
import COLORS from '../../../common/Theme/colors'

export const MinutesDifferenceIndicatorTag = ({ minutes }) => {
  const isNotChanged = minutes === 0
  const isDecreased = minutes < 0
  return (
    <Tag
      style={{ display: 'flex', justifyContent: 'center', width: 70, height: 30, alignItems: 'center', fontSize: 13, cursor: 'pointer', border: 'none', background: isNotChanged ? `${COLORS.orange_1_50}` : 'none' }}
      color={isNotChanged ? 'orange' : isDecreased ? 'red' : 'blue'}
    >
      {isNotChanged
        ? <ClockCircleOutlined />
        : (
          <RowContainer style={{ alignItems: 'center', gap: 5 }}>
            <div>{isDecreased ? '-' : '+'}</div>
            <div>
              {minutes
                ? parseMinutesToHourMin(Math.abs(minutes))
                : null}
            </div>
          </RowContainer>)}
    </Tag>
  )
}
