import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import StatGraph from '../../timeCharge/components/statistics/StatGraph'
import { StatTable } from '../../timeCharge/components/statistics/StatTable'
import { ScrollContainer } from '../../timeCharge/components/ScrollContainer'
import { StatGroupFilter } from '../../timeCharge/components/statistics/StatGroupFilter'

import { useGraphMeta } from '../../timeCharge/hooks/statistics/useGraphMeta'
import { useTimeGroupList } from '../../timeCharge/hooks/apollo/useTimeGroupList'
import { useCaseUserFilter } from '../../timeCharge/hooks/filter/useCaseUserFilter'
import { useTimesheetList } from '../../timeCharge/hooks/apollo/StatisticPage/useTimesheetList'
import { useDefaultDateRangeFilter } from '../../timeCharge/hooks/filter/useDefaultDateRangeFilter'

import { downloadExcel } from '../../common/util'
import { MAX_CONTENT_WIDTH } from '../../common/config'
import { serializeTimesheetList } from '../../common/fastapi/fetchers'

import styled from 'styled-components'
import { Radio, message } from 'antd'
import _ from 'lodash'

const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  > * {
    width: 100%;
    max-width: ${MAX_CONTENT_WIDTH}px;
  }
`

const StatisticContainer = () => {
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false)
  const [spinning, setSpinning] = useState(false)
  const [percent, setPercent] = useState(0)
  const navigate = useNavigate()
  const {
    meta,
    filters,
    searchParams,
    caseNumbers,
    users,
    onChangeMeta,
    getUpdatedSearchParams,
    onResetFilterAndGroup,
    departments
  } = useCaseUserFilter()

  // 필터: '이번주' 기본 세팅
  useDefaultDateRangeFilter(meta, 'week', (defDateRange) => {
    const newSearchString = getUpdatedSearchParams('filter', 'datetimeRange', defDateRange, searchParams)
    navigate(`/stat?${newSearchString}`, {
      state: _.get(window, ['history', 'state', 'usr'], {}),
      replace: true
    })
  })

  const start = _.get(meta.filter.datetimeRange, [0])
  const end = _.get(meta.filter.datetimeRange, [1])
  const {
    loading,
    timeGroups,
    variables,
    totalMinutes
  } = useTimeGroupList(
    meta.group.item,
    meta.group.time === 'all' ? 'daily' : meta.group.time,
    meta.filter.caseIds,
    meta.filter.userIds,
    meta.filter.caseTypeIds,
    start,
    end,
    undefined,
    null,
    undefined,
    undefined,
    undefined,
    meta.filter.categories
  )
  const {
    timesheets,
    totalCount,
    totalMinutes: totalTimesheetMinutes
  } = useTimesheetList({
    projectIds: meta.filter.caseIds,
    userIds: meta.filter.userIds,
    from: start,
    to: end,
    limit: null,
    projectCategories: meta.filter.categories
  })

  const {
    barType,
    onChangeBarType,
    nTimesheetsPerDate,
    ratioOfTimesheetsPerCreators,
    ratioOfTimesheetsPerCaseNumbers
  } = useGraphMeta(
    meta,
    timeGroups,
    timesheets,
    caseNumbers,
    users,
    totalCount
  )

  const totalMin = meta.group.time === 'daily' ? totalMinutes : totalTimesheetMinutes
  const onDownloadExcel = () => {
    setFileDownloadLoading(true)
    showLoader()
    if (_.isEmpty(timeGroups)) {
      message.warning('다운로드 할 항목이 없습니다')
      return
    }
    serializeTimesheetList(variables).then((res) => {
      downloadExcel(_.get(res, ['data']), '날짜별 타임시트 작성 수')
      setFileDownloadLoading(false)
      message.destroy()
    }).catch(e => {
      setFileDownloadLoading(false)
      message.destroy()
      message.error('다운로드 실패')
    })
  }

  const showLoader = () => {
    setSpinning(true)
    let count = -10

    const interval = setInterval(() => {
      count += 10
      setPercent(count)

      if (count > 100 && (fileDownloadLoading === false)) {
        clearInterval(interval)
        setSpinning(false)
        setPercent(0)
      }
    }, 100)
  }
  return (
    <ScrollContainer height='100vh' style={{ padding: '10px' }}>
      <CenterContainer>
        <StatGroupFilter
          meta={meta}
          filters={_.filter(
            filters,
            ({ key }) => _.includes([
              'caseIds',
              'userIds',
              'categories',
              'caseTypeIds'
            ], key)
          )}
          totalMinutes={totalMin}
          onChangeMeta={onChangeMeta}
          onDownloadExcel={onDownloadExcel}
          onResetFilterAndGroup={onResetFilterAndGroup}
          fileDownloadLoading={fileDownloadLoading}
          spinning={spinning}
          percent={percent}
          departments={departments}
        />
        {meta.group.time === 'all' && meta.group.item === 'all'
          ? (
            <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <StatGraph loading={loading} {...ratioOfTimesheetsPerCreators} />
              <StatGraph loading={loading} {...ratioOfTimesheetsPerCaseNumbers} />
            </div>)
          : null}
        <div style={{ padding: '20px 14px 8px' }}>
          <Radio.Group value={barType} onChange={e => onChangeBarType(e.target.value)}>
            <Radio value='totalMinutes'>총 시간</Radio>
            <Radio value='timesheetCount'>작성 수</Radio>
          </Radio.Group>
        </div>
        <StatGraph loading={loading} {...nTimesheetsPerDate} groupFilter={meta} />
        <div>
          <StatTable meta={meta} fileDownloadLoading={fileDownloadLoading} />
        </div>
      </CenterContainer>
    </ScrollContainer>
  )
}

export default StatisticContainer
