import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { getProjectNameCategories } from '../../apollo/queries'

export const useProjectNameTemplateList = () => {
  const { data: _projectNameCategories, loading } = useQuery(getProjectNameCategories, {
    fetchPolicy: 'network-only'
  })
  const projectNamecategories = _.map(_.get(_projectNameCategories, ['GetTimesheetNameTemplateList'], []))
  return {
    loading,
    projectNamecategories
  }
}
