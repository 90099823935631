import { forwardRef, useImperativeHandle, useState } from 'react'
import { ColorPicker, Form, Input } from 'antd'

export const _RankEditableCell = ({
  editing,
  dataIndex,
  title,
  inputtype,
  record,
  index,
  children,
  handleSave,
  ...restProps
}, ref) => {
  const initMeta =
    editing
      ? {
          name: record?.name
        }
      : {}
  const [meta, setMeta] = useState(initMeta)
  useImperativeHandle(ref, () => ({
    getName: () => meta.name
  }))
  return (
    <td {...restProps}>
      {editing
        ? (
          <Form>
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0
              }}
              rules={[
                {
                  required: true,
                  message: `${title}을 입력하세요!`
                }
              ]}
            >
              {inputtype === 'ColorPicker' ? <ColorPicker initialvalues={record.color} onChangeComplete={color => setMeta(meta => ({ ...meta, color: color.toHexString() }))} /> : null}
              {inputtype === 'ColorPicker' ? null : <Input initialvalues={record.name} onChange={e => setMeta(meta => ({ ...meta, name: e.target.value }))} />}
            </Form.Item>
          </Form>)
        : (
            children
          )}
    </td>
  )
}

export const RankEditableCell = forwardRef(_RankEditableCell)
