import { useParams } from 'react-router-dom'
import { getUserMeta } from '../../../timeCharge/cacheAndNavigation/setting/cache'
import { useUserGroupList } from '../../../timeCharge/hooks/apollo/useUserGroupList'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { HeaderContainer, HeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'
import { useSettingUserPermission } from '../../../common/hooks/settings/users/useSettingUserPermission'

import styled from 'styled-components'
import _ from 'lodash'
import { Badge, Button, Select, Switch, Table } from 'antd'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { parseMetaToSelectOptions } from '../../../call/components/common/CommonFunction'

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:  20px;
  width: 100%;
  margin-bottom: 50px;
`

const _autorityCategory = [
  {
    authorityGroupName: '사건 관련',
    detailAuthority: '모든 사건 열람',
    key: 'READ_ALL_PROJECT'
  },
  {
    authorityGroupName: '',
    detailAuthority: '동일부서 사건 열람',
    key: 'READ_SAME_DEPARTMENT_PROJECT'
  },
  {
    authorityGroupName: '타임시트 관련',
    detailAuthority: '모든 타임시트 열람',
    key: 'READ_ALL_TIMESHEET'
  },
  {
    authorityGroupName: '',
    detailAuthority: '동일부서 타임시트 열람',
    key: 'READ_SAME_DEPARTMENT_TIMESHEET'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타임시트 작성',
    key: 'WRITE_TIMESHEET'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타인 타임시트 입력',
    key: 'WRITE_OTHER_TIMESHEET'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타임시트 연혁 열람',
    key: 'READ_TIMESHEET_HISTORY'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타임시트 시간 조정 입력',
    key: 'WRITE_TIMESHEET_TIMEDELTA'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타임시트 시간 조정 열람',
    key: 'READ_TIMESHEET_TIMEDELTA'
  },
  {
    authorityGroupName: '사건 종결 관련',
    detailAuthority: '사건 종결',
    key: 'CLOSE_PROJECT'

  },
  {
    authorityGroupName: '',
    detailAuthority: '사건 종결 취소',
    key: 'REOPEN_PROJECT'
  },
  {
    authorityGroupName: '사건 등록 관련',
    detailAuthority: '사건 등록',
    key: 'CREATE_PROJECT'

  },
  {
    authorityGroupName: '',
    detailAuthority: '자문 등록',
    key: 'CREATE_CONSULTATION'
  },
  {
    authorityGroupName: '',
    detailAuthority: '기타 등록',
    key: 'CREATE_ETC'
  },
  {
    authorityGroupName: '',
    detailAuthority: '사건 정보 관리',
    key: 'UPDATE_PROJECT_DATA'
  },
  {
    authorityGroupName: '사건 배당 관련',
    detailAuthority: '사건 배당 받을 수 있음',
    key: 'ALLOW_ALLOCATION'
  },
  {
    authorityGroupName: '',
    detailAuthority: '사건 배당 또는 위임할 수 있음',
    key: 'ALLOCATE_OR_DELEGATE'
  }
]

const tmp = {
  admin: {
    titile: '관리자',
    dataIndex: 'admin',
    key: 'ADMIN'
  },
  ceo: {
    title: '대표변호사',
    dataIndex: 'ceo',
    key: 'CEO'
  },
  partner: {
    title: '파트너변호사',
    dataIndex: 'partner',
    key: 'PARTNER'
  },
  lawyer: {
    title: '일반변호사',
    dataIndex: 'lawyer',
    key: 'LAWYER'
  },
  groupLeader: {
    title: '부서장',
    dataIndex: 'groupLeader',
    key: 'GROUPLEADER'
  },
  worker: {
    title: '직원',
    dataIndex: 'worker',
    key: 'WORKER'
  },
  consultant: {
    title: '상담실장',
    dataIndex: 'consultant',
    key: 'CONSULTANT'
  }
}
const keys = [
  'admin',
  'ceo',
  'lawyer',
  'partner',
  'groupLeader',
  'worker',
  'consultant'
]

const SettingEmployeeDelegateContainer = () => {
  const { userId } = useParams()
  const { userGroups, loading } = useUserGroupList()
  const userGroupByName = _.keyBy(userGroups, 'name')
  const userMeta = getUserMeta() // 현제 직원의 직무

  const autorityCategory = _autorityCategory.map((v) => {
    const { key: permission } = v
    const checkedByUserGroup = {}
    _.forEach(keys, key => {
      checkedByUserGroup[key] = _.includes(_.get(userGroupByName, [_.get(tmp, [key, 'title']), 'permissions']), permission)
    })
    return {
      ...v,
      checkedByUserGroup
    }
  })

  const {
    onCancel,
    primaryGroupId,
    onChangePrimaryGroupId,
    checkDot,
    onSaveChanges,
    getPrimaryGroupChecked, // 변경된 권한에 따라 체크 표시 변경
    getEditedGroupChecked,
    onChangePermissionToUser
  } = useSettingUserPermission(userId, userGroups)

  return (
    <HeaderContentFooter
      header={(
        <HeaderContainer>
          <HeaderTitle title='직원 권한 설정' />
          <Spacer $flex={5} />
          <Button onClick={onCancel}>취소하기</Button>
          <Button style={{ display: 'flex', alignItems: 'center' }} icon={<EditOutlined />} type='primary' onClick={onSaveChanges}>변경사항 저장하기</Button>
        </HeaderContainer>
      )}
      content={(
        <ColumnContainer>
          <>사용자: {userMeta.email}</>
          <Table
            loading={loading}
            pagination={false}
            columns={[
              {
                title: '권한종류',
                rowScope: 'row',
                children: [
                  {
                    title: '권한 그룹',
                    dataIndex: 'authorityGroupName',
                    key: 'authorityGroupName',
                    width: 100
                  },
                  {
                    title: '권한 세부 카테고리',
                    dataIndex: 'detailAuthority',
                    key: 'detailAuthority',
                    width: 100
                  }
                ]
              },
              {
                title: '권한 기본 설정',
                dataIndex: 'groupPrimarySetting',
                colSpan: 1,
                children: [
                  {
                    title: () => {
                      if (loading) return null
                      return (
                        <Select
                          value={primaryGroupId}
                          style={{
                            width: 120
                          }}
                          onChange={onChangePrimaryGroupId}
                          options={_.map(userGroups, parseMetaToSelectOptions)}
                        />
                      )
                    },
                    dataIndex: 'primarySetting',
                    key: 'primarySetting',
                    render: (checked) => {
                      return <>{checked ? <CheckOutlined /> : null}</>
                    }
                  }
                ]
              },
              {
                title: '개인 설정',
                dataIndex: 'privateAuthorityCategory',
                colSpan: 2,
                children: [
                  {
                    title: '권한 제공',
                    key: 'authority',
                    render: (record) => {
                      // if current authority exist in the permissionAdd or permissionRemove add dot
                      return (
                        <div>
                          <Badge dot={checkDot(record.key)}>
                            <Switch
                              // disabled={editingKey !== true}
                              checked={getEditedGroupChecked(record.key)}
                              onChange={(checked) => onChangePermissionToUser(record.key, checked)}
                            />
                          </Badge>
                        </div>
                      )
                    }
                  }
                ]
              }
            ]}
            dataSource={
              loading
                ? null
                : autorityCategory.map(
                  ({ authorityGroupName, detailAuthority, checkedByUserGroup, key: permission }) => {
                    return ({
                      key: permission,
                      authorityGroupName,
                      detailAuthority,
                      primarySetting: getPrimaryGroupChecked(permission)
                    })
                  }
                )
            }
          />
        </ColumnContainer>
      )}
    />
  )
}

export default SettingEmployeeDelegateContainer
