import _ from 'lodash'
export const parseTimesheet =
  timesheet => {
    return {
      ...timesheet,
      updated: _.get(timesheet, ['date']),
      creator: _.get(timesheet, ['user', 'name']),
      userId: _.get(timesheet, ['user', 'id']),
      caseNumber: _.get(timesheet, ['project', 'name']),
      caseId: _.get(timesheet, ['project', 'id']),
      case: {
        caseNumber: _.get(timesheet, ['project', 'name'])
      }
    }
  }
export const parseUser = (user, caseId) => {
  return {
    ...user,
    type: _.get(user, ['group', 'name']),
    departments: _.map(user?.departments || [], 'name')?.join(', '),
    ...caseId ? { caseTimesheetCount: _.filter(_.get(user, ['timesheets'], []), v => _.get(v, ['project', 'id']) === caseId).length } : {}
  }
}
export const parseCaseList = (caseList) => {
  return _.map(caseList, v => {
    const users = _.map(_.get(v, ['users'], []), user => {
      const timesheets = _.map(_.get(user, ['timesheets'], []), parseTimesheet)
      return {
        ...user,
        key: v.id + _.get(user, ['id']),
        userId: _.get(user, ['id']),
        updated: _.get(v, ['updated'], null),
        name: _.get(user, ['name']),
        minutes: _.get(user, ['totalMinutes'], 0),
        deltaMinutes: _.get(user, ['totalDeltaMinutes'], 0),
        minutesAfterEdit: _.get(user, ['totalMinutes'], 0) + _.get(user, ['totalDeltaMinutes'], 0),
        type: _.get(user, ['group', 'name']),
        // departments: _.map(user?.departments || [], 'name')?.join(', '),
        departments: _.map(user?.departments || [], 'id'),
        timeDeltas: _.map(_.get(user, ['timeDeltas'], []), timeDelta => {
          const {
            createdBy: {
              name
            },
            created,
            minutes
          } = timeDelta
          return {
            user: name,
            created,
            minutes
          }
        }),
        timesheets
      }
    })
    return {
      ...v,
      key: _.get(v, ['id']),
      caseTag: _.get(v, ['type', 'id'], null),
      category: _.get(v, ['type', 'name'], null),
      category2: _.get(v, ['category'], null),
      starred: _.get(v, ['starred']),
      caseNumber: _.get(v, ['name']),
      updated: _.get(v, ['updated'], null),
      caseType: _.get(v, ['type']),
      users,
      totalMinutes: _.get(v, ['totalMinutes'], 0),
      totalDeltaMinutes: _.get(v, ['totalDeltaMinutes'], 0),
      totalMinutesAfterEdit: _.get(v, ['totalMinutes'], 0) + _.get(v, ['totalDeltaMinutes'], 0)
    }
  })
}

export const parseClosedFromArrOfCaseStatusFilter = (_caseStatus = []) => {
  const closed = _.includes(_caseStatus, 'OPEN')
    ? _.includes(_caseStatus, 'CLOSED')
      ? null
      : false
    : _.includes(_caseStatus, 'CLOSED')
      ? true
      : null
  return closed
}
