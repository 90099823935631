import styled from 'styled-components'

const UserInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`
export const UserInputCustomEmptyMessage = ({ text, usersListDataLoading, searchedUserListDataLoading }) => {
  return <UserInputContainer>{text}</UserInputContainer>
}
