import { useMutation } from '@apollo/client'
import { unremoveContractRecord } from '../../apollo/mutations'
import _ from 'lodash'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import { MESSAGES } from '../../../common/config'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { navigateToContractRecordDetail } from '../../cacheAndNavigation/contractRecordDetail/navigation'

export const useContractRecordRestore = (refetch = () => {}, contractRecordData, searchString = null) => {
  const { messageApi } = useOutletContext()
  const [unremoveContractRecordMutFn] = useMutation(unremoveContractRecord)
  const navigate = useNavigate()

  const onUnremoveContractRecord = (id) => {
    unremoveContractRecordMutFn({
      variables: {
        unremoveContractRecordId: id
      }
    }).then(v => {
      if (_.get(v, ['data', 'UnremoveContractRecord', 'ok'])) {
        navigateToContractRecordDetail(navigate, contractRecordData, searchString, 'view')
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreContractError)
      handleError()
    })
  }

  return {
    onUnremoveContractRecord
  }
}
