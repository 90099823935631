import _ from 'lodash'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../../common/config'
import { useUserAddDeleteRank } from '../../../../timeCharge/hooks/apollo/mutations/useUserAddDeleteRank'
import { sleep } from '../../../../common/util'

export const useEditingUserRankForm = (onFetchAndNav, userId, initRankIds = []) => {
  const navigate = useNavigate()
  const { messageApi } = useOutletContext()
  const {
    onAddRanksToUser,
    onRemoveRanksFromUser
  } = useUserAddDeleteRank(() => navigate('/setting/employersAuthority', { replace: true }))
  const onEdit = (savingMeta) => {
    const { rankIds: _rankIds } = savingMeta
    const rankIds = [_rankIds]
    const rankIdsToAdd = _.differenceWith(rankIds, initRankIds)
    const rankIdsToRemove = _.differenceWith(initRankIds, rankIds)

    onFetchAndNav(
      () => {
        if (_.isEmpty(rankIdsToAdd) && !_.isEmpty(rankIdsToRemove)) {
          return onRemoveRanksFromUser(userId, rankIdsToRemove)
        } else if (!_.isEmpty(rankIdsToAdd) && _.isEmpty(rankIdsToRemove)) {
          return onAddRanksToUser(userId, rankIdsToAdd)
        } else if (!_.isEmpty(rankIdsToAdd) && !_.isEmpty(rankIdsToRemove)) {
          return onAddRanksToUser(userId, rankIdsToAdd).then(v => {
            return onRemoveRanksFromUser(userId, rankIdsToRemove)
          })
        } else return sleep(100)
      },
      (v) => {
      },
      (e) => {
        console.log(e)
        messageApi.open(MESSAGES.updateRankError)
      }
    )
  }

  return {
    onEdit
  }
}
