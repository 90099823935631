import * as React from 'react'
import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'

import HomeContainer from './timeCharge/containers/HomeContainer'
import MainContainer from './timeCharge/containers/MainContainer'
import SearchContainer from './timeCharge/containers/SearchContainer'
import StatisticContainer from './timeCharge/containers/StatisticContainer'
import TimesheetContainer from './timeCharge/containers/TimesheetContainer'
import SettingContainer from './timeCharge/containers/setting/SettingContainer'
import TimesheetFormContainer from './timeCharge/containers/TimesheetFormContainer'
import TimesheetEntryContainer from './timeCharge/containers/TimesheetEntryContainer'
import AddEmployeeContainer from './timeCharge/containers/setting/AddEmployeeContainer'
import TimesheetDetailContainer from './timeCharge/containers/TimesheetDetailContainer'
import LoggingOutIndicatingContainer from './timeCharge/containers/LoggingOutIndicatingContainer'
import AddDepartmentContainer from './timeCharge/containers/setting/department/AddDepartmentContainer'
import SettingEmployeeRankContainer from './timeCharge/containers/setting/SettingEmployeeRankContainer'
import EditDepartmentContainer from './timeCharge/containers/setting/department/EditDepartmentContainer'
import AddProjectTypeContainer from './timeCharge/containers/setting/projectType/AddProjectTypeContainer'
import EditProjectTypeContainer from './timeCharge/containers/setting/projectType/EditProjectTypeContainer'
import SettingEmployeeDelegateContainer from './timeCharge/containers/setting/SettingEmployeeDelegateContainer'
import SettingEmployeeDepartmentContainer from './timeCharge/containers/setting/SettingEmployeeDepartmentContainer'
import SettingEmployeeResetPasswordContainer from './timeCharge/containers/setting/SettingEmployeeResetPasswordContainer'

import './index.css'

import { TrashContainer } from './timeCharge/containers/TrashContainer'
import { CreateEtcContainer } from './timeCharge/containers/manageCase/CreateEtcContainer'
import { CaseDetailContainer } from './timeCharge/containers/manageCase/CaseDetailContainer'
import { CreateCaseContainer } from './timeCharge/containers/manageCase/CreateCaseContainer'
import { EmployeeDetailContainer } from './timeCharge/containers/employee/EmployeeDetailContainer'
import { UserAllocationContainer } from './timeCharge/containers/manageCase/UserAllocationContainer'
import { ManageCaseTableContainer } from './timeCharge/containers/manageCase/ManageCaseTableContainer'
import { ManageCaseCreateNavContainer } from './timeCharge/containers/manageCase/ManageCaseCreateNavContainer'

import { ConfigProvider } from 'antd'
import COLORS from './common/Theme/colors'
import CallRecordContainer from './call/containers/CallRecordContainer'
import ContractRecordContainer from './call/containers/ContractRecordContainer'
import ContractRecordDeletedContainer from './call/containers/ContractRecordDeletedContainer'
import { ContractRecordDetailContainer } from './call/containers/contractRecordDetail/ContractRecordDetailContainer'
import OverdueContainer from './call/containers/OverdueContainer'

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainContainer />,
    children: [
      // 타임차지
      {
        path: '/',
        element: <HomeContainer />
      },
      {
        path: '/search',
        element: <SearchContainer />
      },
      {
        path: '/timesheet/:caseStatus',
        element: <TimesheetContainer showCaseTable />
      },
      {
        path: '/timesheetDetail/:timesheetId',
        element: <TimesheetDetailContainer />
      },
      {
        path: '/timesheetEntry/:timesheetId/:timesheetEntryId',
        element: <TimesheetEntryContainer />
      },
      {
        path: '/timesheetForm',
        element: <TimesheetFormContainer />
      },
      {
        path: '/stat',
        element: <StatisticContainer />
      },
      {
        path: '/setting/addEmployee',
        element: <AddEmployeeContainer />
      },
      {
        path: '/setting/addDepartment',
        element: <AddDepartmentContainer />
      },
      {
        path: '/setting/addProjectType',
        element: <AddProjectTypeContainer />
      },
      {
        path: '/setting/editDepartment/:departmentId',
        element: <EditDepartmentContainer />
      },
      {
        path: '/setting/:settingCategory',
        element: <SettingContainer />
      },
      {
        path: '/setting/:settingCategory/employeeDelegate/:userId',
        element: <SettingEmployeeDelegateContainer />
      },
      {
        path: '/setting/:settingCategory/resetPassword/:userId',
        element: <SettingEmployeeResetPasswordContainer />
      },
      {
        path: '/setting/:settingCategory/assignDepartment/:userId',
        element: <SettingEmployeeDepartmentContainer />
      },
      {
        path: '/setting/:settingCategory/assignRank/:userId',
        element: <SettingEmployeeRankContainer />
      },
      {
        path: '/setting/editProjectType/:projectTypeId',
        element: <EditProjectTypeContainer />
      },
      {
        path: '/manageCase/table',
        element: <ManageCaseTableContainer />
      },
      {
        path: '/manageCase/createNav',
        element: <ManageCaseCreateNavContainer />
      },
      {
        path: '/manageCase/createCase',
        element: <CreateCaseContainer />
      },
      {
        path: '/caseDetail/:caseId',
        element: <CaseDetailContainer />
      },
      {
        path: '/employeeDetail/:employeeId',
        element: <EmployeeDetailContainer />
      },
      {
        path: '/manageCase/createConsultation',
        element: <CreateCaseContainer type='consultation' />
      },
      {
        path: '/manageCase/createEtc',
        element: <CreateEtcContainer />
      },
      {
        path: '/manageCase/userAllocation/:caseId',
        element: <UserAllocationContainer />
      },
      {
        path: '/trash',
        element: <TrashContainer />
      },
      {
        path: '/logout',
        element: <LoggingOutIndicatingContainer />
      },
      // 콜선임
      {
        path: '/callRecord/:recordStatus',
        element: <CallRecordContainer />
      },
      {
        path: '/contractRecord',
        element: <ContractRecordContainer />
      },
      {
        path: '/contractRecordDetail/:id',
        element: <ContractRecordDetailContainer />
      },
      {
        path: '/overdue',
        element: <OverdueContainer />
      },
      {
        path: '/contractRecordDeleted',
        element: <ContractRecordDeletedContainer />
      }
    ]
  }
])
const config = {
  token: {
    colorPrimary: `${COLORS.primaryColor}`
  }
}

createRoot(document.getElementById('root')).render(
  <>
    <ConfigProvider theme={config}>
      <RouterProvider router={router} />
    </ConfigProvider>
  </>
)
