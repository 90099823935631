import { CaseFiles } from './CaseFiles'
import { UserFiles } from './UserFiles'

import { useTimesheetFinder } from '../../../hooks/timesheet/useTimesheetFinder'
import { TimesheetFiles } from './TimesheetFiles'
import styled from 'styled-components'
import { useOutletContext } from 'react-router-dom'
import _ from 'lodash'

const Container = styled.div`
  padding: 40px;
`

export const TimesheetFinder = () => {
  const {
    cases,
    caseVirtualListRef,
    users,
    loading,
    timesheets,
    finderState,
    onInfiniteScrollUpdateCases,
    caseInfiniteScrollFetcher,
    caseInfiniteScrollFetchParser,
    onChangeStar,
    onDoubleClickCaseFolder,
    onDoubleClickUserFolder,
    onDoubleClickTimesheetFile,
    totalCount
  } = useTimesheetFinder()
  const { permissions } = useOutletContext()
  const readTimeDeltaPermission = _.includes(permissions, 'READ_TIMESHEET_TIMEDELTA')
  return (
    <Container>
      {finderState === 1
        ? <CaseFiles
            virtualListRef={caseVirtualListRef}
            cases={cases}
            totalCount={totalCount}
            loading={loading}
            readTimeDeltaPermission={readTimeDeltaPermission}
            scrollRefetch={caseInfiniteScrollFetcher}
            scrollDataParser={caseInfiniteScrollFetchParser}
            onCheckStar={onChangeStar}
            onDoubleClickFolder={onDoubleClickCaseFolder}
            onInfiniteScrollUpdateCases={onInfiniteScrollUpdateCases}
          />
        : finderState === 2
          ? <UserFiles users={users} onDoubleClickFolder={onDoubleClickUserFolder} />
          : finderState === 3
            ? <TimesheetFiles timesheets={timesheets} onDoubleClickFile={onDoubleClickTimesheetFile} />
            : null}
    </Container>
  )
}
