import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import { BlockModal } from '../../../timeCharge/components/common/BlockModal'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'

import { useBlockModal } from '../../../timeCharge/hooks/common/useBlockModal'

import AlignedForm from '../../../timeCharge/components/common/AlignedForm'

import { HeaderContainer as _HeaderContainer, HeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'
import { FormContainerFooter } from '../../../timeCharge/components/manageCase/FormContainerFooter'
import { getCaseMeta } from '../../../timeCharge/cacheAndNavigation/caseDetail/cache'
import { getCaseFormStatus } from '../../../timeCharge/cacheAndNavigation/caseForm/cache'

import _ from 'lodash'
import { useFormAttatchments } from '../../../timeCharge/hooks/timesheetForm/useFormAttatchments'
import { useEffect, useRef } from 'react'
import COLORS from '../../../common/Theme/colors'
import { Attatchments } from '../../../timeCharge/components/common/Attatchments'
import { useCreateEtcForm } from '../../../timeCharge/hooks/manageCase/case/useCreateEtcForm'
import { useAddingEtc } from '../../../timeCharge/hooks/manageCase/case/useAddingEtc'

const ContentContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  min-width: 500px;
  max-width: 800px;
`
const HeaderContainer = styled(_HeaderContainer)`
  justify-content: flex-end;
`
const AttatchmentContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: ${COLORS.black_1};
  > * {
    max-width: 800px;
  }
`
const FooterHeight = 150

export const CreateEtcContainer = () => {
  const type = 'etc'
  const caseFormStatus = getCaseFormStatus()
  const prevCaseFormStatus = useRef(caseFormStatus)

  const {
    fetching,
    onFetchAndNav,
    blockModalOpen,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()

  useEffect(() => {
    if (!blockModalOpen) prevCaseFormStatus.current = caseFormStatus
  }, [blockModalOpen])

  const initMeta = getCaseMeta()

  const {
    files,
    fileIds,
    dragDropProps,
    ...attatchmentProps
  } = useFormAttatchments(_.get(initMeta, ['attachments'], undefined) || [])

  const {
    formItems,
    caseNumber,
    content,
    caseTags
  } = useCreateEtcForm(false, initMeta)

  const {
    onSave,
    onCancel
  } = useAddingEtc(
    fileIds,
    caseNumber,
    content,
    onFetchAndNav,
    type,
    caseFormStatus,
    _.get(initMeta, ['caseId']),
    _.get(initMeta, ['status']),
    caseTags
  )
  const location = useLocation()
  const readOnly = _.split(location.pathname, ['/'])[1] === 'createCase'

  const typeToCreate = '기타'
  return (
    <>
      <HeaderContentFooter
        header={
          <HeaderContainer>
            <HeaderTitle
              title={
                `${typeToCreate} 생성`
              }
            />
            <Spacer />
            <FormContainerFooter
              fetching={fetching}
              onSave={onSave}
              onCancel={onCancel}
              isEditing={prevCaseFormStatus.current === 'editing' || prevCaseFormStatus.current === 'creating'}
            />
          </HeaderContainer>
        }
        content={
          <ContentContainer>
            <AlignedForm
              items={formItems}
            />
          </ContentContainer>
        }
        footer={(
          <AttatchmentContainer>
            <Attatchments readOnly={readOnly} files={files} dragDropProps={dragDropProps} {...attatchmentProps} />
          </AttatchmentContainer>
        )}
        footerHeight={FooterHeight}
      />
      <BlockModal
        title={`${typeToCreate} ${prevCaseFormStatus.current === 'creating' ? '생성' : '수정'}을 취소하시겠습니까?`}
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}
