import { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { CustomSearch } from '../../../common/components/CustomSearch'
import { EmployeeList } from '../../../timeCharge/components/settings/EmployeeList'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { TableActions } from '../../../timeCharge/components/common/CustomTable/TableActions'
import { HeaderContainer, HeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'

import { useUserListTable } from '../../../common/hooks/settings/users/useUserListTable'
import { useDepartmentList } from '../../../timeCharge/hooks/apollo/SettingPage/useDepartmentList'
import { useManageEmployeeTable } from '../../../timeCharge/hooks/manageCase/table/useManageEmployeeTable'
import { useEmplyeeTableActions } from '../../../timeCharge/hooks/apollo/SettingPage/useEmplyeeTableActions'

import styled from 'styled-components'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import _ from 'lodash'

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  > * {
    max-width: ${1610}px;
  }
`
const ActionsContainer = styled.div`
  padding: 10px 0;
`

const ManageEmployeesContainer = ({
  hiddenTableActionByKey = {
    stat: true,
    excelUpload: false,
    excelDownload: true,
    templateDownload: false,
    tossCase: true
  }
}) => {
  const navigate = useNavigate()
  const { permissions } = useOutletContext()
  const showAddEmployeeButton = _.includes(permissions, 'MANAGE_USERS')
  const onNavToAddEmployee = () => {
    navigate('/setting/addEmployee')
  }
  const { excelProps } = useManageEmployeeTable()
  const onDepartmentListComplete = (departments) => {
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([]) // 선택된 부서 id
  const [searchQuery, setSearchQuery] = useState('')
  const {
    departments
  } = useDepartmentList(null, onDepartmentListComplete)
  const {
    preAllocationTableProps
  } = useUserListTable(selectedDepartmentIds, searchQuery)
  const {
    onClickTableActions,
    fileDownloadLoading,
    spinning,
    percent
  } = useEmplyeeTableActions()
  return (
    <HeaderContentFooter
      header={(
        <HeaderContainer style={{ justifyContent: 'right' }}>
          <HeaderTitle title='직원 관리' />
          <Spacer $flex={5} />
          {showAddEmployeeButton
            ? (
              <Button style={{ display: 'flex', alignItems: 'center' }} type='primary' onClick={onNavToAddEmployee}>
                <PlusOutlined />
                직원 추가하기
              </Button>)
            : null}
        </HeaderContainer>
      )}
      content={(
        <CenterContainer>
          <CustomSearch onSearch={e => setSearchQuery(e)} placeholder='이름을 입력해주세요.' />
          <ActionsContainer>
            <TableActions
              hiddenTableActionByKey={hiddenTableActionByKey}
              excelProps={excelProps}
              onClick={onClickTableActions}
              dataCategory='employee'
              refetch={preAllocationTableProps.refetch}
              fileDownloadLoading={fileDownloadLoading}
              spinning={spinning}
              percent={percent}
            />
          </ActionsContainer>
          <EmployeeList
            filterProps={{
              scroll: { y: 1180 },
              selectedDepartmentIds,
              departments: _.map(departments, department => ({ name: _.get(department, ['name']), key: _.get(department, ['id']) })),
              onChangeSelection: (rowKeys) => setSelectedDepartmentIds(rowKeys)
            }}
            preAllocationTableProps={preAllocationTableProps}
          />
        </CenterContainer>
      )}
    />
  )
}

export default ManageEmployeesContainer
