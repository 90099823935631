import { useState } from 'react'

const useTossCase = () => {
  const [isTossCaseModalOpen, setIsTossCaseModalOpen] = useState(false)
  // const [btnTossCaseDisabled, setBtnTossCaseDisabled] = useState(false)

  const showTossCaseModal = () => {
    setIsTossCaseModalOpen(true)
  }
  const handleCaseTossModalCancel = () => {
    setIsTossCaseModalOpen(false)
  }
  const handleCaseTossModalOk = () => {
    // setBtnTossCaseDisabled(true)
    setIsTossCaseModalOpen(false) // 임시로 닫히게 설정
  }
  // const proceedNext = () => {
  //   setIsTossCaseModalOpen(false)
  // }

  return {
    isTossCaseModalOpen,
    // btnTossCaseDisabled,
    showTossCaseModal,
    handleCaseTossModalCancel,
    handleCaseTossModalOk
  }
}
export default useTossCase
