import _ from 'lodash'

import { getCaseList } from '../../../apollo/inProgressCaseQueries'
import { useQuery } from '@apollo/client'

const parseTimesheet =
  timesheet => {
    return {
      ...timesheet,
      updated: _.get(timesheet, ['date']),
      creator: _.get(timesheet, ['user', 'name']),
      userId: _.get(timesheet, ['user', 'id']),
      caseNumber: _.get(timesheet, ['project', 'name']),
      caseId: _.get(timesheet, ['project', 'id']),
      case: {
        caseNumber: _.get(timesheet, ['project', 'name'])
      }
    }
  }

const parseCaseList = (caseList) => {
  return _.map(caseList, v => {
    const users = _.map(_.get(v, ['users'], []), user => {
      const timesheets = _.map(_.get(user, ['timesheets'], []), parseTimesheet)
      return {
        ...user,
        key: v.id + _.get(user, ['id']),
        userId: _.get(user, ['id']),
        updated: _.get(v, ['updated'], null),
        name: _.get(user, ['name']),
        minutes: _.get(user, ['totalMinutes'], 0),
        deltaMinutes: _.get(user, ['totalDeltaMinutes'], 0),
        minutesAfterEdit: _.get(user, ['totalMinutes'], 0) + _.get(user, ['totalDeltaMinutes'], 0),
        type: _.get(user, ['group', 'name']),
        // departments: _.map(user?.departments || [], 'name')?.join(', '),
        departments: _.map(user?.departments || [], 'id'),
        timeDeltas: _.map(_.get(user, ['timeDeltas'], []), timeDelta => {
          const {
            createdBy: {
              name
            },
            created,
            minutes
          } = timeDelta
          return {
            user: name,
            created,
            minutes
          }
        }),
        timesheets
      }
    })
    return {
      ...v,
      key: _.get(v, ['id']),
      caseTag: _.get(v, ['type', 'id'], null),
      category: _.get(v, ['type', 'name'], null),
      category2: _.get(v, ['category'], null),
      starred: _.get(v, ['starred']),
      caseNumber: _.get(v, ['name']),
      updated: _.get(v, ['updated'], null),
      caseType: _.get(v, ['type']),
      users,
      totalMinutes: _.get(v, ['totalMinutes'], 0),
      totalDeltaMinutes: _.get(v, ['totalDeltaMinutes'], 0),
      totalMinutesAfterEdit: _.get(v, ['totalMinutes'], 0) + _.get(v, ['totalDeltaMinutes'], 0)
    }
  })
}
// 진행사건 > 필터 > 사건번호 목록 가져옴
export const useCaseList = ({
  sortKey,
  sortAscending = false,
  closed,
  caseSearchQuery,
  projectIds = [],
  limit,
  page,
  skip = false,
  starred,
  deleted
}) => {
  const variables = {
    sortBy: {
      ascending: sortAscending,
      ...sortKey ? { key: sortKey } : {}
    },
    filter: {
      closed,
      starred,
      deleted,
      ...caseSearchQuery ? { searchQuery: caseSearchQuery } : {},
      ..._.isEmpty(projectIds) ? { } : { projectIds }
    },
    ...(limit || limit === 0)
      ? {
          offset: (page - 1) * limit,
          limit
        }
      : limit === null
        ? {
            limit
          }
        : {}
  }

  const excelDownloadVariables = {
    ...variables,
    filter: {
      ...variables.filter
    }
  }

  const {
    data,
    loading,
    refetch
  } = useQuery(getCaseList, {
    variables,
    fetchPolicy: 'no-cache',
    skip
  })

  const gqlCaseListData = _.get(data, ['GetProjectList'], {})
  const cases = parseCaseList(_.get(gqlCaseListData, ['data'], []))
  const totalCount = _.get(gqlCaseListData, ['totalCount'], 0)
  return {
    cases,
    totalCount,
    loading,
    refetch,
    variables,
    excelDownloadVariables
  }
}
