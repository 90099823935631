import { useState } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'

import { navigateToTimesheetDetail } from '../../cacheAndNavigation//timesheetDetail/navigation'
import { navigateToTimesheetForm } from '../../cacheAndNavigation//timesheetForm/navigation'
import { getTimesheetFormStatus } from '../../cacheAndNavigation//timesheetForm/cache'
import { getTimesheetMeta as getInitTimesheetMeta } from '../../cacheAndNavigation//timesheetDetail/cache'

import { parseDateObj } from '../../../common/util'
import { MESSAGES } from '../../../common/config'
import { addTimesheet, editTimesheet } from '../../apollo/mutations'

import { useBlockModal } from '../common/useBlockModal'

import _ from 'lodash'
import { useMutation } from '@apollo/client'

// 타임시트 저장
export const useSavingTimesheetForm = (getCurrentTimesheetMeta = () => ({}), resetFormMeta = () => {}, userInputEnabled) => {
  const navigate = useNavigate()
  const location = useLocation()
  const status = getTimesheetFormStatus()
  const defaultTimesheetMeta = getInitTimesheetMeta()
  const readOnly = _.split(location.pathname, ['/'])[1] === 'timesheetDetail'
  const { messageApi, userId } = useOutletContext()

  const [isKeepWorkingAfterSave, setIsKeepWorkingAfterSave] = useState()
  const [confirmModalWhenMinutesIsZeroOpen, setConfirmModalWhenMinutesIsZeroOpen] = useState(null)

  const [addTimesheetMutationCallback] = useMutation(addTimesheet)
  const [editTimesheetMutationCallback] = useMutation(editTimesheet)

  const {
    fetching,
    onFetchAndNav,
    blockModalOpen,
    onOkCancelModal,
    onCloseCancelModal
  } = useBlockModal()
  const onSave = (skipCheckingMinutes) => {
    const meta = getCurrentTimesheetMeta()
    if (!meta.title || !meta.caseId || (userInputEnabled && !meta.userId)) {
      messageApi.open(MESSAGES.savingTimesheetFormWarning)
      return
    } else if (meta.minutes === 0 && !skipCheckingMinutes) {
      setConfirmModalWhenMinutesIsZeroOpen(true)
      return
    }

    const localizedDate = new Date(parseDateObj(_.get(meta, ['updated']), 'YYYY-MM-DD'))
    const timesheetMutationInput = { ..._.pick(meta, ['content', 'minutes', 'title', 'attachments', 'date']), user: meta.userId, project: meta.caseId, date: localizedDate }
    const fetchMutation = () => {
      if (status === 'creating') {
        return addTimesheetMutationCallback({
          variables: {
            input: timesheetMutationInput,
            projectId: meta.caseId,
            userId: meta.userId || userId
          }
        })
      } else if (status === 'editing') {
        return editTimesheetMutationCallback({
          variables: {
            editTimesheetId: defaultTimesheetMeta.id,
            input: timesheetMutationInput
            // userId: meta.userId || userId
          }
        })
      }
    }
    const fetchFinish = (v) => {
      if (!_.get(v, ['data', status === 'creating' ? 'AddTimesheet' : 'EditTimesheet', 'ok'], false)) {
        messageApi.open(MESSAGES.savingTimesheetFormError)
        return
      }
      if (isKeepWorkingAfterSave && status === 'creating') {
        resetFormMeta()
        messageApi.open(MESSAGES.savingTimesheetFormSuccess)
      } else {
        const timesheetId = _.get(v, ['data', status === 'creating' ? 'AddTimesheet' : 'EditTimesheet', 'data', 'id'])
        navigateToTimesheetDetail(
          navigate,
          timesheetId,
          meta,
          true
        )
      }
    }
    onFetchAndNav(
      fetchMutation,
      fetchFinish,
      (e) => {
        console.log('error', e)
        messageApi.open(MESSAGES.savingTimesheetFormError)
      }
    )
  }

  const onStartEditing = () => {
    navigateToTimesheetForm(navigate, 'editing')
  }
  const onCancel = () => {
    navigate(-1)
  }

  const onChangeKeepWorkingAfterSaveMode = (keepWorkingAfterSave) => {
    setIsKeepWorkingAfterSave(keepWorkingAfterSave)
  }
  const onOkZeroMinConfirmModal = () => {
    onSave(true)
    setConfirmModalWhenMinutesIsZeroOpen(false)
  }
  const onCancelZeroMinConfirmModal = () => {
    setConfirmModalWhenMinutesIsZeroOpen(false)
  }
  return {
    saving: fetching,
    isKeepWorkingAfterSave,
    cancelModalOpen: blockModalOpen,
    readOnly,
    confirmModalWhenMinutesIsZeroOpen,
    onSave,
    onOkCancelModal,
    onCloseCancelModal,
    onStartEditing,
    onCancel,
    onOkZeroMinConfirmModal,
    onCancelZeroMinConfirmModal,
    onChangeKeepWorkingAfterSaveMode
  }
}
