import _ from 'lodash'
import { useState } from 'react'

export const useConfirmModal = (
  onRemoveCallRecord = () => {},
  onRemoveContractRecord = () => {},
  onDeleteContractItem = () => {},
  onDeleteComment = () => {},
  onDeletePaymentRecord = () => {},
  onDeleteScratchPad = () => {},
  onCreateContractRecord = () => {},
  onUnremoveContractRecord = () => {}) => {
  const [confirmModalMeta, setConfirmModalMeta] = useState({
    showConfirmModal: null,
    id: null,
    modalType: null, // delete, close, restore
    boolean: null // onRemoveUser > false: 퇴사, true: 삭제
  })
  const onOpenModal = (id, modalType, boolean) => {
    setConfirmModalMeta(confirmModalMeta => ({
      ...confirmModalMeta,
      modalType,
      showConfirmModal: true,
      id,
      boolean
    }))
  }

  const onConfirmModal = async () => {
    try {
      setConfirmModalMeta(confirmModalMeta => {
        const callbackByModaltype = {
          deleteCallRecord: onRemoveCallRecord,
          deleteContract: onRemoveContractRecord,
          deleteContractItem: onDeleteContractItem,
          deleteComment: onDeleteComment,
          deletePaymentRecord: onDeletePaymentRecord,
          delete: onDeleteScratchPad,
          createContractRecord: onCreateContractRecord,
          restore: onUnremoveContractRecord
        }
        const callbackOnConfirm = (id, boolean) => {
          return callbackByModaltype[confirmModalMeta.modalType](id, boolean)
        }
        callbackOnConfirm(confirmModalMeta.id, confirmModalMeta.boolean)
        return {
          ...confirmModalMeta,
          modalType: null,
          showConfirmModal: false,
          id: null,
          boolean: null
        }
      })
    } catch (e) {
      setConfirmModalMeta(confirmModalMeta => ({
        ...confirmModalMeta,
        modalType: null,
        showConfirmModal: false,
        id: null,
        boolean: null
      }))
    }
  }
  const onCloseModal = () => {
    setConfirmModalMeta(confirmModalMeta => ({
      ...confirmModalMeta,
      modalType: null,
      showConfirmModal: false
    }))
  }

  const titleByModalType = {
    deleteCallRecord: '기록을 삭제하시겠습니까?',
    deleteContract: '계약을 삭제하시겠습니까?',
    deleteContractItem: '계약 사항을 삭제하시겠습니까?',
    deleteComment: '추심을 삭제하시겠습니까?',
    deletePaymentRecord: '입금 내역을 삭제하시겠습니까?',
    restore: '기록을 복구하시겠습니까?',
    delete: '기록을 삭제하시겠습니까?',
    createContractRecord: '계약 페이지를 생성하시겠습니까?'
  }

  const subTitleByModalType = {
    removeUser: '직원이 등록한 사건, 타임시트 모두 삭제됩니다.'
  }
  return {
    confirmModalMeta,
    title: _.get(titleByModalType, [confirmModalMeta.modalType]),
    subTitle: _.get(subTitleByModalType, [confirmModalMeta.modalType]),
    onConfirmModal,
    onOpenModal,
    onCloseModal
  }
}
