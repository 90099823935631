import { useLocation, useNavigate, useParams } from 'react-router-dom'
import NotificationContainer from '../../containers/NotificationContainer'
import { AddHoverButton } from '../../../timeCharge/components/AddHoverButton'
import { navigateToCaseList } from '../../cacheAndNavigation//timesheet/navigation'
import { navigateToTimesheetForm } from '../../cacheAndNavigation//timesheetForm/navigation'

import _ from 'lodash'
import styled from 'styled-components'
import { BellOutlined, FileOutlined, LineChartOutlined, ShareAltOutlined, StarOutlined, SettingOutlined, DeleteOutlined, ProfileOutlined, AuditOutlined, WalletOutlined } from '@ant-design/icons'
import { navigateToCallRecord } from '../../../call/cacheAndNavigation/callRecord/navigation'
import { navigateToOverdueRecord } from '../../../call/cacheAndNavigation/overdueRecord/navigation'
import { navigateToDeletedContract } from '../../../call/cacheAndNavigation/deletedContract/navigation'

const SidbarLabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const useSidebar = (permissions) => {
  const noAuthToReadTimesheet = !_.includes(permissions, 'READ_ALL_TIMESHEET') && !_.includes(permissions, 'READ_SAME_DEPARTMENT_TIMESHEET') && !_.includes(permissions, 'WRITE_TIMESHEET')
  const noAuthForSetting = !(_.includes(permissions, 'MANAGE_USERS') || _.includes(permissions, 'MANAGE_DEPARTMENTS') || _.includes(permissions, 'MANAGE_TIMESHEET_NAME') || _.includes(permissions, 'MANAGE_PROJECT_CATEGORY'))
  const noAuthToManageProject = !_.includes(permissions, 'CREATE_PROJECT') && !_.includes(permissions, 'CREATE_CONSULTATION') && !_.includes(permissions, 'CREATE_ETC') && !_.includes(permissions, 'UPDATE_PROJECT_DATA')
  // 사이드바
  const _sidebarItems = [
    {
      key: 'notification',
      icon: <BellOutlined />,
      label: (
        <NotificationContainer />
      )
    },
    {
      key: 'timecharge',
      label: '타임차지',
      type: 'group',
      children: [
        {
          key: 'timesheet-starred',
          icon: <StarOutlined />,
          label: '즐겨찾기',
          hidden: noAuthToReadTimesheet
        },
        {
          key: 'timesheet-all',
          icon: <FileOutlined />,
          label: (
            <SidbarLabelContainer>
              <div>진행사건</div>
              {
                _.includes(permissions, 'WRITE_TIMESHEET') || _.includes(permissions, 'WRITE_OTHER_TIMESHEET')
                  ? <AddHoverButton
                      onClick={(e) => {
                        e.stopPropagation()
                        navigateToTimesheetForm(navigate, 'creating', {})
                      }}
                      text='타임시트 작성하기'
                    />
                  : null
              }
            </SidbarLabelContainer>
          ),
          hidden: noAuthToReadTimesheet
        },
        {
          key: 'stat',
          icon: <LineChartOutlined />,
          label: '통계',
          hidden: noAuthToReadTimesheet
        },
        {
          key: '/manageCase/table',
          icon: <ShareAltOutlined />,
          label: (
            <SidbarLabelContainer>
              <div>사건관리</div>
              {
                _.includes(permissions, 'CREATE_PROJECT') || _.includes(permissions, 'CREATE_CONSULTATION') || _.includes(permissions, 'CREATE_ETC')
                  ? <AddHoverButton
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate('/manageCase/createNav')
                      }}
                      text='사건 생성하기'
                    />
                  : null
              }
            </SidbarLabelContainer>
          ),
          hidden: noAuthToManageProject
        },
        {
          key: 'trash',
          icon: <DeleteOutlined />,
          label: '휴지통',
          hidden: noAuthToManageProject
        }
      ]
    },
    {
      key: 'call',
      label: '콜선임',
      type: 'group',
      children: [
        // {
        //   key: 'callRecord-starred',
        //   icon: <StarOutlined />,
        //   label: '즐겨찾기',
        //   hidden: noAuthToReadTimesheet
        // },
        {
          key: 'callRecord-all',
          icon: <ProfileOutlined />,
          label: (
            <SidbarLabelContainer>
              <div>콜 기록</div>
            </SidbarLabelContainer>
          )
        },
        {
          key: 'contractRecord',
          icon: <AuditOutlined />,
          label: (
            <SidbarLabelContainer>
              <div>계약 기록</div>
            </SidbarLabelContainer>
          )
        },
        {
          key: 'overdue',
          icon: <WalletOutlined />,
          label: (
            <SidbarLabelContainer>
              <div>추심 목록</div>
            </SidbarLabelContainer>
          )
        },
        {
          key: 'contractRecordDeleted',
          icon: <DeleteOutlined />,
          label: '휴지통',
          hidden: noAuthToManageProject
        }
      ]
    },
    {
      key: 'manager',
      label: '관리자',
      type: 'group',
      children: [
        {
          key: 'setting-timesheetTitle',
          icon: <SettingOutlined />,
          label: (
            <SidbarLabelContainer>
              <div>설정</div>
            </SidbarLabelContainer>
          ),
          hidden: noAuthForSetting
        }]
    }

  ]
  const sidebarItems = _.map(_.filter(_sidebarItems, v => {
    return !v.hidden
  }), item => {
    return {
      ...item
    }
  })

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const history = location.pathname.split('/').slice(1)

  const onSelectSidebarMenu = (e) => {
    const searchString = '' // 메뉴 이동시 필터 초기화
    if (e.key === 'search' || e.key === 'notification') {
      //
    } else if (e.key === 'timesheet-starred' || e.key === 'timesheet-closed' || e.key === 'timesheet-all') {
      navigateToCaseList(navigate, e.key.split('-')[1], undefined, undefined, searchString, true)
    } else if (e.key === 'setting-timesheetTitle') {
      navigate(`/setting/${e.key.split('-')[1]}?${searchString}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    } else if (e.key === 'setting-groupAuthority') {
      navigate(`/setting/${e.key.split('-')[1]}?${searchString}`, {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {})
        }
      })
    } else if (e.key === 'callRecord-all') {
      navigateToCallRecord(navigate, e.key.split('-')[1], searchString, 'list')
    } else if (e.key === 'callRecord-starred') {
      navigateToCallRecord(navigate, e.key.split('-')[1], searchString, 'group')
    } else if (e.key === 'overdue-all') {
      navigateToOverdueRecord(navigate, e.key.split('-')[1], searchString, 'list')
    } else if (e.key === 'contractRecordDeleted') {
      navigateToDeletedContract(navigate, e.key.split('-')[1], searchString, 'list')
    } else { // 휴지통
      navigate(`${e.key}?${searchString}`, {
        state: _.get(window, ['history', 'state', 'usr'], {})
      })
    }
  }

  let selectedKey = null
  if (history[0] === 'timesheet') {
    selectedKey = `timesheet-${params.caseStatus}`
  } else if (history[0] === 'manageCase' || history[0] === 'manageCase') {
    selectedKey = '/manageCase/table'
  } else if (history[0] === 'employers') {
    selectedKey = 'setting-groupAuthority'
  } else if (history[0] === 'setting') {
    selectedKey = 'setting-timesheetTitle'
  } else if (history[0] === 'callRecord') {
    selectedKey = `callRecord-${params.recordStatus}`
  } else {
    selectedKey = history[0]
  }

  return {
    selectedKey,
    sidebarItems,
    onSelectSidebarMenu
  }
}
