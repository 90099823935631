import styled from 'styled-components'
import COLORS from '../Theme/colors'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

export const HeaderGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 40px;
`
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  padding: 3px 15px;
  > * + * {
    margin-left: 1em;
  }
`
export const ContentHeaderContainer = styled(HeaderContainer)``
export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  min-height: 40px;
`
export const Spacer = styled.div`
  display: flex;
  flex: ${props => props.$flex || 1};
`
export const ItemNumber = styled.div`
  color: ${COLORS.primaryColor};
  font-weight: bold;
  font-size: 16px;
`
export const StyledTitle = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.black_88};
`
export const HeaderTitle = ({
  title,
  readOnly,
  onOpenModal,
  contractId
}) => (
  <HeaderTitleContainer>
    <StyledTitle>
      {title}
    </StyledTitle>
  </HeaderTitleContainer>
)

export const PaymentHeaderTitle = ({
  title,
  readOnly,
  onOpenModal,
  contractId
}) => (
  <HeaderTitleContainer>
    <StyledTitle>
      {title}
    </StyledTitle>
    {/* 수정 필요: 사건 기록 보기 임시 주석 */}
    {/* {readOnly
      ? (
        <Button type='primary' ghost>사건 기록 보기</Button>)
      : (
        <Button type='primary' danger onClick={() => onOpenModal(contractId, 'deleteContract')}>
          <DeleteOutlined />
          삭제하기
        </Button>
        )} */}
    {/* <Button variant='outlined' danger onClick={() => onOpenModal(contractId, 'deleteContract')}>
      <DeleteOutlined />
      삭제하기
    </Button> */}
  </HeaderTitleContainer>
)
