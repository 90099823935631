import _ from 'lodash'
import { useQuery } from '@apollo/client'

import { getProjectSubTypeList } from '../../apollo/callRecordQueries'

export const useProjectSubTypes = (skip, searchQuery) => {
  const { data: _projectSubTypes, loading } = useQuery(getProjectSubTypeList, {
    fetchPolicy: 'network-only',
    variables: {
      ...searchQuery
        ? {
            filter: {
              searchQuery
            }
          }
        : {}
    },
    skip
  })
  const initialProjectSubTypes = _.map(_.get(_projectSubTypes, ['GetProjectSubTypeList'], []))
  const projectSubTypes = initialProjectSubTypes.map(v => ({ label: v.name, value: v.id, type: v.type.id }))

  return {
    projectSubTypes,
    loading
  }
}
