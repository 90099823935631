import { useQuery } from '@apollo/client'
import { getRankList } from '../../apollo/queries'
import _ from 'lodash'

export const useRankList = (searchQuery, onComplete = () => {}) => {
  const { data, loading, refetch } = useQuery(getRankList, {
    variables: {
      filter: searchQuery ? { searchQuery } : {}
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => onComplete(_.get(data, ['GetRankList'], []))
  })

  const ranks = _.get(data, ['GetRankList'], [])
  return {
    loading,
    ranks,
    totalCount: ranks !== null ? ranks.length : 0,
    refetch
  }
}
