import { useState } from 'react'
import { Outlet } from 'react-router-dom'

import LoginScreen from './LoginScreen'
import { reportBug } from '../../common/fastapi/fetchers'
import { getCurrentUser } from '../../timeCharge/apollo/queries'
import { Sidebar } from '../../timeCharge/components/main/Sidebar'
import { RowContainer } from '../../common/Theme/style'

import { useQuery } from '@apollo/client'
import { useSidebar } from '../../timeCharge/hooks/navigation/useSidebar'
import { useInitialization } from '../../timeCharge/hooks/navigation/useInitialization'

import './common.css'
import COLORS from '../../common/Theme/colors'
import styled from 'styled-components'
import { GoReport } from 'react-icons/go'
import TextArea from 'antd/es/input/TextArea'
import { Alert, Button, Layout, Popover, Spin, message, theme } from 'antd'
import _ from 'lodash'

const HeaderHeight = 0

const { Content } = Layout

const Container = styled.div`
  display: flex;
  height: 100%;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const ReportContainer = styled(Popover)`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 30px;
`
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 20px;
  height: 45px;
  border-radius: 100px;
  background: ${COLORS.blue_3};
  color: ${COLORS.white};
  z-index: 999;
`
const OkStyledButton = styled(Button)`
  background: ${COLORS.blue_3};
  color: ${COLORS.white};
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 22px;
  background: ${COLORS.blue_3};
  color: ${COLORS.white};
  font-size: 18px;
  border-radius: 6px;
`
const ReportTitle = styled.div`

`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const MessageContainer = styled.div`
  background: ${COLORS.gray_1};
  padding: 18px 22px;
  border-radius: 6px;

`
const InputContainer = styled(TextArea)`
  border-radius: 6px;

`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  gap: 5px;
`

const App = ({ onLogout, loggedIn, logout, onChangePassword }) => {
  const { data, loading, refetch: refetchCurrentUser } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only'
  })
  const currentUser = _.get(data, ['CurrentUser'])
  const userId = _.get(currentUser, ['id'])
  const userStatus = _.get(currentUser, ['status'])
  const permissions = _.get(currentUser, ['permissions'], [])
  const userName = _.get(currentUser, ['name'])
  const userEmail = _.get(currentUser, ['email'])
  const userGroup = _.get(currentUser, ['group'])
  const {
    token: { colorBgContainer }
  } = theme.useToken()
  const [messageApi, contextHolder] = message.useMessage() // 로딩, 에러 메시지 표출
  const [visible, setVisible] = useState(false)
  const [disable, setDisable] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const [reportSubmitted, setReportSubmitted] = useState(false)

  const { selectedKey, sidebarItems, onSelectSidebarMenu } = useSidebar(permissions)
  const handleChangePassword = async ({ confirmPassword, newPassword }) => {
    const ret = await onChangePassword({ confirmPassword, newPassword })
    if (_.get(ret, ['userStatusChanged'])) {
      refetchCurrentUser()
    }
  }
  const handleCancel = () => {
    setInputValue('')
    setVisible(false)
    setDisable(true)
    setReportSubmitted(false)
  }
  const handleOk = () => {
    setInputValue('')
    setReportSubmitted(true)
    reportBug('제목테스트', inputValue)
  }
  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    if (e.target.value.length === 0) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }
  const handleVisibleChange = (popoverVisible) => {
    if (reportSubmitted) {
      setVisible(popoverVisible)
      setReportSubmitted(false)
    }
  }

  useInitialization(loggedIn, userId, permissions)

  if (loading) {
    return <><Spin /></>
  }
  if (userStatus === 'PASSWORD_RESET') {
    return <LoginScreen loggedIn onChangePassword={handleChangePassword} />
  }
  return (
    <Layout style={{ height: '100vh' }}>
      <Container>
        <Sidebar
          userName={userName}
          permissions={permissions}
          userEmail={userEmail}
          selectedKey={selectedKey}
          sidebarItems={sidebarItems}
          userId={userId}
          onLogout={onLogout}
          onSelectSidebarMenu={onSelectSidebarMenu}
        />

        <Layout
          style={{
            height: `calc(100vh - ${HeaderHeight}px)`,
            background: colorBgContainer,
            overflow: 'hidden'
          }}
        >
          <Content
            style={{
              margin: 0,
              minHeight: 280
            }}
          >
            {/* <Alert message='베타 버전입니다.' type='warning' showIcon style={{ margin: '10px 10px 0px 10px' }} /> */}
            <Outlet
              context={{ messageApi, permissions, userId, logout, userGroup }}
            />
            {contextHolder}
          </Content>
        </Layout>
        <ReportContainer
          placement='topRight'
          open={visible}
          trigger='click'
          onClick={() => setVisible(!visible)}
          onOpenChange={handleVisibleChange}
          content={(
            <ContentWrapper>
              <TitleContainer>
                <RowContainer style={{ gap: 12 }}>
                  <GoReport />
                  <ReportTitle>문제 리포트</ReportTitle>
                </RowContainer>
              </TitleContainer>
              <ContentContainer>
                {reportSubmitted
                  ? (
                    <MessageContainer>
                      리포트 해주셔서 감사합니다. <br /> 최대한 빠른 시일내로 적용하겠습니다.
                    </MessageContainer>)
                  : (
                    <>
                      <MessageContainer>
                        문제를 리포트해주세요.<br />
                        문제를 최대한 자세히 적어주시면 문제를 해결하는데 더욱 도움이 됩니다!
                      </MessageContainer>
                      <InputContainer
                        placeholder='내용을 입력해주세요'
                        autoSize={{
                          minRows: 12,
                          maxRows: 12
                        }}
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      <ButtonContainer>
                        <Button
                          onClick={handleCancel}
                          disabled={disable}
                        >
                          취소
                        </Button>
                        <OkStyledButton
                          onClick={handleOk}
                          disabled={disable}
                        >
                          완료
                        </OkStyledButton>
                      </ButtonContainer>
                    </>)}
              </ContentContainer>
            </ContentWrapper>
          )}
        >
          <StyledButton>
            <GoReport />
          </StyledButton>
        </ReportContainer>
      </Container>
    </Layout>
  )
}

export default App
