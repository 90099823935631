import { SettingSidebar } from '../../../timeCharge/components/main/SettingSidebar'
import { useSettingSidebar } from '../../../timeCharge/hooks/navigation/useSettingSidebar'
import ManageRankContainer from './ManageRankContainer'
import ManageEmployeesContainer from './ManageEmployeesContainer'
import ManageGroupAuthorityContainer from './ManageGroupAuthorityContainer'
import ManageDepartmentContainer from './department/ManageDepartmentContainer'
import ManageProjectTypeContainer from './projectType/ManageProjectTypeContainer'
import ManageTimesheetTitleTemplateContainer from './ManageTimesheetTitleTemplateContainer'

import styled from 'styled-components'
import ManageReservationStatusContainer from './ManageReservationStatusContainer'
const AlignItemsContainer = styled.div`
  display: flex;
  height: 100%;
`

const SettingContainer = () => {
  const { selectedKey, sidebarItems, onSelectSettingSidebarMenu } = useSettingSidebar()

  return (
    <AlignItemsContainer>
      <SettingSidebar
        selectedKey={selectedKey}
        sidebarItems={sidebarItems}
        onSelectSettingSidebarMenu={onSelectSettingSidebarMenu}
      />
      {selectedKey === 'timesheetTitle'
        ? <ManageTimesheetTitleTemplateContainer />
        : selectedKey === 'caseTitle'
          ? <ManageProjectTypeContainer />
          : selectedKey === 'rank'
            ? <ManageRankContainer />
            : selectedKey === 'groupAuthority'
              ? <ManageGroupAuthorityContainer />
              : selectedKey === 'department'
                ? <ManageDepartmentContainer />
                : selectedKey === 'reservationStatus'
                  ? <ManageReservationStatusContainer />
                  : <ManageEmployeesContainer />}
    </AlignItemsContainer>
  )
}

export default SettingContainer
