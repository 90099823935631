import _ from 'lodash'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import { navigateToStatWithParams } from '../../../cacheAndNavigation/stat/navigation'
import { navigateToCaseList } from '../../../cacheAndNavigation/timesheet/navigation'
import { navigateToTimesheetForm } from '../../../cacheAndNavigation/timesheetForm/navigation'

import { useStar } from '../../apollo/mutations/useStar'
import { useCustomTable } from '../../common/useCustomTable'
import { useCaseUserFilterSearchParams } from '../../filter/params/useCaseUserFilterSearchParams'

import { getCaseContentViewMode } from '../../../cacheAndNavigation/timesheet/cache'
import { useColumns } from './useColumns'
import { serializeCaseList } from '../../../../common/fastapi/fetchers'
import { message } from 'antd'
import { downloadExcel } from '../../../../common/util'
import { useCaseListForCaseListView } from '../../apollo/InProgressCasePage/useCaseListForCaseListView'
import { useOpenCloseDeleteCases } from '../../apollo/mutations/useOpenCloseDeleteCases'
import { useButtonLoading } from '../../common/useButtonLoading'
import { useState } from 'react'

const rowHeight = 57

export const useCaseTable = () => {
  const navigate = useNavigate()
  const viewMode = getCaseContentViewMode()
  const { caseStatus } = useParams()
  const starred = caseStatus === 'starred'
  const { showLoader, spinning, percent } = useButtonLoading()
  const [fileDownloadLoading, setFileDownloadLoading] = useState(false)
  const { permissions } = useOutletContext()
  const {
    onCloseCases
  } = useOpenCloseDeleteCases()

  // hooks
  // -search filters
  const {
    meta: {
      filter: {
        caseIds,
        userIds,
        categories,
        caseStatus: _caseStatus,
        datetimeRange,
        viewMyCase
      }
    },
    searchParams,
    getUpdatedSearchParams
  } = useCaseUserFilterSearchParams()
  const closed = _.includes(_caseStatus, 'OPEN')
    ? _.includes(_caseStatus, 'CLOSED')
      ? null
      : false
    : _.includes(_caseStatus, 'CLOSED')
      ? true
      : null
  // -case table props
  const caseTableProps = useCustomTable()
  const {
    page,
    limit,
    sortKey,
    sortAscending,
    selectedRowKeys: caseTableSelectedRowKeys,
    hoveredKey: caseTableHoveredKey,
    onChangeHoveredKey,
    onChangeExpandedKeys,
    expandedKeys: caseTableExpandedKeys
  } = caseTableProps

  // -queries
  const from = _.get(datetimeRange, [0])
  const to = _.get(datetimeRange, [1])

  const validTimesheetsOnly = true // 진행사건에서 사건의 타임시트가 0인경우 사건은 보여지지 않는다.
  const {
    cases,
    loading,
    totalCount,
    refetch,
    excelDownloadVariables
  } = useCaseListForCaseListView({
    projectIds: caseIds,
    userIds,
    categories,
    from,
    to,
    page,
    limit,
    sortKey,
    sortAscending,
    closed,
    starred,
    viewMyCase,
    caseTableSelectedRowKeys,
    validTimesheetsOnly
  })

  const caseData = cases

  // -mutations
  const {
    onChangeStar
  } = useStar(refetch)

  // -table columns, expanded table props
  const onAddTimesheetFromCase = (record) => {
    navigateToTimesheetForm(navigate, 'creating', _.pick(record, ['caseNumber', 'caseId'], {}))
  }
  const onClickUser = (userMeta) => {
    const userId = userMeta.userId
    const str = searchParams.toString()
    const _searchParams = new URLSearchParams(str)
    const sp1 = getUpdatedSearchParams('filter', 'userIds', [userId], _searchParams)
    const sp2 = getUpdatedSearchParams('filter', 'caseIds', [userMeta.caseId], sp1)
    navigateToCaseList(navigate, caseStatus, undefined, viewMode, sp2.toString(), false)
  }

  const {
    caseTableColumns,
    expandable,
    totalWidth
  } = useColumns(
    !loading,
    caseTableHoveredKey,
    caseTableExpandedKeys,
    onChangeHoveredKey,
    onChangeExpandedKeys,
    onAddTimesheetFromCase,
    onClickUser,
    onChangeStar,
    refetch
  )
  // variables
  const onClickTableActions = (key) => {
    if (key === 'stat') {
      navigateToStatWithParams(navigate, caseTableSelectedRowKeys)
    } else if (key === 'excelDownload') {
      setFileDownloadLoading(true)
      showLoader()
      message.loading('파일 다운로드 중')
      serializeCaseList(excelDownloadVariables).then((res) => {
        downloadExcel(_.get(res, ['data']), '사건목록')
        setFileDownloadLoading(false)
        message.destroy()
      }).catch(e => {
        message.destroy()
        message.error('다운로드 실패')
        setFileDownloadLoading(false)
      })
    } else if (key === 'closed') {
      onCloseCases(caseTableSelectedRowKeys)
    }
  }
  const disabledTableActionByKey = {
    closed: _.isEmpty(caseTableSelectedRowKeys),
    open: _.isEmpty(caseTableSelectedRowKeys)
  }
  const hiddenTableActionByKey = {
    closed: closed || !_.includes(permissions, 'CLOSE_PROJECT'),
    open: !closed || !_.includes(permissions, 'REOPEN_PROJECT'),
    excelUpload: true,
    templateDownload: true,
    tossCase: true
  }

  return {
    total: totalCount,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    onClickTableActions,
    data: caseData,
    style: {
      width: totalWidth,
      cursor: 'pointer'
    },
    ...caseTableProps,
    columns: caseTableColumns,
    loading,
    expandable,
    rowHeight,
    refetch,
    fileDownloadLoading,
    spinning,
    percent
  }
}
