import { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { CustomTable } from '../../../timeCharge/components/common/CustomTable'
import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { CustomSearch } from '../../../common/components/CustomSearch'
import { ManageCaseTableContainerHeader } from '../../../timeCharge/components/manageCase/table/ManageCaseTableContainerHeader'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'

import { MAX_CONTENT_WIDTH } from '../../../common/config'
import { useManageCaseTable } from '../../../timeCharge/hooks/manageCase/table/useManageCaseTable'
import { useManageCaseTableFilter } from '../../../timeCharge/hooks/manageCase/table/useManageCaseTableFilter'
import { useTableActions } from '../../../timeCharge/hooks/apollo/CaseList/useTableActions'
import styled from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  min-width: 500px;
  max-width: ${MAX_CONTENT_WIDTH}px;
`
// 사건관리 페이지
export const ManageCaseTableContainer = () => {
  const [searchQuery, setSearchQuery] = useState(null) // 검색창
  const [requiresAllocation, setRequiresAllocation] = useState(null)
  const {
    filters,
    categories,
    closed,
    openStatusByKey,
    departments
  } = useManageCaseTableFilter()
  const userIds = filters[0].selectedItems
  const {
    total,
    columns,
    disabledTableActionByKey,
    allowedToCreateCase,
    allocatedUsers,
    delegatedUsers,
    confirmModalMeta,
    onConfirmModal,
    onCloseModal,
    confirmModalTitle,
    refetch,
    selectedRowKeys,
    ...props
  } = useManageCaseTable(
    categories,
    closed,
    requiresAllocation,
    searchQuery,
    userIds
  )
  const {
    onClickTableActions,
    fileDownloadLoading,
    spinning,
    percent
  } = useTableActions({
    selectedRowKeys
  })

  // 사건 추가 권한 (엑셀 업로드 버튼 생성)
  const navigate = useNavigate()
  const { permissions } = useOutletContext()
  const onCreateCase = () => navigate('/manageCase/createNav')
  const onChangeRequiresAllocationFilter = () => setRequiresAllocation(!requiresAllocation)
  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onConfirmModal}
        onCancel={onCloseModal}
      />
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <ManageCaseTableContainerHeader
            filters={filters}
            showCreateButton={allowedToCreateCase}
            onCreateCase={onCreateCase}
            requiresAllocation={requiresAllocation}
            onChangeRequiresAllocationFilter={onChangeRequiresAllocationFilter}
            openStatusByKey={openStatusByKey}
            departments={departments}
          />
        )}
        content={(
          <Container>
            <CustomSearch onSearch={v => setSearchQuery(v)} placeholder='사건번호를 입력해주세요.' />
            <CustomTable
              total={total}
              columns={columns}
              disabledTableActionByKey={disabledTableActionByKey}
              permissions={permissions}
              refetch={refetch}
              onClickTableActions={onClickTableActions}
              fileDownloadLoading={fileDownloadLoading}
              spinning={spinning}
              percent={percent}
              selectedRowKeys={selectedRowKeys}
              {...props}
            />
          </Container>
        )}
      />
    </>
  )
}
