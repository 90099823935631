import { GroupOutlined, MenuOutlined } from '@ant-design/icons'
import { Segmented } from 'antd'

const viewModeOptions = [
  {
    value: 'group',
    label: <GroupOutlined />
  },
  {
    value: 'list',
    label: <MenuOutlined />
  }
]

export const ViewMode = ({ viewMode, onChangeViewMode }) => {
  return (
    <Segmented
      value={viewMode}
      options={viewModeOptions}
      onChange={e => onChangeViewMode(e)}
    />
  )
}
