import { getWindowHistoryMetaByKey } from '../common'

export const getUserMeta = () => {
  return getWindowHistoryMetaByKey('userMeta')
}

export const getDepartmentMeta = () => {
  return getWindowHistoryMetaByKey('departmentMeta')
}

export const getEmployeeAuthorityStatus = () => {
  return getWindowHistoryMetaByKey('employeeAuthorityStatus', true)
}

export const getDepartmentFormStatus = () => {
  return getWindowHistoryMetaByKey('departmentFormStatus', true)
}

export const getUserDepartmentMeta = () => {
  return getWindowHistoryMetaByKey('userDepartmentMeta')
}

export const getUserDepartmentFormStatus = () => {
  return getWindowHistoryMetaByKey('userDepartmentFormStatus', true)
}
export const getUserRankFormStatus = () => {
  return getWindowHistoryMetaByKey('userRankFormStatus', true)
}
export const getUserRankMeta = () => {
  return getWindowHistoryMetaByKey('userRankMeta')
}

export const getProjectTypeMeta = () => {
  return getWindowHistoryMetaByKey('projectTypeMeta')
}
export const getProjectTypeFormStatus = () => {
  return getWindowHistoryMetaByKey('projectTypeFormStatus', true)
}
