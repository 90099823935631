import _ from 'lodash'

import { useQuery } from '@apollo/client'
import { parseCaseList } from '../../../apollo/parser'
import { getCaseListForDeltatime } from '../../../apollo/inProgressCaseQueries'
export const useCaseListForDeltatime = ({
  projectIds = [],
  userIds = [],
  categories = [],
  from,
  to,
  page,
  limit,
  sortKey,
  sortAscending = false,
  closed,
  starred,
  skip,
  requiresAllocation,
  permissions,
  deleted,
  caseSearchQuery,
  viewMyCase = false,
  caseTableSelectedRowKeys
}) => {
  const variables = {
    sortBy: {
      ascending: sortAscending,
      ...sortKey ? { key: sortKey } : {}
    },
    filter: {
      closed,
      starred,
      deleted,
      mineOnly: viewMyCase,
      ...caseSearchQuery ? { searchQuery: caseSearchQuery } : {},
      ..._.isEmpty(projectIds) ? { } : { projectIds },
      ..._.isEmpty(userIds) ? { } : { userIds },
      ..._.isEmpty(categories) ? { } : { categories },
      ...from && to
        ? {
            dateRange: {
              from,
              to
            }
          }
        : {},
      ...requiresAllocation // 배당/위임 or 위임 필터가 켜졌을 경우
        ? _.includes(permissions, 'ALLOCATE_OR_DELEGATE') // 권한: 배당/위임에 따른 목록 반환
          ? { allocated: false, delegated: false }
          : _.includes(permissions, 'ALLOW_ALLOCATION')
            ? { allocated: false } // 권한: 위임에 따른 목록 반환
            : { }
        : { }
    },
    ...page && (limit || limit === 0)
      ? {
          offset: (page - 1) * limit,
          limit
        }
      : limit === null
        ? {
            limit
          }
        : {}
  }

  const {
    data
  } = useQuery(getCaseListForDeltatime, {
    variables,
    fetchPolicy: 'no-cache',
    skip
  })
  const gqlCaseListData = _.get(data, ['GetProjectList'], {})
  const cases = parseCaseList(_.get(gqlCaseListData, ['data'], []))
  return {
    cases
  }
}
