import { forwardRef } from 'react'

import AlignedForm from '../../common/AlignedForm'
import { TitleSelector } from './TitleSelector'
import { MinutesSetter } from '../../common/MinutesSetter'
import { CustomReadOnlyInput } from '../../common/CustomReadOnlyInput'
import { useTimesheetForm } from '../../../hooks/timesheetForm/useTimesheetForm'

import { DatePicker } from 'antd'
import styled from 'styled-components'
import _ from 'lodash'
import dayjs from 'dayjs'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  .ant-upload-wrapper {
    width: 100%;
  }
`

const InputFilter = ({ readOnly, options = [], selectedOptions = [], selectedValue, starredOptions, onChange, onSelectValue, starredCaseListHidden, label, readOnlyValue, disabled, onSearch, searchQuery, hideSearch, customPersistElement, placeholder, ...caseFilterProps }) => {
  return (
    <>
      <CustomReadOnlyInput value={readOnlyValue} />
    </>
  )
}

const _ReadOnlyTimesheet = ({ initMeta, readOnly, userInputEnabled, starredCaseListHidden }, ref) => {
  const {
    meta,
    onChangeMeta
  } = useTimesheetForm(initMeta, readOnly)

  const caseType = meta.project?.category === 'CASE' ? '사건' : meta.project?.category === 'CONSULT' ? '자문' : meta.project?.category === 'ETC' ? '기타' : '사건'
  return (
    <Container>
      <AlignedForm
        items={[
          {
            label: `${caseType}번호`,
            style: { width: '100%' },
            component: (
              <InputFilter
                readOnly={readOnly}
                readOnlyValue={meta.caseNumber}
                selectedValue={meta.caseId}
                onSelectValue={v => onChangeMeta('caseId', v)}
                label='사건번호'
              />
            )
          },
          {
            label: '작성자',
            style: { width: '100%' },
            component: (
              <InputFilter
                readOnly={readOnly}
                readOnlyValue={meta.creator}
                selectedValue={meta.userId}
                starredOptions={starredCaseListHidden}
                onSelectValue={v => onChangeMeta('userId', v)}
                label='작성자'
                disabled={!meta.caseId}
              />
            )
          },
          {
            label: '업무실행일',
            style: { width: '100%' },
            component: (
              <>
                {readOnly
                  ? (
                    <CustomReadOnlyInput value={dayjs(meta.updated).format('YYYY년 M월 D일')} />)
                  : (
                    <DatePicker
                      value={dayjs(meta.updated)}
                      style={{ width: '100%' }}
                      placeholder=''
                      onChange={v => {
                        if (!_.isEmpty(v)) {
                          onChangeMeta('updated', v.toDate())
                        }
                      }}
                    />)}
              </>
            )
          },
          {
            label: '소요시간',
            style: { width: '100%' },
            component: (
              <MinutesSetter
                readOnly={readOnly}
                minutes={meta.minutes}
                onChange={e => onChangeMeta('minutes', e)}
              />
            )
          },
          {
            style: { width: '100%' },
            component: (
              <TitleSelector
                readOnly={readOnly}
                value={meta.title}
                onChangeInput={v => onChangeMeta('title', v)}
              />
            )
          },
          {
            value: meta.content,
            placeholder: '내용을 입력해 주세요.',
            type: 'textArea',
            variant: false,
            onChange: e => onChangeMeta('content', e),
            readOnly,
            autoSize: {
              minRows: 12
            }
          }
        ]}
      />
    </Container>
  )
}

export const ReadOnlyTimesheet = forwardRef(_ReadOnlyTimesheet)
