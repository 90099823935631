import styled from 'styled-components'
import { Spin } from 'antd'
import { withSize } from 'react-sizeme'
import { Pie } from '@nivo/pie'
import { Bar } from '@nivo/bar'

import { StatGroupBox } from '../StatGroupBox'
import COLORS from '../../../../common/Theme/colors'
import { LegendIcon } from './LegendIcon'

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: ${COLORS.black_45};
  min-height: 100px;
  max-height: 160px;
`
const Container = styled.div`
  align-self: center;
`
const PieContainer = styled.div`
  display: flex;
  width: fit-content;
`
const LegendContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  height: ${props => props.$height}px;
  overflow: auto;
  gap: 10px;
`
const BarContainer = styled.div`
  display: flex;
  width: ${props => props.$width}px;
  overflow: auto;
  padding: 0 30px;
  height: ${props => props.$height}px;
`

const StatGraph = ({ size: { width }, loading, graphTitle, graphProps, graphType, minWidth, dataType, groupFilter }) => {
  const height = dataType === 'nTimesheetsPerDate' && groupFilter?.group.item === 'caseNumber' ? 800 : 400 // 필터에 따른 그래프 높이 설정
  return (
    <StatGroupBox title={graphTitle} flex={1} minWidth={minWidth} gap={15}>
      <Container>
        {graphProps.data?.length === 0
          ? (
            <EmptyContainer>
              {loading ? <Spin /> : '관련된 데이터가 없습니다.'}
            </EmptyContainer>
            )
          : (
            <> {graphType === 'bar'
              ? <BarContainer $width={width} $height={height}><Bar width={Math.max(width / 1.1, graphProps.minWidth)} height={height} {...graphProps} style={{ textAlign: 'center' }} /></BarContainer>
              : (
                <PieContainer>
                  <Pie
                    width={width / 1.1 - 200}
                    height={height}
                    {...graphProps}
                  />
                  <LegendContainer $height={height}>
                    {graphProps?.legendData?.map(({ label, color }) => {
                      return (
                        <LegendIcon key={label} color={color} label={label} />
                      )
                    })}
                  </LegendContainer>
                </PieContainer>
                )}
            </>
            )}
      </Container>
    </StatGroupBox>
  )
}

export default withSize()(StatGraph)
