import { parseMinutesToHourMin } from '../../../../common/util'
import styled from 'styled-components'
import COLORS from '../../../../common/Theme/colors'
import { Typography } from 'antd'

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #A7A9AC;
  border-radius: 5px;
  padding: 10px 15px;
`
export const PieChartTooltip = ({
  datum: {
    id,
    color,
    formattedValue,
    data
  }
}) => {
  return (
    <TooltipContainer color={color}>
      <div style={{ display: 'flex', gap: '5px' }}>
        <Typography.Text style={{ fontWeight: 'bold', color: `${COLORS.white}` }}>{`${id}`}</Typography.Text>
        <Typography.Text style={{ fontWeight: '600', color: `${COLORS.white}` }}>{`(${formattedValue})`}</Typography.Text>
      </div>
      <Typography.Text style={{ color: `${COLORS.white}` }}>{data.count}건 / 총 {parseMinutesToHourMin(data.minutes)}</Typography.Text>
    </TooltipContainer>
  )
}
