import { useParams } from 'react-router-dom'
import { useCaseUserFilterSearchParams } from '../filter/params/useCaseUserFilterSearchParams'
import { useCaseListForDeltatime } from '../apollo/InProgressCasePage/useCaseListForDeltatime'
import _ from 'lodash'

export const useDeltaminutesFinder = () => {
  // hooks
  const { caseStatus } = useParams()
  const {
    meta: {
      filter: {
        caseIds,
        categories,
        userIds,
        datetimeRange,
        caseStatus: caseStatusFilterArr,
        viewMyCase
      }
    }
  } = useCaseUserFilterSearchParams()
  const from = _.get(datetimeRange, [0])
  const to = _.get(datetimeRange, [1])

  const starred = caseStatus === 'starred' ? true : null
  const closed = caseStatus === 'closed'
    ? true
    : _.isEqual(caseStatusFilterArr, ['CLOSED'])
      ? true
      : _.isEqual(caseStatusFilterArr, ['OPEN'])
        ? false
        : null

  const {
    cases
  } = useCaseListForDeltatime({
    projectIds: caseIds,
    userIds,
    categories,
    from,
    to,
    sortKey: 'TIMESHEET_UPDATED',
    sortAscending: false,
    closed,
    starred,
    viewMyCase
  })
  return {
    cases
  }
}
