import { gql } from '@apollo/client'

// 통계 페이지 쿼리
export const getTimesheetList = gql`
  query GetTimesheetList($sortBy: SortBy!, $filter: TimesheetListFilter, $offset: Int, $limit: Int) {
    GetTimesheetList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      timesheets {
        id
        minutes
        title
        date
        project {
          id
          name
        }
        user {
          id
          name
        }
      }
      totalMinutes
      totalCount
    }
  }
`
export const getTimeGroupList = gql`
  query GetTimeGroupList($group: TimeGroupGroup!, $filter: TimeGroupFilter, $sortBy: SortBy!, $offset: Int, $limit: Int) {
    GetTimeGroupList(group: $group, filter: $filter, sortBy: $sortBy, offset: $offset, limit: $limit) {
      totalCount
      totalMinutes
      ... on GetTimeGroupListResponse {
        data {
          totalMinutes
          primaryKey
          projectCount
          secondaryKey {
            ... on User {
              id
              name
            }
            ... on Project {
              id
              name
            }
            ... on ProjectCategoryType {
              category
            }
            ... on ProjectType {
              id
              name
            }
          }
        }
      }
      ... on GetTimeGroupWithTimeDeltaListResponse {
        data {
          timesheetCount
          userCount
          totalMinutes
          primaryKey
          projectCount
          secondaryKey {
            ... on User {
              id
              name
              email
            }
            ... on Project {
              id
              name
            }
            ... on ProjectCategoryType {
              category
            }
            ... on ProjectType {
              id
              name
            }
          }
        }
        totalDeltaMinutes
      }
    }
  }
`
