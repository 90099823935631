import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'

const CallTypeContainer = styled.div`
  width: 90px;
  color: ${props => (props.$type === 'ONLINE') ? `${COLORS.primaryColor}` : props.$type === 'VISIT' ? `${COLORS.green}` : props.$type === 'OFFLINE' ? `${COLORS.orange}` : ''};
`
export const OnOffTextColor = ({ type }) => {
  if (type === null) {
    return null
  } else {
    const value = type === 'OFFLINE' ? '오프라인' : type === 'ONLINE' ? '온라인' : '방문&선임'
    return <CallTypeContainer $type={type}>{value}</CallTypeContainer>
  }
}
