import styled from 'styled-components'
import { ButtonShowingCreateLinkOnHover } from './ButtonShowingCreateLinkOnHover'
import { useCreateNavButtons } from '../../../hooks/manageCase/useCreateNavButtons'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`

const Buttons = styled.div`
  display: flex;
  margin: auto;
  min-width: 300px;
  max-width: ${(props) => (props.$linkButtonsLength > 2) ? '950px' : '700px'};
  gap: 20px;
`
export const CreateButtons = () => {
  const {
    linkButtons
  } = useCreateNavButtons()
  return (
    <Container $linkButtonsLength={linkButtons.length}>
      <Buttons>
        {linkButtons.map(({ title, subtitle, key, color, shadowColor, onClickCreateLink }, index) => {
          return (
            <ButtonShowingCreateLinkOnHover
              key={key}
              title={title}
              subtitle={subtitle}
              color={color}
              shadowColor={shadowColor}
              onClickCreateLink={onClickCreateLink}
            />
          )
        })}
      </Buttons>
    </Container>
  )
}
