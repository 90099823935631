import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

export default function LoggingOutIndicatingContainer () {
  const { logout } = useOutletContext()
  useEffect(() => {
    logout()
  }, [])
  return (
    <>로그아웃 중 입니다.</>
  )
}
