import { useMutation } from '@apollo/client'

import { addStar, removeStar } from '../../../apollo/mutations'

export const useStar = (refetch = () => {}, onUnstarComplete = () => {}) => {
  const [starCase] = useMutation(addStar)
  const [unstarCase] = useMutation(removeStar)
  const onChangeStar = (starred, projectId) => {
    if (starred) {
      starCase({
        variables: {
          projectId
        }
      }).then(v => refetch())
    } else {
      unstarCase({
        variables: {
          projectId
        }
      }).then(v => {
        refetch()
        onUnstarComplete(projectId)
      })
    }
  }

  return {
    onChangeStar
  }
}
