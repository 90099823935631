import { useEffect, useRef, useState } from 'react'

import LeaveTag from '../../components/common/Tags/LeaveTag'
import ClosedTag from '../../components/common/Tags/ClosedTag'
import CaseCategoryTag from '../../components/common/Tags/CaseCategoryTag'
import { UserInputCustomEmptyMessage } from '../../components/timesheetForm/UserInputCustomEmptyMessage'

import { useQuery } from '@apollo/client'
import { getCaseById } from '../../apollo/queries'
import { parseCaseList } from '../../apollo/parser'
import { useCaseNumbersAndUsersList } from '../apollo/useCaseNumbersAndUsersList'

import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { BorderlessTableOutlined, UserOutlined } from '@ant-design/icons'
import _ from 'lodash'
import UserIcon from '../../../common/components/UserIcon'
import { Typography } from 'antd'

const RowContainer = styled.div`
  display: flex;
  align-items: center;
`
// 타임시트 작성
export const useCaseUserFilterForTimesheetForm = (
  initFilter = {
    userIds: [],
    caseIds: []
  }
) => {
  const caseVirtualListRef = useRef()
  const starredCaseVirtualListRef = useRef()
  const [caseSearchQuery, setCaseSearchQuery] = useState(null)
  const [meta, setMeta] = useState({
    filter: initFilter
  })
  const onChangeMeta = (type, key, value) => {
    setMeta(meta => ({
      ...meta,
      [type]: {
        ..._.get(meta, [type], {}),
        [key]: value
      }
    }))
  }

  const {
    caseNumbers,
    starredCaseNumbers,
    selectedCaseNumbers,
    caseRefetch,
    starredCaseRefetch,
    starredCaseLoading,
    caseLoading
  } = useCaseNumbersAndUsersList(
    false,
    meta.filter.caseIds,
    caseSearchQuery
  )
  const { data } = useQuery(getCaseById, {
    variables: {
      getProjectId: _.get(meta, ['filter', 'caseIds', 0])
    },
    skip: !_.get(meta, ['filter', 'caseIds', 0])
  })
  const users = _.get(data, ['GetProject', 'users'], [])
  useEffect(() => {
    caseVirtualListRef.current?.refresh()
    starredCaseVirtualListRef.current?.refresh()
  }, [caseSearchQuery])
  const convertToOptions = ({ id, ...v }, type) => {
    const name = _.get(v, ['name'])
    const group = _.get(v, ['group', 'name'])
    return {
      name,
      label:
        type === 'caseIds'
          ? (
            <RowContainer>
              <Typography.Text style={{ width: 360 }} ellipsis={{ tooltip: <>{name}</> }}>{name}</Typography.Text>
              <CaseCategoryTag category={_.get(v, ['category2'])} />
              {v.status === 'CLOSED' ? <ClosedTag /> : null}
            </RowContainer>)
          : type === 'userIds'
            ? (
              <>
                <UserIcon group={group} name={name} filter />
                {_.get(v, ['status']) === 'DELETED' ? <LeaveTag /> : null}
              </>)
            : name,
      value: id
    }
  }

  const filters = [
    {
      key: 'caseIds',
      value: '사건번호',
      icon: <BorderlessTableOutlined style={{ color: `${COLORS.black_88}` }} />,
      options: _.map(caseNumbers, v => convertToOptions(v, 'caseIds')),
      selectedOptions: _.map(selectedCaseNumbers, v => convertToOptions(v, 'caseIds')),
      unit: '건',
      starredCaseListHidden: false,
      starredOptions: _.map(starredCaseNumbers, v => convertToOptions(v, 'caseIds')),
      virtualListRef: caseVirtualListRef,
      starredVirtualListRef: starredCaseVirtualListRef,
      refetch: (offset, limit) => {
        return caseRefetch({
          sortBy: {
            ascending: true,
            key: 'TIMESHEET_UPDATED'
          },
          offset,
          limit
        })
      },
      starredRefetch: (offset, limit) => {
        return starredCaseRefetch({
          sortBy: {
            ascending: true,
            key: 'TIMESHEET_UPDATED'
          },
          offset,
          limit
        })
      },
      dataParser: (data) => _.map(parseCaseList(_.get(data, ['GetProjectList', 'data'], [])), v => convertToOptions(v, 'caseIds')),
      loading: caseLoading,
      starredLoading: starredCaseLoading,
      isVirtualList: true,
      searchQuery: caseSearchQuery,
      onSearch: v => setCaseSearchQuery(v)
    },
    {
      key: 'userIds',
      value: '작성자',
      icon: <UserOutlined style={{ color: `${COLORS.black_88}` }} />,
      unit: '명',
      customEmptyMessage: <UserInputCustomEmptyMessage />,
      options: _.map(users, v => convertToOptions(v, 'userIds'))
    }
  ].map(v => ({
    ...v,
    selectedValue: _.get(meta.filter[v.key], [0]),
    selectedOptions: v?.selectedOptions || v?.options,
    onChange: (value) => {
      value[0] ? onChangeMeta('filter', v.key, [value[0]]) : onChangeMeta('filter', v.key, [])
    }
  }))
  const resetFilter = () => {
    setMeta({
      filter: initFilter
    })
  }
  return {
    meta,
    filters,
    caseNumbers,
    users,
    resetFilter,
    onChangeMeta,
    convertToOptions
  }
}
