import { useNavigate, useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../../common/config'
import { useProjectTypeAddEdit } from '../useProjectTypeAddEdit'
import _ from 'lodash'

export const useEditingProjectTypeForm = (onFetchAndNav, projectTypeId, initProjectSubTypeOrder) => {
  const navigate = useNavigate()
  const { messageApi } = useOutletContext()
  const {
    onEditProjectType
  } = useProjectTypeAddEdit(() => navigate('/setting/caseTitle', { replace: true }))
  const onEdit = (savingMeta) => {
    const { color, name } = savingMeta
    if (_.isString(color) === false) {
      onFetchAndNav(
        () => onEditProjectType(projectTypeId, color.toHexString(), name, initProjectSubTypeOrder),
        (v) => {

        },
        () => {
          messageApi.open(MESSAGES.updateProjectTypeError)
        }
      )
    } else {
      onFetchAndNav(
        () => onEditProjectType(projectTypeId, color, name, initProjectSubTypeOrder),
        (v) => {

        },
        () => {
          messageApi.open(MESSAGES.updateProjectTypeError)
        }
      )
    }
  }

  return {
    onEdit
  }
}
