import { useMutation } from '@apollo/client'
import { addTimesheetTitleTemplate, editTimesheetTitleTemplate, removeTimesheetTitleTemplate } from '../../../../timeCharge/apollo/mutations'
import _ from 'lodash'
import { useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../config'
import { addCallReservationStatus, editCallReservationStatus, removeCallReservationStatus } from '../../../../call/apollo/mutations'

export const useRerservationStatusTitleAdder = () => {
  const { messageApi } = useOutletContext()
  const [handleAadCallReservationStatus] = useMutation(addCallReservationStatus)
  const [handleEditCallReservationStatus] = useMutation(editCallReservationStatus)
  const [handleRemoveCallReservationStatus] = useMutation(removeCallReservationStatus)
  const handleError = () => {
    messageApi.open(MESSAGES.timesheetTitleTemplateError)
  }
  const onAddCallReservationStatus = (name) => {
    handleAadCallReservationStatus({
      variables: {
        input: {
          name
        }
      }
    }).then(v => {
      if (!_.get(v, ['data', 'AddCallReservationStatus', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      handleError()
    })
  }

  const onEditCallReservationStatus = (editCallReservationStatusId, name, order) => {
    handleEditCallReservationStatus({
      variables: {
        editCallReservationStatusId,
        input: {
          name,
          order
        }
      }
    }).then(v => {
      if (!_.get(v, ['data', 'EditCallReservationStatus', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      handleError()
    })
  }

  const onRemoveCallReservationStatus = (removeCallReservationStatusId) => {
    handleRemoveCallReservationStatus({
      variables: {
        removeCallReservationStatusId
      }
    }).then(v => {
      if (!_.get(v, ['data', 'RemoveCallReservationStatus', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      handleError()
    })
  }

  return {
    onAddCallReservationStatus,
    onEditCallReservationStatus,
    onRemoveCallReservationStatus
  }
}
