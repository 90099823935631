import { useMutation } from '@apollo/client'
import { addDepartmentsToUser, removeDepartmentsFromUser } from '../../../apollo/mutations'
import { useOutletContext } from 'react-router-dom'
import _ from 'lodash'
import { MESSAGES } from '../../../../common/config'

export const useUserAddDeleteDepartment = (onFinish = () => {}, onError = () => {}) => {
  const { messageApi } = useOutletContext()
  const [addDepartmentsToUserFn] = useMutation(addDepartmentsToUser)
  const [removeDepartmentsFromUserFn] = useMutation(removeDepartmentsFromUser)

  const onAddDepartmentsToUser = async (
    userId,
    departmentIds
  ) => {
    return addDepartmentsToUserFn({
      variables: {
        userId,
        departmentIds
      }
    }).then(v => {
      if (_.get(v, ['data', 'AddDepartmentsToUser', 'ok'])) onFinish()
      else {
        messageApi.open(MESSAGES.departmentCreationError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.departmentCreationError)
      onError()
    })
  }
  const onRemoveDepartmentsFromUser = async (
    userId,
    departmentIds = []
  ) => {
    return removeDepartmentsFromUserFn({
      variables: {
        userId,
        departmentIds
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveDepartmentsFromUser', 'ok'], false)) onFinish()
      else {
        messageApi.open(MESSAGES.updateDepartmentError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.updateDepartmentError)
      onError()
    })
  }
  return {
    onAddDepartmentsToUser,
    onRemoveDepartmentsFromUser
  }
}
