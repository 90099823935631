import { Popconfirm, Typography } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
  
`
export const EditSaveRowButtons = ({ editable, editingKey, record, onSave, onDelete, onEdit, onCancel }) => {
  return editable
    ? (
      <span>
        <Typography.Link
          onClick={() => onSave(record.key)}
          style={{
            marginRight: 8
          }}
        >
          저장
        </Typography.Link>
        <Typography.Link onClick={() => onCancel()}>
          취소
        </Typography.Link>
      </span>
      )
    : (
      <Container>
        <Typography.Link disabled={editingKey !== ''} onClick={() => onEdit(record)}>
          수정
        </Typography.Link>
        <Popconfirm
          disabled={editingKey !== ''}
          title='정말 삭제하시겠습니까?'
          onConfirm={() => onDelete(record.key)}
          okText='네'
          cancelText='아니요'
        >
          삭제
        </Popconfirm>
      </Container>)
}
