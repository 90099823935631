import { Button } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'
import { ExcelUploadModal } from './ExcelUploadModal'
import { useExcelUpload } from '../../../hooks/common/useExcelUpload'
import { tableActionItems } from './TableActionItems'
import { TossCaseModal } from '../../manageCase/tossCaseModal'
import useTossCase from '../../manageCase/tossCaseModal/useTossCase'

const Container = styled.div`
  display: flex;
  gap: 10px;
`

export const TableActions = ({
  disabledTableActionByKey = {},
  hiddenTableActionByKey = {},
  onClick,
  excelProps = {},
  dataCategory,
  refetch,
  fileDownloadLoading,
  selectedRowKeys
}) => {
  const {
    showFileUploadModal,
    isFileUploadModalOpen,
    fileUploaderProps,
    isFileDataModalOpen,
    handleCancel,
    handleOk,
    excelData,
    btnDisable,
    fileUploadLoading
  } = useExcelUpload(dataCategory, refetch)
  const {
    isTossCaseModalOpen,
    showTossCaseModal,
    handleCaseTossModalCancel,
    handleCaseTossModalOk
  } = useTossCase()
  return (
    <Container>
      {tableActionItems.map(({ label, key, icon }) => {
        return (
          <Button
            icon={icon}
            disabled={_.get(disabledTableActionByKey, [key], false) || fileDownloadLoading || (key === 'tossCase' ? !(selectedRowKeys?.length > 0) : '')}
            key={key}
            type='text'
            style={{ padding: '10px', display: _.get(hiddenTableActionByKey, [key]) && 'none', opacity: _.get(disabledTableActionByKey, [key], false) && 'none' ? 0.5 : 1, padding: '4px' }}
            onClick={() => {
              if (key === 'excelUpload') {
                showFileUploadModal()
              } else if (key === 'tossCase') {
                showTossCaseModal()
              } else {
                onClick(key, dataCategory)
              }
            }}
          >
            {label}
          </Button>
        )
      })}
      <ExcelUploadModal
        {...excelProps}
        fileUploaderProps={fileUploaderProps}
        isFileDataModalOpen={isFileDataModalOpen}
        isFileUploadModalOpen={isFileUploadModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        showFileUploadModal={showFileUploadModal}
        excelData={excelData}
        dataCategory={dataCategory}
        btnDisable={btnDisable}
        fileUploadLoading={fileUploadLoading}
      />
      <TossCaseModal
        isTossCaseModalOpen={isTossCaseModalOpen}
        handleCaseTossModalOk={handleCaseTossModalOk}
        handleCaseTossModalCancel={handleCaseTossModalCancel}
        selectedCaseIdRowKeys={selectedRowKeys}
      />
    </Container>
  )
}
