import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { BlockModal } from '../../../../timeCharge/components/common/BlockModal'
import { useBlockModal } from '../../../../timeCharge/hooks/common/useBlockModal'
import DepartmentForm from '../../../../timeCharge/components/form/DepartmentForm'
import { HeaderContentFooter } from '../../../../common/components/HeaderContentFooter'
import { HeaderContainer, HeaderTitle } from '../../../../common/components/PageHeaderStyles'
import { getDepartmentFormStatus } from '../../../../timeCharge/cacheAndNavigation/setting/cache'
import { useSaveDeleteDepartmentForm } from '../../../../common/hooks/settings/departments/useSaveDeleteDepartmentForm'

const AddDepartmentContainer = () => {
  const navigate = useNavigate()
  const departmentFormStatus = getDepartmentFormStatus()
  const prevDepartmentFormStatus = useRef(departmentFormStatus)
  const {
    blockModalOpen,
    onFetchAndNav,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()
  useEffect(() => {
    if (!blockModalOpen) prevDepartmentFormStatus.current = departmentFormStatus
  }, [blockModalOpen])

  const { onSave } = useSaveDeleteDepartmentForm(onFetchAndNav)
  return (
    <>
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title='부서 등록' />
          </HeaderContainer>
        )}
        content={(
          <div style={{ display: 'flex', padding: '100px', justifyContent: 'center' }}>
            <DepartmentForm
              onSave={onSave}
              onCancel={() => navigate(-1)}
            />
          </div>
        )}
      />
      <BlockModal
        title='부서등록을 취소하시겠습니까?'
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}

export default AddDepartmentContainer
