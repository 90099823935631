import { useState } from 'react'
import { CustomReadOnlyInput } from '../../../../timeCharge/components/common/CustomReadOnlyInput'
import { useCategories } from '../../apollo/useCategories'
import TextArea from 'antd/es/input/TextArea'

export const useCreateEtcForm = (readOnly, initMeta = {
  caseNumber: '',
  content: ''
}) => {
  const { categories } = useCategories(readOnly)
  const [meta, setMeta] = useState(initMeta)

  const onChangeCaseNumber = (caseNumber) => {
    setMeta(meta => ({ ...meta, caseNumber }))
  }

  const secondLabelName = '기타'
  const caseTags = categories.map(({ id, name }) => ({ value: id, label: name }))

  const formItems = [
    {
      label: '제목',
      style: { width: '100%' },
      ...readOnly
        ? {
            component: <CustomReadOnlyInput value={meta.caseNumber} />
          }
        : {
            placeholder: `${secondLabelName} 번호를 입력하세요.`,
            type: 'input',
            value: meta.caseNumber || meta.defaultName,
            onChange: onChangeCaseNumber
          }
    },
    {
      label: '내용',
      ...readOnly
        ? {
            component: <TextArea autoSize style={{ border: 'none' }} value={meta.content} readOnly />
          }
        : {
            type: 'textArea',
            variant: true,
            value: meta.content,
            style: {
              minHeight: 500
            },
            onChange: (content) => setMeta(meta => ({ ...meta, content }))
          }
    }
  ]

  return {
    ...meta,
    formItems,
    showAutoCreateTag: false,
    caseTags
  }
}
