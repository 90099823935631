import { gql } from '@apollo/client'

export const getCallReservationStatusList = gql`
  query GetCallReservationStatusList {
    GetCallReservationStatusList {
      id
      name
      order
    }
  }
`
