import { DepartmentFilter } from './DepartmentFilter'
import { CustomTable } from '../common/CustomTable'
import { useManageEmployeeTable } from '../../hooks/manageCase/table/useManageEmployeeTable'
import styled from 'styled-components'

const TopContainer = styled.div`
  display: flex;
  width: 100%;
`

export const EmployeeList = ({
  preAllocationTableProps, // 직원 목록
  filterProps = {}
}) => {
  const {
    onClickRow
  } = useManageEmployeeTable()

  return (
    <>
      <TopContainer>
        <DepartmentFilter // 필터전체
          {...filterProps}
        />
        <CustomTable
          rowSelection={null}
          onClickRow={onClickRow}
          {...preAllocationTableProps}
        />
      </TopContainer>
    </>
  )
}
