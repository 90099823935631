import _ from 'lodash'
import { useEffect, useState } from 'react'
import { CustomReadOnlyInput } from '../../../../timeCharge/components/common/CustomReadOnlyInput'
import { useCategories } from '../../apollo/useCategories'
import { parseDateObj, parseMinutesToHourMin } from '../../../../common/util'
import TextArea from 'antd/es/input/TextArea'
import { korNameByCategory } from '../../../../common/config'

export const useReadOnlyCaseForm = (readOnly, initMeta = {
  caseNumber: '',
  caseTag: '',
  created: '',
  content: '',
  users: []
}, type, caseFormStatus, files, dragDropProps, loading, attatchmentProps,
created, totalMinutes, timesheetCount) => {
  const { categories } = useCategories(readOnly)
  const [meta, setMeta] = useState(initMeta)
  useEffect(() => {
    if (!loading) setMeta(initMeta)
  }, [loading])
  const onChangeCaseNumber = (caseNumber) => {
    setMeta(meta => ({ ...meta, caseNumber }))
  }

  const onChangeCaseTag = (caseTag) => {
    setMeta(meta => ({ ...meta, caseTag }))
  }
  const secondLabelName = korNameByCategory[type]
  const caseTags = categories.map(({ id, name }) => ({ value: id, label: name }))

  useEffect(() => {
    if (_.get(meta, ['caseTag'])) {
      if ((type === 'case' || type === 'consultation' || type === 'etc') && caseFormStatus === 'creating') {
        const generateRandoemNumber = Math.floor(1000 + Math.random() * 9000)
        setMeta(meta => ({ ...meta, caseNumber: _.get(_.find(caseTags, v => v.value === _.get(meta, ['caseTag'])), ['label']) + generateRandoemNumber }))
      }
    }
  }, [_.get(meta, ['caseTag'])])
  const formItems = type !== 'ETC'
    ? ( // 기타 사건이 아닐경우 분류 목록을 보여준다
        [
          {
            label: secondLabelName + '분류',
            style: { width: '100%' },
            ...readOnly
              ? { component: <CustomReadOnlyInput value={meta.caseTag} /> }
              : {
                  type: 'select',
                  placeholder: '태그를 선택하세요.',
                  value: meta.caseTag,
                  items: caseTags,
                  disabled: caseFormStatus === 'editing',
                  onSelect: onChangeCaseTag
                }
          },
          {
            label: secondLabelName + '번호',
            style: { width: '100%' },
            ...readOnly
              ? { component: <CustomReadOnlyInput value={meta.caseNumber} /> }
              : {
                  placeholder: `${secondLabelName}${type === 'case' ? '를' : '을'} 입력하세요.`,
                  type: 'input',
                  value: meta.caseNumber,
                  onChange: onChangeCaseNumber
                }
          },
          {
            ...readOnly
              ? {
                  label: '생성자',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={meta.createdBy} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: '생성일',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={parseDateObj(meta.created, 'YYYY년 M월 D일 HH:mm')} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: initMeta.status === 'DELETED' ? '삭제자' : '수정자',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={meta.updatedBy} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: initMeta.status === 'DELETED' ? '삭제일' : '최근 사건 세부정보 수정일',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={parseDateObj(meta.updated, 'YYYY년 M월 D일 HH:mm')} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: '최근 타임시트 수정일',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={parseDateObj(meta.timesheetUpdated, 'YYYY년 M월 D일 HH:mm')} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: '총 시간',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={parseMinutesToHourMin(totalMinutes)} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: '총 타임시트 작성',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={`${timesheetCount}개`} />
                }
              : null
          },
          {
            label: '내용',
            ...readOnly
              ? {
                  component: <TextArea autoSize style={{ border: 'none' }} value={meta.content} readOnly />
                }
              : {
                  type: 'textArea',
                  variant: true,
                  value: meta.content,
                  onChange: (content) => setMeta(meta => ({ ...meta, content }))
                }
          }
        ]
      )
    : (
        [
          {
            label: secondLabelName + '번호',
            style: { width: '100%' },
            ...readOnly
              ? { component: <CustomReadOnlyInput value={meta.caseNumber} /> }
              : {
                  placeholder: `${secondLabelName}${type === 'case' ? '를' : '을'} 입력하세요.`,
                  type: 'input',
                  value: meta.caseNumber,
                  onChange: onChangeCaseNumber
                }
          },
          {
            ...readOnly
              ? {
                  label: '생성자',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={meta.createdBy} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: '생성일',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={parseDateObj(meta.created, 'YYYY년 M월 D일 HH:mm')} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: initMeta.status === 'DELETED' ? '삭제자' : '수정자',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={meta.updatedBy} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: initMeta.status === 'DELETED' ? '삭제일' : '수정일',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={parseDateObj(meta.updated, 'YYYY년 M월 D일 HH:mm')} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: '총 시간',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={parseMinutesToHourMin(totalMinutes)} />
                }
              : null
          },
          {
            ...readOnly
              ? {
                  label: '총 타임시트 작성',
                  style: { width: '100%' },
                  component: <CustomReadOnlyInput value={`${timesheetCount}개`} />
                }
              : null
          },
          {
            label: '내용',
            ...readOnly
              ? {
                  component: <TextArea autoSize style={{ border: 'none' }} value={meta.content} readOnly />
                }
              : {
                  type: 'textArea',
                  variant: true,
                  value: meta.content,
                  onChange: (content) => setMeta(meta => ({ ...meta, content }))
                }
          }
        ]
      )

  return {
    ...meta,
    formItems: _.map(formItems, v => ({ ...v }))
  }
}
