import { CustomTable } from '../../common/CustomTable'
import { useTimesheetTable } from '../../../hooks/timesheet/useTimesheetTable'

export const TimesheetTable = () => {
  const {
    total,
    disabledTableActionByKey,
    ...props
  } = useTimesheetTable()
  return (
    <CustomTable
      style={{ width: '100%' }}
      total={total}
      unit='건'
      disabledTableActionByKey={disabledTableActionByKey}
      {...props}
    />
  )
}
