import { useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { ContractCalendarCell } from './ContractCalendarCell'
import { parseDateObj } from '../../../../common/util'
import './style.css'
import _ from 'lodash'

const ContractCustomCalendar = ({
  listByDate,
  onClickCalendarListItem,
  onChangeActiveStartDate,
  formatDay
}) => {
  const calendarRef = useRef()
  const [hoveredId, setHoveredId] = useState()
  return (
    <Calendar
      locale='ko-KO'
      ref={calendarRef}
      formatDay={formatDay}
      minDetail='month'
      onActiveStartDateChange={({ activeStartDate }) => onChangeActiveStartDate(activeStartDate)}
      tileContent={({ date }) => {
        const list = _.get(listByDate, [parseDateObj(new Date(date))], [])
        return (
          <ContractCalendarCell
            totalLength={list.length}
            dataList={list}
            hoveredCaseId={hoveredId}
            onHoverItem={v => setHoveredId(v)}
            onClickCalendarListItem={(v) => onClickCalendarListItem(v)}
          />
        )
      }}
    />
  )
}

export default ContractCustomCalendar
