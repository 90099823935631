import _ from 'lodash'
import { Select } from 'antd'

const { Option } = Select

const CaseTagSelect = ({ readOnly, items, defaultValue, value, listOfDisabledOptions = [], onSelect = () => {}, width = 120, status, ...props }) => {
  if (_.isEmpty(items)) return null
  const isOptionDisabled = (val) => {
    return _.includes(listOfDisabledOptions, val)
  }

  return (
    <div style={{ position: 'relative', width }} id='scrollIssuePreventer'>
      <Select
        style={{ width }}
        status={status}
        defaultValue={defaultValue}
        {...value ? { value } : {}}
        onSelect={onSelect}
        getPopupContainer={() => document.getElementById('scrollIssuePreventer')}
        {...props}
      >
        {items.map((val) => {
          if (_.get(val, ['label'], false) && _.get(val, ['value'], false)) {
            return (
              <Option
                key={val.label}
                value={val.value}
                disabled={isOptionDisabled(val.value)}
              >
                {val.label}
              </Option>
            )
          } else return (<Option disabled={isOptionDisabled(val)} value={val} key={val}>{val}</Option>)
        })}
      </Select>
    </div>
  )
}
export default CaseTagSelect
