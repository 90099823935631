import { gql } from '@apollo/client'

// 사건관리 페이지 쿼리
export const getCaseList = gql`
  query GetProjectList($sortBy: SortBy!, $filter: ProjectListFilter, $offset: Int, $limit: Int) {
    GetProjectList(sortBy: $sortBy, offset: $offset, filter: $filter, limit: $limit) {
      totalCount
      ... on GetProjectWithTimeDeltaListResponse {
        data {
          id
          totalMinutes
          status
          timesheetUpdated
          userCount
          category
          updated
          name
          created
          delegates {
            id
            name
          }
          type {
            id
            name
          }
          createdBy {
            id
            name
          }
        }
      }
      ... on GetProjectListResponse {
        data {
          id
          totalMinutes
          status
          timesheetUpdated
          userCount
          category
          updated
          name
          created
          delegates {
            id
            name
          }
          type {
            id
            name
          }
          createdBy {
            id
            name
          }
        }
      }
    }
  }
`

export const getUserList = gql`
  query GetUserList($sortBy: SortBy!, $filter: UserListFilter, $offset: Int, $limit: Int) {
    GetUserList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        name
        email
        departments {
          id
          name
        }
        group {
          id
          name
        }
      }
    }
  }
`

export const getUserListForTossCaseModal = gql`
  query GetUserList($sortBy: SortBy!, $filter: UserListFilter, $offset: Int, $limit: Int) {
    GetUserList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        name
        email
        departments {
          id
          name
          leaders {
            name
            id
          }
        }
        group {
          id
          name
        }
        ranks {
          id
          name
        }
        projectCount
      }
      totalCount
    }
  }
`
