import { Select } from 'antd'

export const PageLimit = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      defaultValue={20}
      options={[
        {
          value: 20,
          label: '20개씩 보기'
        },
        {
          value: 30,
          label: '30개씩 보기'
        }
      ]}
      onChange={onChange}
    />
  )
}
