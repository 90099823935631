import { useNavigate } from 'react-router-dom'
import { useRankList } from '../../../timeCharge/hooks/apollo/useRankList'
import { BlockModal } from '../../../timeCharge/components/common/BlockModal'
import { useBlockModal } from '../../../timeCharge/hooks/common/useBlockModal'
import AddEmployeeForm from '../../../timeCharge/components/form/AddEmployeeForm'
import { useUserGroupList } from '../../../timeCharge/hooks/apollo/useUserGroupList'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { useDepartmentList } from '../../../timeCharge/hooks/apollo/useDepartmentList'
import { HeaderContainer, HeaderTitle } from '../../../common/components/PageHeaderStyles'
import { useSavingEmployeeForm } from '../../../common/hooks/settings/users/useSavingEmployeeForm'
import _ from 'lodash'
import { parseMetaToSelectOptions } from '../../../call/components/common/CommonFunction'

const AddEmployeeContainer = () => {
  const navigate = useNavigate()
  const {
    blockModalOpen,
    onFetchAndNav,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()

  const {
    departments
  } = useDepartmentList()
  const {
    userGroups
  } = useUserGroupList()
  const {
    ranks
  } = useRankList()
  const { onSave } = useSavingEmployeeForm(onFetchAndNav)

  const onCancel = () => navigate(-1)

  return (
    <>
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title='직원 등록' />
          </HeaderContainer>
        )}
        content={(
          <div style={{ display: 'flex', padding: '100px', justifyContent: 'center' }}>
            <AddEmployeeForm
              departments={_.map(departments, parseMetaToSelectOptions)}
              userGroups={_.map(userGroups, parseMetaToSelectOptions)}
              rank={_.map(ranks, parseMetaToSelectOptions)}
              onSave={onSave}
              onCancel={onCancel}
            />
          </div>
        )}
      />
      <BlockModal
        title='직원등록을 취소하시겠습니까?'
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}

export default AddEmployeeContainer
