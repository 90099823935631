import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import _ from 'lodash'

const CategoryButtonContainer = styled.div`
  display: flex;
  gap: 5px;
`

export const ContractCategoryButton = ({ categories, handleCategoryClick = () => {} }) => {
  return (
    <CategoryButtonContainer>
      {_.map(categories, (data) => {
        return (
          <Button onClick={() => handleCategoryClick(data)} value={data.value}>
            {data.label} 추가
          </Button>
        )
      })}
    </CategoryButtonContainer>
  )
}
