import { useState } from 'react'

import { usePagination } from './usePagination'

export const useCustomTable = (defaultExpandedRowKeys = []) => {
  const {
    page,
    limit,
    onChangePage,
    onChangePageLimit
  } = usePagination()
  const [meta, setMeta] = useState({
    sortKey: null,
    sortAscending: null,
    hoveredKey: null,
    expandedKeys: defaultExpandedRowKeys,
    selectedRowKeys: []
  })

  const onChangeMeta = (key, value) => setMeta({
    ...meta,
    [key]: value
  })
  const onChangeHoveredKey = (val) => onChangeMeta('hoveredKey', val)
  const onChangeExpandedKeys = (val) => onChangeMeta('expandedKeys', val)
  const onChangeSelectedRowKeys = (val) => onChangeMeta('selectedRowKeys', val)

  return {
    ...meta,
    page,
    limit,
    selectedRowKeys: meta.selectedRowKeys,
    sortKey: meta.sortKey,
    sortOrder: meta.sortAscending === true ? 'up' : meta.sortAscending === false ? 'down' : null,
    onChangePageLimit,
    onSortUp: (key) => {
      setMeta(meta => ({
        ...meta,
        sortKey: key,
        sortAscending: true
      }))
    },
    onSortDown: (key) => {
      setMeta(meta => ({
        ...meta,
        sortKey: key,
        sortAscending: false
      }))
    },
    onChangePage,
    onChangeHoveredKey,
    onChangeExpandedKeys,
    onChangeSelectedRowKeys
  }
}
