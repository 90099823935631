import { Typography } from 'antd'
import styled from 'styled-components'

const RowContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const TableTitle = ({ title, children, ...props }) => {
  return (
    <RowContainer>
      {title ? <Typography.Text>{title}</Typography.Text> : children}
    </RowContainer>
  )
}

export default TableTitle
