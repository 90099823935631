import { useState } from 'react'

import { CustomSearch } from '../../common/components/CustomSearch.js'
import { useContractRecordList } from '../hooks/apollo/useContractRecordList.js'
import { HeaderContentFooter } from '../../common/components/HeaderContentFooter.js'
import { ContractCustomTable } from '../components/customTable/ContractCustomTable.js'
import { useOverdueViewerColumns } from '../hooks/overdue/table/useOverdueViewerColumns.js'
import { CustomSearchContainer, ResponsiveGapContainer } from '../../common/Theme/style.js'
import { useContractRecordDeletedColumns } from '../hooks/deleted/useContractRecordDeletedColumns.js'
import { useConfirmModal } from '../hooks/common/useConfirmModal.js'
import { ConfirmModal } from '../../common/components/ConfirmModal.js'
import { useContractRecordRestore } from '../hooks/deleted/useContractRecordRestore.js'
import { usePaymentTable } from '../hooks/contractRecord/table/usePaymentTable.js'
import { todayDate } from '../components/common/CommonFunction.js'
import { usePaymentList } from '../hooks/contractRecord/usePaymentList.js/index.js'
import ContractRecordFilter from '../components/filter/ContractRecordFilter.js'
import { useContractRecordFilter } from '../hooks/filter/contractRecordDetail/useContractRecordFilter.js'
import { useDefaultDateRangeFilter } from '../../timeCharge/hooks/filter/useDefaultDateRangeFilter.js'
import { useNavigate } from 'react-router-dom'
import { navigateToDeletedContract } from '../cacheAndNavigation/deletedContract/navigation.js'

const ContractRecordDeletedContainer = () => {
  const [searchQuery, setSearchQuery] = useState(null) // 검색창
  const [contractRecordData, setContractRecordData] = useState()
  const navigate = useNavigate()

  const {
    meta,
    filters,
    searchParams,
    onChangeMeta,
    onResetFilterAndGroup,
    getUpdatedSearchParams,
  } = useContractRecordFilter()
  /*
  계약 기록 데이터
*/
  const {
    loading,
    variables,
    contractRecordsData,
    totalCount,
    refetch,
    ...preAllocationTableProps
  } = useContractRecordList(
    false, // ascending
    null, // key
    searchQuery,
    meta.filter.datetimeRange[0], // from,
    meta.filter.datetimeRange[1], // to,
    meta.filter.departmentId[0], // departmentId,
    true, // deleted,
    null, // caseNumber,
    meta.filter.projectTypeId[0], // projectTypeId,
    meta.filter.assignedLawyerId[0], // assignedLawyerId,
    null, // viewmode
    false, // outstanding
    null, // outstandingFrom
    null, // outstandingTo
    null // closed
  )

  const {
    contractRecordsData: overdueContractRecords
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    null, // to,
    null, // departmentId,
    null, // deleted,
    null, // caseNumber,
    null, // projectTypeId,
    null, // assignedLawyerId,
    null,
    true, // outstanding
    null, // outstandingFrom
    todayDate // outstandingTo
  )

  const onClickRecoveryRecord = (record, e) => {
    setContractRecordData(record)
    e.stopPropagation()
    const recordId = record.id
    onOpenModal(recordId, 'restore')
  }

  const {
    columns
  } = useContractRecordDeletedColumns(
    onClickRecoveryRecord,
    overdueContractRecords
  )

  const {
    onUnremoveContractRecord
  } = useContractRecordRestore(
    refetch,
    contractRecordData
  )

  const {
    onCloseModal,
    onConfirmModal,
    confirmModalMeta,
    title: confirmModalTitle,
    onOpenModal
  } = useConfirmModal(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    onUnremoveContractRecord
  )
  const {
    getOverdueViewerTableGroupColumns
  } = useOverdueViewerColumns()

  const {
    onClickRow
  } = usePaymentTable()

  const {
    viewMode,
    groupModeAllContract,
    onChangeGroupMode,
    onSelectFilter
  } = usePaymentList()

  // 필터: '이번달' 기본 세팅
  useDefaultDateRangeFilter(meta, 'month', (defDateRange) => {
    const newSearchString = getUpdatedSearchParams('filter', 'datetimeRange', defDateRange, searchParams)
    navigateToDeletedContract(navigate, newSearchString)
  })

  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onConfirmModal}
        onCancel={onCloseModal}
      />
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <ResponsiveGapContainer>
            <CustomSearchContainer>
              <CustomSearch onSearch={v => setSearchQuery(v)} placeholder='의뢰인명/전화번호를 입력해주세요.' />
            </CustomSearchContainer>
            <ContractRecordFilter
              viewMode={viewMode}
              groupMode={groupModeAllContract}
              loading={loading}
              meta={meta}
              filters={filters}
              onChangeMeta={onChangeMeta}
              onResetFilterAndGroup={onResetFilterAndGroup}
              onSelectFilter={onSelectFilter}
              onChangeGroupMode={onChangeGroupMode}
            />
          </ResponsiveGapContainer>
        )}
        content={(
          <ContractCustomTable
            data={contractRecordsData}
            defaultColumns={columns}
            groupColumns={getOverdueViewerTableGroupColumns}
            total={totalCount}
            viewMode='list'
            pageType='contractRecordDeleted'
            scroll={1000}
            loading={loading}
            onClickRow={onClickRow}
            {...preAllocationTableProps}
          />
        )}
      />
    </>
  )
}

export default ContractRecordDeletedContainer
