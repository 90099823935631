import { useEffect, useState } from 'react'
import { convertToDayjs, parseDateObj, uuidv4 } from '../../../../common/util'
import { Input, InputNumber, Select, Form, Button } from 'antd'
import { allPaymentCategoryOptions, expenseProofMethodOptions, paymentTypeOptions } from '../../../config'
import { CustomDatePicker } from '../../../components/common/CustomDatePicker'
import _ from 'lodash'
import { getLabelByValue } from '../../../components/common/CommonFunction'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

// 계약 기록 > 입금 내역 목록
export const useEditContractRecordDetailForm = (
  readOnly,
  contractDataSource,
  setContractDataSource,
  _reasonDataSource,
  extraContractDataSource,
  setExtraContractDataSource,
  paymentRecordDataSource,
  setPaymentRecordDataSource,
  handleDelete = () => {},
  currentUser
) => {
  const dateFormat = 'YYYY-MM-DD'
  const [paymentCategoryState, setPaymentCategoryState] = useState()
  const paymentRecords = _.cloneDeep(paymentRecordDataSource)

  const reformatPayment = (data = contractDataSource) => {
    // 계약 금액 목록 항목 조정 (주기 계산)
    if (paymentCategoryState === 'payment') {
      data = contractDataSource
    } else {
      data = extraContractDataSource
    }
    const transformedContracts = _.map(data, contract => {
      let result = []
      let eachPayment = contract.categoryTotal
      if (contract.depositType === 'MULTIPLE') {
        eachPayment = contract.categoryTotal / contract.count
      }
      result = _.map(contract.paymentDeadLine, (deadline, index) => {
        return {
          key: uuidv4(),
          dueAmount: eachPayment,
          totalDeposit: 0,
          dueDate: [deadline],
          status: false,
          category: contract.category
        }
      })
      return {
        ...contract,
        payment: result
      }
    })
    // 계약 금액 > 입금 내역에 따라 총 입금 금액 업데이트
    const updatedContracts = _.forEach(transformedContracts, contract => {
      _.forEach(contract.payment, payment => {
        while (payment.totalDeposit < payment.dueAmount) {
          const matchingRecordIndex = _.findIndex(paymentRecords, { category: contract.category })
          if (matchingRecordIndex === -1) {
            break
          }
          const matchingRecord = paymentRecords[matchingRecordIndex]
          payment.totalDeposit += matchingRecord.receivedAmount
          _.pullAt(paymentRecords, matchingRecordIndex)
          if (payment.totalDeposit >= payment.dueAmount) {
            payment.status = true
            break
          }
        }
      })
    })
    if (paymentCategoryState === 'payment') {
      // setContractDataSource(updatedContracts)
    } else {
      setExtraContractDataSource(updatedContracts)
    }
  }
  useEffect(() => {
    reformatPayment() // 계약 항목 입금 항목 생성
  }, [paymentRecordDataSource])

  const handleFieldChange = (key, field, value) => {
    if (field === 'category') {
      if (value === '착수금' || value === '중도금' || value === '잔금' || value === '상담료') {
        setPaymentCategoryState('payment')
      } else {
        setPaymentCategoryState('extraPayment')
      }
    }
    const updatedDataSource = paymentRecordDataSource.map(item => {
      if (item.key === key) {
        return { ...item, [field]: value }
      }
      return item
    })
    // const isButtonDisabled = !(updatedDataSource.find(item => item.key === key).category.length > 0 && updatedDataSource.find(item => item.key === key).receivedAmount > 0 && updatedDataSource.find(item => item.key === key).count > 0 && updatedDataSource.find(item => item.key === key).periodic.length > 0)
    setPaymentRecordDataSource(updatedDataSource)
    // reformatPayment(updatedDataSource)
    // setContractData(updatedDataSource)
  }
  const formItems = [
    {
      key: 'commentList',
      dataIndex: 'commentList',
      cardTitle: '추심 목록',
      value: '추가',
      scrollHeight: '240px',
      columns: [
        {
          title: '작성일',
          dataIndex: 'created',
          key: 'created',
          width: 52,
          render: (created, record, index) => {
            const format = (created !== null && created !== undefined) ? created.split(' ')[0] : ''
            return (
              readOnly
                ? (format)
                : (
                  <div>{dayjs(new Date()).format('YYYY-MM-DD')}</div>))
          }
        },
        {
          title: '작성자',
          dataIndex: 'createdBy',
          key: 'createdBy',
          width: 40,
          render: (createdBy, _, index) => {
            return (
              readOnly
                ? (createdBy?.name)
                : (
                  <div>{currentUser?.name}</div>))
          }
        },
        {
          title: '내용',
          dataIndex: 'content',
          key: 'content',
          width: 140,
          render: (content, _, index) => {
            return (
              readOnly
                ? (content)
                : (
                  <Form.Item
                    name={['commentList', index, 'content']}
                    // rules={[{ required: true, message: '내용을 입력해주세요.' }]}
                  >
                    <Input defaultValue={content} />
                  </Form.Item>))
          }
        },
        {
          title: '',
          dataIndex: 'delete',
          key: 'delete',
          width: 20,
          render: (_, record) => {
            if (!readOnly) {
              return (
                <Button danger onClick={() => handleDelete(record.id, 'commentList')}>삭제</Button>
              )
            }
          }
        }
      ]

    }
  ]
  // if (!readOnly) {
  //   formItems.find(item => item.key === 'depositRecordList').columns.push({
  //     title: '',
  //     dataIndex: 'delete',
  //     key: 'delete',
  //     width: 20,
  //     render: (_, record) => {
  //       return (
  //         <Button danger onClick={() => handleDelete(record.id, 'depositRecordList')}>삭제</Button>
  //       )
  //     }
  //   })
  // }
  return {
    formItems,
    reformatPayment
  }
}
