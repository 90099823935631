import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'

import { updateUser } from '../../../../timeCharge/apollo/mutations'
import { useUserListForPersonalPermission } from '../../../../timeCharge/hooks/apollo/SettingPage/useUserListForPersonalPermission'

export const useSettingUserPermission = (userId, userGroups = []) => {
  const navigate = useNavigate()

  const {
    loading: userListLoading,
    users
  } = useUserListForPersonalPermission(false, null, null, null, null, true, userId)
  const [handleUpdateUser] = useMutation(updateUser)

  const [meta, setMeta] = useState({})

  useEffect(() => {
    if (userListLoading) {
      return
    }

    const currentUser = _.find(users, { id: userId })
    const groupId = _.get(currentUser, ['group', 'id'])
    const defaultGroupPermissions = _.get(_.find(userGroups, { id: groupId }), ['permissions'], [])
    const addedUserPermissions = _.without(_.get(currentUser, ['permissionsAdd'], []), ...defaultGroupPermissions)
    const removedUserPermissions = _.get(currentUser, ['permissionsRemove'], [])
    const editedUserPermissions = _.get(currentUser, ['permissions'], [])

    setMeta({
      primaryGroupId: groupId,
      defaultGroupPermissions,

      addedUserPermissions,
      removedUserPermissions,
      editedUserPermissions
    })
  }, [userId, userListLoading, userGroups])

  // 권한 설정
  const onChangePrimaryGroupId = (newPrimaryGroupId) => {
    const defaultGroupPermissions = _.get(_.find(userGroups, { id: newPrimaryGroupId }), ['permissions'], [])

    setMeta(meta => ({
      primaryGroupId: newPrimaryGroupId,
      defaultGroupPermissions,
      addedUserPermissions: [],
      removedUserPermissions: [],
      editedUserPermissions: defaultGroupPermissions
    }))
  }

  // 저장
  const onSaveChanges = () => {
    handleUpdateUser({
      variables: {
        updateUserId: userId,
        input: {
          group: _.get(meta, ['primaryGroupId']),
          permissionsAdd: _.get(meta, ['addedUserPermissions'], []),
          permissionsRemove: _.get(meta, ['removedUserPermissions'], [])
        }
      }
    }).then(v => {
      if (!_.get(v, ['data', 'UpdateUser', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      console.log(e)
      handleError()
    })
  }

  const checkDot = (permission) => {
    const addedUserPermissions = _.get(meta, ['addedUserPermissions'], [])
    const removedUserPermissions = _.get(meta, ['removedUserPermissions'], [])

    const userUpdatedPermissions = _.union(addedUserPermissions, removedUserPermissions)

    return _.includes(userUpdatedPermissions, permission)
  }

  // 개인 권한 변경
  const onChangePermissionToUser = (permission, checked) => {
    setMeta(meta => {
      const defaultGroupPermissions = _.get(meta, ['defaultGroupPermissions'], [])

      const editedUserPermissions = _.get(meta, ['editedUserPermissions'], [])
      const addedUserPermissions = _.get(meta, ['addedUserPermissions'], [])
      const removedUserPermissions = _.get(meta, ['removedUserPermissions'], [])

      if (checked) {
        const newEditedUserPermissions = _.union(editedUserPermissions, [permission])

        if (_.includes(defaultGroupPermissions, permission)) {
          // reset
          return {
            ...meta,
            editedUserPermissions: newEditedUserPermissions,
            addedUserPermissions: _.without(addedUserPermissions, permission),
            removedUserPermissions: _.without(removedUserPermissions, permission)
          }
        } else {
          // add
          return {
            ...meta,
            editedUserPermissions: newEditedUserPermissions,
            addedUserPermissions: _.union(addedUserPermissions, [permission]),
            removedUserPermissions: _.without(removedUserPermissions, permission)
          }
        }
      } else {
        const newEditedUserPermissions = _.without(editedUserPermissions, permission)

        if (_.includes(defaultGroupPermissions, permission)) {
          // add
          return {
            ...meta,
            editedUserPermissions: newEditedUserPermissions,
            addedUserPermissions: _.without(addedUserPermissions, permission),
            removedUserPermissions: _.union(removedUserPermissions, [permission])
          }
        } else {
          // reset
          return {
            ...meta,
            editedUserPermissions: newEditedUserPermissions,
            addedUserPermissions: _.without(addedUserPermissions, permission),
            removedUserPermissions: _.without(removedUserPermissions, permission)
          }
        }
      }
    })
  }

  const getPrimaryGroupChecked = (permission) => { // 변경된 직무에 따라 체크 표시 변경
    const permissions = _.get(meta, ['defaultGroupPermissions'], [])
    return _.includes(permissions, permission)
  }

  const getEditedGroupChecked = (permission) => {
    const permissions = _.get(meta, ['editedUserPermissions'], [])
    return _.includes(permissions, permission)
  }

  return {
    ..._.pick(meta, ['primaryGroupId']),
    checkDot,
    onSaveChanges,
    onChangePrimaryGroupId,
    onChangePermissionToUser,
    getPrimaryGroupChecked,
    getEditedGroupChecked,
    // onSave,
    onCancel: () => navigate(-1)
  }
}
