import React, { useState } from 'react'
import { todayDate } from '../common/CommonFunction'
import { useDepositAddEditRemove } from '../../hooks/contractRecord/useDepositAddEditRemove'
import { usePaymentCompleteColumns } from '../../hooks/contractRecord/usePaymentCompleteColumns'

import _ from 'lodash'
import styled from 'styled-components'
import { Button, Form, Modal, Table } from 'antd'

const PaymentStatusButton = styled(Button)`
  border: ${props => props.complete ? '1px solid #64B93E' : '1px solid #d9d9d9'};
  color: ${props => props.complete ? '#64B93E' : '#000'};
`
const StyledModal = styled(Modal)`
  .ant-modal-content {
    overflow: scroll;
  }
`

const handleFullPayment = (values, fullPaymentForm, remain, buttonKey, onAddDepositRecord, periodicId) => {
  const currentValues = fullPaymentForm.getFieldsValue()
  if (remain < 0 || remain === 0) { // 초과, 완납 상태
    return
  } else { // 완납이 아닌 상태
    const addDepositFormatDataArray = {
      id: buttonKey,
      input: {
        received: _.get(currentValues, ['depositRecordList', periodicId, 'received'], null) !== null ? _.get(currentValues, ['depositRecordList', periodicId, 'received'], null) : todayDate,
        receivedAmount: remain,
        type: _.get(currentValues, ['depositRecordList', periodicId, 'type'], null),
        expenseProofMethod: _.get(currentValues, ['depositRecordList', periodicId, 'expenseProofMethod'], null)
      }
    }
    onAddDepositRecord(addDepositFormatDataArray)
  }
  fullPaymentForm.resetFields()
}

const CompletePaymentButton = ({ index, editMode, payments, buttonKey, periodicId, remain, refetch }) => {
  const [isModalOpenPaymentInfo, setIsModalOpenPaymentInfo] = useState(false)
  const [fullPaymentForm] = Form.useForm()
  const handleOk = () => {
    setIsModalOpenPaymentInfo(false)
    fullPaymentForm.submit()
  }

  const handleCancel = () => {
    setIsModalOpenPaymentInfo(false)
    fullPaymentForm.resetFields()
  }
  const handleModal = (value) => {
    setIsModalOpenPaymentInfo(true)
  }

  const {
    onAddDepositRecord
  } = useDepositAddEditRemove(refetch)

  const {
    paymentCompleteColumns
  } = usePaymentCompleteColumns(buttonKey, remain, index, fullPaymentForm, periodicId)
  return (
    <>
      <StyledModal
        width={1000}
        height={800}
        open={isModalOpenPaymentInfo}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='저장'
        cancelText='취소'
      >
        <Form form={fullPaymentForm} onFinish={values => handleFullPayment(values, fullPaymentForm, remain, buttonKey, onAddDepositRecord, periodicId)}>
          <h4>입금 내역 추가</h4>
          <Table
            pagination={false}
            columns={paymentCompleteColumns}
            dataSource={payments}
          />
        </Form>
      </StyledModal>
      <PaymentStatusButton
        disabled={editMode}
        complete={remain === 0}
        onClick={e => remain !== 0 && handleModal(e)}
      >
        완납
      </PaymentStatusButton>
    </>
  )
}
export default CompletePaymentButton
