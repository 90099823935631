import { useState } from 'react'

import UserIcon from '../../../../common/components/UserIcon'
import LeaveTag from '../../../../timeCharge/components/common/Tags/LeaveTag'
import ClosedTag from '../../../../timeCharge/components/common/Tags/ClosedTag'
import CaseCategoryTag from '../../../../timeCharge/components/common/Tags/CaseCategoryTag'
import { useDepartmentList } from '../../apollo/useDepartmentList'
import { useCaseNumbersAndUsersList } from '../../apollo/useCaseNumbersAndUsersList'
import { useCaseUserFilterSearchParams } from '../../filter/params/useCaseUserFilterSearchParams'

import { RowContainer } from '../../../../common/Theme/style'
import COLORS from '../../../../common/Theme/colors'
import { Empty, Typography } from 'antd'
import { DeploymentUnitOutlined, UserOutlined } from '@ant-design/icons'
import _ from 'lodash'

// 사건관리 필터
export const useManageCaseTableFilter = (userListSkip = false) => {
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([])
  const [openStatusByKey, setOpenStatusByKey] = useState({}) // 필터가 열려 렌더링 발생할때만 쿼리 호출할 수 있도록 스테이트 관리
  const {
    meta,
    onChangeMeta
  } = useCaseUserFilterSearchParams()
  const onDepartmentListComplete = (departments) => {
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }
  const {
    departments
  } = useDepartmentList(null, onDepartmentListComplete, !_.get(openStatusByKey, ['userIds'], false) && !_.get(openStatusByKey, ['caseIds'], false)) // 부서의 경우 사건필터 사용자 필터 중 하나라도 열려있으면 호출
  const {
    users,
    selectedCaseNumbers,
    searchedUsers
  } = useCaseNumbersAndUsersList(
    null,
    null,
    null,
    userSearchQuery,
    !_.get(openStatusByKey, ['caseIds'], false), // 닫혀있으면 쿼리 호출 안하도록 수정
    userListSkip ? true : _.isEmpty(_.get(meta, ['filter', 'userIds'], [])) ? !_.get(openStatusByKey, ['userIds'], false) : false // 휴지통 페이지 인직 확인, 작성자가 선택이 안되어있을 경우, 작성자 모달이 켜져 있는 경우 확인
  )
  const convertToOptions = ({ id, ...v }, type) => {
    const name = _.get(v, ['name'])
    const group = _.get(v, ['group', 'name'])

    return {
      name,
      label:
        type === 'caseIds'
          ? (
            <RowContainer>
              <Typography.Text style={{ width: 300 }} ellipsis={{ tooltip: <>{name}</> }}>{name}</Typography.Text>
              <CaseCategoryTag category={_.get(v, ['category2'])} />
              {v.status === 'CLOSED' ? <ClosedTag /> : <Empty />}
            </RowContainer>)
          : type === 'userIds'
            ? (
              <>
                <UserIcon group={group} name={name} filter />
                {_.get(v, ['status']) === 'DELETED' ? <LeaveTag /> : null}
              </>)
            : name,
      value: id
    }
  }
  // 선택된 사건에 속한 직원들 목록
  const caseFilteredUsers = _.uniqBy(
    _.flatMap(
      selectedCaseNumbers,
      v => _.concat(_.get(v, ['users'], []), _.get(v, ['delegates'], []))
    ),
    'id'
  )
  // 사건번호 선택시 사건에 속해진 직원들의 부서
  const caseFilteredUsersDepartment = () => {
    const departments = _.flatMap(
      _.filter(
        caseFilteredUsers,
        user => user?.departments?.length > 0
      ),
      user => user.departments.map(id => id)
    )
    return _.uniq(departments)
  }
  // 주석 풀어주기: 사건관리 - 필터 > 작성자
  const filters = [
    {
      key: 'userIds',
      value: '작성자',
      icon: <UserOutlined style={{ color: `${COLORS.black_88}` }} />,
      unit: '명',
      options: _.map(
        _.filter(
          _.isEmpty(meta.filter.caseIds) ? userSearchQuery ? searchedUsers : users : caseFilteredUsers,
          user => {
            return _.isEmpty(meta.filter.caseIds) ? _.intersection(selectedDepartmentIds, _.map(_.get(user, ['departments'], []), 'id')).length > 0 : user
          }
        ),
        v => convertToOptions(v, 'userIds')
      ),
      selectedOptions: _.map(users, v => convertToOptions(v, 'userIds')),
      selectedDepartmentOptions: _.isEmpty(meta.filter.caseIds) ? selectedDepartmentIds : caseFilteredUsersDepartment(),
      departmentOptions: _.map(departments, 'id'),
      searchQuery: userSearchQuery,
      hideSearch: !_.isEmpty(meta.filter.caseIds),
      onSearch: v => setUserSearchQuery(v),
      emptyText: '선택 가능한 사용자가 없습니다.'
    },
    {
      key: 'categories',
      value: '카테고리',
      options: [
        {
          name: '사건',
          label: '사건',
          value: 'CASE',
          color: `${COLORS.pink}`
        },
        {
          name: '자문',
          label: '자문',
          value: 'CONSULT',
          color: `${COLORS.primaryColor}`
        },
        {
          name: '기타',
          label: '기타',
          value: 'ETC',
          color: `${COLORS.orange}`
        }
      ],
      icon: <DeploymentUnitOutlined style={{ color: `${COLORS.black_88}` }} />
    },
    {
      key: 'caseStatus',
      value: '진행상태',
      options: [
        {
          value: 'OPEN',
          label: '진행',
          key: '1'
        },
        {
          value: 'CLOSED',
          label: '종결',
          key: '2'
        }
      ]
    }
  ].map(v => ({
    ...v,
    selectedItems: _.get(meta, ['filter', v.key], []),
    onChangeFilter: (value) => {
      onChangeMeta('filter', v.key, value)
    },
    onOpenChange: open => setOpenStatusByKey(openStatusByKey => ({ ...openStatusByKey, [v.key]: open }))
  }))
  const closed = _.includes(_.get(meta, ['filter', 'caseStatus'], []), 'OPEN')
    ? _.includes(_.get(meta, ['filter', 'caseStatus'], []), 'CLOSED')
      ? null
      : false
    : _.includes(_.get(meta, ['filter', 'caseStatus'], []), 'CLOSED')
      ? true
      : null
  return {
    filters,
    closed,
    openStatusByKey,
    departments,
    ...meta.filter
  }
}
