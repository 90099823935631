import styled from 'styled-components'
import { CustomTable } from '../../common/CustomTable'
import _ from 'lodash'
import { Button, Tag, Typography } from 'antd'
import { UserAllocationFilter } from './UserAllocationFilter'
import COLORS from '../../../../common/Theme/colors'
import { ArrowDownOutlined } from '@ant-design/icons'
import UserIcon from '../../../../common/components/UserIcon'
import { MAX_CONTENT_WIDTH } from '../../../../common/config'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  max-width: ${MAX_CONTENT_WIDTH}px;
  overflow:auto;
`
const TopContainer = styled.div`
  display: flex;
  width: 100%;
`
const AddButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(219, 234, 255, 0.15);
  border: 1px solid rgba(219, 234, 255, 1);
  padding: 22px 25px;
  min-width: 300px;
`
const StyledButton = styled(Button)`
  transition: transform .2s; /* Animation */
  &:hover{
    transform: scale(1.02);
    border:none;
  }

  &:not([disabled]) {
    color: ${COLORS.white} !important;
  }
  background-color: ${COLORS.orange};

  padding: 10px 20px;
  width: 133px;
  height: 45px;
  font-size: 16px;
`
const StyledTag = styled(Tag)`
  display: flex;
  padding: 8px 20px;
  border-radius: 30px;
  gap:10px;
  .ant-tag-close-icon{
    padding-left: 20px;
  }
`
const UsersContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  > * {
    padding: 20px;
  }
`
const LeftContainer = styled.div`
  width: 100px;
  border-right-width: 1px;
  border-style: solid;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-color: #BDBDBD;
`
const TagsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const UsersWrapper = ({ children, title }) => {
  return (
    <UsersContainer>
      <LeftContainer>
        <Typography.Text>{title}</Typography.Text>
      </LeftContainer>
      <TagsContainer>
        {children}
      </TagsContainer>
    </UsersContainer>
  )
}

export const Allocator = ({
  preAllocationTableProps,
  onAllocateUsers,
  onDisAllocateUser,
  onDelegateUsersByDepartments,
  onAbdicateUser,
  allocatedUsers = [],
  delegatedUsers = [],
  chartState,
  filterProps = {},
  disableAbdicate
}) => {
  const userIcon = true
  return (
    <Container>
      <TopContainer>
        {chartState === '직원'
          ? <UserAllocationFilter // 필터전체
              {...filterProps}
              scroll={_.get(preAllocationTableProps, ['scroll'], {})}
            />
          : null}
        <CustomTable {...preAllocationTableProps} userIcon={userIcon} />
      </TopContainer>
      <AddButtonContainer>
        <StyledButton
          style={{ background: `${COLORS.orange}`, color: `${COLORS.white}`, padding: '10px 20px', width: '133px', height: '45px', fontSize: '16px' }}
          disabled={_.isEmpty(_.get(preAllocationTableProps, ['selectedRowKeys'], []))}
          onClick={() => chartState === '직원' ? onAllocateUsers() : onDelegateUsersByDepartments()}
        >
          <ArrowDownOutlined />
          추가하기
        </StyledButton>
      </AddButtonContainer>
      <div>
        <Typography.Title level={5} style={{ marginTop: 0 }}>선택된 담당자</Typography.Title>
        <BottomContainer>
          <UsersWrapper title='위임'>
            {delegatedUsers.map((v) => {
              return (
                <StyledTag closable={!disableAbdicate} key={v.id} onClose={() => disableAbdicate ? () => {} : onAbdicateUser(v.id)}>
                  <UserIcon group={v.group?.name} name={v?.name} />
                </StyledTag>
              )
            })}
          </UsersWrapper>
          <UsersWrapper title='배당'>
            {allocatedUsers.map(v => {
              return (
                <StyledTag closable key={v.id} onClose={() => onDisAllocateUser(v.id)}>
                  <UserIcon group={v.group?.name} name={v?.name} />
                </StyledTag>
              )
            })}
          </UsersWrapper>
        </BottomContainer>
      </div>
    </Container>
  )
}
