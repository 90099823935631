import _ from 'lodash'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../../common/config'
import { useAddUpdateDepartment } from '../../../../timeCharge/hooks/apollo/mutations/useAddUpdateDepartment'
import { useMutation } from '@apollo/client'
import { removeDepartment } from '../../../../timeCharge/apollo/mutations'

export const useSaveDeleteDepartmentForm = (onFetchAndNav, onError = () => {}, refetch = () => {}) => {
  const navigate = useNavigate()
  const { messageApi } = useOutletContext()

  const {
    onAddDepartment
  } = useAddUpdateDepartment(() => navigate('/setting/department', { replace: true }))
  const onSave = (v) => {
    onFetchAndNav(
      () => onAddDepartment(
        _.get(v, ['name'])
      ),
      (v) => {

      },
      () => {
        messageApi.open(MESSAGES.departmentCreationError)
      }
    )
  }
  const [deleteDepartmentMutFn] = useMutation(removeDepartment)
  const onDeleteDepartment = (removeDepartmentId) => {
    deleteDepartmentMutFn({
      variables: {
        removeDepartmentId
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveDepartment', 'ok'])) refetch(removeDepartmentId)
      else messageApi.open(MESSAGES.removeDepartmentError)
    }).catch(() => {
      messageApi.open(MESSAGES.removeDepartmentError)
      onError()
    })
  }
  return {
    onSave,
    onDeleteDepartment
  }
}
