import _ from 'lodash'
import styled from 'styled-components'
import { Tag } from 'antd'
import { FilterOutlined } from '@ant-design/icons'

import COLORS from '../../../../common/Theme/colors'
import MultipleSelect from '../../common/MultipleSelect'
import { RowGapWrapper, TextNoWrap } from '../../../../common/Theme/style'
import { HeaderContainer as _HeaderContainer, HeaderGroup } from '../../../../common/components/PageHeaderStyles'

const StyledTag = styled(Tag)`
  display: flex;
  padding: 5px 11px;
  border: 1px solid ${COLORS.primaryColor};
  gap: 5px;
  color: ${COLORS.primaryColor};
  font-size: 13px;
`
const HeaderContainer = styled(_HeaderContainer)`
`

export const BecomesTagOnSelect = ({ tagged, onSelect, onClose, permissions, children }) => {
  const name = _.includes(permissions, 'ALLOCATE_OR_DELEGATE') ? '배당 / 위임 필요 목록만 보기' : '배당 필요 목록만 보기'
  return (
    <>
      {tagged
        ? <StyledTag closable onClose={onClose}>{name}</StyledTag>
        : (
          <div onClick={onSelect} style={{ display: 'flex', gap: '5px' }}>
            {children}
          </div>
          )}
    </>
  )
}

export const ManageDeletedTableContainerHeader = ({ filters = [], showCreateButton, onCreateCase = () => {} }) => {
  return (
    <HeaderContainer>
      <HeaderGroup>
        <RowGapWrapper>
          <FilterOutlined />
          <TextNoWrap>필터</TextNoWrap>
        </RowGapWrapper>
        {filters.map(({ value, key, icon, options = [], selectedItems, onChangeFilter, starredCaseListHidden, ...props }, index) => {
          return (
            (key === 'categories' || key === 'caseStatus')
              ? (
                <MultipleSelect
                  key={key}
                  style={{ minWidth: 120 }}
                  selectedValues={selectedItems}
                  options={options}
                  placeholder={value}
                  onSelectChange={v => onChangeFilter(v)}
                />
                )
              : null)
        })}
      </HeaderGroup>
    </HeaderContainer>
  )
}
