import _ from 'lodash'
import { useState } from 'react'

export const useConfirmModal = (onCloseCases = () => {}, onDeleteCases = () => {}, onReopenCases = () => {}, onRestoreCases = () => {}, onDeleteDepartment = () => {}, onDeleteTimesheet = () => {}, onRemoveUser = () => {}) => {
  const [confirmModalMeta, setConfirmModalMeta] = useState({
    showConfirmModal: null,
    id: null,
    modalType: null, // delete, close, restore
    boolean: null // onRemoveUser > false: 퇴사, true: 삭제
  })
  const onOpenModal = (id, modalType, boolean) => {
    setConfirmModalMeta(confirmModalMeta => ({
      ...confirmModalMeta,
      modalType,
      showConfirmModal: true,
      id,
      boolean
    }))
  }

  const onConfirmModal = async () => {
    try {
      setConfirmModalMeta(confirmModalMeta => {
        const callbackByModaltype = {
          close: onCloseCases,
          delete: onDeleteCases,
          reopen: onReopenCases,
          restore: onRestoreCases,
          deleteDepartment: onDeleteDepartment,
          deleteTimesheet: onDeleteTimesheet,
          removeUser: onRemoveUser
        }
        const callbackOnConfirm = (id, boolean) => {
          return callbackByModaltype[confirmModalMeta.modalType](id, boolean)
        }
        callbackOnConfirm(confirmModalMeta.id, confirmModalMeta.boolean)
        return {
          ...confirmModalMeta,
          modalType: null,
          showConfirmModal: false,
          id: null,
          boolean: null
        }
      })
    } catch (e) {
      setConfirmModalMeta(confirmModalMeta => ({
        ...confirmModalMeta,
        modalType: null,
        showConfirmModal: false,
        id: null,
        boolean: null
      }))
    }
  }
  const onCloseModal = () => {
    setConfirmModalMeta(confirmModalMeta => ({
      ...confirmModalMeta,
      modalType: null,
      showConfirmModal: false
    }))
  }

  const titleByModalType = {
    delete: '사건을 삭제하시겠습니까?',
    close: '사건을 종결하시겠습니까?',
    reopen: '사건 종결을 취소하시겠습니까?',
    restore: '사건을 복구하시겠습니까?',
    deleteDepartment: '부서를 삭제하시겠습니까?',
    deleteTimesheet: '타임시트를 삭제하시겠습니까?',
    removeUser: '직원을 삭제하시겠습니까?'
  }

  const subTitleByModalType = {
    removeUser: '직원이 등록한 사건, 타임시트 모두 삭제됩니다.'
  }
  return {
    confirmModalMeta,
    title: _.get(titleByModalType, [confirmModalMeta.modalType]),
    subTitle: _.get(subTitleByModalType, [confirmModalMeta.modalType]),
    onConfirmModal,
    onOpenModal,
    onCloseModal,
    onDeleteDepartment,
    onDeleteTimesheet,
    onRemoveUser
  }
}
