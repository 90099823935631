import { RowContainer } from '../../../common/Theme/style'
import { Select, Form, Input, Button } from 'antd'

const layout = {
  labelCol: {
    span: 6
  }
}
const permissionsAdd = []
const permissionsRemove = []
const AddEmployeeForm = ({ onSave, departments, userGroups, rank, onCancel }) => {
  const onGenderChange = (value) => {
    switch (value) {
      case 'male':
        break
      case 'female':
        break
      case 'other':
        break
      default:
    }
  }

  return (
    <Form
      onFinish={v => onSave(v, permissionsAdd, permissionsRemove)}
      name='basic'
      style={{ width: '800px', display: 'flex', flexDirection: 'column', gap: 10 }}
    >
      <Form.Item
        label='이름'
        name='name'
        {...layout}
        rules={[
          {
            required: true,
            message: '이름을 입력하세요!'
          }
        ]}
      >
        <Input
          placeholder='이름을 입력하세요.'
        />
      </Form.Item>
      <Form.Item
        label='이메일'
        name='email'
        {...layout}
        rules={[
          {
            required: true,
            message: '이메일을 입력하세요!'
          }
        ]}
      >
        <Input
          placeholder='이메일을 입력하세요.'
        />
      </Form.Item>
      <Form.Item
        label='권한그룹'
        name='group'
        rules={[
          {
            required: true,
            message: '권한그룹을 선택하세요!'
          }
        ]}
        {...layout}
      >
        <Select
          placeholder='권한그룹을 선택해주세요.'
          onChange={onGenderChange}
          allowClear
          options={userGroups}
        />
      </Form.Item>
      <Form.Item
        {...layout}
        label='부서'
        name='departments'
        rules={[
          {
            required: true,
            message: '부서를 선택하세요!'
          }
        ]}
      >
        <Select
          placeholder='부서를 선택해주세요.'
          options={departments}
          allowClear
        />
      </Form.Item>
      <Form.Item
        {...layout}
        label='직급'
        name='rank'
        rules={[
          {
            required: true,
            message: '직급을 선택하세요!'
          }
        ]}
      >
        <Select
          placeholder='직급을 선택해주세요.'
          options={rank}
          allowClear
        />
      </Form.Item>
      <Form.Item>
        <RowContainer style={{ gap: '10px', justifyContent: 'right' }}>
          <Button onClick={onCancel}>
            취소하기
          </Button>
          <Button type='primary' htmlType='submit'>
            등록하기
          </Button>
        </RowContainer>
      </Form.Item>
    </Form>
  )
}

export default AddEmployeeForm
