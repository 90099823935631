import styled from 'styled-components'
import { Statistic } from 'antd'

const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CardTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const TotalMoneyContainer = styled(Statistic)`
  .ant-statistic-content-value-int {
    font-size: 16px;
    font-weight: 600;
  }
`

export const CardHeader = ({ type, title, value, readOnly, dataIndex, contractTotal }) => {
  return (
    <CardHeaderContainer>
      <CardTitle>{title}</CardTitle>
      {type === 'text' && <TotalMoneyContainer value={contractTotal} />}
    </CardHeaderContainer>
  )
}
