// 통계 > 전체
const statTableColumns = [
  {
    title: '제목',
    dataIndex: 'title',
    key: 'TIMESHEET_NAME',
    sort: true
  },
  {
    title: '사건번호',
    dataIndex: 'caseNumber',
    key: 'caseNumber'
  },
  {
    title: '업무실행일',
    dataIndex: 'updated',
    key: 'DATE',
    sort: true
  },
  {
    title: '작성자',
    dataIndex: 'creator',
    key: 'creator'
  },
  {
    title: '소요시간',
    dataIndex: 'minutes',
    key: 'MINUTES',
    sort: true
  }
]

export const useColumns = (userColumnCell, caseNumberColumnCell, categoryColumnCell, time, item) => {
  // 통계 > 일간
  const dailyStatTableColumns = [
    {
      title: time === 'monthly' ? '수정월' : '수정일',
      dataIndex: 'updated',
      key: 'TIME_GROUP',
      sort: true
    },
    {
      title: '작성 수',
      dataIndex: 'timesheetCount',
      key: 'N_TIMESHEETS',
      sort: true
    },
    {
      title: '작성자 수',
      dataIndex: 'userCount',
      key: 'N_USERS',
      sort: true
    },
    {
      title: '사건번호 수',
      dataIndex: 'projectCount',
      key: 'N_PROJECTS',
      sort: true
    },
    {
      title: '소요시간',
      dataIndex: 'totalMinutes',
      key: 'MINUTES',
      sort: true
    }
  ]
  return {
    columns: time !== 'all'
      ? item === 'all'
        ? dailyStatTableColumns
        : [
            ...dailyStatTableColumns.slice(0, 1),
            item === 'creator' ? userColumnCell : item === 'caseCategory' ? categoryColumnCell : caseNumberColumnCell,
            ...dailyStatTableColumns.slice(1)
          ]
      : statTableColumns
  }
}
