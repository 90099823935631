import { useState } from 'react'

export const useButtonLoading = () => {
  const [spinning, setSpinning] = useState(false)
  const [percent, setPercent] = useState(0)

  const showLoader = () => {
    setSpinning(true)
    let count = -10

    const interval = setInterval(() => {
      count += 1
      setPercent(count)

      if (count > 550) {
        clearInterval(interval)
        setSpinning(false)
        setPercent(0)
      }
    }, 1000)
  }

  return {
    showLoader,
    spinning,
    percent
  }
}
