import _ from 'lodash'

export const navigateToContractRecordDetail = (navigate, contractRecordMeta = {}, searchString, formStatus = 'view') => {
  navigate(`/contractRecordDetail/${contractRecordMeta.id}/${searchString ? `?${searchString}` : ''}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      recordFormStatus: formStatus,
      contractRecordMeta: JSON.stringify(_.pick(contractRecordMeta, ['paymentNumber', 'paymentpayment']))
    }
  })
}
