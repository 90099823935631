import { useMutation } from '@apollo/client'
import { addRank, editRank, removeRank } from '../../../timeCharge/apollo/mutations'
import _ from 'lodash'
import { useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../common/config'

export const useRankAdder = () => {
  const { messageApi } = useOutletContext()
  const [handleAddRank] = useMutation(addRank)
  const [handleEditRank] = useMutation(editRank)
  const [handleRemoveRank] = useMutation(removeRank)
  const handleError = () => {
    messageApi.open(MESSAGES.rankError)
  }
  const onAddRank = (name, order) => {
    handleAddRank({
      variables: {
        input: {
          name,
          order
        }
      }
    }).then(v => {
      if (!_.get(v, ['data', 'AddRank', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      handleError()
    })
  }

  const onEditRank = (editRankId, name, order) => {
    handleEditRank({
      variables: {
        editRankId,
        input: {
          name,
          order
        }
      }
    }).then(v => {
      if (!_.get(v, ['data', 'EditRank', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      handleError()
    })
  }

  const onRemoveRank = (removeRankId) => {
    handleRemoveRank({
      variables: {
        removeRankId
      }
    }).then(v => {
      if (!_.get(v, ['data', 'RemoveRank', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      handleError()
    })
  }

  return {
    onAddRank,
    onEditRank,
    onRemoveRank
  }
}
