import { useQuery } from '@apollo/client'
import { getUserGroupList } from '../../apollo/queries'
import _ from 'lodash'

export const useUserGroupList = (onComplete = () => {}) => {
  const { data, loading } = useQuery(getUserGroupList, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      onComplete(_.get(data, ['GetUserGroupList'], []))
    }
  })
  return {
    loading,
    userGroups: _.get(data, ['GetUserGroupList'], [])
  }
}
