import { useOutletContext } from 'react-router-dom'
import { parseDateObj } from '../../../common/util'
import { MESSAGES } from '../../../common/config'
import _ from 'lodash'
import { useMutation, useQuery } from '@apollo/client'
import { addTimesheetDelta } from '../../apollo/mutations'
import { getTimeDeltas } from '../../apollo/queries'

export const useTimesheetDeltaAdder = (caseId, userId, onFinishAddingTimeDelta, skip) => {
  const { messageApi } = useOutletContext()
  const [addTimesheetDeltaFunc] = useMutation(addTimesheetDelta)
  const { data, refetch } = useQuery(getTimeDeltas, {
    variables: {
      projectId: caseId,
      userId
    },
    fetchPolicy: 'network-only',
    skip
  })
  const timeDeltas = _.map(
    _.get(data, ['GetTimeDeltaList'], []),
    v => {
      return {
        key: _.get(v, ['id']),
        created: parseDateObj(_.get(v, ['created']), 'YYYY년 M월 D일'),
        creator: _.get(v, ['createdBy', 'name']),
        minutes: _.get(v, ['minutes'])
      }
    }
  )

  // 조정 된 총 시간
  const timeDelta = _.sumBy(_.map(
    _.get(data, ['GetTimeDeltaList'], []),
    v => {
      return {
        minutes: _.get(v, ['minutes'])
      }
    }), 'minutes')
  const onAddTimeDelta = (minutes) => {
    return addTimesheetDeltaFunc({
      variables: {
        projectId: caseId,
        userId,
        input: {
          // content,
          minutes
        }
      }
    }).then((v) => {
      if (_.get(v, ['data', 'AddTimeDelta', 'ok'], false)) {
        onFinishAddingTimeDelta()
        refetch()
      } else messageApi.open(MESSAGES.timesheetDeltaAddError)
    })
  }

  return {
    timeDelta,
    timeDeltas,
    onAddTimeDelta
  }
}
