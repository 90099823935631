import { useState } from 'react'
// 타임시트 작성/수정
export const useTimesheetForm = (
  initMeta = {}, readOnly
) => {
  const [meta, setMeta] = useState({
    title: null,
    updated: null,
    caseId: null,
    userId: null,
    minutes: null,
    ...initMeta
  })

  const onChangeMeta = (key, value) => {
    setMeta({ ...meta, [key]: value })
  }
  const onResetFormMetaToInitialValues = () => setMeta(initMeta)

  return {
    meta: readOnly ? initMeta : meta,
    onChangeMeta,
    onResetFormMetaToInitialValues
  }
}
