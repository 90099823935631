import { Form, Input } from 'antd'
import { forwardRef, useImperativeHandle, useState } from 'react'

export const _ProjectSubTypeEditableCell = ({
  editing,
  dataIndex,
  title,
  inputtype,
  record,
  index,
  children,
  handleSave,
  ...restProps
}, ref) => {
  const initMeta =
    editing
      ? {
          color: record?.color,
          name: record?.name
        }
      : {}
  const [meta, setMeta] = useState(initMeta)
  useImperativeHandle(ref, () => ({
    getColor: () => meta.color,
    getName: () => meta.name
  }))
  return (
    <td {...restProps}>
      {editing
        ? (
          <Form.Item
            style={{
              margin: 0
            }}
            rules={[
              {
                required: true,
                message: `${title}을 입력하세요!`
              }
            ]}
          >
            <Input defaultValue={record.name} onChange={e => setMeta(meta => ({ ...meta, name: e.target.value }))} />
          </Form.Item>
          )
        : (
            children
          )}
    </td>
  )
}

export const ProjectSubTypeEditableCell = forwardRef(_ProjectSubTypeEditableCell)
