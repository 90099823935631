import CaseTagSelect from '../manageCase/CaseTagSelect'
import styled from 'styled-components'
import { Input, InputNumber, Typography, Form } from 'antd'

import _ from 'lodash'

const WrapperContainer = styled.div`
  display: flex;
  gap: 10px;
`
const Label = styled(Typography.Text)`
  & {
    margin-top: 5px;
    min-width: 160px;
  }
`
const TextAreaStyleProps = { resize: 'none', border: 'none', outline: 'none' }

export const FormFieldWrapper = ({ label, containerStyle, style, children, labelWidth }) => {
  return (
    <Form.Item style={style}>
      <WrapperContainer labelWidth={labelWidth}>
        <Label>{label}</Label>
        <div style={{ display: 'flex', width: '100%' }}>{children}</div>
      </WrapperContainer>
    </Form.Item>
  )
}

export const renderItem = ({ label, labelWidth, type, style = { flex: 1 }, component = null, text, key, ...props }) => {
  if (type === 'inputNumber') {
    return (
      <FormFieldWrapper label={label} style={style} labelWidth={labelWidth} key={key}>
        <InputNumber style={{ width: '100%' }} {...props} />
      </FormFieldWrapper>
    )
  } else if (type === 'input') {
    return (
      <FormFieldWrapper label={label} style={style} labelWidth={labelWidth} key={key}>
        <Input
          {...props}
          style={{ width: '100%' }}
          onChange={({ target: { value } }) => props.onChange(value)}
        />
      </FormFieldWrapper>
    )
  } else if (type === 'select') {
    return (
      <FormFieldWrapper label={label} style={style} labelWidth={labelWidth} key={key}>
        <CaseTagSelect width='100%' {...props} />
      </FormFieldWrapper>
    )
  } else if (type === 'textArea') {
    return (
      <FormFieldWrapper label={label} style={style} labelWidth={labelWidth} key={key}>
        <Input.TextArea
          variant={false}
          onInput={e => {
            const targetElem = e.target
            targetElem.style.height = targetElem.scrollHeight + 'px'
          }}
          style={{ ...TextAreaStyleProps, ...style }}
          {...props}
          onChange={({ target: { value } }) => props.onChange(value)}
        />
      </FormFieldWrapper>
    )
  } else {
    return (
      <FormFieldWrapper label={label} style={style} labelWidth={labelWidth} key={key} {...props}>
        {component}
      </FormFieldWrapper>
    )
  }
}

const AlignedForm = ({ items = [], initMeta, ...formProps }) => {
  return (
    <Form {...formProps} style={{ marginTop: 40, display: 'flex', flexDirection: 'column', gap: 10 }}>
      {items.map((item, index) => {
        if (_.isArray(item)) {
          return <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }} key={`formItem_${index}`}>{item.map((data, index) => renderItem({ ...data, key: `n_${index}` }))}</div>
        } else return renderItem({ ...item, key: `formItem2_${index}` })
      })}
    </Form>
  )
}

export default AlignedForm
