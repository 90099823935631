import {
  BranchesOutlined,
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  FileOutlined,
  FileSearchOutlined,
  MenuOutlined,
  StarFilled,
  KeyOutlined,
  SafetyCertificateOutlined,
  FlagOutlined,
  DeleteRowOutlined,
  ApartmentOutlined,
  RadarChartOutlined
} from '@ant-design/icons'
import COLORS from './Theme/colors'

export const apolloServerUrl = process.env.REACT_APP_GRAPHQL_SERVER_URL || 'https://gq-api.data-yk.com'
export const authUrl = process.env.REACT_APP_AUTH_SERVER_URL || 'https://auth.data-yk.com'
export const fastapiUrl = process.env.REACT_APP_API_SERVER_URL || 'https://api.data-yk.com'
export const korNameByCategory = {
  CASE: '사건',
  CONSULT: '자문',
  ETC: '기타'
}
export const callRecordType = {
  ONLINE: '온라인',
  OFFLINE: '오프라인',
  VISIT: '방문&선임'
}
export const callRecordTypes = [
  {
    id: 'ONLINE',
    label: '온라인',
    color: `${COLORS.primaryColor}`
  },
  {
    id: 'OFFLINE',
    label: '오프라인',
    color: `${COLORS.orange}`
  },
  {
    id: 'VISIT',
    label: '방문&선임',
    color: `${COLORS.green}`
  }
]
export const MESSAGES = {
  savingTimesheetFormWarning: {
    key: 'savingTimesheetFormWarning',
    content: '작성하지 않은 항목이 있는지 확인해주세요.',
    type: 'warning'
  },
  savingTimesheetFormSuccess: {
    key: 'savingTimesheetFormSuccess',
    content: '타임시트 작성을 완료했습니다.',
    type: 'success'
  },
  savingTimesheetFormError: {
    key: 'savingTimesheetFormError',
    content: '타임시트 저장 중 오류가 있습니다.',
    type: 'error'
  },
  caseCreationError: {
    key: 'caseCreationError',
    content: '사건생성 중 오류가 있습니다.',
    type: 'error'
  },
  caseFormWarning: {
    key: '3',
    content: '사건분류와 사건번호를 작성해주세요.',
    type: 'warning'
  },
  consultationFormWarning: {
    key: '4',
    content: '자문분류와 자문번호를 작성해주세요.',
    type: 'warning'
  },
  etcFormWarning: {
    key: '5',
    content: '제목과 내용을 입력해주세요.',
    type: 'warning'
  },
  timesheetDeleteError: {
    key: 'timesheetDeleteError',
    content: '타임시트를 삭제 중 오류가 있습니다.',
    type: 'error'
  },
  delegateUsersError: {
    key: 'delegateUsersError',
    content: '사용자 위임 중 오류가 있습니다.',
    type: 'error'
  },
  allocateUsersError: {
    key: 'allocateUsersError',
    content: '사용자 배당 중 오류가 있습니다.',
    type: 'error'
  },
  disAllocaateUsersError: {
    key: 'disAllocaateUsersError',
    content: '사용자 배당 취소 중 오류가 있습니다.',
    type: 'error'
  },
  employeeSheetFormError: {
    key: 'employeeSheetFormError',
    content: '직원 등록 중 오류가 있습니다.',
    type: 'error'
  },
  employeeDeleteError: {
    key: 'employeeRemoveError',
    content: '직원 삭제 중 오류가 있습니다.',
    type: 'error'
  },
  employeeRemoveError: {
    key: 'employeeRemoveError',
    content: '직원 퇴사 처리 중 오류가 있습니다.',
    type: 'error'
  },
  employeeUnremoveError: {
    key: 'employeeUnremoveError',
    content: '직원 퇴사 취소 중 오류가 있습니다.',
    type: 'error'
  },
  timesheetDeltaAddError: {
    key: 'timesheetDeltaAddError',
    content: '타임시트 조정 중 오류가 있습니다.',
    type: 'error'
  },
  categoryError: {
    key: 'categoryError',
    content: '카테고리 수정 중 오류가 있습니다.',
    type: 'error'
  },
  projectTypeAddError: {
    key: 'projectTypeAddError',
    content: '사건분류 생성 중 오류가 있습니다.',
    type: 'error'
  },
  projectTypeEditError: {
    key: 'projectTypeEditError',
    content: '사건분류 수정 중 오류가 있습니다.',
    type: 'error'
  },
  projectTypeDeleteError: {
    key: 'projectTypeDeleteError',
    content: '사건분류 삭제 중 오류가 있습니다.',
    type: 'error'
  },
  projectSubTypeAddError: {
    key: 'projectSubTypeAddError',
    content: '사건 소분류 생성 중 오류가 있습니다.',
    type: 'error'
  },
  projectSubTypeEditError: {
    key: 'projectSubTypeAddError',
    content: '사건 소분류 수정 중 오류가 있습니다.',
    type: 'error'
  },
  projectSubTypeDeleteError: {
    key: 'projectSubTypeAddError',
    content: '사건 소분류 삭제 중 오류가 있습니다.',
    type: 'error'
  },
  rankError: {
    key: 'rankError',
    content: '직급 수정 중 오류가 있습니다.',
    type: 'error'
  },
  timesheetTitleTemplateError: {
    key: 'timesheetTitleTemplateError',
    content: '타임시트 제목 템플릿 수정 중 오류가 있습니다.',
    type: 'error'
  },
  updateEmployeeError: {
    key: 'updateEmployeeError',
    content: '직원 정보 수정 중 오류가 있습니다.',
    type: 'error'
  },
  caseTitleAddMissingColorWarning: {
    key: 'caseTitleAddMissingColorWarning',
    content: '색깔을 선택해주세요.',
    type: 'warning'
  },
  caseTitleAddMissingNameWarning: {
    key: 'caseTitleAddMissingNameWarning',
    content: '분류 이름을 적어주세요.',
    type: 'warning'
  },
  rankTitleAddMissingNameWarning: {
    key: 'rankTitleAddMissingNameWarning',
    content: '직급 이름을 적어주세요.',
    type: 'warning'
  },
  projectSubTypeTitleAddMissingNameWarning: {
    key: 'rankTitleAddMissingNameWarning',
    content: '소분류 이름을 적어주세요.',
    type: 'warning'
  },
  timesheetTitleMissingWarning: {
    key: 'timesheetTitleMissingWarning',
    content: '제목을 적어주세요.',
    type: 'warning'
  },
  departmentCreationError: {
    key: 'departmentCreationError',
    content: '부서생성 중 오류가 있습니다.',
    type: 'error'
  },
  updateDepartmentError: {
    key: 'updateDepartmentError',
    content: '부서 정보 수정 중 오류가 있습니다.',
    type: 'error'
  },
  rankCreationError: {
    key: 'rankCreationError',
    content: '직급 생성 중 오류가 있습니다.',
    type: 'error'
  },
  rankDeleteError: {
    key: 'rankCreationError',
    content: '직급 삭제 중 오류가 있습니다.',
    type: 'error'
  },
  updateRankError: {
    key: 'updateRankError',
    content: '직급 수정 중 오류가 있습니다.',
    type: 'error'
  },
  removeTimesheetError: {
    key: 'removeTimesheetError',
    content: '타임시트 삭제 중 오류가 있습니다.',
    type: 'error'
  },
  removeDepartmentError: {
    key: 'removeDepartmentError',
    content: '부서 삭제 중 오류가 있습니다.',
    type: 'error'
  },
  removeProjectsError: {
    key: 'removeProjectsError',
    content: '사건 삭제 중 오류가 있습니다.',
    type: 'error'
  },
  closeProjectsError: {
    key: 'closeProjectsError',
    content: '사건 종결 중 오류가 있습니다.',
    type: 'error'
  },
  restoreProjectsError: {
    key: 'restoreProjectsError',
    content: '사건 복구 중 오류가 있습니다.',
    type: 'error'
  },
  reopenProjectsError: {
    key: 'reopenProjectsError',
    content: '사건 종결 취소 중 오류가 있습니다.',
    type: 'error'
  },
  recordAddError: {
    key: 'recordAddError',
    content: '콜 기록 저장 중 오류가 있습니다.',
    type: 'error'
  },
  removeCallRecords: {
    key: 'removeCallRecords',
    content: '콜 기록 제거 중 오류가 있습니다.',
    type: 'error'
  },
  restoreContractError: {
    key: 'restoreContractError',
    content: '계약 복구 중 오류가 있습니다.',
    type: 'error'
  },
  closeContractError: {
    key: 'closeContractError',
    content: '계약 종결 중 오류가 있습니다.',
    type: 'error'
  },
  openContractError: {
    key: 'openContractError',
    content: '계약 종결 취소 중 오류가 있습니다.',
    type: 'error'
  }
}

// 타임시트 제목
export const timesheetTitles = [
  '의뢰인 상담',
  '세무 상담',
  '소비자 상담',
  '금융분쟁'
]
export const searchSuggestionTablesTitles = [
  {
    label: '최근 조회',
    icon: <FileSearchOutlined />,
    key: 'visited',
    color: COLORS.pink
  },
  {
    label: '최근 등록',
    icon: <FileAddOutlined />,
    key: 'registered',
    color: COLORS.purple
  },
  {
    label: '즐겨찾기',
    icon: <StarFilled />,
    key: 'starred',
    color: COLORS.orange
  }
]

// 사건 테이블 > 사건 > 사용자 클릭 > 테이블
export const timesheetTableColumns = [
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: '소요시간',
    dataIndex: 'minutes',
    key: 'minutes'
  },
  {
    title: '사건번호',
    dataIndex: 'caseNumber',
    key: 'caseNumber'
  },
  {
    title: '수정일',
    dataIndex: 'updated',
    key: 'updated'
  }
]

export const searchSuggestionTablesColumns = (title) => [
  {
    title,
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: '사건번호',
    dataIndex: 'caseNumber',
    key: 'caseNumber'
  },
  {
    title: '수정일',
    dataIndex: 'updated',
    key: 'updated'
  },
  {
    title: '작성자',
    dataIndex: 'creator',
    key: 'creator'
  }
]

export const viewModeOptions = [
  {
    value: 'calendar',
    label: <CalendarOutlined />
  },
  {
    value: 'file',
    label: <FileOutlined />
  },
  {
    value: 'table',
    label: <MenuOutlined />
  }
]

//  통계 > 그룹
export const dayGroups = [
  {
    value: 'daily',
    label: '일간',
    key: '2'
  },
  {
    label: '주간',
    value: 'weekly',
    key: '3'

  },
  {
    label: '월간',
    value: 'monthly',
    key: '4'
  }
]

// 사건관리
export const allocationOptions = [
  {
    key: 'manager',
    label: '담당자 배당',
    icon: <BranchesOutlined style={{ color: `${COLORS.blue_4}` }} />
  },
  {
    key: 'edit',
    label: '사건정보 수정',
    icon: <EditOutlined style={{ color: `${COLORS.orange}` }} />
  },
  {
    key: 'delete',
    label: '사건 삭제',
    icon: <DeleteOutlined style={{ color: `${COLORS.red}` }} />
  },
  {
    key: 'closed',
    label: '사건종결',
    icon: <FlagOutlined style={{ color: `${COLORS.primaryColor}` }} />
  },
  {
    key: 'open',
    label: '사건종결 취소',
    icon: <DeleteRowOutlined style={{ color: `${COLORS.purple}` }} />
  }
]

// 권한관리
export const employersOptions = [
  {
    key: 'resetPassword',
    label: '비밀번호 재설정',
    icon: <KeyOutlined style={{ color: `${COLORS.orange}` }} />
  },
  {
    key: 'employeeDelegate',
    label: '권한 관리',
    icon: <SafetyCertificateOutlined style={{ color: `${COLORS.green}` }} />
  },
  {
    key: 'assignDepartment',
    label: '부서 관리',
    icon: <ApartmentOutlined style={{ color: `${COLORS.pink}` }} />
  },
  {
    key: 'assignRank',
    label: '직급 관리',
    icon: <RadarChartOutlined style={{ color: `${COLORS.purple}` }} />
  }
]

export const departmentColumns = [
  {
    title: '부서 이름',
    dataIndex: 'name'
  }
]

export const unAppointedDepartmentId = 'unAppointedDepId'

export const MAX_CONTENT_WIDTH = 1300
