import axios from 'axios'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { fastapiUrl } from '../../../common/config'
import { message } from 'antd'

export const useFormAttatchments = (initAttachments = [], loading) => {
  const [fileUploadLoading, setFileUploadLoading] = useState(false)

  const [meta, setMeta] = useState({
    files: [],
    fileIds: []
  })
  useEffect(() => {
    setMeta({
      files: _.map(initAttachments, v => ({
        id: _.get(v, ['id']),
        originalFilename: _.get(v, ['originalFilename']),
        filename: _.get(v, ['filename'])
      })),
      fileIds: _.map(initAttachments, 'id')
    })
  }, [loading])

  const onAddFiles = async ({ fileList }) => {
    const newFiles = []
    const newFileIds = []
    for (const file of fileList) {
      const formData = new FormData()
      formData.append('file', file.originFileObj)

      try {
        const parsedFile = {
          id: file.fetchedId,
          originalFilename: _.get(file, 'name'),
          size: file.size,
          filename: file.fetchedId
        }
        if (file.fetchedId) {
          newFiles.push(parsedFile)
          newFileIds.push(file.fetchedId)
        }
      } catch (e) {
        console.log(e)
      }
    }

    setMeta(meta => ({
      ...meta,
      files: [...newFiles, ...meta.files],
      fileIds: [...newFileIds, ...meta.fileIds]
    }))
  }
  const debouncedOnAddFiles = _.debounce(onAddFiles, 300)
  const onDeleteFileByKey = (key) => {
    setMeta(meta => ({
      ...meta,
      files: _.filter(meta.files, v => v.id !== key),
      fileIds: _.filter(meta.fileIds, id => id !== key)
    }))
  }
  return {
    dragDropProps: {
      multiple: true,
      onChange: debouncedOnAddFiles,
      name: 'file',
      action: async (file) => {
        const name = file.name
        const filesize = file.size
        const BASE_URL = fastapiUrl + '/attachments'
        const { data } = await axios.put(BASE_URL, { filename: name, size_b: filesize })
        file.fetchedId = data.id
        return data.url
      },
      customRequest: async ({ file, action }) => {
        setFileUploadLoading(true)
        try {
          if (typeof file !== 'string') {
            await axios.put(action, file, {
              headers: {
                'Content-Type': file.type
              }
            })
          }
        } catch (error) {
          message.error('파일 업로드 중 오류가 있습니다.')
        } finally {
          setFileUploadLoading(false)
        }
      },
      method: 'PUT'
    },
    onDeleteAll: () => {
      setMeta([])
    },
    onDownloadAll: (key) => {
      // meta.files
    },
    onDeleteFileByKey,
    fileUploadLoading,
    ...meta
  }
}
