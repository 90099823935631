import { RowGapWrapper } from '../../../common/Theme/style'
import { DateRangeFilter } from '../../../timeCharge/components/filters/DateRangeFilter'

import { Button, InputNumber } from 'antd'
import _ from 'lodash'
import MultipleSelect from '../common/MultipleSelect'

// 계약 기록 > 회수/미수금액 필터
const ContractRecordFilter = ({ viewMode, loading, meta, filters, onChangeMeta = () => {}, onResetFilterAndGroup = () => {}, onSelectFilter, mode = 'multiple', searchParams, pageType = null }) => {
  return (
    <div>
      <RowGapWrapper style={{ alignItems: 'center' }}>
        {(viewMode !== 'calendar' && pageType !== 'overdue') && (
          <DateRangeFilter
            value={meta.filter.datetimeRange}
            onCalendarChange={v => onChangeMeta('filter', 'datetimeRange', v)}
          />
        )}
        {_.filter(filters, ({ key }) => key !== '본사가 아닐 경우').map(({ key, selectedOptions, selectedItems, options, value, onChangeFilter, ...props }) => {
          return (
            (key !== 'price')
              ? (
                <MultipleSelect
                  multiple={false}
                  loading={loading}
                  key={key}
                  type={key}
                  style={{ minWidth: 200 }}
                  selectedOptions={selectedOptions}
                  selectedItems={selectedItems}
                  filters={filters}
                  options={options}
                  placeholder={value}
                  onChangeFilter={v => onChangeFilter(v)}
                  // mode={mode}
                  {...props}
                />)
              : (
                <InputNumber
                  key={key}
                  changeOnWheel
                  placeholder='0'
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  addonAfter='원'
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  style={{ minWidth: 120 }}
                />)
          )
        })}
        <Button onClick={onResetFilterAndGroup}>필터 초기화</Button>
      </RowGapWrapper>
    </div>
  )
}

export default ContractRecordFilter
