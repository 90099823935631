import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRankList } from '../../../timeCharge/hooks/apollo/useRankList'
import { BlockModal } from '../../../timeCharge/components/common/BlockModal'
import { useBlockModal } from '../../../timeCharge/hooks/common/useBlockModal'
import EmployeeRankForm from '../../../timeCharge/components/form/EmployeeRankForm'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { HeaderContainer, HeaderTitle } from '../../../common/components/PageHeaderStyles'
// import { useEditingUserRankForm } from '../../../timeCharge/hooks/settings/users/useEditingUserRankForm'
import { useEditingUserRankForm } from '../../../common/hooks/settings/users/useEditingUserRankForm'
import { getUserRankFormStatus, getUserRankMeta } from '../../../timeCharge/cacheAndNavigation/setting/cache'
import _ from 'lodash'
import { parseMetaToSelectOptions } from '../../../call/components/common/CommonFunction'

const SettingEmployeeRankContainer = () => {
  const { ranks } = useRankList()
  const navigate = useNavigate()
  const { userId } = useParams()
  const userRankFormStatus = getUserRankFormStatus()
  const prevUserRankFormStatus = useRef(userRankFormStatus)
  const {
    blockModalOpen,
    onFetchAndNav,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()
  useEffect(() => {
    if (!blockModalOpen) prevUserRankFormStatus.current = userRankFormStatus
  }, [blockModalOpen])
  const initMeta = getUserRankMeta()
  // 유저 직급 변경
  const userRanks = _.get(initMeta, ['ranks2'], [])
  const initRankIds = _.map(userRanks, 'id')
  const { onEdit } = useEditingUserRankForm(onFetchAndNav, userId, initRankIds)

  return (
    <>
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title='직급 수정' />
          </HeaderContainer>
        )}
        content={(
          <div style={{ display: 'flex', padding: '100px', justifyContent: 'center' }}>
            <EmployeeRankForm
              initialValues={{
                rankIds: _.get(initRankIds, [0]) // 직급 여러개
              }}
              ranks={_.map(ranks, parseMetaToSelectOptions)}
              onSave={onEdit}
              editing
              onCancel={() => navigate(-1)}
            />
          </div>
        )}
      />
      <BlockModal
        title='직급 수정을 취소하시겠습니까?'
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}

export default SettingEmployeeRankContainer
