import styled from 'styled-components'

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: ${props => props.height ? `${props.height}` : '100vh'};
`
export const MaxHeightScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
  max-height: ${props => props.$maxHeight ? `${props.$maxHeight}` : '100vh'};
`
