import { forwardRef, useImperativeHandle, useRef } from 'react'
import { useOutletContext } from 'react-router-dom'

import { TitleSelector } from './TitleSelector'
import AlignedForm from '../../common/AlignedForm'
import { MinutesSetter } from '../../common/MinutesSetter'
import { CustomPopover } from '../../common/CustomPopover'
import { SearchParamsFilter } from '../../filters/SearchParamsFilter'
import { CustomReadOnlyInput } from '../../common/CustomReadOnlyInput'
import { CustomSearch } from '../../../../common/components/CustomSearch'
import { useTimesheetForm } from '../../../hooks/timesheetForm/useTimesheetForm'
import { useTimesheetNameList } from '../../../hooks/apollo/useTimesheetNameList'
import { getTimesheetFormStatus } from '../../../cacheAndNavigation/timesheetForm/cache'
import { useCaseUserFilterForTimesheetForm } from '../../../hooks/timesheetForm/useCaseUserFilterForTimesheetForm'

import styled from 'styled-components'
import COLORS from '../../../../common/Theme/colors'
import _ from 'lodash'
import dayjs from 'dayjs'
import { Button, ConfigProvider, DatePicker } from 'antd'
import { customLocale } from '../../../../call/components/common/CommonFunction'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  .ant-upload-wrapper {
    width: 100%;
  }
`
const StyledButton = styled(Button)`
  display: flex;
  justify-content: left;
`
// 타임시트 작성란
const InputFilter = ({ readOnly, options = [], selectedOptions = [], selectedValue, starredOptions, onChange, onSelectValue, starredCaseListHidden, label, readOnlyValue, disabled, onSearch, searchQuery, hideSearch, customPersistElement, placeholder, ...caseFilterProps }) => {
  const popOverRef = useRef()
  const inputWide = true
  return (
    <>
      <CustomPopover
        ref={popOverRef}
        style={{ width: label === '사건번호' ? 1100 : 800 }}
        triggerStyle={{ width: '100%' }}
        persistElement={
          <CustomSearch
            autoFocus
            defaultValue={searchQuery}
            style={{ width: '100%' }}
            contentEditable={false}
            disabled={disabled}
            {...placeholder ? { placeholder } : {}}
            onSearch={onSearch}
            inputWide={inputWide}
            label={label}
          />
        }
        triggerElement={
          <StyledButton disabled={disabled} style={{ width: '100%' }}>{_.find(selectedOptions, ({ value }) => value === selectedValue)?.name !== undefined ? _.find(selectedOptions, ({ value }) => value === selectedValue)?.name : <div style={{ color: `${COLORS.gray_3}` }}>{placeholder}</div>}</StyledButton>
        }
        content={
          <SearchParamsFilter
            hideSearch
            multiple={false}
            options={options}
            {...caseFilterProps}
            selectedOptions={selectedOptions}
            selectedItems={selectedValue ? [selectedValue] : []}
            onChange={v => {
              onChange(v)
              onSelectValue(v[0])
              popOverRef.current?.closePopover()
            }}
            starredOptions={starredOptions}
            starredCaseListHidden={starredCaseListHidden}
          />
        }
      />
    </>
  )
}

const _Timesheet = ({ initMeta, readOnly, userInputEnabled, starredCaseListHidden }, ref) => {
  const status = getTimesheetFormStatus()
  const {
    meta,
    onChangeMeta,
    onResetFormMetaToInitialValues
  } = useTimesheetForm(initMeta, readOnly)
  const {
    filters,
    resetFilter
  } = useCaseUserFilterForTimesheetForm(
    {
      caseIds: _.get(initMeta, ['caseId']) ? [_.get(initMeta, ['caseId'])] : [],
      userIds: _.get(initMeta, ['userId']) ? [_.get(initMeta, ['userId'])] : []
    },
    undefined,
    () => onChangeMeta('caseId', undefined)
  )
  const { timesheetNames } = useTimesheetNameList()
  const { permissions } = useOutletContext()
  const caseFilter = _.find(filters, v => v.key === 'caseIds')
  const userFilter = _.find(filters, v => v.key === 'userIds')
  const caseType = meta.project?.category === 'CASE' ? '사건' : meta.project?.category === 'CONSULT' ? '자문' : meta.project?.category === 'ETC' ? '기타' : '사건'

  useImperativeHandle(ref, () => ({
    getMeta: () => meta,
    resetMeta: () => {
      onResetFormMetaToInitialValues()
      resetFilter()
    },
    resetCaseId: () => onChangeMeta('caseId', undefined),
    resetUserId: () => onChangeMeta('userId', undefined)
  }))
  return (
    <Container>
      <AlignedForm // readOnly ? 작성자 보여짐 : 타인타임시트 작성권한(&수정시) ?  작성자 보여짐 : 작성자 안보임
        items={[
          userInputEnabled // 타인 타임시트 작성 권한 있을 경우
            ? status === 'creating'
              ? [
                  {
                    label: `${caseType}번호`,
                    style: { width: '100%' },
                    component: (
                      <InputFilter
                        readOnly={readOnly}
                        readOnlyValue={meta.caseNumber}
                        selectedValue={meta.caseId}
                        {...caseFilter}
                        placeholder='사건번호를 선택해주세요.'
                        onSelectValue={v => onChangeMeta('caseId', v)}
                        label='사건번호'
                      />
                    )
                  },
                  _.includes(permissions, 'WRITE_OTHER_TIMESHEET')
                    ? {
                        label: '작성자',
                        style: { width: '100%' },
                        component: (
                          <InputFilter
                            readOnly={readOnly}
                            {...userFilter}
                            readOnlyValue={meta.creator}
                            selectedValue={meta.userId}
                            starredOptions={starredCaseListHidden}
                            onSelectValue={v => onChangeMeta('userId', v)}
                            label='작성자'
                            placeholder={!meta.caseId ? '사건번호를 먼저 선택해 주세요' : '작성자를 선택해 주세요.'}
                            disabled={!meta.caseId}
                          />
                        )
                      }
                    : null
                ]
              : [
                  {
                    label: `${caseType}번호`,
                    style: { width: '100%' },
                    component: (
                      <InputFilter
                        readOnly={readOnly}
                        readOnlyValue={meta.caseNumber}
                        selectedValue={meta.caseId}
                        {...caseFilter}
                        placeholder='사건번호를 선택해주세요.'
                        onSelectValue={v => onChangeMeta('caseId', v)}
                        label='사건번호'
                      />
                    )
                  },
                  _.includes(permissions, 'WRITE_OTHER_TIMESHEET')
                    ? {
                        label: '작성자',
                        style: { width: '100%' },
                        component: (
                          <InputFilter
                            readOnly={readOnly}
                            {...userFilter}
                            readOnlyValue={meta.creator}
                            selectedValue={meta.userId}
                            starredOptions={starredCaseListHidden}
                            onSelectValue={v => onChangeMeta('userId', v)}
                            label='작성자'
                            placeholder='작성자를 선택해 주세요.'
                          />
                        )
                      }
                    : null
                ]
            : {
                label: '사건번호',
                style: { width: '100%' },
                component: (
                  <InputFilter
                    readOnly={readOnly}
                    readOnlyValue={meta.caseNumber}
                    selectedValue={meta.caseId}
                    {...caseFilter}
                    placeholder='사건번호를 선택해주세요.'
                    onSelectValue={v => onChangeMeta('caseId', v)}
                    label='사건번호'
                  />
                )
              },
          {
            label: '업무실행일',
            style: { width: '100%' },
            component: (
              <>
                {readOnly
                  ? (
                    <CustomReadOnlyInput value={dayjs(meta.updated).format('YYYY년 M월 D일')} />)
                  : (
                    <ConfigProvider>
                      <DatePicker
                        locale={customLocale}
                        value={dayjs(meta.updated)}
                        style={{ width: '100%' }}
                        placeholder=''
                        onChange={v => {
                          if (!_.isEmpty(v)) {
                            onChangeMeta('updated', v.toDate())
                          }
                        }}
                      />
                    </ConfigProvider>
                    )}
              </>
            )
          },
          {
            label: '소요시간',
            style: { width: '100%' },
            component: (
              <MinutesSetter
                readOnly={readOnly}
                minutes={meta.minutes}
                onChange={e => onChangeMeta('minutes', e)}
              />
            )
          },
          {
            style: { width: '100%' },
            component: (
              <TitleSelector
                readOnly={readOnly}
                value={meta.title}
                items={_.map(_.sortBy(timesheetNames, v => v.order), 'name')}
                onChangeInput={v => onChangeMeta('title', v)}
              />
            )
          },
          {
            value: meta.content,
            placeholder: '내용을 입력해 주세요.',
            type: 'textArea',
            variant: false,
            onChange: e => onChangeMeta('content', e),
            readOnly,
            autoSize: {
              minRows: 12
            }
          }
        ]}
      />
    </Container>
  )
}

export const Timesheet = forwardRef(_Timesheet)
