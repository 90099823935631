import { useMutation } from '@apollo/client'
import { addContractRecord, editContractRecord, removeContractRecord } from '../../apollo/mutations'
import _ from 'lodash'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import { navigateToContractRecordDetail } from '../../cacheAndNavigation/contractRecordDetail/navigation'
import { MESSAGES } from '../../../common/config'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { navigateToContractRecord } from '../../cacheAndNavigation/paymentRecord/navigation'
import { useContractRecordFilter } from '../filter/contractRecordDetail/useContractRecordFilter'

export const useContractRecordAddEditRemove = (refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [addContractRecordMutFn] = useMutation(addContractRecord)
  const [editContractRecordMutFn] = useMutation(editContractRecord)
  const [removeContractRecordMutFn] = useMutation(removeContractRecord)
  const navigate = useNavigate()
  const { searchParams } = useContractRecordFilter()
  const searchString = searchParams.toString()
  const parseContractRecord = (data) => {
    return {
      callRecordId: _.get(data, 'id'),
      projectId: _.get(data, 'projectType.id'),
      caseNumber: _.get(data, 'caseNumber'),
      caseName: _.get(data, 'caseName'),
      projectTypeId: _.get(data, 'projectType.id'),
      departmentId: _.get(data, 'department.id'),
      assignedLawyerIds: _.get(data, 'assignedLawyers.id'),
      name: _.get(data, 'name'),
      representer: _.get(data, 'representer'),
      phone: _.get(data, 'phone'),
      address: _.get(data, 'address'),
      email: _.get(data, 'email'),
      opponent: _.get(data, 'opponent'),
      jurisdiction: _.get(data, 'jurisdiction'),
      registrationNumber: _.get(data, 'registrationNumber')
    }
  }

  const onCreateContractRecord = (data) => {
    const transformedData = parseContractRecord(data)
    addContractRecordMutFn({
      variables: {
        input: transformedData
      }
    }).then(v => {
      const newRecordId = _.get(v, ['data', 'AddContractRecord', 'data'])
      navigateToContractRecordDetail(navigate, newRecordId, searchString, 'edit')
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  const onEditContractRecord = (id, input) => {
    const transformedData = parseContractRecord(input)
    editContractRecordMutFn({
      variables: {
        editContractRecordId: id,
        input: transformedData
      }
    }).then(v => {
      if (_.get(v, ['data', 'EditContractRecord', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      console.log(e)
      handleError()
    })
  }

  const onRemoveContractRecord = (id) => {
    removeContractRecordMutFn({
      variables: {
        removeContractRecordId: id
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveContractRecord', 'ok'])) {
        navigateToContractRecord(navigate, null)
      }
    }).catch(e => {
      console.log(e)
      handleError()
    })
  }

  return {
    onCreateContractRecord,
    onEditContractRecord,
    onRemoveContractRecord
  }
}
