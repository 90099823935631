import { useEffect, useState } from 'react'
// 스테이트 1 , 2, 3

// 스테이트 2에서 사건번호 비었을때 1로가기
// 스테이트 3에서 사용자가 비었을때 2로 가기
// 스테이트 3에서 사용자와 사건번호가 모두 비었을때 1로 가기
export const useTimesheetFinderState = (isCaseEmpty, isUserEmpty) => {
  const [finderState, setFinderState] = useState(1)

  useEffect(() => {
    setFinderState(finderState => {
      if (isUserEmpty === false && isCaseEmpty === false) return 3
      if (finderState === 2 && isCaseEmpty) return 1
      if (finderState === 3) {
        if (isUserEmpty && isCaseEmpty) return 1
        else if (isUserEmpty) return 2
      }
      return finderState
    })
  }, [isCaseEmpty, isUserEmpty])

  return {
    finderState,
    changeFinderState: (v) => setFinderState(v)
  }
}
