import _ from 'lodash'
import { useMutation } from '@apollo/client'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { createCase, updateCase } from '../../../apollo/mutations'
import { MESSAGES, korNameByCategory } from '../../../../common/config'
import { navigateToCaseDetail } from '../../../cacheAndNavigation/caseDetail/navigation'

const categoryByTypeOfContainer = {
  case: 'CASE',
  consultation: 'CONSULT',
  etc: 'ETC'
}
export const useAddingCase = (attachments, caseNumber, caseType, content, onFetchAndNav, typeOfContainer, caseFormStatus, caseId, caseStatus, automaticName, caseTags = []) => {
  const navigate = useNavigate()
  const { messageApi } = useOutletContext()
  const [createCasesMutFn] = useMutation(
    createCase,
    {
      onCompleted (data) {
        const projId = _.get(data, ['AddProject', 'data', 'id'], null)
        if (_.isNull(projId)) {
          console.error('Failed to Allocate Project to creator: ID issue')
        }
      }
    }
  )
  const [updateCaseMutFn] = useMutation(updateCase)
  const onSave = () => {
    if ((caseNumber && caseType) || (caseType && automaticName)) {
      onFetchAndNav(
        () => {
          const randNamePrefix = `${korNameByCategory[categoryByTypeOfContainer[typeOfContainer]]}-${_.get(_.find(caseTags, v => v.value === caseType), ['label'])}`
          if (caseFormStatus === 'creating') {
            return createCasesMutFn({
              variables: {
                project:
                  {
                    category: categoryByTypeOfContainer[typeOfContainer],
                    name: automaticName ? randNamePrefix : caseNumber,
                    type: caseType,
                    content,
                    status: 'OPEN'
                  },
                attachments,
                automaticName
              }
            })
          } else if (caseFormStatus === 'editing') {
            return updateCaseMutFn({
              variables: {
                updateProjectId: caseId,
                input: {
                  name: caseNumber,
                  content,
                  status: caseStatus
                },
                attachments
              }
            })
          } else {
            console.log('formstatus unknown')
          }
        },
        (v) => {
          if (!_.get(v, ['data', caseFormStatus === 'creating' ? 'AddProject' : 'UpdateProject', 'ok'], false)) {
            messageApi.open(MESSAGES.caseCreationError)
            return
          }
          if (_.get(v, ['data', 'AddProject', 'data', 'id'], false)) navigateToCaseDetail(navigate, { caseId: _.get(v, ['data', 'AddProject', 'data', 'id'], false) }, true)
          else if (caseId) navigateToCaseDetail(navigate, { caseId }, true)
          else messageApi.open(MESSAGES[`${typeOfContainer}FormWarning`])
        },
        (e) => console.log(e)
      )
    } else {
      messageApi.open(MESSAGES[`${typeOfContainer}FormWarning`])
    }
  }

  return {
    onSave,
    onCancel: () => navigate(-1, { replace: true })
  }
}
