import { gql } from '@apollo/client'

export const getTimesheetList = gql`
  query GetTimesheetList($sortBy: SortBy!, $filter: TimesheetListFilter, $offset: Int, $limit: Int) {
    GetTimesheetList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      timesheets {
        created
        content
        history {
          date
          id
          user {
            id
            name
          }
        }
        project {
          id
          name
          type {
            color
          }
        }
        id
        minutes
        title
        date
        attachments {
          filename
          id
          originalFilename
          sizeB
        }
        user {
          id
          name
        }
        createdBy {
          id
          name
        }

      }
      totalMinutes
      totalCount
    }
  }
`

export const getCaseList = gql`
   query GetProjectList($sortBy: SortBy!, $filter: ProjectListFilter, $offset: Int, $limit: Int) {
    GetProjectList(sortBy: $sortBy, offset: $offset, filter: $filter, limit: $limit) {
      totalCount
      # totalMinutes
      ... on GetProjectWithTimeDeltaListResponse {
        totalDeltaMinutes
        data {
          id
          totalMinutes
          totalDeltaMinutes
          starred
          status
          # timesheetUpdated
          # createdBy {
          #   id
          #   name
          # }
          # delegates {
          #   id
          #   name
          # }
          # type {
          #   color
          #   id
          #   name
          #   order
          # }
          # content
          category
          # updated
          # updatedBy {
          #   name
          #   id
          # }
          # created
          name
          # attachments {
          #   filename
          #   id
          #   originalFilename
          #   sizeB
          # }
          timesheets {
              # date
              # title
              # minutes
              id
              # project {
              #   name
              # }
              # user {
              #   name
              # }
              # history {
              #   created
              #   date
              #   id
              #   user {
              #     id
              #     name
              #   }
              # }
            }
          userCount
          users {
            # status
            id
            name
            totalMinutes
            timesheetCount
            totalDeltaMinutes
            # departments {
            #   id
            #   name
            # }
            # relation
            timesheets {
              date
              title
              minutes
              id
              # project {
              #   name
              # }
              # user {
              #   name
              # }
              # history {
              #   created
              #   date
              #   id
              #   user {
              #     id
              #     name
              #   }
              # }
            }
            # group {
            #   name
            # }
          }
        }
      }
      ... on GetProjectListResponse {
        data {
          id
          totalMinutes
          starred
          status
          # timesheetUpdated
          # createdBy {
          #   id
          #   name
          # }
          # delegates {
          #   id
          #   name
          # }
          # type {
          #   color
          #   id
          #   name
          #   order
          # }
          # content
          category
          # updated
          # updatedBy {
          #   name
          #   id
          # }
          # created
          name
          # attachments {
          #   filename
          #   id
          #   originalFilename
          #   sizeB
          # }
          timesheets {
              # date
              # title
              # minutes
              id
              # project {
              #   name
              # }
              # user {
              #   name
              # }
              # history {
              #   created
              #   date
              #   id
              #   user {
              #     id
              #     name
              #   }
              # }
            }
          userCount
          users {
            # status
            id
            name
            totalMinutes
            timesheetCount
            # departments {
            #   id
            #   name
            # }
            # relation
            timesheets {
              date
              title
              minutes
              id
              # project {
              #   name
              # }
              # user {
              #   name
              # }
              # history {
              #   created
              #   date
              #   id
              #   user {
              #     id
              #     name
              #   }
              # }
            }
            # group {
            #   name
            # }
          }
        }
      }
    }
  }
`
export const getCurrentUser = gql`
  query Departments {
    CurrentUser {
      departments {
        id
        name
      }
      group {
        id
        name
      }
      id
      name
      email
      status
      permissions
    }
  }
`

export const getTimeGroupList = gql`
  query GetTimeGroupList($group: TimeGroupGroup!, $filter: TimeGroupFilter, $sortBy: SortBy!, $offset: Int, $limit: Int) {
    GetTimeGroupList(group: $group, filter: $filter, sortBy: $sortBy, offset: $offset, limit: $limit) {
      totalCount
      totalMinutes
      ... on GetTimeGroupListResponse {
        data {
          timesheetCount
          userCount
          totalMinutes
          primaryKey
          projectCount
          secondaryKey {
            ... on User {
              id
              name
            }
            ... on Project {
              id
              name
            }
            ... on ProjectCategoryType {
              category
            }
            ... on ProjectType {
              id
              name
            }
          }
        }
      }
      ... on GetTimeGroupWithTimeDeltaListResponse {
        data {
          timesheetCount
          userCount
          totalMinutes
          primaryKey
          projectCount
          secondaryKey {
            ... on User {
              id
              name
              email
            }
            ... on Project {
              id
              name
            }
            ... on ProjectCategoryType {
              category
            }
            ... on ProjectType {
              id
              name
            }
          }
        }
        totalDeltaMinutes
      }
    }
  }
`

export const getUserList = gql`
  query GetUserList($sortBy: SortBy!, $filter: UserListFilter, $offset: Int, $limit: Int) {
    GetUserList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        name
        email
        status
        departments {
          id
          name
        }
        group {
          id
          name
        }
        ranks {
          id
          name
          order
        }
        projects {
          id
          name
          category
          timesheets {
            id
            title
            minutes
            createdBy {
              id
            }
          }
          type {
            id
            name
          }
          users {
            id
            name
            relation
          }
        }
        permissions
        permissionsAdd
        permissionsRemove
        projectCount
        timesheetCount
        totalMinutes
        lastLoggedIn
        passwordUpdated
      }
      totalCount
    }
  }
`

export const getCategories = gql`
  query GetProjectTypeList($filter: ProjectTypeFilter) {
    GetProjectTypeList(filter: $filter) {
      color
      id
      name
      order
    }
  }
`

export const getProjectSubTypeList = gql`
  query GetProjectSubTypeList($filter: ProjectSubTypeFilter) {
    GetProjectSubTypeList(filter: $filter) {
      id
      order
      name
      type {
        color
        id
        name
        order
      }
    }
  }
`

export const getProjectNameCategories = gql`
query GetTimesheetNameTemplateList {
  GetTimesheetNameTemplateList {
    id
    name
    order
  }
}
`

export const getRankList = gql`
  query GetRankList {
  GetRankList {
    id
    name
    order
  }
}
`

export const getTimesheetInfoById = gql`
  query GetTimesheet($getTimesheetId: ID!) {
    GetTimesheet(id: $getTimesheetId) {
      created
      content
      history {
        date
        id
        user {
          id
          name
        }
        created
        createdBy {
          name
        }
        content
        minutes
        title
        attachments {
          filename
          id
          originalFilename
          sizeB
        }
        project {
          id
          name
          type {
            color
          }
        }
      }
      project {
        id
        name
        category
        type {
          color
        }
      }
      id
      minutes
      title
      date
      attachments {
        filename
        id
        originalFilename
        sizeB
      }
      user {
        id
        name
      }
    }
  }
`
export const getTimeDeltas = gql`
  query GetTimeDeltaList($projectId: ID!, $userId: ID!) {
    GetTimeDeltaList(projectId: $projectId, userId: $userId) {
      id
      created
      minutes
      createdBy {
        id
        name
      }
    }
  }
`

export const getCaseById = gql`
query GetProject($getProjectId: ID!) {
  GetProject(id: $getProjectId) {
    id
    timesheetUpdated
    name
    status
    attachments {
      filename
      id
      originalFilename
      sizeB
    }
    category
    type {
      id
      name
    }
    
    delegates {
      email
      id
      name
      status
      departments {
        id
        name
        leaders {
          id
          name
        }
      }
      email
      group {
        id
        name
      }
      timesheets {
        id
        title
        project {
          id
        }
      }
      permissions
      projectCount
      timesheetCount
      totalMinutes
      lastLoggedIn
      passwordUpdated
    }
    totalMinutes
    timesheets {
      id
    }
    created
    createdBy {
      id
      name
      created
    }
    updated
    updatedBy {
      name
      id
    }
    
    content
    users {
      relation
      email
      id
      name
      status
      departments {
        id
        name
        leaders {
          id
          name
        }
      }
      email
      group {
        id
        name
      }
      timesheets {
        id
        title
        project {
          id
        }
      }
      permissions
      timesheetCount
      totalMinutes
    }
  }
}
`

export const getDepartmentList = gql`
query GetDepartmentList($filter: DepartmentListFilter) {
  GetDepartmentList(filter: $filter) {
    id
    name
    head
    leaders {
      id
      name
      permissions
    }
    members {
      id
      name
    }
    order
  }
}
`

export const getUserGroupList = gql`
  query GetUserGroupList {
  GetUserGroupList {
    id
    name
    permissions
  }
}
`

export const getPushNotifications = gql`
  query GetPushNotifications {
    GetPushNotifications {
      created
      data {
        ... on PushNotificationSimpleMessage {
          message
        }
        ... on PushNotificationProjectMessage {
          project {
            id
            category
            name
          }
        }
        ... on PushNotificationUserMessage {
          user {
            id
            name
          }
        }
      }
      id
      read
      type
    }
  }
`
