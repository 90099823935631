import _ from 'lodash'
import { timesheetTitles } from './common/config'
import { uuidv4 } from './common/util'
// timelist query
export const getDD = (v) => {
  return v >= 10 ? v : `0${v}`
}

export const generateTimeListData = (caseId, caseNumber, userId, userName) => {
  return (
    _.range(1, 10).map(v => {
      return {
        userId,
        key: v,
        title: timesheetTitles[Math.round(Math.random() * 3)],
        caseNumber,
        caseId,
        creator: userName,
        created: `2024-01-${getDD(v)} 12:12:00`,
        updated: `2024-01-${getDD(v)} 23:12:00`,
        minutes: Math.round(Math.random() * 1000)
      }
    })
  )
}

export const caseListData = _.range(1, 11).flatMap(v => {
  const nUsers = Math.ceil(Math.random() * 10)
  let totalMinutes = 0
  let totalMinutesAfterEdit = 0

  return [
    {
      key: `caseId_${2 * v - 1}`,
      starred: true,
      caseNumber: `2021가합${6 * v - 1} 손해배상`,
      updated: `2024-01-0${v} 23:12:00`,
      users: _.range(nUsers).map(i => {
        if (i === 0) {
          totalMinutes = 0
          totalMinutesAfterEdit = 0
        }
        const minutes = Math.round(Math.random() * 1000)
        const minutesAfterEdit = minutes + Math.round(Math.random() * 50) - Math.round(Math.random() * 50)
        totalMinutes += minutes
        totalMinutesAfterEdit += minutesAfterEdit
        return {
          key: `userId_${2 * v - 1}_${i}`,
          updated: '2024-01-24 23:12:00',
          // name: `사용자_${2 * v - 1}_${i}`,
          name: '사용자',
          minutes,
          minutesAfterEdit,
          timesheets: generateTimeListData(`caseId_${2 * v - 1}`, `사건번호 ${2 * v - 1}`, `userId_${2 * v - 1}_${i}`, `사용자_${2 * v - 1}_${i}`)
        }
      }),
      totalMinutes,
      totalMinutesAfterEdit
    },
    {
      key: `caseId_${2 * v}`,
      starred: false,
      caseNumber: `사건번호 ${2 * v}`,
      updated: `2024-01-0${v} 23:12:00`,
      users: _.range(nUsers).map(i => {
        if (i === 0) {
          totalMinutes = 0
          totalMinutesAfterEdit = 0
        }
        const minutes = Math.round(Math.random() * 1000)
        const minutesAfterEdit = minutes
        totalMinutes += minutes
        totalMinutesAfterEdit += minutesAfterEdit
        return {
          key: `userId_${2 * v}_${i}`,
          updated: '2024-01-24 23:12:00',
          name: `사용자_${2 * v}_${i}`,
          minutes,
          minutesAfterEdit,
          timesheets: generateTimeListData(`caseId_${2 * v}`, `사건번호 ${2 * v}`, `userId_${2 * v}_${i}`, `사용자_${2 * v}_${i}`)
        }
      }),
      totalMinutes,
      totalMinutesAfterEdit
    }
  ]
})

export const caseNumbers = caseListData.map(({ key, caseNumber }) => ({ key, value: caseNumber }))
export const users = _.flatMap(caseListData, 'users').map(({ key, name }) => {
  return {
    key,
    value: name
  }
})
export const colors = [
  'purple',
  'skyBlue',
  'green',
  'lightGreen'
]
export const timeListData = _.range(1, 30).map(v => {
  const rand = Math.round(Math.random() * 3)
  return {
    key: `timesheet_1_${v}`,
    color: colors[rand],
    title: timesheetTitles[Math.round(Math.random() * 3)],
    caseNumber: caseNumbers[rand].value,
    caseId: caseNumbers[rand].key,
    creator: '김길동',
    created: `2024-01-${getDD(v)} 12:12:00`,
    updated: `2024-01-${getDD(v)} 23:12:00`,
    minutes: Math.round(Math.random() * 1000)
  }
}).concat(
  _.range(1, 30).map(v => {
    const rand = Math.round(Math.random() * 3)

    return {
      key: `timesheet_2_${v}`,
      color: colors[rand],
      title: timesheetTitles[Math.round(Math.random() * 3)],
      caseNumber: caseNumbers[rand].value,
      caseId: caseNumbers[rand].key,
      creator: '김길동',
      created: `2024-01-${getDD(v)} 12:12:00`,
      updated: `2024-01-${getDD(v)} 23:12:00`,
      minutes: Math.round(Math.random() * 1000)
    }
  })
)

// 통계
export const createGraphData = (timeGroup) => {
  return _.range(timeGroup === 'weekly' ? 4 : 10).flatMap(v => {
    return _.range(1, 10).map(i => {
      return {
        nTimesheets: Math.round(Math.random() * 10),
        date: timeGroup === 'weekly' ? [`2024-01-${(v * 7) + 1}`, `2024-01-${(v * 7) + 8}`] : [`2024-01-${v + 1}`],
        creator: `사용자 ${i}`,
        caseNumber: `사건번호 ${i}`
      }
    })
  })
}

export const pushNotifications = _.range(10).map(v => {
  const date = new Date()
  date.setDate(date.getDate() - v)
  return (
    {
      text: `사건 ${v}에 추가되었습니다.`,
      date: date,
      reviewed: v > 3,
      type: ''
    }
  )
})

export const contractDuplication = [
  {
    key: '0',
    ID: 123123,
    SN: 'YK0120240101',
    category: '형사',
    subcategory: '형사'
  }
]

// 콜 기록 항목
export const callMockData = [
  {
    key: '1',
    paymentId: 1,
    starred: true,
    initial: true,
    id: 'YK120240101',
    created: '2024-10-01',
    name: '고길동',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행 인과\n 시비후 폭행 인과 시비 후 폭행 인과 시비 후 폭행 인과 시비 후 폭행 인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visited: '2024-07-02',
    visitReserved: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '고길동'
  },
  {
    key: '2',
    paymentId: 2,
    starred: true,
    initial: false,
    id: 'YK220240102',
    created: '2024-10-01',
    name: '박길동',
    type: '오프라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건사건사건사건사건사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행지인과 시비 후 폭행 지인과 시비 후 폭행 22\n지인과 시비 후 폭행 지인과 시비 후 폭행 지인과 시비 후 폭행 지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visited: '2024-07-02',
    visitReserved: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '400,000,000',
    contractState: 'OVERDUE',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '박길동'
  },
  {
    key: '3',
    paymentId: 3,
    starred: false,
    initial: false,
    id: 'YK320240102',
    created: '2024-10-01',
    name: '김길동',
    type: '방문&선임',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행 \n22',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visited: '2024-07-02',
    visitReserved: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'NOCONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '김길동'
  },
  {
    key: '4',
    paymentId: 4,
    starred: false,
    initial: false,
    id: 'YK420240102',
    created: '2024-10-01',
    name: '김영희',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visited: '2024-07-02',
    visitReserved: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'PROGRESS',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '김영희'
  },
  {
    key: '5',
    paymentId: 5,
    starred: false,
    initial: false,
    id: 'YK520240102',
    created: '2024-10-01',
    name: '박영희',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visited: '2024-07-02',
    visitReserved: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CLOSE',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '박영희'
  },
  {
    key: '6',
    paymentId: 6,
    starred: false,
    initial: false,
    id: 'YK620240102',
    created: '2024-10-01',
    name: '박길동',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visited: '2024-07-02',
    visitReserved: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '박길동'
  },
  {
    key: '7',
    paymentId: 7,
    starred: false,
    initial: false,
    id: 'YK720240102',
    created: '2024-10-01',
    name: '박길동',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visitReserved: '2024-07-02',
    visited: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,

    callCount: 1,
    contractor: '박길동'
  },
  {
    key: '8',
    paymentId: 8,
    starred: false,
    initial: false,
    id: 'YK820240102',
    created: '2024-10-01',
    name: '박길동',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visitReserved: '2024-07-02',
    visited: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '박길동'
  },
  {
    key: '9',
    paymentId: 9,
    starred: false,
    initial: false,
    id: 'YK920240102',
    created: '2024-10-01',
    name: '박길동',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visitReserved: '2024-07-02',
    visited: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '박길동'
  },
  {
    key: '10',
    paymentId: 10,
    starred: false,
    initial: false,
    id: 'YK1020240102',
    created: '2024-10-01',
    name: '박길동',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visitReserved: '2024-07-02',
    visited: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '박길동'
  },
  {
    key: '11',
    paymentId: 11,
    starred: false,
    initial: false,
    id: 'YK1120240102',
    created: '2024-10-01',
    name: '박길동',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visitReserved: '2024-07-02',
    visited: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '박길동'
  },
  {
    key: '12',
    paymentId: 12,
    starred: false,
    initial: false,
    id: 'YK1220240102',
    created: '2024-10-01',
    name: '박길동',
    type: '온라인',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visitReserved: '2024-07-02',
    visited: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수', '김민수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '박길동'
  },
  {
    key: '13',
    paymentId: 13,
    starred: true,
    initial: false,
    id: 'YK130240101',
    created: '2024-10-01',
    name: '고길동',
    type: '방문&선임',
    gender: '남성',
    age: '40',
    phone: '010-0000-0000',
    email: '1004@mail.mail',
    address: '서울특별시 00구',
    callDate: '2024-07-01',
    createdBy: '홍길동',
    inputDatetime: '2024-10-07T15:41:16.900Z',
    projectType: '형사',
    projectSubType: '경찰',
    department: '서울',
    via: '홈페이지1',
    expectedCaseName: '사건',
    manager: '홍길동',
    jurisdiction: '서울경찰서',
    caseNumber: '0000가0000',
    content: '지인과 시비 후 폭행 인과',
    inBound: '1',
    outBound: '1',
    callType: '신규통화',
    reservationStatus: '진행',
    reservationCancelReason: '단순변심',
    calcelDate: '2024-07-01',
    visitReserved: '2024-07-02',
    visited: '2024-07-02',
    assigned: '2024-12-12',
    delegates: '홍길동',
    counselLawyers: ['홍길동', '김철수'],
    counselEmployees: ['홍길동', '김철수', '김민수'],
    nVisit: '1',
    nRevisit: '1',
    nAssign: '1',
    cancelled: '2024-02-02',
    cancelReason: '',
    paymentDeadLine: '2024-12-12',
    contractTotal: '4,000,000',
    contractState: 'CONTRACT',
    primaryAuthor: 'NA',
    secondaryAuthor: 'NA',
    amount: 1000000,
    callCount: 1,
    contractor: '고길동'
  }
]
