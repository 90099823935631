import { useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import { useCaseUserFilterSearchParams } from '../filter/params/useCaseUserFilterSearchParams'
import { navigateToCaseList } from '../../cacheAndNavigation//timesheet/navigation'
import { navigateToTimesheetForm } from '../../cacheAndNavigation//timesheetForm/navigation'
import { navigateToTimesheetDetail } from '../../cacheAndNavigation//timesheetDetail/navigation'
import { useTimesheetList } from '../apollo/InProgressCasePage/useTimesheetList'
import { parseClosedFromArrOfCaseStatusFilter } from '../../apollo/parser'
import { parseDateObj } from '../../../common/util'
import _ from 'lodash'
import { navigateToContractRecordDetail } from '../../../call/cacheAndNavigation/contractRecordDetail/navigation'

export const useCalendar = () => {
  const [activeStartDate, setActiveStartDate] = useState(new Date().setDate(1))
  const { caseStatus } = useParams()
  const { permissions } = useOutletContext()

  const navigate = useNavigate()
  const {
    meta: {
      filter
    },
    searchParams,
    getUpdatedSearchParams
  } = useCaseUserFilterSearchParams()
  const searchString = searchParams.toString()

  const from = activeStartDate ? parseDateObj(new Date(activeStartDate).setMonth(new Date(activeStartDate).getMonth())) : undefined
  const to = activeStartDate ? parseDateObj(new Date(activeStartDate).setMonth(new Date(activeStartDate).getMonth() + 1)) : undefined
  const caseIds = filter.caseIds
  const userIds = filter.userIds
  const categories = filter.categories
  const closed = parseClosedFromArrOfCaseStatusFilter(filter.caseStatus)
  const { timesheets } = useTimesheetList({
    projectIds: caseIds,
    userIds,
    from,
    to,
    limit: null,
    projectCategories: categories,
    closed,
    viewMyCase: filter.viewMyCase
  })
  const timeListData = _.map(timesheets, timesheet => {
    return {
      color: _.get(timesheet, ['case', 'caseType', 'color']),
      caseNumber: _.get(timesheet, ['case', 'caseNumber']),
      caseId: _.get(timesheet, ['case', 'id']),
      key: _.get(timesheet, ['id']),
      ...timesheet
    }
  })
  const timesheetsByDate = _.groupBy(timeListData, v => v.date ? parseDateObj(new Date(v.date)) : null)
  const onDoubleClickCalendarCell = (day) => {
    if (_.includes(permissions, 'WRITE_TIMESHEET')) {
      navigateToTimesheetForm(navigate, 'creating', {
        updated: new Date(day)
      })
    }
  }
  const onClickCalendarListItem = (v, type) => {
    if (type === 'call') {
      const tmp = { // 수정필요: 임시로 페이지 이동
        paymentId: 0
      }
      navigateToContractRecordDetail(navigate, tmp, searchString)
    } else {
      navigateToTimesheetDetail(navigate, v.key, v)
    }
  }
  const formatDay = (l, date) => {
    if (activeStartDate && new Date(date).getMonth() !== new Date(activeStartDate).getMonth() && new Date(date).getDate() === 1) {
      return `${new Date(date).getMonth() + 1}월 1일`
    } else return new Date(date).getDate()
  }
  const onClickCellSummary = (date, list) => {
    const sp1 = getUpdatedSearchParams('filter', 'datetimeRange', [new Date(date), new Date(date).setHours(23, 59, 59, 999)], searchParams)
    navigateToCaseList(navigate, caseStatus, undefined, 'table', sp1.toString(), false)
  }

  return {
    listByDate: timesheetsByDate,
    formatDay,
    onChangeActiveStartDate: v => setActiveStartDate(v),
    onClickCalendarListItem,
    onDoubleClickCalendarCell,
    onClickCellSummary
  }
}