import _ from 'lodash'
import { useQuery } from '@apollo/client'

import { getTimeGroupList } from '../../apollo/queries'

const itemMapObj = {
  creator: 'USER',
  caseNumber: 'PROJECT_NAME',
  caseCategory: 'PROJECT_CATEGORY',
  caseType: 'PROJECT_TYPE'
}
const timeMapObj = {
  daily: 'DAILY',
  weekly: 'WEEKLY',
  monthly: 'MONTHLY'
}
export const useTimeGroupList = (
  groupItem,
  groupTime,
  projectIds,
  userIds,
  projectTypeIds,
  from,
  to,
  offset,
  limit,
  sortKey,
  sortAscending = true,
  skip,
  projectCategories
) => {
  const _groupItem = _.get(itemMapObj, [groupItem], null)
  const _groupTime = _.get(timeMapObj, [groupTime], null)
  const variables = {
    group: {
      item: _groupItem,
      time: _groupTime
    },
    filter: {
      ..._.isEmpty(projectIds) ? { } : { projectIds },
      ..._.isEmpty(userIds) ? { } : { userIds },
      ..._.isEmpty(projectTypeIds) ? { } : { projectTypeIds },
      ..._.isEmpty(projectCategories) ? { } : { projectCategories },
      ...from && to
        ? {
            dateRange: {
              from,
              to
            }
          }
        : {}
    },
    sortBy: {
      ascending: sortAscending,
      key: sortKey
    },
    offset,
    limit
  }
  const { loading, data } = useQuery(getTimeGroupList, {
    variables,
    fetchPolicy: 'network-only',
    skip
  })

  return {
    loading,
    variables,
    totalCount: _.get(data, ['GetTimeGroupList', 'totalCount']),
    totalMinutes: _.get(data, ['GetTimeGroupList', 'totalMinutes']),
    timeGroups: _.get(data, ['GetTimeGroupList', 'data'], [])
  }
}
