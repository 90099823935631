import { useMutation } from '@apollo/client'
import { allocateUsersToCase, deallocateUsersFromCase } from '../../../apollo/mutations'

export const useAllocateUsers = () => {
  const [allocateUsersToCaseFn] = useMutation(allocateUsersToCase)
  const [deallocateUsersFromCaseFn] = useMutation(deallocateUsersFromCase)

  const allocateUsers = (projectId, userIds) => allocateUsersToCaseFn({
    variables: {
      projectId,
      userIds
    }
  })

  const deallocateUsers = (projectId, userIds) => deallocateUsersFromCaseFn({
    variables: {
      projectId,
      userIds
    }
  })

  return {
    allocateUsers,
    deallocateUsers
  }
}
