import _ from 'lodash'

export const navigateToContractRecord = (navigate, searchString, _viewMode, _groupModeAllContract) => {
  const viewMode = _viewMode
  const groupModeAllContract = _groupModeAllContract

  navigate(`/contractRecord/${searchString ? `?${searchString}` : ''}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      ...viewMode ? { viewMode } : {},
      ...groupModeAllContract ? { groupModeAllContract } : {}

    }
  })
}
