// 타임시트 작성/수정
import { useRef } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import { ConfirmModal } from '../../common/components/ConfirmModal'
import { Attatchments } from '../../timeCharge/components/common/Attatchments'
import { Timesheet } from '../../timeCharge/components/timesheetForm/TimesheetForm'
import { ReadOnlyTimesheet } from '../../timeCharge/components/timesheetForm/TimesheetForm/ReadOnlyTimesheet'
import { HeaderContentFooter } from '../../common/components/HeaderContentFooter'
import { HeaderContainer, HeaderGroup, HeaderTitle, Spacer } from '../../common/components/PageHeaderStyles'

import { useConfirmModal } from '../../timeCharge/hooks/manageCase/table/useConfirmModal'
import { useDeleteTimesheet } from '../../timeCharge/hooks/apollo/mutations/useDeleteTimesheet'
import { useFormAttatchments } from '../../timeCharge/hooks/timesheetForm/useFormAttatchments'

import { MAX_CONTENT_WIDTH } from '../../common/config'
import { getTimesheetFormStatus } from '../../timeCharge/cacheAndNavigation/timesheetForm/cache'
import { navigateToTimesheetEntry } from '../../timeCharge/cacheAndNavigation/timesheetDetail/navigation'
import { navigateToTimesheetForm } from '../../timeCharge/cacheAndNavigation/timesheetForm/navigation'

import { useQuery } from '@apollo/client'
import { parseTimesheet } from '../../timeCharge/apollo/parser'
import { getTimesheetInfoById } from '../../timeCharge/apollo/timesheetDetailQueries'

import styled from 'styled-components'
import COLORS from '../../common/Theme/colors'
import { Button, Skeleton, message } from 'antd'
import { ClockCircleOutlined, EditOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { downloadUploadedFile } from '../../common/fastapi/fetchers'
import { downloadFile } from '../../common/util'

const TimesheetContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;

  flex-direction: column;
  align-items: center;
  > * {
    max-width: ${MAX_CONTENT_WIDTH}px;
  }
`
const AttatchmentContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: ${COLORS.black_1};
  > * {
    max-width: ${MAX_CONTENT_WIDTH}px;
  }
`

const FooterHeight = 300

const TimesheetDetailContainer = () => {
  const navigate = useNavigate()
  const timesheetRef = useRef()
  const status = getTimesheetFormStatus()

  const { userId, permissions } = useOutletContext()
  const displayCache = false
  const { timesheetId } = useParams()
  const { data, loading } = useQuery(getTimesheetInfoById, {
    variables: {
      getTimesheetId: timesheetId
    },
    fetchPolicy: 'network-only',
    skip: displayCache
  })
  const timesheetDetail = _.get(data, ['GetTimesheet'])
  const initMeta = parseTimesheet(timesheetDetail)
  const initEntryId = _.get(timesheetDetail, ['history', 0, 'id'], null)
  const AuthToDeleteAndEditTimesheet = _.includes(permissions, 'MANAGE_USERS') || _.includes(permissions, 'MANAGE_DEPARTMENTS') || _.includes(permissions, 'MANAGE_TIMESHEET_NAME') || _.includes(permissions, 'MANAGE_TIMESHEET_NAME') || initMeta.userId === userId

  const {
    files,
    onDeleteFileByKey,
    dragDropProps,
    fileUploadLoading
  } = useFormAttatchments(_.get(initMeta, ['attachments'], undefined) || [], loading)
  const {
    onDeleteTimesheet
  } = useDeleteTimesheet()
  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal
  } = useConfirmModal(
    onDeleteTimesheet
  )
  const onOkDeleteConfirmModal = () => {
    onDeleteTimesheet(timesheetId)
  }

  return (
    <>
      {AuthToDeleteAndEditTimesheet
        ? (
          <ConfirmModal
            open={confirmModalMeta.showConfirmModal}
            title={confirmModalTitle}
            onOk={onOkDeleteConfirmModal}
            onCancel={onCloseModal}
          />)
        : null}
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title='타임시트 상세보기' />
            {AuthToDeleteAndEditTimesheet
              ? (
                <Button danger type='primary' onClick={() => onOpenModal([timesheetId], 'deleteTimesheet')}>
                  삭제하기
                </Button>
                )
              : null}
            <Spacer />
            <HeaderGroup>
              {_.includes(permissions, 'READ_TIMESHEET_HISTORY') && initEntryId
                ? (
                  <Button
                    type='primary'
                    ghost
                    icon={<ClockCircleOutlined />}
                    onClick={() => navigateToTimesheetEntry(navigate, timesheetId, initEntryId)}
                  >
                    수정연혁
                  </Button>)
                : null}
              {_.includes(permissions, 'WRITE_OTHER_TIMESHEET') || AuthToDeleteAndEditTimesheet
                ? (
                  <Button
                    type='primary'
                    style={{ display: 'flex', alignItems: 'center' }}
                    icon={<EditOutlined />}
                    onClick={() => navigateToTimesheetForm(navigate, 'editing', initMeta)}
                  >
                    수정하기
                  </Button>
                  )
                : null}
            </HeaderGroup>
          </HeaderContainer>
        )}
        content={(
          <TimesheetContainer>
            {loading
              ? <Skeleton />
              : status === null
                ? <ReadOnlyTimesheet
                    ref={timesheetRef}
                    readOnly
                    status={status}
                    initMeta={initMeta}
                  />
                : <Timesheet
                    ref={timesheetRef}
                    readOnly
                    status={status}
                    initMeta={initMeta}
                  />}
          </TimesheetContainer>
        )}
        footer={(
          <AttatchmentContainer>
            <Attatchments
              onDeleteFileByKey={onDeleteFileByKey}
              dragDropProps={dragDropProps}
              readOnly
              onDownloadAll={(key) => {
                downloadUploadedFile().then(res => {
                  downloadFile(_.get(res, ['data']), key)
                  message.destroy()
                }).catch(e => {
                  message.destroy()
                })
              }}
              files={files}
              fileUploadLoading={fileUploadLoading}
            />
          </AttatchmentContainer>
        )}
        footerHeight={FooterHeight}
      />
    </>
  )
}

export default TimesheetDetailContainer
