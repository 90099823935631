import _ from 'lodash'

export const navigateToCaseList = (navigate, caseListStatus, defaultExpandedRowKeys = [], _viewMode, searchString, showCaseTable = null) => {
  let viewMode = _viewMode
  const isNavigatingToAllTimesheets = window.location.pathname !== '/timesheet/all' && caseListStatus === 'all'
  const isNavigatingToFilteredTimesheets = window.location.pathname !== `/timesheet/${caseListStatus}` && caseListStatus !== 'all'
  if (isNavigatingToAllTimesheets) {
    viewMode = 'calendar'
  }
  if (isNavigatingToFilteredTimesheets) {
    viewMode = 'file'
  }

  navigate(`/timesheet/${caseListStatus}${searchString ? `?${searchString}` : ''}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      ...caseListStatus ? { caseListStatus } : {},
      defaultExpandedRowKeys,
      ...viewMode ? { viewMode } : {},
      ...showCaseTable === null ? {} : { showCaseTable }
    }
  })
}

export const navigateToCaseListTable = (navigate, caseListStatus, caseId, userId, showCaseTable = null) => {
  const params = new URLSearchParams()
  params.append('filter_caseIds', caseId)
  params.append('filter_userIds', userId)
  const searchString = params.toString()
  navigate(`/timesheet/${caseListStatus}${searchString ? `?${searchString}` : ''}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      ...caseListStatus ? { caseListStatus } : {},
      viewMode: 'table',
      ...showCaseTable === null ? {} : { showCaseTable }
    }
  })
}
