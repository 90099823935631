import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Button } from 'antd'

const Container = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`
const EditButton = styled(Button)`
  color: ${props => props.color};
  font-size: 18px;
  display: flex;
  justify-content:center;
  border: none;
`

export const EditSaveRowButton = ({ editable, record, onSave, onEdit, disabled }) => {
  return editable
    ? (
      <Container>
        <EditButton color={COLORS.green} onClick={() => onSave(record.key)}>
          <UnlockOutlined />
        </EditButton>
      </Container>
      )
    : (
      <Container>
        <EditButton disabled={disabled} color={disabled ? `${COLORS.black_45}` : `${COLORS.red}`} onClick={() => onEdit(record)}>
          <LockOutlined />
        </EditButton>
      </Container>)
}
