import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { navigateToHome } from '../../cacheAndNavigation/common'
const Container = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const YKLogo = ({ permissions = [], userId }) => {
  const navigate = useNavigate()

  return (
    <Container onClick={() => navigateToHome(navigate, permissions, userId)}>
      <img src='/logo.png' alt='fireSpot' width='140' />
    </Container>
  )
}
