import { useMutation } from '@apollo/client'
import { addContractPayments, editContractPayments, removeContractPayments } from '../../apollo/mutations'
import _ from 'lodash'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import { MESSAGES } from '../../../common/config'
import { useOutletContext } from 'react-router-dom'

export const useContractPaymentsAddEditRemove = (refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [addContractPaymentsMutFn] = useMutation(addContractPayments)
  const [removeContractPaymentMutFn] = useMutation(removeContractPayments)
  const [editContractPaymentMutFn] = useMutation(editContractPayments)

  const onCreateContractPayments = (data) => {
    addContractPaymentsMutFn({
      variables: {
        input: data
      }
    }).then(v => {
      if (_.get(v, ['data', 'AddContractPayments', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  const onRemoveContractPayment = (id) => {
    removeContractPaymentMutFn({
      variables: {
        periodicId: id
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveContractPayments', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  const onEditContractPayment = (data) => {
    editContractPaymentMutFn({
      variables: {
        periodicId: data.periodicId,
        input: data.input
      }
    }).then(v => {
      if (_.get(v, ['data', 'EditContractPayments', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  return {
    onCreateContractPayments,
    onRemoveContractPayment,
    onEditContractPayment
  }
}
