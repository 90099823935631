import { navigateToContractRecordDetail } from '../../../cacheAndNavigation/contractRecordDetail/navigation'
import { useNavigate } from 'react-router-dom'
import { navigateToContractRecord } from '../../../cacheAndNavigation/paymentRecord/navigation'
import { getUpdatedSearchParams } from '../../filter/callRecord/useRecordFilterSearchParams'
import _ from 'lodash'

export const usePaymentTable = (searchParams) => {
  const navigate = useNavigate()
  const onClickRow = (contractRecordMeta, viewMode) => {
    if (viewMode !== 'group') {
      navigateToContractRecordDetail(navigate, contractRecordMeta)
    } else {
      const projectTypeId = _.get(contractRecordMeta, 'projectType.id', []) || []
      const departmentId = _.get(contractRecordMeta, 'department.id') || []
      const departmentNewSearchParams = getUpdatedSearchParams('filter', 'projectTypeId', projectTypeId, searchParams)
      const projectTypenewSearchParams = getUpdatedSearchParams('filter', 'departmentId', departmentId, searchParams)

      if (viewMode === 'group' && departmentNewSearchParams !== null) {
        navigateToContractRecord(navigate, departmentNewSearchParams, viewMode = 'list')
      } else if (viewMode === 'group' && projectTypenewSearchParams !== null) {
        navigateToContractRecord(navigate, projectTypenewSearchParams, viewMode = 'list')
      }
    }
  }

  return {
    onClickRow
  }
}
