import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useCaseUserFilterSearchParams } from '../filter/params/useCaseUserFilterSearchParams'
import { getCaseContentViewMode } from '../../cacheAndNavigation//timesheet/cache'
import { navigateToCaseList } from '../../cacheAndNavigation//timesheet/navigation'
import _ from 'lodash'

export const useTimesheetHeader = () => {
  const { permissions, userGroup } = useOutletContext()
  const viewMode = getCaseContentViewMode() // for history
  const { caseStatus } = useParams()
  const navigate = useNavigate()
  const {
    meta: {
      filter: filterMeta
    },
    searchParams,
    getUpdatedSearchParams
  } = useCaseUserFilterSearchParams()

  const hiddenKeys = caseStatus === 'all' ? [] : ['calendar']
  const showCreateTimesheetButton = _.includes(permissions, 'WRITE_TIMESHEET') || _.includes(permissions, 'WRITE_OTHER_TIMESHEET')
  // 개선 필요 : userGroup.name이 없을 경우 로그아웃 처리하기
  const showViewMyCaseButton = (userGroup !== null && userGroup !== undefined) ? userGroup.name === '대표변호사' || userGroup.name === '파트너변호사' || userGroup.name === '관리자' : ''
  // functions
  const onChangeViewMode = v => {
    navigateToCaseList(navigate, caseStatus, undefined, v, searchParams.toString())
  }
  const onSelectFilter = (key, value) => {
    // user or case
    const newSearchParams = getUpdatedSearchParams('filter', key, value, searchParams)
    navigateToCaseList(navigate, caseStatus, undefined, viewMode, newSearchParams.toString())
  }

  return {
    viewMode,
    filterMeta,
    hiddenKeys,
    showCreateTimesheetButton,
    showViewMyCaseButton,
    onSelectFilter,
    onChangeViewMode
  }
}
