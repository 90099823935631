import { useCustomTable } from '../../common/useCustomTable'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { Tag } from 'antd'

export const useUserAllocatorByDepartment = (allDepartments = [], preAllocationLoading, departmentsToAllocate = [], postAllocationLoading, initDelegatedUsers = []) => {
  const getLeadersFromDepartmentIds = (departmentIds) => {
    return _.flatMap(_.filter(allDepartments, v => _.includes(departmentIds, v.id)), 'leaders')
  }
  const parseData = (data, isPreAllocation) => _.map(data,
    v => {
      const leaders = _.get(v, ['leaders'], [])
      const leaderAssignmentNecessary = _.isEmpty(leaders) // 부서장 지정 필요
      let allocatePermissionNecessary = false // 부서 목록에서 부서장이 위임권한이 없을 경우 '위임 권한 부여 필요' 표시
      _.forEach(leaders, leader => {
        allocatePermissionNecessary = allocatePermissionNecessary || !_.includes(_.get(leader, ['permissions'], []), 'ALLOCATE_OR_DELEGATE')
      })
      const isLeadersDelegated = _.difference(_.map(leaders, 'id'), _.map(meta.delegatedUsers, 'id')).length === 0
      return {
        ...v,
        key: v.id,
        leaderAssignmentNecessary,
        allocatePermissionNecessary,
        disabled: (isPreAllocation && isLeadersDelegated) || leaderAssignmentNecessary || allocatePermissionNecessary
      }
    })
  // states
  const [meta, setMeta] = useState({
    initDelegatedUsers: [],
    delegatedUsers: []
  })
  useEffect(() => {
    if (!postAllocationLoading) {
      setMeta(meta => ({
        ...meta,
        initDelegatedUsers,
        delegatedUsers: initDelegatedUsers
      }))
    }
  }, [postAllocationLoading])

  // variables
  // -pre allocation
  const preAllocationTableProps = useCustomTable()
  const {
    selectedRowKeys: preAllocationSelectedKeys
  } = preAllocationTableProps

  const preAllocationData = parseData(allDepartments, true)
  const preDepartmentColumns = [
    {
      dataIndex: 'name',
      title: '부서 이름',
      render: (departmentName, record) => {
        return (
          <div style={{ gap: 10, display: 'flex' }}>
            {departmentName}
            {_.get(record, ['leaderAssignmentNecessary'])
              ? <Tag color='volcano'>부서장 지정 필요</Tag>
              : _.get(record, ['allocatePermissionNecessary'])
                ? <Tag color='geekblue'>부서장 위임 권한 부여 필요</Tag>
                : null}
          </div>
        )
      }
    }
  ]

  // functions
  const onDelegateUsersByDepartments = () => {
    setMeta(meta => ({
      ...meta,
      delegatedUsers: _.uniqBy(getLeadersFromDepartmentIds(preAllocationSelectedKeys).concat(meta.delegatedUsers), v => v.id)
    }))
  }
  const onAbdicateUser = (userId) => {
    setMeta(meta => ({
      ...meta,
      delegatedUsers: _.filter(meta.delegatedUsers, v => v.id !== userId)
    }))
  }
  const userIdsToDelegate = _.difference(_.map(meta.delegatedUsers, 'id'), _.map(initDelegatedUsers, 'id'))
  const userIdsToAbdicate = _.difference(_.map(initDelegatedUsers, 'id'), _.map(meta.delegatedUsers, 'id'))

  return {
    userIdsToDelegate,
    userIdsToAbdicate,
    preAllocationTableProps: {
      ...preAllocationTableProps,
      data: preAllocationData,
      pageLimitHidden: true,
      tableActionHidden: true,
      columns: preDepartmentColumns,
      style: {
        flex: 1
      },
      scroll: {
        y: 400
      },
      loading: preAllocationLoading,
      total: preAllocationData.length,
      unit: '개',
      pagination: false
    },
    postDelegationUsers: meta.delegatedUsers,
    onAbdicateUser,
    onDelegateUsersByDepartments
  }
}
