import { useMutation } from '@apollo/client'
import { removeTimesheet } from '../../../apollo/mutations'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../../common/config'
import _ from 'lodash'

export const useDeleteTimesheet = (onFinish = () => {}, onError = () => {}) => {
  const [removeTimesheetFn] = useMutation(removeTimesheet)
  const { messageApi } = useOutletContext()
  const navigate = useNavigate()
  const onDeleteTimesheet = (removeTimesheetId) => {
    removeTimesheetFn({
      variables: {
        removeTimesheetId
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveTimesheet', 'ok'], false)) {
        navigate(-1)
        onFinish()
      } else {
        messageApi.open(MESSAGES.removeTimesheetError)
        onError()
      }
    }).catch(() => {
      messageApi.open(MESSAGES.removeTimesheetError)
      onError()
    })
  }

  return {
    onDeleteTimesheet
  }
}
