import { Button, Segmented } from 'antd'
import React from 'react'
import { dayGroups } from '../../../common/config'
import { RowGapWrapper } from '../../../common/Theme/style'
import styled from 'styled-components'
import { ResponsiveBar } from '@nivo/bar'
import { useGraphMeta } from '../../hooks/callStatistics/useGraphMeta'
import { CloseOutlined } from '@ant-design/icons'

const Container = styled.div`
  min-height: 450px;
  height: 100%;
  padding: 0 20px;
  border: 1px solid #efefef;
  border-radius: 10px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledButton = styled(Button)`
  border: none;
`

export const StatTable = ({ meta, onChangeMeta, handleStatViewer, callRecordsData }) => {
  const {
    barGraphProps
  } = useGraphMeta(
    callRecordsData,
    meta
  )
  const data = [
    {
      'country': 'AD',
      '온라인': 111,
      '사건1Color': 'hsl(128, 70%, 50%)',
      '방문&선임': 51,
      '사건2Color': 'hsl(149, 70%, 50%)',
      'sandwich': 117,
      'sandwichColor': 'hsl(210, 70%, 50%)',
      'kebab': 93,
      'kebabColor': 'hsl(261, 70%, 50%)',
      'fries': 138,
      'friesColor': 'hsl(322, 70%, 50%)',
      'donut': 144,
      'donutColor': 'hsl(133, 70%, 50%)'
    },
    {
      'country': 'AE',
      '온라인': 121,
      '사건1Color': 'hsl(225, 70%, 50%)',
      '방문&선임': 92,
      '사건2Color': 'hsl(54, 70%, 50%)',
      'sandwich': 142,
      'sandwichColor': 'hsl(117, 70%, 50%)',
      'kebab': 72,
      'kebabColor': 'hsl(164, 70%, 50%)',
      'fries': 92,
      'friesColor': 'hsl(184, 70%, 50%)',
      'donut': 89,
      'donutColor': 'hsl(114, 70%, 50%)'
    },
    {
      'country': 'AF',
      '온라인': 165,
      '사건1Color': 'hsl(275, 70%, 50%)',
      '방문&선임': 198,
      '사건2Color': 'hsl(87, 70%, 50%)',
      'sandwich': 67,
      'sandwichColor': 'hsl(280, 70%, 50%)',
      'kebab': 197,
      'kebabColor': 'hsl(276, 70%, 50%)',
      'fries': 192,
      'friesColor': 'hsl(14, 70%, 50%)',
      'donut': 171,
      'donutColor': 'hsl(53, 70%, 50%)'
    },
    {
      'country': 'AG',
      '온라인': 151,
      '사건1Color': 'hsl(11, 70%, 50%)',
      '방문&선임': 192,
      '사건2Color': 'hsl(156, 70%, 50%)',
      'sandwich': 86,
      'sandwichColor': 'hsl(260, 70%, 50%)',
      'kebab': 81,
      'kebabColor': 'hsl(4, 70%, 50%)',
      'fries': 55,
      'friesColor': 'hsl(113, 70%, 50%)',
      'donut': 101,
      'donutColor': 'hsl(23, 70%, 50%)'
    },
    {
      'country': 'AI',
      '온라인': 107,
      '사건1Color': 'hsl(264, 70%, 50%)',
      '방문&선임': 108,
      '사건2Color': 'hsl(304, 70%, 50%)',
      'sandwich': 127,
      'sandwichColor': 'hsl(148, 70%, 50%)',
      'kebab': 159,
      'kebabColor': 'hsl(350, 70%, 50%)',
      'fries': 164,
      'friesColor': 'hsl(325, 70%, 50%)',
      'donut': 154,
      'donutColor': 'hsl(120, 70%, 50%)'
    },
    {
      'country': 'AL',
      '온라인': 164,
      '사건1Color': 'hsl(13, 70%, 50%)',
      '방문&선임': 140,
      '사건2Color': 'hsl(128, 70%, 50%)',
      'sandwich': 116,
      'sandwichColor': 'hsl(241, 70%, 50%)',
      'kebab': 188,
      'kebabColor': 'hsl(108, 70%, 50%)',
      'fries': 96,
      'friesColor': 'hsl(12, 70%, 50%)',
      'donut': 7,
      'donutColor': 'hsl(213, 70%, 50%)'
    },
    {
      'country': 'AM',
      '온라인': 72,
      '사건1Color': 'hsl(180, 70%, 50%)',
      '방문&선임': 199,
      '사건2Color': 'hsl(325, 70%, 50%)',
      'sandwich': 197,
      'sandwichColor': 'hsl(23, 70%, 50%)',
      'kebab': 94,
      'kebabColor': 'hsl(115, 70%, 50%)',
      'fries': 34,
      'friesColor': 'hsl(60, 70%, 50%)',
      'donut': 60,
      'donutColor': 'hsl(26, 70%, 50%)'
    }
  ]
  return (
    <Container>
      <Header>
        <RowGapWrapper style={{ alignItems: 'center', gap: '15px' }}>
          <h3>통계</h3>
          <Segmented
            value={meta.group.time}
            onChange={e => onChangeMeta('group', 'time', e)}
            style={{ width: 'fit-content' }}
            options={dayGroups}
          />
        </RowGapWrapper>
        <StyledButton onClick={() => handleStatViewer()}>
          <CloseOutlined />
        </StyledButton>
      </Header>
      <ResponsiveBar
        height={300}
        data={data}
        {...barGraphProps}
      />
    </Container>
  )
}
