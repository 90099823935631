import { gql } from '@apollo/client'

// 진행사건 페이지 쿼리
export const getUserListForTimesheet = gql`
  query GetUserList($sortBy: SortBy!, $filter: UserListFilter, $offset: Int, $limit: Int) {
    GetUserList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      data {
        id
        name
        departments {
          id
          name
        }
        group {
          name
        }
      }
    }
  }
`

export const getCaseList = gql`
  query GetProjectList($sortBy: SortBy!, $filter: ProjectListFilter, $offset: Int, $limit: Int) {
    GetProjectList(sortBy: $sortBy, offset: $offset, filter: $filter, limit: $limit) {
      totalCount
      ... on GetProjectWithTimeDeltaListResponse {
        data {
          id
          name
          starred
          status
          category
          users {
            departments {
              id
              name
            }
            group {
              name
            }
            status
            id
            name
          }
        }
      }
      ... on GetProjectListResponse {
        data {
          id
          name
          starred
          status
          category
          users {
            departments {
              id
              name
            }
            group {
              name
            }
            status
            id
            name
          }
        }
      }
    }
  }
`
export const getCaseListForDeltatime = gql`
   query GetProjectList($sortBy: SortBy!, $filter: ProjectListFilter, $offset: Int, $limit: Int) {
    GetProjectList(sortBy: $sortBy, offset: $offset, filter: $filter, limit: $limit) {
      totalCount
      totalMinutes
      ... on GetProjectWithTimeDeltaListResponse {
        # totalDeltaMinutes
        data {      
          users {
            id
            totalDeltaMinutes
          }
        }
      }
    }
  }
`

export const getTimesheetList = gql`
  query GetTimesheetList($sortBy: SortBy!, $filter: TimesheetListFilter, $offset: Int, $limit: Int) {
    GetTimesheetList(sortBy: $sortBy, filter: $filter, offset: $offset, limit: $limit) {
      timesheets {
        id
        minutes
        title
        date
        project {
          name
          type {
            color
          }
        }
      }
    }
  }
`

export const getDepartmentList = gql`
query GetDepartmentList($filter: DepartmentListFilter) {
  GetDepartmentList(filter: $filter) {
    id,
    name,
  }
}
`
