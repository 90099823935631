import { gql } from '@apollo/client'

export const getDepartmentList = gql`
  query GetDepartmentList($filter: DepartmentListFilter) {
    GetDepartmentList(filter: $filter) {
      id,
      name,
    }
  }
`
