import { useNavigate, useOutletContext } from 'react-router-dom'
import { HeaderContentFooter } from '../../../../common/components/HeaderContentFooter'
import { useDepartmentList } from '../../../../timeCharge/hooks/apollo/useDepartmentList'
import DepartmentTypeAdder from '../../../../timeCharge/components/settings/DepartmentTypeAdder'
import { HeaderContainer, HeaderTitle, Spacer } from '../../../../common/components/PageHeaderStyles'
import { navigateToSettingDetail } from '../../../../timeCharge/cacheAndNavigation/setting/navigation'
import { useAddUpdateDepartment } from '../../../../timeCharge/hooks/apollo/mutations/useAddUpdateDepartment'

import styled from 'styled-components'
import _ from 'lodash'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const Container = styled.div`
  width: 100%;
`

const ManageDepartmentContainer = () => {
  const navigate = useNavigate()
  const { permissions } = useOutletContext()
  const showAddDepartmentButton = _.includes(permissions, 'MANAGE_DEPARTMENTS')
  const onNavToAddDepartment = () => {
    navigateToSettingDetail(navigate, 'creating', 'addDepartment', 'addDepartment')
  }
  const {
    departments,
    loading
  } = useDepartmentList()
  const {
    onUpdateDepartment
  } = useAddUpdateDepartment()
  return (
    <HeaderContentFooter
      header={(
        <HeaderContainer style={{ justifyContent: 'right' }}>
          <HeaderTitle title='부서 관리' />
          <Spacer $flex={5} />
          {showAddDepartmentButton
            ? (
              <Button style={{ display: 'flex', alignItems: 'center' }} type='primary' onClick={onNavToAddDepartment}>
                <PlusOutlined />
                부서 추가하기
              </Button>)
            : null}
        </HeaderContainer>
      )}
      content={(
        <Container>
          {loading
            ? null
            : <DepartmentTypeAdder
                initDataSource={_.map(departments, ({ ...v }) => ({ key: v.id, ...v }))}
                onUpdateDepartment={onUpdateDepartment}
              />}
        </Container>
      )}
    />
  )
}

export default ManageDepartmentContainer
