import App from './App'
import LoginScreen from './LoginScreen'
import ApolloWrapper from '../../timeCharge/apollo/ApolloWrapper'

import './common.css'
import { useLogin } from '../../timeCharge/hooks/login/useLogin'

const AppLayout = () => {
  // hooks
  const {
    loggedIn,
    token,
    onLogin,
    onLogout,
    onChangePassword,
    logout,
    loading
  } = useLogin()

  if (!(token) || token === 'null') {
    return (
      <>
        {/* <Button onClick={skipLogin}>(개발용)건너뛰기</Button> */}
        <LoginScreen
          loggedIn={loggedIn}
          onLogin={onLogin}
          loading={loading}
        />
      </>
    )
  }

  return (
    <ApolloWrapper>
      <App loggedIn={loggedIn} onLogout={onLogout} logout={logout} onChangePassword={onChangePassword} />
    </ApolloWrapper>
  )
}

export default AppLayout
