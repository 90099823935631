import _ from 'lodash'

export const navigateToSettingDetail = (
  navigate, formstatus = 'reading',
  settingCategory, settingDetail,
  id, formMeta = {}
) => {
  if (settingDetail === 'employeeDelegate') {
    // 권한 설정 페이지
    navigate(
      `/setting/${settingCategory}/employeeDelegate/${id}`,
      {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {}),
          employeeAuthorityStatus: formstatus,
          userMeta: JSON.stringify(formMeta)
        }
      }
    )
  } else if (settingDetail === 'resetPassword') {
    // 비밀번호 재설정 페이지
    navigate(
      `/setting/${settingCategory}/resetPassword/${id}`,
      {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {}),
          employeeAuthorityStatus: formstatus,
          userMeta: JSON.stringify(formMeta)
        }
      }
    )
  } else if (settingDetail === 'editDepartment') {
    // 부서 수정 페이지
    navigate(
      `/setting/${settingCategory}/${id}`,
      {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {}),
          departmentFormStatus: formstatus,
          departmentMeta: JSON.stringify(formMeta)
        }
      }
    )
  } else if (settingDetail === 'addDepartment') {
    // 부서 추가 페이지
    navigate(
      `/setting/${settingCategory}`,
      {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {}),
          departmentFormStatus: formstatus,
          departmentMeta: JSON.stringify(formMeta)
        }
      }
    )
  } else if (settingDetail === 'assignDepartment') {
    // 직원 부서 관리 페이지
    navigate(
      `/setting/${settingCategory}/assignDepartment/${id}`,
      {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {}),
          userDepartmentFormStatus: formstatus,
          userDepartmentMeta: JSON.stringify(formMeta)
        }
      }
    )
  } else if (settingDetail === 'assignRank') {
    // 직원 직급 관리 페이지
    navigate(
      `/setting/${settingCategory}/assignRank/${id}`,
      {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {}),
          userRankFormStatus: formstatus,
          userRankMeta: JSON.stringify(formMeta)
        }
      }
    )
  } else if (settingDetail === 'addProjectType') {
    // 사건분류 관리 페이지
    navigate(
      `/setting/${settingCategory}`,
      {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {}),
          projectTypeFormStatus: formstatus,
          projectTypeMeta: JSON.stringify(formMeta)
        }
      }
    )
  } else if (settingDetail === 'editProjectType') {
    // 사건분류 관리 페이지
    navigate(
      `/setting/${settingCategory}/${id}`,
      {
        state: {
          ..._.get(window, ['history', 'state', 'usr'], {}),
          projectTypeFormStatus: formstatus,
          projectTypeMeta: JSON.stringify(formMeta)
        }
      }
    )
  }
}
