import _ from 'lodash'
import { getContractRecordList } from '../../apollo/callRecordQueries'
import { useQuery } from '@apollo/client'
import { useCustomTable } from '../../../timeCharge/hooks/common/useCustomTable'
import { useEffect, useRef } from 'react'
import { parseToInt } from '../../components/common/CommonFunction'

export const useContractRecordList = (
  ascending = false,
  key = null,
  searchQuery,
  from,
  to,
  departmentId = null,
  deleted = null,
  caseNumber = null,
  projectTypeId = null,
  assignedLawyerId = null,
  groupModeContract = null,
  outstanding,
  outstandingFrom = null,
  outstandingTo = null,
  closed = null
) => {
  const preAllocationTableProps = useCustomTable()
  const {
    page,
    limit,
    onChangePage
  } = preAllocationTableProps
  const filter = {
    searchQuery,
    closed,
    ...from && to
      ? {
          dateRange: {
            from,
            to
          }
        }
      : {},
    assignedLawyerId,
    departmentId,
    deleted,
    caseNumber,
    projectTypeId,
    outstanding,
    dueDateRange: {
      from: outstandingFrom,
      to: outstandingTo
    }
  }
  // page handling
  const prevFilter = useRef(filter)
  useEffect(() => {
    if (!_.isEqual(prevFilter.current, filter)) {
      onChangePage(1)
      prevFilter.current = filter
    }
  }, [filter])

  const variables = {
    sortBy: {
      ascending,
      key
    },
    filter: {
      searchQuery,
      closed,
      ...from && to
        ? {
            dateRange: {
              from,
              to
            }
          }
        : {},
      assignedLawyerId,
      departmentId,
      deleted,
      caseNumber,
      projectTypeId,
      outstanding,
      dueDateRange: {
        from: outstandingFrom,
        to: outstandingTo
      }
    },
    groupBy: groupModeContract,
    offset: (page - 1) * limit,
    limit
  }
  const { data, loading, refetch } = useQuery(getContractRecordList, {
    variables,
    fetchPolicy: 'no-cache'
  })
  const format = (groupModeContract === 'DEPARTMENT' || groupModeContract === 'TYPE') ? 'groups' : 'records'
  const contractRecordsData = _.get(data, ['GetContractRecordList', format], [])
  const parseContractRecords = (contractRecords) => {
    return _.map(contractRecords, contractRecord => {
      const assignedLawyerId = _.get(contractRecord, 'assignedLawyers', [])?.map(lawyer => lawyer.id) || []

      return {
        ...contractRecord,
        assignedLawyerId
      }
    })
  }
  const updatedData = _.map(parseContractRecords(contractRecordsData), (item) => {
    if (item.paymentRecords && _.isArray(item.paymentRecords)) {
      item.paymentRecords = _.map(item.paymentRecords, (record) => {
        if (record.dueAmount) {
          record.dueAmount = parseInt(record.dueAmount, 10)
        }
        if (record.deposits && _.isArray(record.deposits)) {
          record.deposits = _.map(record.deposits, (deposit) => {
            if (deposit.receivedAmount) {
              deposit.receivedAmount = parseInt(deposit.receivedAmount, 10)
            }
            return deposit
          })
        }
        return record
      })
    }
    return item
  })
  const parseAndUpdateContractRecords = (contractRecords) => {
    return _.map(contractRecords, (contractRecord) => {
      // Extract assignedLawyerId
      const assignedLawyerId = _.get(contractRecord, 'assignedLawyers', []).map(lawyer => lawyer.id) || [];
      // Process paymentRecords
      if (contractRecord.paymentRecords && _.isArray(contractRecord.paymentRecords)) {
        contractRecord.paymentRecords = _.map(contractRecord.paymentRecords, (record) => {
          if (record.dueAmount) {
            record.dueAmount = parseInt(record.dueAmount, 10)
          }
          // Process deposits
          if (record.deposits && _.isArray(record.deposits)) {
            record.deposits = _.map(record.deposits, (deposit) => {
              if (deposit.receivedAmount) {
                deposit.receivedAmount = parseInt(deposit.receivedAmount, 10)
              }
              return deposit
            })
          }
          return record
        })
      }
      return {
        ...contractRecord,
        assignedLawyerId
      }
    })
  }
  return {
    loading,
    variables,
    contractRecordsData: parseAndUpdateContractRecords(contractRecordsData),
    totalCount: _.get(data, ['GetContractRecordList', 'totalCount'], 0),
    totalDueAmount: parseToInt(_.get(data, ['GetContractRecordList', 'totalDueAmount'], 0)) - parseToInt(_.get(data, ['GetContractRecordList', 'totalReceived'], 0)),
    totalReceived: parseToInt(_.get(data, ['GetContractRecordList', 'totalReceived'], 0)),
    refetch,
    ...preAllocationTableProps
  }
}
