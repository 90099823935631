import UserIcon from '../../../../../common/components/UserIcon'
import LeaveTag from '../../../../components/common/Tags/LeaveTag'
import { TimeEditModal } from '../../../../components/timesheet/TimeEditModal'
import { parseDateObj, parseMinutesToHourMin } from '../../../../../common/util'
import RemovedFromCaseTag from '../../../../components/common/Tags/RemovedFromCaseTag'
import { MinutesDifferenceIndicatorTag } from '../../../../components/timesheet/MinutesDifferenceIndicatorTag'
import { starIconWidth, categoryWidth, updatedWidth, totalMinutesWidth, minutesDifferenceWidth, minutesAfterEditWidth, iconDisplayWidth } from './columnWidths'

import { Typography } from 'antd'
import _ from 'lodash'

const caseTableCommonProps = { wordWrap: 'break-word', wordBreak: 'break-word', fontSize: 12, paddingLeft: '8px' }

// 사건 행 열었을때 테이블
export const getCaseUserExpandedTableColumns = ({
  onClickUserName,
  hoveredKey,
  isPartner,
  isDatetimerangeFilter,
  caseId,
  caseNumberWidth,
  hideTimesheetDeltaEditButton,
  permissions,
  onAddTimesheetDelta
}) => [
  {
    width: starIconWidth
  },
  {
    dataIndex: 'name',
    key: 'name',
    width: caseNumberWidth,
    render: (name, record) => {
      return (
        <Typography.Text style={{ fontWeight: '400', display: 'flex', gap: 5, ...caseTableCommonProps }} onClick={() => onClickUserName(record)}>
          <div style={{ width: 20 }} />
          <UserIcon group={record.group.name} name={name} />
          {record.status === 'DELETED' ? <LeaveTag /> : null}
          {record.relation === 'DEALLOCATED' ? <RemovedFromCaseTag /> : null}
        </Typography.Text>
      )
    }
  },
  {
    width: categoryWidth
  },
  {
    dataIndex: 'updated',
    key: 'updated',
    width: updatedWidth,
    render: (v, record) => {
      const timesheets = _.get(record, ['timesheets'], 0)
      const timesheetUpdated = _.map(timesheets, ({ history }) => {
        return history[0].created
      })
      const currentUpdatedDate = _.max(timesheetUpdated)
      return (
        <Typography.Text ellipsis>
          {(currentUpdatedDate !== undefined) ? parseDateObj(currentUpdatedDate, 'YYYY년 MM월 DD일') : ''}
        </Typography.Text>
      )
    }
  },
  {
    dataIndex: 'minutes',
    key: 'minutes',
    render: (totalMinutes) => {
      return <div>{parseMinutesToHourMin(totalMinutes)}</div>
    },
    width: totalMinutesWidth
  },
  ...!isDatetimerangeFilter
    ? [
        {
          hidden: !isPartner,
          width: minutesDifferenceWidth,
          render: (_a, record) => {
            const minutes = _.get(record, ['minutes'], 0)
            const minutesAfterEdit = _.get(record, ['minutesAfterEdit'], 0)
            const isHidden = hoveredKey !== record.key && (minutesAfterEdit - minutes === 0)
            if (hideTimesheetDeltaEditButton) return null
            return (
              <TimeEditModal
                totalMinutes={minutes}
                caseId={caseId}
                userId={record.id}
                onFinishAddingTimeDelta={onAddTimesheetDelta}
              >
                {isHidden
                  ? <div style={{ width: iconDisplayWidth }} />
                  : _.includes(permissions, 'WRITE_TIMESHEET_TIMEDELTA')
                    ? (
                      <MinutesDifferenceIndicatorTag
                        minutes={minutesAfterEdit - minutes}
                      />)
                    : null}
              </TimeEditModal>
            )
          }
        }
      ]
    : [],
  ...!isDatetimerangeFilter
    ? [
        {
          hidden: !isPartner,
          dataIndex: 'minutesAfterEdit',
          key: 'minutesAfterEdit',
          width: minutesAfterEditWidth,
          render: (totalMinutes) => {
            return <div style={{ paddingLeft: '5px' }}>{parseMinutesToHourMin(totalMinutes)}</div>
          }
        }
      ]
    : []
]
