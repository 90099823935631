import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { VerticalAlignTopOutlined } from '@ant-design/icons'
import { Button } from 'antd'

const Container = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`
const EditButton = styled(Button)`
  color: ${props => props.color};
  font-size: 18px;
  display: flex;
  justify-content:center;
  border: none;
`

export const SaveNewDataButton = ({ record, onSave }) => {
  return (
    <Container>
      <EditButton color={COLORS.primaryColor} onClick={() => onSave(record.key)}>
        <VerticalAlignTopOutlined />
      </EditButton>
    </Container>
  )
}
