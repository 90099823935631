import { Tag } from 'antd'

// 콜 기록 부서 태그
const DepartmentTag = ({ departments }) => {
  return (
    <>
      {departments.map(({ id, name }) => {
        return (
          <Tag key={id}>
            {name}
          </Tag>
        )
      })}
    </>
  )
}

export default DepartmentTag
