import { useMutation } from '@apollo/client'
import { addComment, closeContractRecord, editComment, removeComment, uncloseContractRecord } from '../../apollo/mutations'
import _ from 'lodash'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import { MESSAGES } from '../../../common/config'
import { useOutletContext } from 'react-router-dom'

export const useContractRecordClose = (refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [closeContractRecordMutFn] = useMutation(closeContractRecord)
  const [uncloseContractRecordMutFn] = useMutation(uncloseContractRecord)

  const onCloseContractRecord = (id) => {
    closeContractRecordMutFn({
      variables: {
        closeContractRecordId: id
      }
    }).then(v => {
      if (_.get(v, ['data', 'CloseContractRecord', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.closeContractError)
      handleError()
    })
  }

  const onUncloseContractRecord = (id) => {
    uncloseContractRecordMutFn({
      variables: {
        uncloseContractRecordId: id
      }
    }).then(v => {
      if (_.get(v, ['data', 'UncloseContractRecord', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.openContractError)
      handleError()
    })
  }

  return {
    onCloseContractRecord,
    onUncloseContractRecord
  }
}
