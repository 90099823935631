import { useState } from 'react'

export const usePagination = (fetch) => {
  const [meta, setMeta] = useState({
    page: 1,
    limit: 20
  })
  const onChangePage = (v) => {
    setMeta(meta => ({
      ...meta,
      page: v
    }))
  }
  const onChangePageLimit = (v) => {
    setMeta(meta => ({
      ...meta,
      page: 1,
      limit: v
    }))
  }
  return {
    ...meta,
    // totalPages: Math.ceil(nTotalItems / meta.limit),
    onChangePage,
    onChangePageLimit
  }
}
