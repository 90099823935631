import { useNavigate } from 'react-router-dom'
import { getUpdatedSearchParams } from '../../filter/callRecord/useRecordFilterSearchParams'
import { navigateToOverdueRecord } from '../../../cacheAndNavigation/overdueRecord/navigation'
import { navigateToContractRecordDetail } from '../../../cacheAndNavigation/contractRecordDetail/navigation'
import _ from 'lodash'

export const useOverdueTable = (searchParams) => {
  const navigate = useNavigate()

  const onClickRow = (contractRecordMeta, viewMode) => {
    if (viewMode !== 'group') {
      navigateToContractRecordDetail(navigate, contractRecordMeta)
    } else {
      const projectTypeId = _.get(contractRecordMeta, 'projectType.id', []) || []
      const departmentId = _.get(contractRecordMeta, 'department.id') || []
      const departmentNewSearchParams = getUpdatedSearchParams('filter', 'projectTypeId', projectTypeId, searchParams)
      const projectTypenewSearchParams = getUpdatedSearchParams('filter', 'departmentId', departmentId, searchParams)

      if (viewMode === 'group' && departmentNewSearchParams !== null) {
        navigateToOverdueRecord(navigate, departmentNewSearchParams, viewMode = 'list')
      } else if (viewMode === 'group' && projectTypenewSearchParams !== null) {
        navigateToOverdueRecord(navigate, projectTypenewSearchParams, viewMode = 'list')
      }
    }
  }

  return {
    onClickRow
  }
}
