import { Modal, Result, Button } from 'antd'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const ConfirmModal = ({ open, title, subTitle, onOk, onCancel, status = 'warning' }) => {
  return (
    <Modal style={{ zIndex: 1000 }} open={open} footer={null} onCancel={onCancel}>
      <Container>
        <Result
          status={status}
          title={title}
          subTitle={subTitle}
          extra={[
            <Button type='primary' key='1' onClick={onOk}>
              확인
            </Button>,
            <Button key='2' onClick={onCancel}>취소</Button>
          ]}
        />
      </Container>
    </Modal>
  )
}
