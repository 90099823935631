import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { RowContainer } from '../../../common/Theme/style'
import { navigateToSettingDetail } from '../../../timeCharge/cacheAndNavigation/setting/navigation'
import { useSaveDeleteDepartmentForm } from './departments/useSaveDeleteDepartmentForm'

import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { Button, Popover } from 'antd'
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import _ from 'lodash'

const StyledPopover = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
`
const FlatButton = styled(Button)`
  &.ant-btn {
    font-size: 18px;
    border: none;
    display: flex;
    align-items: center;
    width: 10px;
    justify-content: center;
    box-shadow: none;
    background: none;
  }
`

const DepartmentPopOverContent = ({ onEditDepartment, onDeleteDepartment }) => {
  const [open, setOpen] = useState(null)
  return (
    <StyledPopover>
      <RowContainer style={{ gap: 10 }} onClick={onEditDepartment}>
        <EditOutlined style={{ color: `${COLORS.orange}` }} />
        <div>부서정보 수정</div>
      </RowContainer>
      <RowContainer style={{ gap: 10 }} onClick={(e) => setOpen(true)}>
        <DeleteOutlined style={{ color: `${COLORS.red}` }} />
        <div>부서 삭제</div>
      </RowContainer>
      <ConfirmModal
        title='부서를 삭제하시겠습니까?'
        open={open}
        onOk={() => {
          onDeleteDepartment()
          setOpen(false)
        }}
        onCancel={() => {
          setOpen(false)
        }}
      />
    </StyledPopover>
  )
}

export const useDepartmentColumnsOfSetting = (refetch = () => {}) => {
  const navigate = useNavigate()
  const {
    onDeleteDepartment
  } = useSaveDeleteDepartmentForm(
    null,
    null,
    refetch)

  const MenuColumnWidth = 80
  const departmentTableColumns = [
    {
      key: 'sort',
      width: MenuColumnWidth,
      editable: false
    },
    {
      title: '부서 이름',
      dataIndex: 'name',
      key: 'DEPARTMENT_NAME',
      width: 70
    },
    {
      title: '부서장',
      dataIndex: 'leaders',
      key: 'DEPARTMENT_LEADER',
      width: 180,
      render: (leaders) => {
        return (
          <div>
            {_.map(leaders, 'name').join(',')}
          </div>
        )
      }
    },
    {
      title: '총 인원',
      dataIndex: 'members',
      width: 80,
      render: (_v, record) => {
        const total = record.leaders.length + record.members.length
        return (
          <div>
            {total}
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: 'departmentMenu',
      key: 'departmentMenu',
      width: 20,
      render: (_v, record) => {
        return (
          <>
            <Popover
              onClick={(e) => {
                e.stopPropagation()
              }}
              placement='right'
              content={
                <DepartmentPopOverContent
                  onEditDepartment={() => {
                    navigateToSettingDetail(
                      navigate,
                      'editing',
                      'editDepartment',
                      'editDepartment',
                      record.id,
                      record
                    )
                  }}
                  onDeleteDepartment={() => onDeleteDepartment(record.id)}
                />
              }
            >
              <FlatButton>
                <MoreOutlined />
              </FlatButton>
            </Popover>
          </>
        )
      }
    }
  ]
  return {
    departmentTableColumns
  }
}
