import CustomCalendar from '../../common/CustomCalendar'
import { useCalendar } from '../../../hooks/timesheet/useCalendar'
import styled from 'styled-components'
import { MAX_CONTENT_WIDTH } from '../../../../common/config'

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  min-width: 800px;
  /* max-width: ${MAX_CONTENT_WIDTH}px; */
  /* max-width: 1600px; */
  
`

export const TimesheetCalendar = () => {
  const calendarProps = useCalendar()
  return (
    <Container>
      <CustomCalendar {...calendarProps} />
    </Container>
  )
}
